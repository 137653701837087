import { useSwellWeb3 } from '@/swell-web3/core'
import { useSwellDaoApi } from './context'
import { useWeb3Call } from '@/hooks/useWeb3Call'
import useSWRImmutable from 'swr/immutable'
import { merkleDropClaimAndLockGasEstimate } from '@/constants/gasEstimates'
import { BigNumber, ContractReceipt, ContractTransaction, ethers } from 'ethers'
import { formatBytes32String } from 'ethers/lib/utils'

export function useSwellDaoToken() {
  return useSwellDaoApi().daoToken
}
export function useRestakedSwellDaoToken() {
  return useSwellDaoApi().restakedDaoToken
}
export function useRecentDaoTokenClaim() {
  const api = useSwellDaoApi()
  const {
    amountRestakedDaoToken,
    amountWithdrawnDaoToken,
    clearRecentDaoTokenClaim,
    onClaimDaoToken,
  } = api
  return {
    amountRestakedDaoToken,
    amountWithdrawnDaoToken,
    clearRecentDaoTokenClaim,
    onClaimDaoToken,
  }
}

export function useSwellDaoAirdrop() {
  const { account } = useSwellWeb3()
  const api = useSwellDaoApi()
  return useSWRImmutable(
    account ? ['swell-user-airdrop', account] : null,
    api.read.airdrop
  )
}

export function useSwellDaoBalances() {
  const { account } = useSwellWeb3()
  const api = useSwellDaoApi()
  return useSWRImmutable(account ? ['balances', account] : null, () => {
    return api.read.balances()
  })
}
export function useSwellDaoWavedrop() {
  const { account } = useSwellWeb3()
  const api = useSwellDaoApi()
  return useSWRImmutable(account ? ['wavedrop', account] : null, () => {
    return api.read.wavedrop()
  })
}
export function useSwellDaoWavedrop2() {
  const { account } = useSwellWeb3()
  const api = useSwellDaoApi()
  return useSWRImmutable(account ? ['wavedrop2', account] : null, () =>
    api.read.wavedrop2()
  )
}
export function useSwellDaoWaveDropStats() {
  const api = useSwellDaoApi()
  return useSWRImmutable(['wavedrop-stats'], () => {
    return api.read.waveDropStats()
  })
}

export function useSwellDaoAirdropContractsState() {
  const { chainId } = useSwellWeb3()
  const api = useSwellDaoApi()
  return useSWRImmutable(['airdrop-contracts-state', chainId], () => {
    return api.read.airdropContractsState()
  })
}
export function useSwellDaoAirdropOffchainState() {
  const api = useSwellDaoApi()
  return useSWRImmutable(['airdrop-offchain-state'], () => {
    return api.read.airdropOffchainState()
  })
}
export function useSwellDaoAirdropUserVesting() {
  const { account } = useSwellWeb3()
  const api = useSwellDaoApi()
  return useSWRImmutable(
    account ? ['airdrop-user-vesting', account] : null,
    () => {
      return api.read.airdropUserVesting()
    }
  )
}
export function useSwellDaoClaimAirdrop() {
  const cumulativeClaimed = useSwellDaoAirdrop()?.data?.cumulativeClaimed
  const { write, read } = useSwellDaoApi()
  return useWeb3Call({
    fn: write.claimAirdrop,
    estimateGas: write.claimAirdropEstimateGas,
    staticGasEstimate: ({ amountToLock, cumulativeAmount }) => {
      return merkleDropClaimAndLockGasEstimate({
        amountToLock,
        cumulativeAmount,
        cumulativeClaimed: cumulativeClaimed ?? ethers.constants.Zero,
        lockType: 'yearn',
      })
    },
    validate: async ({ amountToLock, cumulativeAmount, merkleProof }) => {
      const valid = await read.checkClaimAirdrop({
        cumulativeAmount,
        amountToLock,
        merkleProof,
      })

      if (!valid) {
        return 'Invalid merkle proof'
      }

      return null
    },
  })
}
export type SwellDaoClaimAirdrop = ReturnType<typeof useSwellDaoClaimAirdrop>

export function useSwellDaoSelectOption1() {
  const { write } = useSwellDaoApi()

  // synthetic web3 call (only asks for a signature, does not actually send a transaction)
  return useWeb3Call({
    fn: async () => {
      await write.selectAirdropOption1()

      return {
        hash: formatBytes32String('0'),
        wait: async () => {
          return {
            transactionHash: formatBytes32String('0'),
          } as ContractReceipt
        },
      } as ContractTransaction
    },
    estimateGas: async () => BigNumber.from(0),
  })
}
export type SwellDaoSelectOption1 = ReturnType<typeof useSwellDaoSelectOption1>

export function useSwellDaoSelectOption2() {
  const { write } = useSwellDaoApi()

  // synthetic web3 call (only asks for a signature, does not actually send a transaction)
  return useWeb3Call({
    fn: async () => {
      await write.selectAirdropOption2()

      return {
        hash: formatBytes32String('0'),
        wait: async () => {
          return {
            transactionHash: formatBytes32String('0'),
          } as ContractReceipt
        },
      } as ContractTransaction
    },
    estimateGas: async () => BigNumber.from(0),
  })
}
export type SwellDaoSelectOption2 = ReturnType<typeof useSwellDaoSelectOption2>
