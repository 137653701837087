import useSWRImmutable from 'swr/immutable'
import { useBtcLrtVaultApi, useRswellVaultApi } from './context'
import { useSwellWeb3 } from '@/swell-web3/core'
import { useWeb3Call } from '@/hooks/useWeb3Call'
import {
  getApproveGasEstimate,
  getYearnCancelWithdrawGasEstimate,
  getYearnCompleteWithdrawGasEstimate,
  getYearnDepositGasEstimate,
  getYearnRequestWithdrawGasEstimate,
} from '@/constants/gasEstimates'

type UseYearnVaultAPi = typeof useBtcLrtVaultApi
function makeYearnVaultHooks(_key: string, useYearnVaultApi: UseYearnVaultAPi) {
  function useVault() {
    const { read, write, ...vault } = useYearnVaultApi()
    return vault
  }

  // global API
  function useVaultState() {
    const { chainId } = useSwellWeb3()
    const api = useYearnVaultApi()
    return useSWRImmutable([_key, 'vaultState', chainId], () =>
      api.read.vaultState()
    )
  }
  function useVaultStats() {
    const { chainId } = useSwellWeb3()
    const api = useYearnVaultApi()
    return useSWRImmutable([_key, 'vaultStats', chainId], () =>
      api.read.vaultStats()
    )
  }
  function useVaultPoints() {
    const api = useYearnVaultApi()
    return useSWRImmutable([_key, 'vaultPoints'], () => api.read.vaultPoints())
  }

  // user API
  function useBalances() {
    const { account, chainId } = useSwellWeb3()
    const api = useYearnVaultApi()
    return useSWRImmutable(
      account ? [_key, 'balances', account, chainId] : null,
      () => api.read.balances()
    )
  }
  function useAllowances() {
    const { account, chainId } = useSwellWeb3()
    const api = useYearnVaultApi()
    return useSWRImmutable(
      account ? [_key, 'allowances', account, chainId] : null,
      () => api.read.allowances()
    )
  }
  function useAuth() {
    const { account, chainId } = useSwellWeb3()
    const api = useYearnVaultApi()
    return useSWRImmutable(
      account ? [_key, 'auth', account, chainId] : null,
      () => api.read.auth()
    )
  }
  function useWithdrawRequest() {
    const { account, chainId } = useSwellWeb3()
    const api = useYearnVaultApi()
    return useSWRImmutable(
      account ? [_key, 'withdrawRequest', account, chainId] : null,
      () => api.read.withdrawRequest()
    )
  }
  function useUserPoints() {
    const { account } = useSwellWeb3()
    const api = useYearnVaultApi()
    return useSWRImmutable(account ? [_key, 'userPoints', account] : null, () =>
      api.read.userPoints()
    )
  }

  // web3 calls
  function useDeposit() {
    const api = useYearnVaultApi()
    return useWeb3Call({
      estimateGas: api.write.depositEstimateGas,
      fn: api.write.deposit,
      staticGasEstimate: () => getYearnDepositGasEstimate(),
      validate: async (params) => {
        if (!params.amount) throw new Error('no amount')
        const ok = await api.read.checkDeposit(params)
        if (!ok) throw new Error('external validation failed')
        return null
      },
    })
  }
  function useApproveAssetForDeposit() {
    const api = useYearnVaultApi()
    return useWeb3Call({
      estimateGas: api.write.approveAssetForDepositEstimateGas,
      fn: api.write.approveAssetForDeposit,
      staticGasEstimate: () => getApproveGasEstimate(),
      validate: async (params) => {
        if (!params.amount) throw new Error('no amount')
        return null
      },
    })
  }
  function useRequestWithdraw() {
    const api = useYearnVaultApi()
    return useWeb3Call({
      estimateGas: api.write.requestWithdrawEstimateGas,
      fn: api.write.requestWithdraw,
      staticGasEstimate: () => getYearnRequestWithdrawGasEstimate(),
      validate: async (params) => {
        if (!params.shares) throw new Error('no shares')
        if (typeof params.maxLossBasisPoints !== 'number')
          throw new Error('no maxLossBasisPoints')
        return null
      },
    })
  }
  function useApproveVaultTokenForWithdraw() {
    const api = useYearnVaultApi()
    return useWeb3Call({
      estimateGas: api.write.approveVaultTokenForWithdrawEstimateGas,
      fn: api.write.approveVaultTokenForWithdraw,
      staticGasEstimate: () => getApproveGasEstimate(),
      validate: async (params) => {
        if (!params.amount) throw new Error('no amount')
        return null
      },
    })
  }
  function useCancelWithdraw() {
    const api = useYearnVaultApi()
    return useWeb3Call({
      estimateGas: api.write.cancelWithdrawEstimateGas,
      fn: api.write.cancelWithdraw,
      staticGasEstimate: () => getYearnCancelWithdrawGasEstimate(),
    })
  }
  function useCompleteWithdraw() {
    const api = useYearnVaultApi()
    return useWeb3Call({
      estimateGas: api.write.completeWithdrawEstimateGas,
      fn: api.write.completeWithdraw,
      staticGasEstimate: () => getYearnCompleteWithdrawGasEstimate(),
    })
  }

  return {
    useVault,
    useVaultState,
    useVaultStats,
    useVaultPoints,
    useBalances,
    useAllowances,
    useAuth,
    useUserPoints,
    useWithdrawRequest,
    useDeposit,
    useApproveAssetForDeposit,
    useRequestWithdraw,
    useApproveVaultTokenForWithdraw,
    useCancelWithdraw,
    useCompleteWithdraw,
  }
}

export const {
  useVault: useBtcLrtVault,
  useVaultState: useBtcLrtVaultState,
  useVaultStats: useBtcLrtVaultStats,
  useVaultPoints: useBtcLrtVaultPoints,
  useBalances: useBtcLrtBalances,
  useAllowances: useBtcLrtAllowances,
  useAuth: useBtcLrtAuth,
  useUserPoints: useBtcLrtUserPoints,
  useWithdrawRequest: useBtcLrtWithdrawRequest,
  useDeposit: useBtcLrtDeposit,
  useApproveAssetForDeposit: useBtcLrtApproveAssetForDeposit,
  useRequestWithdraw: useBtcLrtRequestWithdraw,
  useApproveVaultTokenForWithdraw: useBtcLrtApproveVaultTokenForWithdraw,
  useCancelWithdraw: useBtcLrtCancelWithdraw,
  useCompleteWithdraw: useBtcLrtCompleteWithdraw,
} = makeYearnVaultHooks('btc-lrt', useBtcLrtVaultApi)

export type YearnDeposit = ReturnType<typeof useBtcLrtDeposit>
export type YearnApproveAssetForDeposit = ReturnType<
  typeof useBtcLrtApproveAssetForDeposit
>
export type YearnRequestWithdraw = ReturnType<typeof useBtcLrtRequestWithdraw>
export type YearnApproveVaultTokenForWithdraw = ReturnType<
  typeof useBtcLrtApproveVaultTokenForWithdraw
>
export type YearnCancelWithdraw = ReturnType<typeof useBtcLrtCancelWithdraw>
export type YearnCompleteWithdraw = ReturnType<typeof useBtcLrtCompleteWithdraw>

export type YearnVaultCalls = {
  deposit: YearnDeposit
  approveAssetForDeposit: YearnApproveAssetForDeposit
  requestWithdraw: YearnRequestWithdraw
  approveVaultTokenForWithdraw: YearnApproveVaultTokenForWithdraw
  cancelWithdraw: YearnCancelWithdraw
  completeWithdraw: YearnCompleteWithdraw
}
export function useBtcLrtVaultCalls(): YearnVaultCalls {
  return {
    deposit: useBtcLrtDeposit(),
    approveAssetForDeposit: useBtcLrtApproveAssetForDeposit(),
    requestWithdraw: useBtcLrtRequestWithdraw(),
    approveVaultTokenForWithdraw: useBtcLrtApproveVaultTokenForWithdraw(),
    cancelWithdraw: useBtcLrtCancelWithdraw(),
    completeWithdraw: useBtcLrtCompleteWithdraw(),
  }
}

export const {
  useVault: useRswellVault,
  useVaultState: useRswellVaultState,
  useVaultStats: useRswellVaultStats,
  useVaultPoints: useRswellVaultPoints,
  useBalances: useRswellBalances,
  useAllowances: useRswellAllowances,
  useAuth: useRswellAuth,
  useUserPoints: useRswellUserPoints,
  useWithdrawRequest: useRswellWithdrawRequest,
  useDeposit: useRswellDeposit,
  useApproveAssetForDeposit: useRswellApproveAssetForDeposit,
  useRequestWithdraw: useRswellRequestWithdraw,
  useApproveVaultTokenForWithdraw: useRswellApproveVaultTokenForWithdraw,
  useCancelWithdraw: useRswellCancelWithdraw,
  useCompleteWithdraw: useRswellCompleteWithdraw,
} = makeYearnVaultHooks('rswell', useRswellVaultApi)
export function useRswellVaultCalls(): YearnVaultCalls {
  return {
    deposit: useRswellDeposit(),
    approveAssetForDeposit: useRswellApproveAssetForDeposit(),
    requestWithdraw: useRswellRequestWithdraw(),
    approveVaultTokenForWithdraw: useRswellApproveVaultTokenForWithdraw(),
    cancelWithdraw: useRswellCancelWithdraw(),
    completeWithdraw: useRswellCompleteWithdraw(),
  }
}
