import React from 'react'
import { useHasAcceptedAirdropTerms } from './airdropHooks'
import { Dialog } from '@/swell-ui/Dialog'
import styled, { css } from 'styled-components'
import { StyledButtonThin } from './AirdropButtons'
import { DecoBox } from '../DecoBox'

export function AirdropToSModal({ open }: { open?: boolean }) {
  const { accept, hasAcceptedTerms } = useHasAcceptedAirdropTerms()
  return (
    <MuiDialog open={!!open && !hasAcceptedTerms}>
      <Layout
        direction="column"
        gap="16"
        padding="42px !important"
        align="start"
        opaque
      >
        <h2>Terms & Conditions</h2>
        <ScrollBox>
          <p>
            Lorem ipsum dolor sit amet consectetur. Cras nulla aliquam rutrum
            egestas integer faucibus. Eget diam facilisi eu tincidunt. Volutpat
            tincidunt blandit egestas nisi feugiat odio sit volutpat. Non diam
            posuere donec dui nunc magna. Consectetur ac nisi faucibus dui ut in
            amet sollicitudin ut. At tellus suscipit et enim in diam at
            suspendisse. Nisi massa purus diam dictumst aliquet. Pretium sem et
            cras malesuada quisque enim tortor. Phasellus aliquam rhoncus nunc
            tincidunt rhoncus ac pharetra bibendum pretium.{' '}
          </p>
          <p>
            Neque leo orci vivamus blandit suscipit pulvinar cras. Faucibus
            curabitur in enim habitasse. Faucibus consectetur laoreet purus
            aliquet purus vulputate nec augue vulputate. Orci tincidunt
            condimentum aliquet pellentesque sodales amet. Tellus volutpat
            mattis sit vitae volutpat pretium sit. Aliquet tortor quis nulla
            gravida vestibulum. Neque quam netus lectus viverra hac. Eros eu ac
            id tempus nibh nisl purus laoreet. Bibendum eros ullamcorper et nunc
            erat eget cras enim nunc. Amet nulla tortor in quis dolor id amet
            fames etiam.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur. Cras nulla aliquam rutrum
            egestas integer faucibus. Eget diam facilisi eu tincidunt. Volutpat
            tincidunt blandit egestas nisi feugiat odio sit volutpat. Non diam
            posuere donec dui nunc magna. Consectetur ac nisi faucibus dui ut in
            amet sollicitudin ut. At tellus suscipit et enim in diam at
            suspendisse. Nisi massa purus diam dictumst aliquet. Pretium sem et
            cras malesuada quisque enim tortor. Phasellus aliquam rhoncus nunc
            tincidunt rhoncus ac pharetra bibendum pretium.{' '}
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur. Cras nulla aliquam rutrum
            egestas integer faucibus. Eget diam facilisi eu tincidunt. Volutpat
            tincidunt blandit egestas nisi feugiat odio sit volutpat. Non diam
            posuere donec dui nunc magna. Consectetur ac nisi faucibus dui ut in
            amet sollicitudin ut. At tellus suscipit et enim in diam at
            suspendisse. Nisi massa purus diam dictumst aliquet. Pretium sem et
            cras malesuada quisque enim tortor. Phasellus aliquam rhoncus nunc
            tincidunt rhoncus ac pharetra bibendum pretium.{' '}
          </p>
        </ScrollBox>
        <div />
        <StyledButtonThin onClick={accept}>I acknowledge</StyledButtonThin>
      </Layout>
    </MuiDialog>
  )
}

const MuiDialog = styled(Dialog)`
  .MuiPaper-root {
    /* border-radius: 12px; */
    border: none;
    background: none;
    padding: none;
    overflow: visible;
  }
`

const ScrollBox = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 494px;
  width: 100%;
  padding-right: 8px;

  ${({ theme }) =>
    theme.scrollbar &&
    css`
      &::-webkit-scrollbar {
        width: ${theme.scrollbar.width};
      }

      &::-webkit-scrollbar-track {
        background: ${theme.scrollbar.trackBg};
        border-radius: ${theme.scrollbar.borderRadius};
      }

      &::-webkit-scrollbar-thumb {
        background: ${theme.scrollbar.thumbBg};
        border-radius: ${theme.scrollbar.borderRadius};
      }
    `}
`

const Layout = styled(DecoBox)`
  h2 {
    margin: 0;
    color: var(--white-50, #fff);
    text-align: left;
    /* Body/large */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 38.4px */
    letter-spacing: -0.72px;
  }

  button {
    width: 100%;
  }
`
