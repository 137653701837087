import { MaybeRangeAPR } from '@/types/apr'
import { displayPercentFromPercent } from './displayNumbers'

export function parseRangeAPR(aprs: number[]): MaybeRangeAPR {
  let apr: MaybeRangeAPR

  if (aprs.length === 2) {
    apr = {
      type: 'range',
      basePercent: aprs[0],
      maxPercent: aprs[1],
    }
  } else if (aprs.length === 1) {
    apr = {
      type: 'scalar',
      aprPercent: aprs[0],
    }
  } else {
    apr = {
      type: 'scalar',
      aprPercent: 0,
    }
  }

  return apr
}

export function displayRangeAPR(apr: MaybeRangeAPR): string {
  function displayScalar(nPercent: number): string {
    return `${displayPercentFromPercent(nPercent, { precision: 1 })}`
  }
  function displayRange(basePercent: number, maxPercent: number): string {
    return `${displayPercentFromPercent(basePercent, {
      precision: 2,
    })}-${displayPercentFromPercent(maxPercent, { precision: 2 })}`
  }

  if (apr.type === 'range') {
    const { basePercent, maxPercent } = apr
    if (basePercent >= maxPercent) {
      return displayScalar(basePercent)
    }
    const diff = maxPercent - basePercent
    if (diff < 0.01) {
      return displayScalar(basePercent)
    }

    return displayRange(basePercent, maxPercent)
  }
  return displayScalar(apr.aprPercent)
}
