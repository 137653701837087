import { bigNumberRateToFloat } from '@/util/big'
import useSWR from 'swr'
import { useSwellWeb3 } from '@/swell-web3/core'
import { usePricesApi } from './context'
import ms from 'ms.macro'

export function usePrices() {
  const api = usePricesApi()
  return useSWR('prices', () => api.prices(), {
    refreshInterval: ms`300s`,
  })
}
export function usePrimaryRates() {
  const { chainId } = useSwellWeb3()
  const api = usePricesApi()
  return useSWR(['primaryRates', chainId], () => api.primaryRates(), {
    refreshInterval: ms`300s`,
  })
}

export function useEthUsdRate() {
  const prices = usePrices()
  return {
    isLoading: prices.isLoading,
    isValidating: prices.isValidating,
    error: prices.error,
    mutate: prices.mutate,

    get data() {
      if (!prices.data) return undefined
      return { rate: prices.data.ethUsd.priceUsd }
    },
  }
}

export function useSwEthEthPrimaryRate() {
  const { data, ...query } = usePrimaryRates()

  return {
    ...query,
    get data() {
      if (!data) return undefined
      const { swETHPrimaryRate } = data
      return { rate: bigNumberRateToFloat(swETHPrimaryRate) }
    },
  }
}
export function useRswEthEthPrimaryRate() {
  const { data, ...query } = usePrimaryRates()

  return {
    ...query,
    get data() {
      if (!data) return undefined
      const { rswETHPrimaryRate } = data
      return { rate: bigNumberRateToFloat(rswETHPrimaryRate) }
    },
  }
}

export function useSwEthUsdMarketRate() {
  const swEthEthRate = useSwEthEthPrimaryRate()
  const ethUsdRate = useEthUsdRate()

  return {
    get isLoading() {
      return swEthEthRate.isLoading || ethUsdRate.isLoading
    },
    get error() {
      return swEthEthRate.error ?? ethUsdRate.error
    },
    get isValidating() {
      return swEthEthRate.isValidating || ethUsdRate.isValidating
    },
    get data() {
      if (!swEthEthRate.data || !ethUsdRate.data) return undefined
      return { rate: swEthEthRate.data.rate * ethUsdRate.data.rate }
    },
  }
}

export function useRswEthUsdMarketRate() {
  const rswEthEthRate = useRswEthEthPrimaryRate()
  const ethUsdRate = useEthUsdRate()

  return {
    get isLoading() {
      return rswEthEthRate.isLoading || ethUsdRate.isLoading
    },
    get error() {
      return rswEthEthRate.error ?? ethUsdRate.error
    },
    get isValidating() {
      return rswEthEthRate.isValidating || ethUsdRate.isValidating
    },
    get data() {
      if (!rswEthEthRate.data || !ethUsdRate.data) return undefined
      return { rate: rswEthEthRate.data.rate * ethUsdRate.data.rate }
    },
  }
}

export function useBtcEthMarketRate() {
  const btcEth = usePrices()

  return {
    get isLoading() {
      return btcEth.isLoading
    },
    get error() {
      return btcEth.error
    },
    get isValidating() {
      return btcEth.isValidating
    },
    get data() {
      if (!btcEth.data) return undefined
      return { rate: btcEth.data.btcEth.priceUsd }
    },
    mutate: btcEth.mutate,
  }
}

export function useBtcUsdMarketRate() {
  const btcEth = useBtcEthMarketRate()
  const ethUsd = useEthUsdRate()

  return {
    get isLoading() {
      return ethUsd.isLoading || btcEth.isLoading
    },
    get error() {
      return ethUsd.error ?? btcEth.error
    },
    get isValidating() {
      return ethUsd.isValidating || btcEth.isValidating
    },
    get data() {
      if (!ethUsd.data || !btcEth.data) return undefined
      const { rate: ethUsdRate } = ethUsd.data
      const { rate: btcEthRate } = btcEth.data
      return { rate: btcEthRate * ethUsdRate }
    },
    mutate: btcEth.mutate,
  }
}
