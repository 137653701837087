import {
  NucleusVaultCalls,
  useNucleusEthAllowances,
  useNucleusEthAuth,
  useNucleusEthBalances,
  useNucleusEthPoints,
  useNucleusEthRates,
  useNucleusEthRecentFulfilledRequests,
  useNucleusEthSharesState,
  useNucleusEthSupportedAssets,
  useNucleusEthVault,
  useNucleusEthVaultState,
  useNucleusEthVaultStats,
  useNucleusEthWithdrawRequest,
} from '@/state/nucleusVault/hooks'
import swellL2PointsIcon from '@/assets/images/swell-l2-logo-56x56.png'
import nucleusLogoUrl from '@/assets/images/nucleus_logo_64x64.png'
import blackPearlUrl from '@/assets/images/black-pearls-128x128.png'
import { useEthUsdRate } from '@/state/prices/hooks'
import { useEveryNthBlock } from '@/hooks/useBlockNumber'
import { NucleusVaultWidget } from '@/components/Nucleus/NucleusVaultWidget'
import { useEarnVaultRefetchOnTxSuccess } from './hooks'
import { NucleusPointsCampaigns } from '@/components/Nucleus/types'

const POINTS_CAMPAIGNS: NucleusPointsCampaigns = {
  blackPearlsCampaign: {
    logoURI: blackPearlUrl,
    multiplier: 3,
    name: 'Black Pearls',
  },
  ecosystemPtsCampaign: {
    logoURI: swellL2PointsIcon,
    multiplier: 1.5,
    name: 'Ecosystem Pts',
  },
  nucleusPtsCampaign: {
    logoURI: nucleusLogoUrl,
    multiplier: 3,
    name: 'Nucleus Pts',
  },
}

const ZEROX_MACRO_URL =
  'https://github.com/Ion-Protocol/nucleus-boring-vault/blob/master/audit/0xmacro-boring-vault-arctic-0.pdf'
const PASHOV_URL =
  'https://github.com/Ion-Protocol/nucleus-boring-vault/blob/master/audit/pashov-boring-vault.pdf'
const SPEARBIT_URL =
  'https://github.com/Ion-Protocol/nucleus-boring-vault/blob/master/audit/spearbit-boring-vault-arctic-0.pdf'

const VAULT_LEARN_MORE_URL =
  'https://docs.swellnetwork.io/swell-earn/earneth-vault'

const DESCRIPTION = (
  <>
    <h3>Vault description</h3>
    <p>
      earnETH is an ETH denominated vault that allows users to deposit their ETH
      assets to get automated risk adjusted yields and points.{' '}
      <a href={VAULT_LEARN_MORE_URL} target="_blank" rel="noopener noreferrer">
        Learn more
      </a>
    </p>
    <p>
      <span style={{ fontWeight: 'bold' }}>Deposit Assets</span>
    </p>
    <p>WETH, rswETH, swETH, WSTETH, pxETH, apxETH</p>
    <p>
      <span style={{ fontWeight: 'bold' }}>Audited by</span>
    </p>
    <p>
      <a href={ZEROX_MACRO_URL} target="_blank" rel="noopener noreferrer">
        0xMacro
      </a>
      ,{' '}
      <a href={PASHOV_URL} target="_blank" rel="noopener noreferrer">
        Pashov
      </a>
      ,{' '}
      <a href={SPEARBIT_URL} target="_blank" rel="noopener noreferrer">
        Spearbit
      </a>
    </p>
  </>
)

// TODO: real copy
const PERFORMANCE = (
  <>
    <h3>Performance over time</h3>
    <p>
      Deposited assets are deployed into a basket of DeFi protocols, earning a
      variable yield based on market conditions. Periodically, the vault will
      rebalance allocations across DeFi positions to maximize returns while
      staying within risk policy. Any rewards earned in positions are sold for
      more weETH to redistribute across strategies.
    </p>
    <p>Chart denotes performance of vault gains since inception</p>
  </>
)

export function EthEarnVaultWidget({ calls }: { calls: NucleusVaultCalls }) {
  const vault = useNucleusEthVault()

  // user
  const allowancesQuery = useNucleusEthAllowances()
  const authQuery = useNucleusEthAuth()
  const balancesQuery = useNucleusEthBalances()
  const sharesStateQuery = useNucleusEthSharesState()
  const currentWithdrawRequestQuery = useNucleusEthWithdrawRequest()
  const fulfilledRequestsQuery = useNucleusEthRecentFulfilledRequests()
  const pointsQuery = useNucleusEthPoints()

  // vault
  const statsQuery = useNucleusEthVaultStats()
  const ratesQuery = useNucleusEthRates()
  const supportedTokensQuery = useNucleusEthSupportedAssets()
  const vaultStateQuery = useNucleusEthVaultState()

  const ethRateUsdQuery = useEthUsdRate()

  useEarnVaultRefetchOnTxSuccess({
    calls,
    refetchAllowances: allowancesQuery.mutate,
    refetchBalances: balancesQuery.mutate,
    refetchCurrentWithdrawRequest: currentWithdrawRequestQuery.mutate,
  })

  useEveryNthBlock({
    blocksPerFetch: 1,
    fetch: () => {
      allowancesQuery.mutate()
      authQuery.mutate()
      balancesQuery.mutate()
      sharesStateQuery.mutate()
      currentWithdrawRequestQuery.mutate()
      fulfilledRequestsQuery.mutate()
    },
  })
  useEveryNthBlock({
    blocksPerFetch: 5,
    fetch: () => {
      statsQuery.mutate()
      ratesQuery.mutate()
      vaultStateQuery.mutate()
      pointsQuery.mutate()
      ethRateUsdQuery.mutate()
    },
  })

  const stats = statsQuery.data
  const allowances = allowancesQuery.data
  const auth = authQuery.data
  const balances = balancesQuery.data
  const rates = ratesQuery.data
  const sharesState = sharesStateQuery.data
  const supportedTokens = supportedTokensQuery.data
  const vaultState = vaultStateQuery.data
  const currentWithdrawRequest = currentWithdrawRequestQuery.data
  const fulfilledRequests = fulfilledRequestsQuery.data

  const points = pointsQuery.data
  const ethUsdRate = ethRateUsdQuery.data?.rate

  return (
    <NucleusVaultWidget
      noPerformanceData
      title={vault.name}
      strategyProvider="Nucleus Earn"
      scrollToId="eth-earn-vault"
      calls={calls}
      stats={stats}
      allowances={allowances}
      auth={auth}
      balances={balances}
      rates={rates}
      sharesState={sharesState}
      supportedTokens={supportedTokens}
      vaultState={vaultState}
      currentWithdrawRequest={currentWithdrawRequest}
      points={points}
      descriptionNode={DESCRIPTION}
      performanceNode={PERFORMANCE}
      fulfilledRequests={fulfilledRequests}
      vault={vault}
      pointsCampaigns={POINTS_CAMPAIGNS}
      baseAssetRateUsd={ethUsdRate}
    />
  )
}
