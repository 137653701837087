import { NucleusVaultCalls } from '@/state/nucleusVault/hooks'
import { useEffect } from 'react'

export function useEarnVaultRefetchOnTxSuccess({
  calls,
  refetchAllowances,
  refetchBalances,
  refetchCurrentWithdrawRequest,
}: {
  calls: NucleusVaultCalls
  refetchAllowances: () => void
  refetchBalances: () => void
  refetchCurrentWithdrawRequest: () => void
}) {
  useEffect(() => {
    if (
      calls.approveAssetForDeposit.status ===
      calls.approveAssetForDeposit.STATUS.FULFILLED
    ) {
      refetchAllowances()
    }
  }, [
    calls.approveAssetForDeposit.STATUS.FULFILLED,
    calls.approveAssetForDeposit.status,
    refetchAllowances,
  ])
  useEffect(() => {
    if (
      calls.approveVaultTokenForAtomicQueue.status ===
      calls.approveVaultTokenForAtomicQueue.STATUS.FULFILLED
    ) {
      refetchAllowances()
    }
  }, [
    calls.approveVaultTokenForAtomicQueue.STATUS.FULFILLED,
    calls.approveVaultTokenForAtomicQueue.status,
    refetchAllowances,
  ])

  useEffect(() => {
    if (calls.deposit.status === calls.deposit.STATUS.FULFILLED) {
      refetchBalances()
    }
  }, [calls.deposit.STATUS.FULFILLED, calls.deposit.status, refetchBalances])

  useEffect(() => {
    if (
      calls.requestWithdraw.status === calls.requestWithdraw.STATUS.FULFILLED
    ) {
      refetchBalances()
      refetchCurrentWithdrawRequest()
    }
  }, [
    calls.requestWithdraw.STATUS.FULFILLED,
    calls.requestWithdraw.status,
    refetchBalances,
    refetchCurrentWithdrawRequest,
  ])

  useEffect(() => {
    if (calls.cancelWithdraw.status === calls.cancelWithdraw.STATUS.FULFILLED) {
      refetchCurrentWithdrawRequest()
    }
  }, [
    calls.cancelWithdraw.STATUS.FULFILLED,
    calls.cancelWithdraw.status,
    refetchCurrentWithdrawRequest,
  ])
}

export function useEarnVaultClearTxOnSuccess({
  calls,
  delay = 5000,
}: {
  calls: NucleusVaultCalls
  delay?: number
}) {
  useEffect(() => {
    if (
      calls.approveAssetForDeposit.status ===
      calls.approveAssetForDeposit.STATUS.FULFILLED
    ) {
      setTimeout(() => {
        calls.approveAssetForDeposit.clear()
      }, delay)
    }
  }, [calls.approveAssetForDeposit, delay])

  useEffect(() => {
    if (
      calls.approveVaultTokenForAtomicQueue.status ===
      calls.approveVaultTokenForAtomicQueue.STATUS.FULFILLED
    ) {
      setTimeout(() => {
        calls.approveVaultTokenForAtomicQueue.clear()
      }, delay)
    }
  }, [calls.approveVaultTokenForAtomicQueue, delay])

  useEffect(() => {
    if (calls.deposit.status === calls.deposit.STATUS.FULFILLED) {
      setTimeout(() => {
        calls.deposit.clear()
      }, delay)
    }
  }, [calls.deposit, delay])

  useEffect(() => {
    if (
      calls.requestWithdraw.status === calls.requestWithdraw.STATUS.FULFILLED
    ) {
      setTimeout(() => {
        calls.requestWithdraw.clear()
      }, delay)
    }
  }, [calls.requestWithdraw, delay])

  useEffect(() => {
    if (calls.cancelWithdraw.status === calls.cancelWithdraw.STATUS.FULFILLED) {
      setTimeout(() => {
        calls.cancelWithdraw.clear()
      }, delay)
    }
  }, [calls.cancelWithdraw, delay])
}
