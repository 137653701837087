import { BigNumber } from 'ethers'
import { useEffect, useMemo, useState } from 'react'

export function useLocalStorageObject<T>(
  _key: string,
  defaultValue: T,
  parse: (item: string) => T = JSON.parse,
  stringify: (value: T) => string = JSON.stringify
) {
  const initialValueMemoS = useMemo(() => {
    const item = localStorage.getItem(_key)
    if (item) {
      return item
    }
    return stringify(defaultValue)
  }, [_key, defaultValue, stringify])

  const [value, setValue] = useState<T>(parse(initialValueMemoS))
  const [valueS, setValueS] = useState<string>(initialValueMemoS)
  useEffect(() => {
    setValueS(stringify(value))
  }, [value, stringify])

  useEffect(() => {
    localStorage.setItem(_key, valueS)
  }, [_key, valueS])

  return [value, setValue] as const
}

export function useLocalStorageBigNumber(
  _key: string,
  defaultValue: BigNumber
) {
  return useLocalStorageObject(
    _key,
    defaultValue,
    (item) => BigNumber.from(item),
    (value) => value.toString()
  )
}

export function useLocalStorageNumber(_key: string, defaultValue: number) {
  return useLocalStorageObject(
    _key,
    defaultValue,
    (item) => parseFloat(item),
    (value) => value.toString()
  )
}

export function useLocalStorageUnixTimestamp(
  _key: string,
  defaultTimestamp: number
) {
  return useLocalStorageObject(
    _key,
    defaultTimestamp,
    (item) => parseInt(item),
    (value) => value.toString()
  )
}

export function useLocalStorageDuration(
  _key: string,
  defaultDurationMs: number
) {
  return useLocalStorageObject(
    _key,
    defaultDurationMs,
    (item) => parseInt(item) * 1000,
    (value) => Math.floor(value / 1000).toString()
  )
}

export function useLocalStorageBoolean(_key: string, defaultValue: boolean) {
  return useLocalStorageObject(
    _key,
    defaultValue,
    (item) => item === 'true',
    (value) => value.toString()
  )
}
