import { getChainIcon, getChainName } from '@/constants/chainInfo'
import { Badge } from '@/swell-ui/Badge'
import { FlexRow } from '@/swell-ui/FlexRow'
import { MultiSelectInput } from '@/swell-ui/inputs/MultiSelectInput'
import React, { Fragment, ReactNode } from 'react'

function ChainLabel({ chainId }: { chainId: number }) {
  const icon = getChainIcon(chainId)
  const name = getChainName(chainId)

  const res: ReactNode[] = []
  if (icon?.uri) {
    res.push(
      <img
        src={icon.uri}
        alt={name}
        width="22px"
        height="22px"
        style={{ borderRadius: icon.shape === 'circle' ? '50%' : '0' }}
      />
    )
  }

  if (name) {
    res.push(name)
  } else {
    res.push(`Chain ${chainId}`)
  }

  return (
    <FlexRow gap="4">
      {res.map((r, i) => (
        <Fragment key={i}>{r}</Fragment>
      ))}
    </FlexRow>
  )
}

export function ChainMultiSelect({
  chainIds,
  selectedChainIds,
  setChainFilter,
}: {
  chainIds: number[]
  selectedChainIds: number[]
  setChainFilter: (chainIds: number[]) => void
}) {
  const selectedSet = new Set(selectedChainIds)
  return (
    <MultiSelectInput
      selected={Array.from(selectedSet.values()).map((v) => v.toString())}
      options={chainIds.map((chainId) => ({
        key: chainId.toString(),
        label: <ChainLabel chainId={chainId} />,
        selected: selectedSet.has(chainId),
      }))}
      onSelect={(selected) => {
        setChainFilter(selected.map((s) => parseInt(s)))
      }}
      renderLabel={(selected) => {
        if (selected.length === 0) {
          return 'No Chains Selected'
        }
        if (selected.length === chainIds.length) {
          return 'All Chains'
        }
        return (
          <FlexRow gap="6">
            <Badge variant={'info'}>{selectedChainIds.length}</Badge>
            <span>All Chains</span>
          </FlexRow>
        )
      }}
    />
  )
}
