import { useEffect, useState } from 'react'

export function usePageDimensions() {
  const [pageHeight, setPageHeight] = useState<number>()
  const [pageWidth, setPageWidth] = useState<number>()

  useEffect(() => {
    let done = false

    const animate = () => {
      if (done) return
      setPageHeight(window.innerHeight)
      setPageWidth(window.innerWidth)
      requestAnimationFrame(animate)
    }

    animate()

    return () => {
      done = true
    }
  }, [])

  return { pageHeight, pageWidth }
}
