import { NucleusWithdrawRequest } from '@/types/nucleus'
import { applyRateBigNumberToFloat } from '@/util/big'
import { BigNumber } from 'ethers'
import { formatUnits, parseUnits } from 'ethers/lib/utils'

// internal functions, use case specific APIs below

// converts a specified amount of vault tokens to deposit assets
// due to the inverting nature of this calculation, the result is rounded up until it matches the forward conversion
function vaultTokenToAsset({
  vaultTokenAmount,
  vaultTokenRateInQuote,
  base,
}: {
  vaultTokenAmount: BigNumber
  vaultTokenRateInQuote: BigNumber
  base: { decimals: number }
}) {
  const unit = parseUnits('1', base.decimals)
  let assetAmount = vaultTokenAmount.mul(vaultTokenRateInQuote).div(unit)
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const vaultToken = assetToVaultToken({
      assetAmount,
      vaultTokenRateInQuote,
      base,
    })
    if (vaultToken.gte(vaultTokenAmount)) {
      break
    }
    assetAmount = assetAmount.add(1)
  }
  return assetAmount
}

// converts a specified amount of deposit assets to vault tokens
// expects vaultTokenRateInQuote from the accountant
function assetToVaultToken({
  assetAmount,
  vaultTokenRateInQuote,
  base,
}: {
  assetAmount: BigNumber
  vaultTokenRateInQuote: BigNumber
  base: { decimals: number }
}) {
  const unit = parseUnits('1', base.decimals)
  return assetAmount.mul(unit).div(vaultTokenRateInQuote)
}

// partial AtomicRequest withdrawal, not including deadline
type NucleusWithdrawalExchangeResult = {
  assetAmount: BigNumber
  offerAmount: BigNumber
  atomicPrice: BigNumber // implied
}

// calculates the exchange rate for a withdrawal
// solverDiscount is a ratio of the solver's fee, 0.0001 means 0.01% fee
// returns a result which includes the atomic price and intermediate values
function withdrawalExchange({
  offerAmount,
  vaultTokenRateInQuote,
  solverDiscount,
  base,
}: {
  offerAmount: BigNumber
  vaultTokenRateInQuote: BigNumber
  solverDiscount: number
  base: { decimals: number }
}): NucleusWithdrawalExchangeResult {
  if (solverDiscount < 0 || solverDiscount > 1) {
    throw new Error('solverDiscount must be between 0 and 1')
  }
  const solverDiscountPrecision = 12
  const unit = parseUnits('1', solverDiscountPrecision)
  // reduce vaultTokenRateInQuote, as the user is expecting less "assets" in return
  const vaultTokenRateInQuoteWithFees = vaultTokenRateInQuote
    .mul(
      unit.sub(parseUnits(solverDiscount.toString(), solverDiscountPrecision))
    )
    .div(unit)

  const assetAmount = vaultTokenToAsset({
    vaultTokenAmount: offerAmount,
    vaultTokenRateInQuote: vaultTokenRateInQuoteWithFees,
    base,
  })

  return {
    atomicPrice: vaultTokenRateInQuoteWithFees,
    offerAmount,
    assetAmount,
  }
}

// --
// deposit conversions

// converts an amount of deposit assets to vault tokens
export function vaultTokenReceivedForAssetDeposit({
  toSendAsset,
  vaultTokenRateInQuote,
  base,
}: {
  toSendAsset: BigNumber
  vaultTokenRateInQuote: BigNumber
  base: { decimals: number }
}): BigNumber {
  return assetToVaultToken({
    assetAmount: toSendAsset,
    vaultTokenRateInQuote,
    base,
  })
}

// converts an amount of vault tokens to deposit assets
export function depositAssetRequiredForVaultToken({
  toReceiveVaultToken,
  vaultTokenRateInQuote,
  base,
}: {
  toReceiveVaultToken: BigNumber
  vaultTokenRateInQuote: BigNumber
  depositAssetDecimals: number
  base: { decimals: number }
}): BigNumber {
  return vaultTokenToAsset({
    vaultTokenAmount: toReceiveVaultToken,
    vaultTokenRateInQuote,
    base,
  })
}

// calculates the minimum mint amount for deposit (application of slippage)
export function depositAssetMinimumMint({
  toSendAsset,
  vaultTokenRateInQuote,
  slippage,
  base,
}: {
  toSendAsset: BigNumber
  vaultTokenRateInQuote: BigNumber
  slippage: number
  base: { decimals: number }
}): BigNumber {
  if (slippage < 0 || slippage > 1) {
    throw new Error('slippage must be between 0 and 1')
  }

  const mintNoSlippage = vaultTokenReceivedForAssetDeposit({
    toSendAsset,
    vaultTokenRateInQuote,
    base,
  })

  const slippagePrecision = 12
  const slippageRate = parseUnits(slippage.toString(), slippagePrecision)
  const minMint = mintNoSlippage.sub(
    mintNoSlippage.mul(slippageRate).div(parseUnits('1', slippagePrecision))
  )
  return minMint
}

// --
// withdrawal conversions

// converts an amount of vault tokens to withdrawal assets
export function withdrawalAssetReceivedForVaultToken({
  toSendVaultToken,
  vaultTokenRateInQuote,
  solverDiscount,
  base,
}: {
  toSendVaultToken: BigNumber
  vaultTokenRateInQuote: BigNumber
  solverDiscount: number
  base: { decimals: number }
}): BigNumber {
  return withdrawalExchange({
    offerAmount: toSendVaultToken,
    vaultTokenRateInQuote,
    solverDiscount,
    base,
  }).assetAmount
}

// converts an amount of withdrawal assets to vault tokens
export function withdrawalVaultTokenRequiredForAsset({
  toReceiveAsset,
  vaultTokenRateInQuote,
  solverDiscount,
  vaultTokenDecimals,
  base,
}: {
  toReceiveAsset: BigNumber
  vaultTokenRateInQuote: BigNumber
  solverDiscount: number
  vaultTokenDecimals: number
  base: { decimals: number }
}): BigNumber {
  const unitExchange = withdrawalExchange({
    offerAmount: parseUnits('1', vaultTokenDecimals),
    vaultTokenRateInQuote,
    solverDiscount,
    base,
  })
  const adjustedRate = unitExchange.assetAmount
  return assetToVaultToken({
    assetAmount: toReceiveAsset,
    vaultTokenRateInQuote: adjustedRate,
    base,
  })
}

// calculates the atomic price and offer amount, given some amount of vault tokens
// returns a NucleusWithdrawRequest object, made from the given inputs.
// This object is viable for submission on-chain to update an atomic request.
export function makeNucleusWithdrawalRequest({
  toSendVaultToken,
  vaultTokenRateInQuote,
  solverDiscount,
  deadlineUnix,
  base,
}: {
  toSendVaultToken: BigNumber
  vaultTokenRateInQuote: BigNumber
  solverDiscount: number
  deadlineUnix: number
  base: { decimals: number }
}): NucleusWithdrawRequest {
  const exchange = withdrawalExchange({
    offerAmount: toSendVaultToken,
    vaultTokenRateInQuote,
    solverDiscount,
    base,
  })
  return {
    atomicPrice: exchange.atomicPrice,
    offerAmount: exchange.offerAmount,
    deadlineUnix: Math.floor(deadlineUnix),
  }
}

// Given an atomic price, calculates the amount of assets that will be received from a withdrawal
export function withdrawAssetReceivedFromAtomicPrice({
  atomicPrice,
  offerAmount,
  base,
}: {
  atomicPrice: BigNumber
  offerAmount: BigNumber
  base: { decimals: number }
}): BigNumber {
  return vaultTokenToAsset({
    vaultTokenAmount: offerAmount,
    vaultTokenRateInQuote: atomicPrice,
    base,
  })
}

export function atomicPriceFromFulfilledEvent({
  fulfilledEvent,
  vaultToken,
  wantAsset,
}: {
  fulfilledEvent: { offerAmountSpent: BigNumber; wantAmountReceived: BigNumber }
  vaultToken: { decimals: number }
  wantAsset: { decimals: number }
}): BigNumber {
  const { offerAmountSpent, wantAmountReceived } = fulfilledEvent
  const offerAmountNum = Number(
    formatUnits(offerAmountSpent, vaultToken.decimals)
  )
  const wantAmountNum = Number(
    formatUnits(wantAmountReceived, wantAsset.decimals)
  )
  if (offerAmountNum === 0) {
    return BigNumber.from(0)
  }

  const ratio = wantAmountNum / offerAmountNum
  return parseUnits(ratio.toString(), vaultToken.decimals)
}

// --
// USD conversions

// converts a specified amount of vault tokens to a USD value
// Path: VT/BASE * BASE/USD -> VT/USD
export function nucleusVaultTokenToUsd({
  vaultTokenAmount,
  baseAssetRateUsd,
  vaultTokenPrimaryRate,
  base,
}: {
  baseAssetRateUsd: number
  vaultTokenAmount: BigNumber
  vaultTokenPrimaryRate: BigNumber
  base: { decimals: number }
}) {
  const amountAsset = vaultTokenToAsset({
    vaultTokenAmount,
    vaultTokenRateInQuote: vaultTokenPrimaryRate,
    base,
  })

  return applyRateBigNumberToFloat(amountAsset, baseAssetRateUsd, { base })
}

// converts a specified amount of assets to a USD value
// Path: Asset/VT * VT/BASE * BASE/USD -> Asset/USD
export function nucleusAssetValueUsd({
  assetAmount,
  baseAssetRateUsd,
  vaultTokenPrimaryRate,
  vaultTokenRateInQuote,
  base,
}: {
  baseAssetRateUsd: number
  assetAmount: BigNumber
  vaultTokenPrimaryRate: BigNumber
  vaultTokenRateInQuote: BigNumber
  base: { decimals: number }
}) {
  const vaultTokenAmount = assetToVaultToken({
    assetAmount,
    vaultTokenRateInQuote,
    base,
  })
  return nucleusVaultTokenToUsd({
    baseAssetRateUsd,
    vaultTokenPrimaryRate,
    vaultTokenAmount,
    base,
  })
}
