import React, { ReactNode, useEffect } from 'react'
import { StakingConfirmationProgressWidget } from '.'
import { Web3Call } from '@/hooks/useWeb3Call'

export function Web3Toast({
  call,
  title,
  message,
  anyTransactionInProgress,
}: {
  call: Web3Call
  title: string
  message: ReactNode
  anyTransactionInProgress?: boolean
}) {
  const onClose = call.clear

  const idle = call.status === call.STATUS.IDLE
  const complete = call.status === call.STATUS.FULFILLED
  const confirming = call.status === call.STATUS.PROMPTING
  const pending = call.status === call.STATUS.PENDING

  useEffect(() => {
    if (complete && anyTransactionInProgress) {
      onClose()
    }
  }, [complete, anyTransactionInProgress, onClose])

  const txHash = call.txHash
  const open = !!title && !!message && !idle

  return (
    <StakingConfirmationProgressWidget
      complete={complete}
      confirming={confirming}
      pending={pending}
      title={title}
      txHash={txHash}
      onClose={onClose}
      open={open}
      message={message}
    />
  )
}
