import React from 'react'
import styled from 'styled-components/macro'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { FlexRow } from '@swell-ui/FlexRow'
import { GasStationIcon } from '@swell-ui/icons/GasStationIcon'
import { Skeleton } from '@swell-ui/Skeleton'
import { Typography } from '@swell-ui/Typography'
import { useGasPrice } from '@/state/gas/hooks'
import { useEveryNthBlock } from '@/hooks/useBlockNumber'

interface GasPriceLinkDisplayProps {
  gasPriceWei: BigNumber | undefined
}

const StyledGasStationIcon = styled(GasStationIcon)`
  position: relative;
  top: 5px;
  height: 19px;
  margin-right: 2px;

  path {
    fill: ${({ theme }) => theme.footer.link.color};
  }
`

const GasPriceSkeleton = styled(Skeleton)`
  display: inline-block;
  width: 48px;
  background-color: ${({ theme }) => theme.footer.link.color};
`

const GasPriceA = styled.a`
  position: relative;
  top: -3px;
  color: ${({ theme }) => theme.footer.link.color};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    left: -5px;
  }

  &:hover {
    svg {
      path {
        fill: #2f43ec;
      }
    }
  }
`

function GasPriceLinkDisplay({ gasPriceWei }: GasPriceLinkDisplayProps) {
  return (
    <FlexRow width="auto" flex="1 1 auto" justify="end">
      <Typography variant="body" size="xsmall">
        <GasPriceA
          href="https://etherscan.io/gastracker"
          target="_blank"
          rel="noreferrer"
        >
          <StyledGasStationIcon />
          {!gasPriceWei && <GasPriceSkeleton />}
          {gasPriceWei &&
            `${Math.floor(Number(formatUnits(gasPriceWei, 'gwei')))} gwei`}
        </GasPriceA>
      </Typography>
    </FlexRow>
  )
}

function GasPriceLink() {
  const gasPrice = useGasPrice()

  return <GasPriceLinkDisplay gasPriceWei={gasPrice.data?.gasPriceWei} />
}

export { GasPriceLink, GasPriceLinkDisplay }
