import { FlexRow } from '@/swell-ui/FlexRow'
import styled from 'styled-components'
import { DecoBox } from '@/components/DecoBox'
import { Typography } from '@/swell-ui/Typography'
import { useSwellDaoWavedrop, useSwellDaoWavedrop2 } from '@/state/dao/hooks'
import { useSwellUiTheme } from '@/swell-ui/theme'
import { useMediaQuery } from '@mui/material'
import { WavedropCard } from '@/components/WavedropCard'

const DISPLAY_MULTIPLIER = false // temporarily disabled until S1 voyage is finalized and multiplier is confirmed
const WAVEDROP_1_END = 1728352800 // Tuesday, 8 October 2024 02:00:00 UTC
const WAVEDROP_1_END_FORMATTED = '8th Oct. 2024'
const WAVEDROP_2_END = 1734400800 // Tuesday, 17 December 2024 02:00:00 UTC
const WAVEDROP_2_END_FORMATTED = '17th Dec. 2024'

export function SwellCity() {
  const { theme } = useSwellUiTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const wavedrop1Query = useSwellDaoWavedrop()
  const wavedrop2Query = useSwellDaoWavedrop2()

  const wavedrop = wavedrop1Query.data
  const wavedrop2 = wavedrop2Query.data

  return (
    <Layout
      align="center"
      direction="column"
      maxWidth={isMdUp ? '912px' : '433px'}
      gap="24"
    >
      <Typography
        variant="headline"
        xs="h3"
        md="h2"
        size="h2"
        fstyle="bold"
        letterSpacing="small"
      >
        Swell City
      </Typography>
      <span className="sub">🏄🏼 Ride the waves to boost your Wavedrops</span>
      <DecoBox>
        <p>Wavedrop 2 has begun!</p>
      </DecoBox>
      <WavedropCard
        endTimeUnix={WAVEDROP_1_END}
        wavedrop={wavedrop}
        ended={true}
        endTimeFormatted={WAVEDROP_1_END_FORMATTED}
        waveNumber={1}
        showMultiplier={DISPLAY_MULTIPLIER}
        claimTooltip={'Claim will be available within 3 weeks of TGE'}
      />
      <WavedropCard
        endTimeUnix={WAVEDROP_2_END}
        wavedrop={wavedrop2}
        ended={false}
        endTimeFormatted={WAVEDROP_2_END_FORMATTED}
        waveNumber={2}
        showMultiplier={DISPLAY_MULTIPLIER}
      />
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  /* display: flex; */
  margin: 0 auto;
  padding-top: 60px;

  > .sub {
    color: var(--white-125, #b0b0b0);
    text-align: center;
    /* Body/large */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 38.4px */
    letter-spacing: -0.72px;
  }

  .swc-label-1 {
    color: var(--white-50, #fff);
    text-align: center;
    /* Body/large */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 38.4px */
    letter-spacing: -0.72px;
  }

  .swc-label-colored {
    color: var(--lightblue-50, #a4abf1);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 13.2px */
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }

  .info-icon {
    svg {
      position: relative;
      top: 2px;

      path {
        stroke: currentColor;
      }
    }
  }

  .swc-med-value {
    color: var(--white-50, #fff);
    text-align: center;
    /* Headlines/H6 */
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 33.8px */
    letter-spacing: -0.78px;
  }

  // Make loader container shrink to fit content when not loading
  *[aria-busy='false'] {
    min-width: unset;
  }
`
