import { useMediaQuery } from '@mui/material'
import { makeRewardSummary } from '../../airdropFormatting'
import { AirdropResult } from '@/state/dao/types'
import { Token } from '@/types/tokens'
import { DecoBox } from '@/components/DecoBox'
import { FlexRow } from '@/swell-ui/FlexRow'
import { TokenLogo } from '@/components/TokenLogo'
import { TOKEN_LIST_SWELL } from '@/constants/tokens'
import styled, { css } from 'styled-components'

const bodyMediumCSS = css`
  color: var(--Swell-White-50, #fff);
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
`
const bodyLargeCSS = css`
  color: var(--Swell-White-50, #fff);
  /* Body/large */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 38.4px */
  letter-spacing: -0.72px;
`

export function RewardBreakdown({
  airdropResult,
  daoToken,
}: {
  airdropResult: AirdropResult
  daoToken: Token
}) {
  const desktop = useMediaQuery('(min-width: 900px)')
  const rewardSummary = makeRewardSummary({ airdropResult, daoToken })

  const rewardBreakdownMobile = (
    <MobileLayout direction="column" width="100%" gap="6">
      <FlexRow
        direction="column"
        maxWidth="235px"
        align="center"
        gap="6"
        textAlign="center"
        className="body-medium"
      >
        <span>Base reward</span>
        <SwellAmount amount={rewardSummary.baseReward} />
      </FlexRow>
      <Plus />
      <FlexRow
        direction="column"
        maxWidth="235px"
        align="center"
        gap="6"
        textAlign="center"
        className="body-medium"
      >
        <span>Loyalty Bonus</span>
        <SwellAmount amount={rewardSummary.loyaltyBonus} />
      </FlexRow>
      <Equals />
      <FlexRow
        direction="column"
        maxWidth="235px"
        align="center"
        gap="6"
        textAlign="center"
      >
        <span>Total claimable</span>
        <SwellAmount amount={rewardSummary.totalClaimable} />
      </FlexRow>
    </MobileLayout>
  )

  const rewardBreakdownDesktop = (
    <DesktopLayout direction="row" minWidth="711px" width="auto" gap="24">
      <div className="body-large grid">
        <span className="body-medium">Base reward</span>
        <SwellAmount amount={rewardSummary.baseReward} largeIcon />
        <div />
        <Plus />
        <span className="body-medium">Loyalty Bonus</span>
        <SwellAmount amount={rewardSummary.loyaltyBonus} largeIcon />
        <div />
        <Equals />
        <span className="body-medium">Total claimable</span>
        <SwellAmount amount={rewardSummary.totalClaimable} largeIcon />
      </div>
    </DesktopLayout>
  )

  return desktop ? rewardBreakdownDesktop : rewardBreakdownMobile
}

const BaseLayout = styled(DecoBox)`
  .body-medium {
    ${bodyMediumCSS}
  }
  .body-large {
    ${bodyLargeCSS}
  }

  .border-gradient {
    display: none;
  }
  border: 1px solid var(--lightblue-100, #2f43ec);
`
const MobileLayout = styled(BaseLayout)`
  @media (min-width: 600px) {
    max-width: 343px;
  }
`
const DesktopLayout = styled(BaseLayout)`
  > div {
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto min-content auto min-content auto;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    gap: 12px;
    align-items: center;
    justify-items: center;
  }
`

function SwellAmount({
  amount,
  largeIcon,
}: {
  amount: string
  largeIcon?: boolean
}) {
  return (
    <FlexRow gap="6" justify="center">
      <TokenLogo token={TOKEN_LIST_SWELL} size={largeIcon ? 32 : 24} />
      <span>{amount}</span>
      <span>{TOKEN_LIST_SWELL.symbol}</span>
    </FlexRow>
  )
}

function Plus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      style={{ width: '33px', height: '32px' }}
      viewBox="0 0 33 32"
      fill="none"
      overflow={'visible'}
    >
      <g clip-path="url(#clip0_8943_4037)">
        <path
          d="M5.5 16H27.5"
          stroke="#2F43EC"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.5 5V27"
          stroke="#2F43EC"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8943_4037">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

function Equals() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      style={{ width: '33px', height: '32px' }}
      viewBox="0 0 33 32"
      fill="none"
      overflow={'visible'}
    >
      <g clip-path="url(#clip0_8943_4041)">
        <path
          d="M5.5 20H27.5"
          stroke="#2F43EC"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.5 12H27.5"
          stroke="#2F43EC"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8943_4041">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
