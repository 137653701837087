import { css } from 'styled-components'

export const buttonThinCSS = css`
  height: unset;
  color: var(--white-50, #fff);
  /* Body/medium bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 25.6px */
  padding: 8px 16px;
`

const title1CSSDesktop = css`
  color: var(--white-50, #fff);
  /* Headlines/H3 */
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 57.6px */
  letter-spacing: -1.44px;
`
const title1MobileCSS = css`
  color: var(--white-50, #fff);
  /* Headlines/H5 */
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 38.4px */
  letter-spacing: -0.96px;
`
export const title1CSS = css`
  ${title1MobileCSS}
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      ${title1CSSDesktop}
    }
  `}
`
const title2CSSDesktop = css`
  color: var(--white-50, #fff);
  /* Headlines/H5 */
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 38.4px */
  letter-spacing: -0.96px;
`
const title2CSSMobile = css`
  color: var(--white-50, #fff);
  /* Headlines/H5 */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 31.2px */
  letter-spacing: -0.72px;
`

export const title2CSS = css`
  ${title2CSSMobile}
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      ${title2CSSDesktop}
    }
  `}
`

export const largeParagraphHeadingCSS = css`
  color: var(--white-50, #fff);
  /* Headlines/H6 */
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 33.8px */
  letter-spacing: -0.78px;
`

export const paragraphMajorCSS = css`
  color: var(--white-50, #fff);
  /* Body/large */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 38.4px */
  letter-spacing: -0.72px;
`
export const paragraphHintCSS = css`
  color: var(--white-125, #b0b0b0);
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
`

export const largeCapsColoredCSS = css`
  color: var(--lightblue-50, #a4abf1);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 32px */
  letter-spacing: 1.5px;
  text-transform: uppercase;
`
export const smallCapsColoredCSS = css`
  color: var(--lightblue-50, #a4abf1);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: 1.05px;
  text-transform: uppercase;
`
export const bodyTextCSS = css`
  color: var(--white-125, #b0b0b0);
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
`

export const bigValueCSS = css`
  color: var(--white-50, #fff);
  /* Headlines/H5 */
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 38.4px */
  letter-spacing: -0.96px;
`

export const medValueCSS = css`
  /* Headlines/H7 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
  letter-spacing: -0.6px;
`
