import { StepperProgress } from '@/components/StepperProgress'
import styled from 'styled-components'
import { ReactNode } from 'react'
import { FlexRow } from '@/swell-ui/FlexRow'
import { DecoRoundButton } from '../DecoBox/DecoRoundButton'
import { css } from 'styled-components'

export function StepperControls({
  onClickNext,
  onClickBack,
  currentStep,
  disabled,
  children,
  showStepper,
  firstVisibleStep,
  lastVisibleStep,
}: {
  disabled?: boolean
  currentStep: number
  onClickNext: () => void
  onClickBack: () => void
  children?: ReactNode
  showStepper?: boolean
  firstVisibleStep: number
  lastVisibleStep: number
}) {
  const hideAll = !showStepper

  const numSteps = lastVisibleStep - firstVisibleStep + 1

  const visibleStep = currentStep - firstVisibleStep

  const isFirstStep = visibleStep === 0
  const isLastStep = currentStep - firstVisibleStep === numSteps - 1

  const backDisabled = isFirstStep || disabled
  const nextDisabled = isLastStep || disabled

  const backHidden = isFirstStep || hideAll
  const nextHidden = isLastStep || hideAll

  if (currentStep < firstVisibleStep || currentStep > lastVisibleStep) {
    return (
      <Layout>
        <FlexRow direction="column" className="content">
          <div>
            <div>{children}</div>
          </div>
        </FlexRow>
      </Layout>
    )
  }

  return (
    <LayoutMob align="stretch">
      <FlexRow direction="column" gap="12" className="mid">
        <FlexRow direction="column" className="content">
          <div>
            <div>{children}</div>
          </div>
        </FlexRow>
        <ProgressLayout
          direction="column"
          margin="0 0"
          width="100%"
          maxWidth="342px"
          align="center"
          gap="12"
          style={{ visibility: hideAll ? 'hidden' : 'visible' }}
        >
          <StepperProgress
            currentStep={visibleStep}
            numSteps={numSteps}
            disabled={disabled}
          />
        </ProgressLayout>
      </FlexRow>
      <div className="side left">
        <ArrowButtonBox
          width="61px"
          height="61px"
          round
          onClick={onClickBack}
          disabled={backDisabled}
          hide={backHidden}
        >
          <ArrowLeft />
        </ArrowButtonBox>
      </div>
      <div className="side right">
        <ArrowButtonBox
          width="61px"
          height="61px"
          round
          onClick={onClickNext}
          disabled={nextDisabled}
          hide={nextHidden}
        >
          <ArrowRight />
        </ArrowButtonBox>
      </div>
    </LayoutMob>
  )
}

const Layout = styled(FlexRow)`
  flex-grow: 1;
  .mid {
  }
  .content {
    margin: auto 0;
  }

  *[aria-hidden='true'] {
    visibility: hidden;
  }
  margin-bottom: 24px;
`

const LayoutMob = styled(Layout)`
  position: relative;
  .side {
    position: fixed;
    z-index: 100;

    &.left {
      top: 50%;
      left: 6px;
      transform: translateY(-50%);

      @media (min-width: 740px) {
        left: unset;
      }
      @media (min-width: 2064px) {
        left: 50%;
        transform: translateY(-50%) translateX(-1008px);
      }
    }
    &.right {
      top: 50%;
      right: 6px;
      transform: translateY(-50%);

      @media (min-width: 740px) {
        right: 36px;
      }
      @media (min-width: 2064px) {
        right: 50%;
        transform: translateY(-50%) translateX(1008px);
      }
    }
  }

  .content {
    padding: 0 32px;
    flex-grow: 1;

    position: relative;
    > div {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      overflow-x: hidden;
      overflow-y: auto;

      &,
      > div {
        display: flex;
        flex-flow: column nowrap;
        flex: 1 0 auto;
        ${({ theme }) =>
          theme.scrollbar &&
          css`
            &::-webkit-scrollbar {
              width: ${theme.scrollbar.width};
            }

            &::-webkit-scrollbar-track {
              background: ${theme.scrollbar.trackBg};
              border-radius: ${theme.scrollbar.borderRadius};
            }

            &::-webkit-scrollbar-thumb {
              background: ${theme.scrollbar.thumbBg};
              border-radius: ${theme.scrollbar.borderRadius};
            }
          `}
      }
    }
  }
`

const ProgressLayout = styled(FlexRow)`
  flex: 0 0 auto;
  display: flex;

  margin-top: 12px;
`

const ArrowButtonBox = styled(DecoRoundButton)<{ hide?: boolean }>`
  position: relative;
  svg.arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translate(0, 4px);
  }
  /* transform: translateY(-36px); */
  align-self: start;

  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`

function ArrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      className="arrow"
    >
      <g filter="url(#filter0_dd_10648_3835)">
        <path
          d="M26.9404 15.0344C27.5117 15.0344 27.9749 14.5713 27.9749 14C27.9749 13.4287 27.5117 12.9656 26.9404 12.9656V15.0344ZM7.69043 12.9656H6.65598V15.0344H7.69043V12.9656ZM26.9404 12.9656L7.69043 12.9656V15.0344L26.9404 15.0344V12.9656Z"
          fill="white"
        />
        <path
          d="M19.9404 6.125L27.8154 14L19.9404 21.875"
          stroke="white"
          stroke-width="2.06889"
          stroke-linecap="square"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_10648_3835"
          x="-2.90679"
          y="0"
          width="40.4444"
          height="40.4444"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.22222" />
          <feGaussianBlur stdDeviation="3.11111" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_10648_3835"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.22222" />
          <feGaussianBlur stdDeviation="3.11111" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_10648_3835"
            result="effect2_dropShadow_10648_3835"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_10648_3835"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

function ArrowLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      className="arrow"
    >
      <g filter="url(#filter0_dd_10648_3833)">
        <path
          d="M8.69043 12.9656C8.11912 12.9656 7.65599 13.4287 7.65599 14C7.65599 14.5713 8.11912 15.0344 8.69043 15.0344V12.9656ZM27.9404 15.0344H28.9749V12.9656H27.9404V15.0344ZM8.69043 15.0344L27.9404 15.0344V12.9656L8.69043 12.9656V15.0344Z"
          fill="white"
        />
        <path
          d="M15.6904 21.875L7.81543 14L15.6904 6.125"
          stroke="white"
          stroke-width="2.06889"
          stroke-linecap="square"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_10648_3833"
          x="-1.90679"
          y="0"
          width="40.4444"
          height="40.4444"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.22222" />
          <feGaussianBlur stdDeviation="3.11111" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_10648_3833"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.22222" />
          <feGaussianBlur stdDeviation="3.11111" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_10648_3833"
            result="effect2_dropShadow_10648_3833"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_10648_3833"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
