import React from 'react'
import MuiCheckbox from '@mui/material/Checkbox'
import { FormControlLabel as MuiFormControlLabel } from '@mui/material'
import styled from 'styled-components'

export function Checkbox({
  onChange,
  value,
  label,
  disabled,
}: {
  value: boolean
  onChange: (value: boolean) => void
  label?: string
  disabled?: boolean
}) {
  return (
    <SFormControlLabel
      disabled={disabled}
      control={
        <SCheckbox
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          size="small"
        />
      }
      label={label}
    />
  )
}

const SFormControlLabel = styled(MuiFormControlLabel)`
  color: white;

  .MuiButtonBase-root:not(.Mui-checked) {
    .MuiSvgIcon-root {
      fill: white;
    }
  }

  &.Mui-disabled {
    .MuiTypography-root {
      color: rgba(255, 255, 255, 0.6);
    }

    .MuiButtonBase-root:not(.Mui-checked) {
      .MuiSvgIcon-root {
        fill: rgba(255, 255, 255, 0.6);
      }
    }
  }
`
const SCheckbox = styled(MuiCheckbox)``
