import { Token } from '@/types/tokens'
import { YearnVaultContext } from '../context'
import {
  IYearnVaultApiRead,
  IYearnVaultApiWrite,
  YearnVaultStats,
  YearnAllowances,
  YearnBalances,
  YearnAuthResult,
  YearnAssets,
  YearnWithdrawRequestResult,
  YearnVaultState,
} from '../types'
import {
  Multicall3,
  YearnDelayedWithdrawV2,
  YearnDelayedWithdrawV2__factory,
  YearnV3Vault,
  YearnV3Vault__factory,
} from '@/abis/types'
import {
  useIERC20Contract,
  useMulticallContract,
  useYearnDelayedWithdrawV2Contract,
  useYearnV3VaultContract,
} from '@/hooks/useContract'
import { useSwellWeb3 } from '@/swell-web3/core'
import { SolmateRolesAuthorityService } from '@/services/SolmateRolesAuthority'
import { TokenMulticalls } from '@/services/Tokens'
import { YearnAeraVault } from '@/types/yearnAera'
import { yearnAeraChooseWithdrawRequestV2 } from '@/util/yearnAera'
import { IPointsFetcher, IVaultStatsFetcher } from './deps'
import { BigNumber } from 'ethers'

/*
API V2 uses V2 of the DelayedWithdraw contract. The yearn vault is yearn V3.
It does not support request cancelation. Thus there is no claim deadline either.
Users are able to request up to maxWithdrawPerUser per asset.

(So far) vaults only have one withdraw asset.
*/

const REQUEST_WITHDRAW_SIGNATURE_V2 = '0xbec0cf19' // keccak256("requestWithdraw(address,uint96,bool)")
const COMPLETE_WITHDRAW_SIGNATURE_V2 = '0x795b7ed2' // keccak256("completeWithdraw(address,address,uint256)")

const ALLOW_THIRD_PARTY_TO_COMPLETE = false

export function useYearnVaultImplV2({
  statsFetcher,
  vault,
  pointsFetcher,
}: {
  pointsFetcher: IPointsFetcher
  vault: YearnAeraVault
  statsFetcher: IVaultStatsFetcher
}): YearnVaultContext {
  return {
    ...vault,
    read: useYearnVaultReadApi({
      vault,
      statsFetcher,
      pointsFetcher,
    }),
    write: useYearnVaultWriteImpl({
      vault,
    }),
  }
}
async function fetchVaultStateV2({
  multicall,
  vault,
}: {
  multicall: Multicall3
  vault: YearnAeraVault
}): Promise<YearnVaultState> {
  const calls: Multicall3.Call3Struct[] = [
    {
      target: vault.delayedWithdrawAddress,
      callData:
        YearnDelayedWithdrawV2__factory.createInterface().encodeFunctionData(
          'withdrawAssets',
          [vault.depositAsset.address]
        ),
      allowFailure: false,
    },
    {
      allowFailure: false,
      callData:
        YearnV3Vault__factory.createInterface().encodeFunctionData(
          'pricePerShare'
        ),
      target: vault.vaultToken.address,
    },
    {
      target: vault.vaultToken.address,
      callData:
        YearnV3Vault__factory.createInterface().encodeFunctionData(
          'isShutdown'
        ),
      allowFailure: false,
    },
    {
      target: vault.delayedWithdrawAddress,
      callData:
        YearnDelayedWithdrawV2__factory.createInterface().encodeFunctionData(
          'isPaused'
        ),
      allowFailure: false,
    },
  ]

  const results = await multicall.callStatic.tryAggregate(true, calls)
  const withdrawAssetStruct =
    YearnDelayedWithdrawV2__factory.createInterface().decodeFunctionResult(
      'withdrawAssets',
      results[0].returnData
    )
  const pricePerShare =
    YearnV3Vault__factory.createInterface().decodeFunctionResult(
      'pricePerShare',
      results[1].returnData
    )[0]
  const depositPaused =
    YearnV3Vault__factory.createInterface().decodeFunctionResult(
      'isShutdown',
      results[2].returnData
    )[0]
  const withdrawPaused =
    YearnDelayedWithdrawV2__factory.createInterface().decodeFunctionResult(
      'isPaused',
      results[3].returnData
    )[0]

  const depositAsset = vault.depositAsset
  const allowWithdraws = withdrawAssetStruct.allowWithdraws

  const maxLossBasisPoints = withdrawAssetStruct.maxLoss
  const withdrawDelaySeconds = withdrawAssetStruct.withdrawDelay
  const withdrawFeeBasisPoints = withdrawAssetStruct.withdrawFee

  const withdrawAsset: YearnAssets['withdrawAsset'] = {
    ...vault.depositAsset,
    allowWithdraws,
    maxLossBasisPoints,
    withdrawDelaySeconds,
    withdrawFeeBasisPoints,
    version: 'v2',
    maxWithdrawPerUser: BigNumber.from(
      withdrawAssetStruct.maxWithdrawPerUser
    ).toNumber(),
  }

  return {
    depositAsset,
    depositPaused,
    pricePerShare,
    withdrawAsset,
    withdrawPaused,
  }
}

async function fetchAllowances(
  multicall: Multicall3,
  vaultTokenAddress: string,
  depositAssetAddress: string,
  delayedWithdrawalsAddress: string,
  account: string
): Promise<YearnAllowances> {
  const tmc = new TokenMulticalls(multicall)

  const [
    { allowance: depositAssetForVault },
    { allowance: vaultTokenForWithdrawals },
  ] = await tmc.fetchAllowances(
    [
      {
        spender: vaultTokenAddress,
        token: depositAssetAddress,
      },
      {
        spender: delayedWithdrawalsAddress,
        token: vaultTokenAddress,
      },
    ],
    account
  )

  return {
    depositAssetForVault,
    vaultTokenForWithdrawals,
  }
}

async function fetchBalances(
  multicall: Multicall3,
  vaultTokenAddress: string,
  depositAssetAddress: string,
  account: string
): Promise<YearnBalances> {
  const tmc = new TokenMulticalls(multicall)

  const [{ balance: vaultTokenBalance }, { balance: depositAssetBalance }] =
    await tmc.fetchBalances([vaultTokenAddress, depositAssetAddress], account)

  return {
    vaultToken: vaultTokenBalance,
    depositAsset: depositAssetBalance,
  }
}

async function fetchVaultStats(
  statsFetcher: IVaultStatsFetcher,
  vaultContract: YearnV3Vault
): Promise<YearnVaultStats> {
  const totalAssets = await vaultContract.totalAssets()
  const { apr, totalDepositors } = await statsFetcher.stats()

  return {
    apr,
    totalAssets,
    totalDepositors,
    feePercent: 0,
  }
}

async function fetchYearnAuthV2(
  multicall: Multicall3,
  rolesAuthorityAddress: string,
  delayedWithdrawalsAddress: string,
  account: string
): Promise<YearnAuthResult> {
  const rolesAuth = new SolmateRolesAuthorityService(
    multicall,
    rolesAuthorityAddress
  )

  const [{ canCall: canRequestWithdraw }, { canCall: canCompleteWithdraw }] =
    await rolesAuth.fetchCanCall([
      {
        user: account,
        target: delayedWithdrawalsAddress,
        sig: REQUEST_WITHDRAW_SIGNATURE_V2,
      },
      {
        user: account,
        target: delayedWithdrawalsAddress,
        sig: COMPLETE_WITHDRAW_SIGNATURE_V2,
      },
    ])

  return {
    canDeposit: true,
    canRequestWithdraw,
    canCompleteWithdraw,
    canCancelWithdraw: false, // not supported
  }
}

async function fetchYearnWithdrawRequestV2(
  delayedWithdrawal: YearnDelayedWithdrawV2,
  depositAsset: Token,
  account: string
): Promise<YearnWithdrawRequestResult> {
  const [requests, keys, lastIdx] =
    await delayedWithdrawal.getAllWithdrawRequests(
      account,
      depositAsset.address
    )

  // if there are no requests, return false
  // if there are claimable requests return the oldest one
  // if all requests are pending, return the oldest one
  const chosen = yearnAeraChooseWithdrawRequestV2({
    nowUnix: Math.floor(Date.now() / 1000),
    onchainRequests: {
      requests,
      keys,
      lastIdx,
    },
  })

  return chosen
}

function useYearnVaultReadApi({
  vault,
  statsFetcher,
  pointsFetcher,
}: {
  vault: YearnAeraVault
  statsFetcher: IVaultStatsFetcher
  pointsFetcher: IPointsFetcher
}): IYearnVaultApiRead {
  const { account: maybeAccount } = useSwellWeb3()
  const multicall = useMulticallContract()
  const vaultContract = useYearnV3VaultContract(vault.vaultToken.address)
  const delayedWithdrawalV2 = useYearnDelayedWithdrawV2Contract(
    vault.delayedWithdrawAddress
  )

  const account = maybeAccount!

  return {
    userPoints: async () => {
      return pointsFetcher.userPoints()
    },
    vaultPoints: async () => {
      return pointsFetcher.vaultPoints()
    },
    vaultStats: async () => {
      if (vault.withdrawType !== 'v2') {
        throw new Error('Not supported')
      }
      return fetchVaultStats(statsFetcher, vaultContract)
    },
    vaultState: async () => {
      if (vault.withdrawType !== 'v2') {
        throw new Error('Not supported')
      }
      return fetchVaultStateV2({
        multicall,
        vault,
      })
    },
    allowances: async () => {
      return fetchAllowances(
        multicall,
        vault.vaultToken.address,
        vault.depositAsset.address,
        vault.delayedWithdrawAddress,
        account
      )
    },
    balances: async () => {
      return fetchBalances(
        multicall,
        vault.vaultToken.address,
        vault.depositAsset.address,
        account
      )
    },
    auth: async () => {
      if (vault.withdrawType !== 'v2') {
        throw new Error('Not supported')
      }
      return fetchYearnAuthV2(
        multicall,
        vault.delayedWithdrawAuthAddress,
        vault.delayedWithdrawAddress,
        account
      )
    },
    withdrawRequest: async () => {
      if (vault.withdrawType !== 'v2') {
        throw new Error('Not supported')
      }

      return fetchYearnWithdrawRequestV2(
        delayedWithdrawalV2,
        vault.depositAsset,
        account
      )
    },
    checkDeposit: async (params) => {
      return true // TODO
    },
    checkRequestWithdraw: async (params) => {
      return true // TODO
    },
  }
}

function useYearnVaultWriteImpl({
  vault,
}: {
  vault: YearnAeraVault
}): IYearnVaultApiWrite {
  const { account: maybeAccount } = useSwellWeb3()
  const vaultContract = useYearnV3VaultContract(vault.vaultToken.address)
  const depositAssetContract = useIERC20Contract(vault.depositAsset.address)!
  const delayedWithdrawalV2 = useYearnDelayedWithdrawV2Contract(
    vault.delayedWithdrawAddress
  )

  const account = maybeAccount!
  const allowThirdPartyToComplete = ALLOW_THIRD_PARTY_TO_COMPLETE

  return {
    // deposit
    deposit: async ({ amount }, opts) => {
      return vaultContract.deposit(amount, account, opts)
    },
    depositEstimateGas: async ({ amount }) => {
      return vaultContract.estimateGas.deposit(amount, account)
    },
    approveAssetForDeposit: async ({ amount }, opts) => {
      return depositAssetContract.approve(
        vault.vaultToken.address,
        amount,
        opts
      )
    },
    approveAssetForDepositEstimateGas: async ({ amount }) => {
      return depositAssetContract.estimateGas.approve(
        vault.vaultToken.address,
        amount
      )
    },
    // request withdraw
    requestWithdraw: async ({ shares, maxLossBasisPoints }, opts) => {
      if (maxLossBasisPoints !== 0) {
        throw new Error('Not supported')
      }
      return delayedWithdrawalV2.requestWithdraw(
        vault.depositAsset.address,
        shares,
        allowThirdPartyToComplete,
        opts
      )
    },
    requestWithdrawEstimateGas: async ({ shares, maxLossBasisPoints }) => {
      if (maxLossBasisPoints !== 0) {
        throw new Error('Not supported')
      }
      return delayedWithdrawalV2.estimateGas.requestWithdraw(
        vault.depositAsset.address,
        shares,
        allowThirdPartyToComplete
      )
    },
    approveVaultTokenForWithdraw: async ({ amount }, opts) => {
      return vaultContract.approve(vault.delayedWithdrawAddress, amount, opts)
    },
    approveVaultTokenForWithdrawEstimateGas: async ({ amount }) => {
      return vaultContract.estimateGas.approve(
        vault.delayedWithdrawAddress,
        amount
      )
    },
    // cancel withdraw
    cancelWithdraw: async () => {
      throw new Error('Not supported')
    },
    cancelWithdrawEstimateGas: async () => {
      throw new Error('Not supported')
    },
    // complete withdraw
    completeWithdraw: async (params, opts) => {
      if (params.version !== 'v2') {
        throw new Error('Not supported')
      }
      const { withdrawalIdx } = params
      if (withdrawalIdx === undefined) {
        throw new Error('withdrawalIdx is required')
      }
      return delayedWithdrawalV2.completeWithdraw(
        vault.depositAsset.address,
        account,
        withdrawalIdx,
        opts
      )
    },
    completeWithdrawEstimateGas: async (params) => {
      if (params.version !== 'v2') {
        throw new Error('Not supported')
      }
      const { withdrawalIdx } = params
      if (withdrawalIdx === undefined) {
        throw new Error('withdrawalIdx is required')
      }
      return delayedWithdrawalV2.estimateGas.completeWithdraw(
        vault.depositAsset.address,
        account,
        withdrawalIdx
      )
    },
  }
}
