import { IErrorTransport } from './types'
import { enrichError } from './util'
import * as Sentry from '@sentry/react'

export class SentryErrorTransport implements IErrorTransport {
  onError: IErrorTransport['onError'] = async (err) => {
    let errObj: any
    try {
      errObj = await enrichError(err)
    } catch (e) {
      console.error('log error', { e, err })
      return
    }

    console.error('Error:', errObj, JSON.stringify(errObj))

    Sentry.captureException(errObj)
  }
}

export class ConsoleErrorTransport implements IErrorTransport {
  onError: IErrorTransport['onError'] = async (err) => {
    let errObj: any
    try {
      errObj = await enrichError(err)
    } catch (e) {
      console.error('log error', { e, err })
    }

    console.error('Error:', errObj, JSON.stringify(errObj))
  }
}

export class SilentErrorTransport implements IErrorTransport {
  onError: IErrorTransport['onError'] = async () => {}
}
