import { EarnAPY } from '@/submodules/v3-shared/ts/connect/swell/v3/stats_pb'
import {
  UserEarnCampaign as APIUserEarnCampaign,
  BlackPearlsWavedrop,
  UserPortfolioHoldingsResponse,
} from '@/submodules/v3-shared/ts/connect/swell/v3/wallet_pb'
import {
  EarnAsset,
  EarnBlackPearlsWavedrop,
  EarnHoldings,
  EarnPortfolioPosition,
  EarnPortfolioPositionWithoutBalance,
  EarnProtocol,
  UserEarnCampaign,
} from '@/types/earn'
import {
  EarnFilters,
  EarnPositionsOrderBy,
  PositionBalanceMessage,
} from '../state/earn/types'
import { SortingDirection } from '@/types/sorting'
import { MaybeRangeAPR } from '@/types/apr'

export function parseEarnHoldings(
  data: UserPortfolioHoldingsResponse
): EarnHoldings {
  return {
    numAssetsHeld: data.numAssetsHeld,
    totalAssetBalanceUsd: data.totalAssetBalanceCents / 100,
    numParticipatingCampaigns: data.numParticipatingCampaigns,
  }
}

export function parseCampaign(data: APIUserEarnCampaign): UserEarnCampaign {
  return {
    claimableAssets: data.claimableAssets,
    claimableAssetSymbol: data.claimableAssetSymbol,
    points: data.points,
    isClaimable: data.isClaimable,
  }
}
export function parseCampaignOrDefault(
  data: APIUserEarnCampaign | undefined
): UserEarnCampaign {
  if (!data) {
    return {
      claimableAssets: 0,
      claimableAssetSymbol: '',
      points: 0,
      isClaimable: false,
    }
  }
  return parseCampaign(data)
}

export function parseBlackPearlsCampaign(
  data: BlackPearlsWavedrop
): EarnBlackPearlsWavedrop {
  const campaign = parseCampaignOrDefault(data.userCampaign)
  return {
    ...campaign,
    waveNumber: data.waveNumber,
    endTimeMs: data.endUnix * 1000,
  }
}

export function parseEarnPortfolioPosition(
  data: EarnAPY
): EarnPortfolioPositionWithoutBalance {
  let apr: MaybeRangeAPR
  if (data.apy.length === 0) {
    apr = { type: 'scalar', aprPercent: 0 }
  } else if (data.apy.length === 1) {
    apr = { type: 'scalar', aprPercent: data.apy[0] }
  } else {
    apr = {
      type: 'range',
      basePercent: data.apy[0],
      maxPercent: data.apy[1],
    }
  }

  const assets: EarnAsset[] = []

  for (const symbol of data.positionTokens) {
    assets.push({
      symbol,
      logoURI: '',
    })
  }
  const numIcons = Math.min(
    data.tokenIconList.length,
    data.positionTokens.length
  )
  for (let i = 0; i < numIcons; i++) {
    assets[i].logoURI = data.tokenIconList[i]
  }
  let boostMultiplier = 0
  if (data.boost) {
    boostMultiplier = data.boost
  }

  let category = ''
  if (data.category) {
    category = data.category
  }

  const protocol: EarnProtocol = { logoURI: '', name: '' }
  if (data.logo) {
    protocol.logoURI = data.logo
  }
  if (data.protocol) {
    protocol.name = data.protocol
  }

  let tvlUsd = 0
  if (data.tvl) {
    tvlUsd = data.tvl
  }

  return {
    chainId: data.chainId,
    apr,
    assets,
    boostMultiplier,
    category,
    protocol,
    tvlUsd,
    link: data.link,
    positionName: data.positionName,
  }
}

export function applyPortfolioSortingAndFilters({
  balanceMessages,
  globalPositionMap,
  filters,
  orderBy,
  orderDirection,
}: {
  globalPositionMap: Record<string, EarnPortfolioPositionWithoutBalance>
  balanceMessages: PositionBalanceMessage[]
  filters: EarnFilters
  orderBy: EarnPositionsOrderBy
  orderDirection: SortingDirection
}) {
  const positionToBalanceUsdMap = new Map<string, number>()
  for (const msg of balanceMessages) {
    if (msg.balanceType === 'balanceUsd') {
      positionToBalanceUsdMap.set(msg.positionName, msg.balance)
    }
  }

  const positionToPearlsMap = new Map<string, number>()
  for (const msg of balanceMessages) {
    if (msg.balanceType === 'pearls') {
      positionToPearlsMap.set(msg.positionName, msg.balance)
    }
  }

  let positions: EarnPortfolioPosition[] = []
  for (const [positionName, positionWithoutBalance] of Object.entries(
    globalPositionMap
  )) {
    const balanceUsd = positionToBalanceUsdMap.get(positionName)
    const pearls = positionToPearlsMap.get(positionName)

    positions.push({
      ...positionWithoutBalance,
      pearls:
        pearls !== undefined
          ? { exists: true, balance: pearls }
          : { exists: false },
      balanceUsd:
        balanceUsd !== undefined
          ? { exists: true, balance: balanceUsd }
          : { exists: false },
    })
  }

  if (filters.hideZeroBalances) {
    positions = positions.filter(
      (position) =>
        position.balanceUsd.exists && position.balanceUsd.balance > 0
    )
  }

  if (filters.chainIds) {
    positions = positions.filter((position) =>
      filters.chainIds!.includes(position.chainId)
    )
  }

  if (filters.includeTokenSymbols && filters.includeTokenSymbols.length) {
    const lowercaseSymbols = filters.includeTokenSymbols.map((s) =>
      s.toLowerCase()
    )

    positions = positions.filter((position) => {
      for (const asset of position.assets) {
        if (lowercaseSymbols.includes(asset.symbol.toLowerCase())) {
          return true
        }
      }
      return false
    })
  }

  if (filters.searchTerm) {
    positions = positions.filter((position) => {
      const matchesCategory = position.category
        .toLowerCase()
        .includes(filters.searchTerm!.toLowerCase())
      const matchesProtocol = position.protocol.name
        .toLowerCase()
        .includes(filters.searchTerm!.toLowerCase())

      let matchesAsset = false
      for (const asset of position.assets) {
        if (
          asset.symbol.toLowerCase().includes(filters.searchTerm!.toLowerCase())
        ) {
          matchesAsset = true
          break
        }
      }

      return matchesCategory || matchesProtocol || matchesAsset
    })
  }

  const compareFnProtocol = (
    a: EarnPortfolioPosition,
    b: EarnPortfolioPosition
  ) => a.protocol.name.localeCompare(b.protocol.name)
  const defaultSortFn = compareFnProtocol

  let defaultDirection: SortingDirection = 'asc'
  let sortFn: (a: EarnPortfolioPosition, b: EarnPortfolioPosition) => number
  if (orderBy === 'protocol') {
    sortFn = compareFnProtocol
  } else if (orderBy === 'asset') {
    sortFn = (a, b) => {
      let aSymbol = ''
      if (a.assets.length > 0) {
        aSymbol = a.assets[0].symbol
      }
      let bSymbol = ''
      if (b.assets.length > 0) {
        bSymbol = b.assets[0].symbol
      }
      return aSymbol.localeCompare(bSymbol)
    }
  } else if (orderBy === 'category') {
    sortFn = (a, b) => a.category.localeCompare(b.category)
  } else if (orderBy === 'tvl') {
    sortFn = (a, b) => a.tvlUsd - b.tvlUsd
    defaultDirection = 'desc'
  } else if (orderBy === 'apr') {
    sortFn = (a, b) => {
      const aApr =
        a.apr.type === 'scalar' ? a.apr.aprPercent : a.apr.basePercent
      const bApr =
        b.apr.type === 'scalar' ? b.apr.aprPercent : b.apr.basePercent
      return aApr - bApr
    }
    defaultDirection = 'desc'
  } else if (orderBy === 'multiplier') {
    sortFn = (a, b) => a.boostMultiplier - b.boostMultiplier
    defaultDirection = 'desc'
  } else if (orderBy === 'pearls') {
    sortFn = (a, b) => {
      let aBalance = -1
      if (a.pearls.exists) {
        aBalance = a.pearls.balance
      }
      let bBalance = -1
      if (b.pearls.exists) {
        bBalance = b.pearls.balance
      }
      return aBalance - bBalance
    }
    defaultDirection = 'desc'
  } else if (orderBy === 'balance') {
    sortFn = (a, b) => {
      let aBalance = -1
      if (a.balanceUsd.exists) {
        aBalance = a.balanceUsd.balance
      }
      let bBalance = -1
      if (b.balanceUsd.exists) {
        bBalance = b.balanceUsd.balance
      }
      return aBalance - bBalance
    }
    defaultDirection = 'desc'
  } else {
    sortFn = defaultSortFn
  }

  if (orderDirection === 'unspecified') {
    orderDirection = defaultDirection
  }

  if (orderDirection === 'asc') {
    positions.sort(sortFn)
  } else {
    positions.sort((a, b) => sortFn(b, a))
  }

  return positions
}
