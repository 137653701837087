import useSWRImmutable from 'swr/immutable'
import { useSwETHContractView } from '@/hooks/useContract'

export const useSwEthTotalSupply = () => {
  const swEth = useSwETHContractView()
  return useSWRImmutable(
    swEth ? ['totalSupply', swEth.address] : null,
    async () => ({
      totalSupply: await swEth!.totalSupply(),
    })
  )
}
