import styled from 'styled-components'
import { css } from 'styled-components'
import { Typography } from '@/swell-ui/Typography'
import { ThemeData } from '@/swell-ui/theme/branding'
import {
  useNucleusBtcVaultCalls,
  useNucleusEthVaultCalls,
} from '@/state/nucleusVault/hooks'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { displayFiat } from '@/util/displayFiat'
import { EthEarnVaultWidget } from './EthEarnVaultWidget'
import { ColoredHeadingTypography } from '@/swell-ui/Typography/typographyPresets'
import { TransactionContext } from '@/state/transactions/context'
import { useWeb3CallErrorNotify } from '@/hooks/useWeb3CallErrorNotify'
import { useEveryNthBlock } from '@/hooks/useBlockNumber'
import { useNucleusGlobalStats } from '@/state/nucleusGlobal/hooks'
import { useEarnVaultClearTxOnSuccess } from './hooks'

export function EarnVaults() {
  const globalStatsQuery = useNucleusGlobalStats()
  useEveryNthBlock({
    blocksPerFetch: 4,
    fetch: () => {
      globalStatsQuery.mutate()
    },
  })

  const globalStats = globalStatsQuery.data

  const nucleusBtcCalls = useNucleusBtcVaultCalls()
  const nucleusEthCalls = useNucleusEthVaultCalls()

  let tvlStr: string | undefined
  if (globalStats) {
    tvlStr = displayFiat(globalStats.tvlCents / 100)
  }

  const anyTransactionInProgress = [
    ...Object.values(nucleusBtcCalls),
    ...Object.values(nucleusEthCalls),
  ].some(
    (call) =>
      call.status === call.STATUS.PENDING ||
      call.status === call.STATUS.PROMPTING
  )

  for (const call of Object.values(nucleusBtcCalls)) {
    useWeb3CallErrorNotify(call)
  }
  for (const call of Object.values(nucleusEthCalls)) {
    useWeb3CallErrorNotify(call)
  }

  useEarnVaultClearTxOnSuccess({ calls: nucleusEthCalls })
  useEarnVaultClearTxOnSuccess({ calls: nucleusBtcCalls })

  return (
    <TransactionContext.Provider value={{ anyTransactionInProgress }}>
      <Layout className="v-page-layout">
        <div className="v-title">
          <Title />
        </div>
        <div className="v-description">
          <Typography
            variant="body"
            xs="medium"
            md="medium"
            size="medium"
            letterSpacing="small"
          >
            A vault aggregator that earns yield on your Swell staked assets in
            DeFi.
          </Typography>
        </div>

        <div className="v-tvl">
          <ColoredHeadingTypography>
            Total earn vaults TVL
          </ColoredHeadingTypography>
          <AsyncDiv loading={!tvlStr}>
            {() => <span className="value">{tvlStr}</span>}
          </AsyncDiv>
        </div>

        <div className="v-widgets">
          <div className="v-widget">
            <EthEarnVaultWidget calls={nucleusEthCalls} />
          </div>
          {/* <div className="v-widget">
            <BtcEarnVaultWidget calls={nucleusBtcCalls} />
          </div> */}
        </div>
      </Layout>
    </TransactionContext.Provider>
  )
}

const TitleT = styled(Typography)`
  color: ${ThemeData.Symbiotic.symbioticTitleGrey};
  .btc {
    color: ${ThemeData.Symbiotic.symbioticBtcOrange};
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    white-space: nowrap;
    br {
      display: none;
    }
  }
`
function Title() {
  return (
    <TitleT
      variant="headline"
      xs="h3"
      md="h2"
      size="h2"
      fstyle="bold"
      letterSpacing="small"
    >
      Swell Earn Vaults
    </TitleT>
  )
}

const mobileLayout = css`
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  align-items: center;
  justify-items: center;
  grid-template-areas:
    'title'
    'description'
    'tvl'
    'widgets';
  gap: 24px;

  .v-title,
  .v-description {
    text-align: left;
  }

  .v-title,
  .v-description {
    justify-self: start;
  }

  .v-tvl {
    text-align: left;
    align-self: start;
    justify-self: start;

    [aria-busy='true'] {
      height: 38.4px;
    }
  }
`
const tabletLayout = css`
  margin: 0 auto;

  padding: 0 12px;

  grid-template-columns: 1fr auto;
  grid-auto-rows: auto;
  grid-template-areas:
    'title tvl'
    'description tvl'
    'widgets widgets';
  gap: unset;
  row-gap: 16px;

  .v-tvl {
    text-align: right;
    align-self: end;

    [aria-busy='true'] {
      height: 49px;
    }
  }
  .v-description {
    white-space: nowrap;
  }
`
const desktopLayout = css``

const Layout = styled.div`
  max-width: 1100px;

  width: 100%;

  display: grid;

  .v-title {
    grid-area: title;
  }
  .v-description {
    grid-area: description;
    color: #fff;
  }
  .v-tvl {
    grid-area: tvl;
    display: flex;
    flex-flow: column nowrap;
    gap: 12px;

    .value {
      color: var(--Swell-White-50, #fff);
      /* Headlines/H4 */
      font-family: Inter;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 48px */
      letter-spacing: -1.2px;

      ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 32px;
      }
    }
  }
  .v-widgets {
    grid-area: widgets;
    margin-top: 84px;
    width: 100%;

    display: flex;
    flex-flow: column nowrap;
    gap: 24px;

    .v-widget {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 42px;
      }
    }
  }

  ${mobileLayout}
  @media (min-width: 900px) {
    ${tabletLayout}
  }
  @media (min-width: 1391px) {
    ${desktopLayout}
  }
`
