import useSWRImmutable from 'swr/immutable'
import axios from 'axios'
import { BigNumber } from 'ethers'
import { useSwellWeb3 } from '@swell-web3/core'
import {
  useSwETHContractView,
  useSwExitContractView,
} from '@/hooks/useContract'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import { Claim } from '@/types/claims'

export function useSwEthBalance() {
  const { account, chainId } = useSwellWeb3()

  const swEth = useSwETHContractView()

  return useSWRImmutable(
    account && swEth ? [swEth.address, chainId, 'balanceOf', account] : null,
    async () => ({ balance: await swEth!.balanceOf(account!) })
  )
}

export function useEthBalance() {
  const { account, chainId, provider } = useSwellWeb3()

  return useSWRImmutable(
    account ? [chainId, 'getBalance', account] : null,
    async () => {
      return {
        balance: await provider!.getBalance(account!),
      }
    }
  )
}

// gets unclaimed swExit NFT balance
export function useSwExitBalance() {
  const { account, chainId } = useSwellWeb3()

  const swExit = useSwExitContractView()

  return useSWRImmutable(
    account && swExit ? [swExit.address, chainId, 'balanceOf', account] : null,
    async () => {
      const balanceBN = await swExit!.balanceOf(account!)
      const balance = balanceBN.toNumber()
      return { balance }
    }
  )
}

const NFT_METADATA_ATTR_TO_CLAIM_KEY: any = {
  'ETH Amount': 'ethAmount',
  'swETH Amount': 'swethAmount',
  Status: 'status',
  'Queue Position': 'queuePosition',
  'Total In Queue': 'totalInQueue',
  'Request Timestamp': 'requestTimestamp',
  'Exchange Rate': 'exchangeRate',
}

type TokenMetadata = {
  attributes: any[]
}

const claimAdapter = (tokenId: number, tokenMetadata: TokenMetadata): Claim => {
  const claim = {} as any
  tokenMetadata.attributes.forEach((attribute: any) => {
    const key: any = NFT_METADATA_ATTR_TO_CLAIM_KEY[attribute.trait_type]
    claim[key] = attribute.value
  })
  claim.tokenId = tokenId
  return claim as Claim
}

export async function fetchClaim(v3BackendLstUrl: string, tokenId: number) {
  const res = await axios.get(
    `${v3BackendLstUrl}/api/swexit/sweth/metadata/${tokenId}`
  )

  return claimAdapter(tokenId, res.data)
}

export function useClaims() {
  const { account, chainId } = useSwellWeb3()
  const { v3BackendLstUrl } = useDeploymentSetConfig()

  const swExit = useSwExitContractView()

  return useSWRImmutable(
    account ? ['claims', v3BackendLstUrl, account] : null,
    async () => {
      // get swExit NFT balance
      const balanceBN = await swExit!.balanceOf(account!)
      const balanceInt = balanceBN.toNumber()

      if (balanceInt === 0) {
        return []
      }

      // get swExit NFT ids
      const tokenIdPromises: Promise<any>[] = []

      for (let i = 0; i < balanceInt; i++) {
        tokenIdPromises.push(swExit!.tokenOfOwnerByIndex(account!, i))
      }

      const tokenBNIds = await Promise.all(tokenIdPromises)
      const tokenIds = tokenBNIds.map((idBN: BigNumber) => {
        return idBN.toNumber()
      })

      const claims: Claim[] = []

      // get metadata for token ids
      const promises = tokenIds.map(async (tokenId: number) => {
        claims.push(await fetchClaim(v3BackendLstUrl, tokenId))
      })

      await Promise.all(promises)

      return claims
        .filter((claim) => claim.status !== 'Claimed')
        .sort((claimA, claimB) => claimA.status.localeCompare(claimB.status))
    }
  )
}
