import { useSwellWeb3 } from '@/swell-web3/core'
import {
  NucleusVaultContext,
  useNucleusBtcApi,
  useNucleusEthApi,
} from './context'
import useSWRImmutable from 'swr/immutable'
import { useWeb3Call } from '@/hooks/useWeb3Call'
import { getNucleusDepositAssetGasEstimate } from '@/constants/gasEstimates'

type UseNucleusApi = () => NucleusVaultContext

// makeNucleusVaultHooks is a factory function that creates a set of hooks for a specific Nucleus Vault implementation.
function makeNucleusVaultHooks(_key: string, useNucleusApi: UseNucleusApi) {
  function useVault() {
    const { read, write, ...vault } = useNucleusApi()
    return {
      ...vault,
    }
  }

  function useBalances() {
    const { account, chainId } = useSwellWeb3()
    const api = useNucleusApi()
    return useSWRImmutable(
      account ? [_key, 'balances', account, chainId] : null,
      () => api.read.balances()
    )
  }
  function useAllowances() {
    const { account, chainId } = useSwellWeb3()
    const api = useNucleusApi()
    return useSWRImmutable(
      account ? [_key, 'allowances', account, chainId] : null,
      () => api.read.allowances()
    )
  }
  function useAuth() {
    const { account } = useSwellWeb3()
    const api = useNucleusApi()
    return useSWRImmutable(account ? [_key, 'auth', account] : null, () =>
      api.read.auth()
    )
  }
  function useSharesState() {
    const { account } = useSwellWeb3()
    const api = useNucleusApi()
    return useSWRImmutable(
      account ? [_key, 'sharesState', account] : null,
      () => api.read.sharesState()
    )
  }
  function useWithdrawRequest() {
    const { account } = useSwellWeb3()
    const api = useNucleusApi()
    return useSWRImmutable(
      account ? [_key, 'withdrawRequest', account] : null,
      () => api.read.withdrawRequest()
    )
  }
  function useRecentFulfilledRequests() {
    const { account } = useSwellWeb3()
    const api = useNucleusApi()
    return useSWRImmutable(
      account ? [_key, 'recentFulfilledRequests', account] : null,
      () => api.read.recentFulfilledRequests()
    )
  }
  function useSupportedAssets() {
    const { chainId } = useSwellWeb3()
    const api = useNucleusApi()
    return useSWRImmutable([_key, 'supportedAssets', chainId], () =>
      api.read.supportedAssets()
    )
  }
  function useRates() {
    const { chainId } = useSwellWeb3()
    const api = useNucleusApi()
    return useSWRImmutable([_key, 'rates', chainId], () => api.read.rates())
  }
  function useVaultState() {
    const api = useNucleusApi()
    return useSWRImmutable([_key, 'vaultState'], () => api.read.vaultState())
  }
  function useVaultStats() {
    const { chainId } = useSwellWeb3()
    const api = useNucleusApi()
    return useSWRImmutable([_key, 'vaultStats', chainId], () =>
      api.read.stats()
    )
  }
  function usePoints() {
    const { account } = useSwellWeb3()
    const api = useNucleusApi()
    return useSWRImmutable(account ? [_key, 'points', account] : null, () =>
      api.read.points()
    )
  }

  function useDeposit() {
    const api = useNucleusApi()
    const { account } = useSwellWeb3()

    return useWeb3Call({
      estimateGas: api.write.depositEstimateGas,
      fn: api.write.deposit,
      staticGasEstimate: () => getNucleusDepositAssetGasEstimate(),
      validate: async (params) => {
        if (!account) throw new Error('no account')
        if (!params.depositAmount) throw new Error('no amount')
        if (!params.depositAsset) throw new Error('no asset')
        if (!params.minimumMint) throw new Error('no minimumMint')

        const ok = await api.read.checkDeposit({
          account: account,
          depositAmount: params.depositAmount,
          depositAsset: params.depositAsset,
          minimumMint: params.minimumMint,
        })
        if (!ok) return 'Invalid deposit'
        return null
      },
    })
  }

  function useApproveAssetForDeposit() {
    const api = useNucleusApi()
    return useWeb3Call({
      estimateGas: api.write.approveAssetForDepositEstimateGas,
      fn: api.write.approveAssetForDeposit,
      validate: async (params) => {
        if (!params.amount) throw new Error('no amount')
        return null
      },
    })
  }

  function useRequestWithdraw() {
    const api = useNucleusApi()
    return useWeb3Call({
      estimateGas: api.write.requestWithdrawalEstimateGas,
      fn: api.write.requestWithdrawal,
      validate: async (params) => {
        if (!params.withdrawRequest) throw new Error('no withdrawRequest')
        if (!params.wantAssetAddress) throw new Error('no wantAssetAddress')
        return null
      },
    })
  }

  function useApproveVaultTokenForAtomicQueue() {
    const api = useNucleusApi()
    return useWeb3Call({
      estimateGas: api.write.approveVaultTokenForAtomicQueueEstimateGas,
      fn: api.write.approveVaultTokenForAtomicQueue,
      validate: async (params) => {
        if (!params.amount) throw new Error('no amount')
        return null
      },
    })
  }

  function useCancelWithdraw() {
    const api = useNucleusApi()
    return useWeb3Call({
      estimateGas: api.write.cancelWithdrawalEstimateGas,
      fn: api.write.cancelWithdrawal,
      validate: async (params) => {
        if (!params.wantAssetAddress) throw new Error('no wantAssetAddress')
        return null
      },
    })
  }

  return {
    useVault,
    useBalances,
    useAllowances,
    useAuth,
    useSharesState,
    useWithdrawRequest,
    useRecentFulfilledRequests,
    useSupportedAssets,
    useRates,
    useVaultState,
    useVaultStats,
    usePoints,
    useDeposit,
    useApproveAssetForDeposit,
    useRequestWithdraw,
    useApproveVaultTokenForAtomicQueue,
    useCancelWithdraw,
  }
}

// ETH implementation
export const {
  useVault: useNucleusEthVault,
  useAllowances: useNucleusEthAllowances,
  useBalances: useNucleusEthBalances,
  useAuth: useNucleusEthAuth,
  useSharesState: useNucleusEthSharesState,
  useWithdrawRequest: useNucleusEthWithdrawRequest,
  useRecentFulfilledRequests: useNucleusEthRecentFulfilledRequests,
  useSupportedAssets: useNucleusEthSupportedAssets,
  useRates: useNucleusEthRates,
  useVaultState: useNucleusEthVaultState,
  useVaultStats: useNucleusEthVaultStats,
  usePoints: useNucleusEthPoints,
  useDeposit: useNucleusEthDeposit,
  useApproveAssetForDeposit: useNucleusEthApproveAssetForDeposit,
  useRequestWithdraw: useNucleusEthRequestWithdraw,
  useApproveVaultTokenForAtomicQueue:
    useNucleusEthApproveVaultTokenForAtomicQueue,
  useCancelWithdraw: useNucleusEthCancelWithdraw,
} = makeNucleusVaultHooks('eth-nucleus', useNucleusEthApi)

//
//    COMMON TYPES
//

export type NucleusDeposit = ReturnType<typeof useNucleusEthDeposit>
export type NucleusApproveAssetForDeposit = ReturnType<
  typeof useNucleusEthApproveAssetForDeposit
>
export type NucleusRequestWithdraw = ReturnType<
  typeof useNucleusEthRequestWithdraw
>
export type NucleusApproveVaultTokenForAtomicQueue = ReturnType<
  typeof useNucleusEthApproveVaultTokenForAtomicQueue
>
export type NucleusCancelWithdraw = ReturnType<
  typeof useNucleusEthCancelWithdraw
>
export type NucleusVaultCalls = {
  deposit: NucleusDeposit
  approveAssetForDeposit: NucleusApproveAssetForDeposit
  requestWithdraw: NucleusRequestWithdraw
  approveVaultTokenForAtomicQueue: NucleusApproveVaultTokenForAtomicQueue
  cancelWithdraw: NucleusCancelWithdraw
}
export function useNucleusEthVaultCalls(): NucleusVaultCalls {
  return {
    deposit: useNucleusEthDeposit(),
    approveAssetForDeposit: useNucleusEthApproveAssetForDeposit(),
    requestWithdraw: useNucleusEthRequestWithdraw(),
    approveVaultTokenForAtomicQueue:
      useNucleusEthApproveVaultTokenForAtomicQueue(),
    cancelWithdraw: useNucleusEthCancelWithdraw(),
  }
}

//
//    OTHER IMPLEMENTATIONS
//

export const {
  useVault: useNucleusBtcVault,
  useAllowances: useNucleusBtcAllowances,
  useBalances: useNucleusBtcBalances,
  useAuth: useNucleusBtcAuth,
  useSharesState: useNucleusBtcSharesState,
  useWithdrawRequest: useNucleusBtcWithdrawRequest,
  useRecentFulfilledRequests: useNucleusBtcRecentFulfilledRequests,
  useSupportedAssets: useNucleusBtcSupportedAssets,
  useRates: useNucleusBtcRates,
  useVaultState: useNucleusBtcVaultState,
  useVaultStats: useNucleusBtcVaultStats,
  usePoints: useNucleusBtcPoints,
  useDeposit: useNucleusBtcDeposit,
  useApproveAssetForDeposit: useNucleusBtcApproveAssetForDeposit,
  useRequestWithdraw: useNucleusBtcRequestWithdraw,
  useApproveVaultTokenForAtomicQueue:
    useNucleusBtcApproveVaultTokenForAtomicQueue,
  useCancelWithdraw: useNucleusBtcCancelWithdraw,
} = makeNucleusVaultHooks('btc-nucleus', useNucleusBtcApi)
export function useNucleusBtcVaultCalls(): NucleusVaultCalls {
  return {
    deposit: useNucleusBtcDeposit(),
    approveAssetForDeposit: useNucleusBtcApproveAssetForDeposit(),
    requestWithdraw: useNucleusBtcRequestWithdraw(),
    approveVaultTokenForAtomicQueue:
      useNucleusBtcApproveVaultTokenForAtomicQueue(),
    cancelWithdraw: useNucleusBtcCancelWithdraw(),
  }
}
