import { Token } from '@/types/tokens'
import styled from 'styled-components'
import { hScrollStyle, labelStyle, valueStyle } from './common'
import { useChainInfo } from '@/state/deployments/hooks'
import { ExternalLinkIcon } from '@/swell-ui/icons/ExternalLinkIcon'
import { YearnAeraVault } from '@/types/yearnAera'

const ROW_HEIGHT = '25.6px'

export function YearnAeraContracts({ vault }: { vault: YearnAeraVault }) {
  const { explorer } = useChainInfo()
  return (
    <ContractsLayout>
      <span className="label">Vault Address</span>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${explorer}/address/${vault.vaultToken.address}`}
        className="value"
      >
        {vault.vaultToken.address}
        <ExternalLinkIcon />
      </a>
      <span className="label">Strategy Address</span>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${explorer}/address/${vault.strategyAddress}`}
        className="value"
      >
        {vault.strategyAddress}
        <ExternalLinkIcon />
      </a>
    </ContractsLayout>
  )
}

const ContractsLayout = styled.div`
  white-space: nowrap;

  display: grid;
  grid-template-columns: max-content 1fr;
  grid-auto-rows: ${ROW_HEIGHT};
  gap: 24px;
  align-items: center;

  .label {
    ${labelStyle}
  }
  .value {
    ${valueStyle}
    overflow-x: auto;

    svg {
      margin-left: 8px;
      stroke: currentColor;
    }
    font-size: 14px;

    ${hScrollStyle};
  }

  a:hover {
    text-decoration: underline;
  }
`
