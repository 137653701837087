import { YearnAeraVault } from '@/types/yearnAera'
import {
  TOKEN_LIST_RSWELL,
  TOKEN_LIST_RSWELL_HOLESKY,
  TOKEN_LIST_SWBTC,
  TOKEN_LIST_SWBTC_HOLESKY,
  TOKEN_LIST_SWBTC_SEPOLIA,
  TOKEN_LIST_SWELL,
  TOKEN_LIST_SWELL_HOLESKY,
  TOKEN_LIST_WBTC,
  TOKEN_LIST_WBTC_HOLESKY,
  TOKEN_LIST_WBTC_SEPOLIA,
} from './tokens'
import { ChainId } from './chains'

/**
 * Max loss basis points for yearn withdrawals
 * If 0, the contract's default max loss basis points will be used
 */
export const MAX_LOSS_BASIS_POINTS = 0
/**
 * Flag specifying whether to override the max loss basis points for yearn withdrawals
 */
export const OVERRIDE_MAX_LOSS = false

export const YEARN_AERA_VAULT_SWBTC_MAINNET: YearnAeraVault = {
  chainId: 1,
  depositAsset: TOKEN_LIST_WBTC,
  vaultToken: TOKEN_LIST_SWBTC,
  withdrawAssets: [TOKEN_LIST_WBTC],
  withdrawType: 'v1',
  delayedWithdrawAddress: '0x805c6d95c9e707332215F42cb89f93752FFa55B8',
  delayedWithdrawAuthAddress: '0xA9Bd691b166aAFCC9EF55aaBC1960825630558d6',
  strategyAddress: '0x8041bA598f0E656EBe80c67289efb42C09E86aE3',
}
export const YEARN_AERA_VAULT_SWBTC_SEPOLIA: YearnAeraVault = {
  chainId: ChainId.SEPOLIA,
  depositAsset: TOKEN_LIST_WBTC_SEPOLIA,
  vaultToken: TOKEN_LIST_SWBTC_SEPOLIA,
  withdrawAssets: [TOKEN_LIST_WBTC_SEPOLIA],
  withdrawType: 'v1',
  delayedWithdrawAddress: '0x64f912AA11C1b91913990f66A1d6B69c2Aa94F21',
  delayedWithdrawAuthAddress: '0xdE83B888FCe42fDFEa6057cCb51EAe79581F0087',
  strategyAddress: '0x0000000000000000000000000000000000000000',
}
export const YEARN_AERA_VAULT_SWBTC_HOLESKY: YearnAeraVault = {
  chainId: ChainId.HOLESKY,
  depositAsset: TOKEN_LIST_WBTC_HOLESKY,
  vaultToken: TOKEN_LIST_SWBTC_HOLESKY,
  withdrawAssets: [TOKEN_LIST_WBTC_HOLESKY],
  withdrawType: 'v1',
  delayedWithdrawAddress: '0xCBb9Ed4b034F1B6ec9475021A284FE2568063B2b',
  delayedWithdrawAuthAddress: '0x524D0E81965757C0ddEB9714872f9B6D1B7ec7eF',
  strategyAddress: '0x0000000000000000000000000000000000000000',
}
export const YEARN_AERA_VAULT_RSWELL_MAINNET: YearnAeraVault = {
  chainId: 1,
  depositAsset: TOKEN_LIST_SWELL,
  vaultToken: TOKEN_LIST_RSWELL,
  withdrawAssets: [TOKEN_LIST_SWELL],
  withdrawType: 'v2',
  delayedWithdrawAddress: '0x755A078Dee1c264A3053F02CD634e750d505CF85',
  delayedWithdrawAuthAddress: '0x9D663f1dD3cB752B47301Abdc22d620d1868F377',
  strategyAddress: '0x473F0ADEc308dC82440665d9FC7d5E26EBcf0E05',
}
export const YEARN_AERA_VAULT_RSWELL_HOLESKY: YearnAeraVault = {
  chainId: ChainId.HOLESKY,
  depositAsset: TOKEN_LIST_SWELL_HOLESKY,
  vaultToken: TOKEN_LIST_RSWELL_HOLESKY,
  withdrawAssets: [TOKEN_LIST_SWELL_HOLESKY],
  withdrawType: 'v2',
  delayedWithdrawAddress: '0x3510E57932B77A7e58f8D18862B7E0D1b8f46C60',
  delayedWithdrawAuthAddress: '0x866B26528b9798753f800145dC3B2111545bCb2A',
  strategyAddress: '0x98aB40630944955B71D34812c63F7E4AD74cA550',
}
