import React from 'react'
import styled from 'styled-components/macro'

type TypographySize =
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'display'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'

interface TypographyProps {
  fstyle?: 'bold' | 'italic'
  letterSpacing?: 'small'
  variant: 'body' | 'headline'
  size: TypographySize
  xs?: TypographySize
  sm?: TypographySize
  md?: TypographySize
  lg?: TypographySize
  xl?: TypographySize
  className?: string
  children: React.ReactNode
}

const StyledTypography = styled.div<TypographyProps>`
  ${({ theme, variant, size, fstyle, letterSpacing, xs, sm, md, lg, xl }) => `
    font-size: ${theme.typography[variant][size].fontSize};
    line-height: ${theme.typography[variant][size].lineHeight};
    font-weight: 500;

    ${
      fstyle === 'bold'
        ? `
      font-weight: 600;
    `
        : ``
    }

    ${
      fstyle === 'italic'
        ? `
      font-style: italic;
    `
        : ``
    }

    ${letterSpacing === 'small' ? 'letter-spacing: -0.03em;' : ``}

    ${theme.breakpoints.up('xs')} {
      ${
        xs
          ? `
        font-size: ${theme.typography[variant][xs].fontSize};
        line-height: ${theme.typography[variant][xs].lineHeight};
      `
          : ``
      }
    }

    ${theme.breakpoints.up('sm')} {
      ${
        sm
          ? `
        font-size: ${theme.typography[variant][sm].fontSize};
        line-height: ${theme.typography[variant][sm].lineHeight};
      `
          : ``
      }
    }

    ${theme.breakpoints.up('md')} {
      ${
        md
          ? `
        font-size: ${theme.typography[variant][md].fontSize};
        line-height: ${theme.typography[variant][md].lineHeight};
      `
          : ``
      }
    }

    ${theme.breakpoints.up('lg')} {
      ${
        lg
          ? `
        font-size: ${theme.typography[variant][lg].fontSize};
        line-height: ${theme.typography[variant][lg].lineHeight};
      `
          : ``
      }
    }

    ${theme.breakpoints.up('xl')} {
      ${
        xl
          ? `
        font-size: ${theme.typography[variant][xl].fontSize};
        line-height: ${theme.typography[variant][xl].lineHeight};
      `
          : ``
      }
    }
  `}
`

function Typography(props: TypographyProps) {
  return <StyledTypography {...props} />
}

export { Typography }
