import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { KeyValueList } from '@/swell-ui/KeyValueList'
import { YearnAeraTransactionFees, YearnWithdrawPendingSummary } from '../types'

export function YearnPendingWithdrawInfo({
  pendingSummary,
  transactionFees,
}: {
  pendingSummary: YearnWithdrawPendingSummary
  transactionFees: Partial<YearnAeraTransactionFees>
}) {
  const withdrawFeeConfig = {
    label: 'Withdrawal fee',
    value: (
      <AsyncDiv loading={!pendingSummary.withdrawFee}>
        {() => pendingSummary.withdrawFee}
      </AsyncDiv>
    ),
    hasInfo: false,
  }

  const processingTimeConfig = {
    label: 'Processing time',
    value: (
      <AsyncDiv loading={!pendingSummary.processingTime}>
        {() => pendingSummary.processingTime}
      </AsyncDiv>
    ),
  }

  const gasFeeConfig = {
    label: 'Transaction fee',
    value: (
      <AsyncDiv loading={!transactionFees.cancelWithdraw}>
        {() => transactionFees.cancelWithdraw}
      </AsyncDiv>
    ),
  }

  const exchangeInfoListConfig = {
    list: [withdrawFeeConfig, processingTimeConfig],
  }
  if (pendingSummary.isCancelable) {
    exchangeInfoListConfig.list.push(gasFeeConfig)
  }

  return <KeyValueList config={exchangeInfoListConfig} />
}
