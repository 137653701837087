import { createContext, useContext } from 'react'
import { NucleusVaultApi } from './types'
import { NucleusVault } from '@/types/nucleus'

const MISSING_PROVIDER = Symbol()

export type NucleusVaultContext = NucleusVaultApi &
  NucleusVault & {
    atomicQueueAddress: string
  }

// makeNucleusVaultContext is a factory function that creates a NucleusVaultContext and a useNucleusVaultApi hook.
function makeNucleusVaultContext() {
  const NucleusVaultContext = createContext<
    NucleusVaultContext | typeof MISSING_PROVIDER
  >(MISSING_PROVIDER)

  function useNucleusVaultApi() {
    const ctx = useContext(NucleusVaultContext)
    if (ctx === MISSING_PROVIDER) {
      throw new Error(
        'NucleusVaultContext hooks must be wrapped in a <NucleusVaultContext.Provider>'
      )
    }
    return ctx
  }

  return { NucleusVaultContext, useNucleusVaultApi }
}

export const {
  NucleusVaultContext: NucleusEthContext,
  useNucleusVaultApi: useNucleusEthApi,
} = makeNucleusVaultContext()

export const {
  NucleusVaultContext: NucleusBtcContext,
  useNucleusVaultApi: useNucleusBtcApi,
} = makeNucleusVaultContext()
