import { ReactNode } from 'react'
import styled from 'styled-components'
import { FlexRow } from '@/swell-ui/FlexRow'
import { useMediaQuery } from '@mui/material'
import {
  TableHeaderAlign,
  TableRowProp,
  TableV2,
} from '@/swell-ui/TableV2/TableV2'
import { makeNucleusPositionSummary } from './nucleusFormatting'
import { NucleusPosition } from '@/types/nucleus'

const POSITION_ICON_SIZE = 20

type Row = {
  position: ReactNode
  allocation: string
  apy: string
}
type RowIntermediate = {
  position: string
  allocation: string
  apy: string
  iconURIs: string[]
}

export function makeTableRows({
  positions,
}: {
  positions: NucleusPosition[]
}): RowIntermediate[] {
  const sortedPositions = positions.sort((a, b) => {
    const aprA = a.apr
    const aprB = b.apr

    let baseAprA = 0
    if (aprA.type === 'scalar') {
      baseAprA = aprA.aprPercent
    } else if (aprA.type === 'range') {
      baseAprA = aprA.basePercent
    }

    let baseAprB = 0
    if (aprB.type === 'scalar') {
      baseAprB = aprB.aprPercent
    } else if (aprB.type === 'range') {
      baseAprB = aprB.basePercent
    }

    const allocDelta = a.allocationPercent - b.allocationPercent
    const apyDelta = baseAprA - baseAprB

    if (allocDelta !== 0) {
      return -allocDelta
    }
    return -apyDelta
  })

  const rows: RowIntermediate[] = []

  for (const position of sortedPositions) {
    const summary = makeNucleusPositionSummary({ position })
    const protocolListOne = position.protocolIconURIs.slice(0, 1)
    rows.push({
      allocation: summary.allocation,
      apy: summary.apr,
      position: position.name,
      iconURIs: [...protocolListOne],
    })
  }
  return rows
}

export function NucleusPositionsTable({
  positions,
}: {
  positions: NucleusPosition[] | undefined
}) {
  const is600Up = useMediaQuery('(min-width:600px)')

  let iRows: RowIntermediate[] = []
  if (positions) {
    iRows = makeTableRows({ positions })
  }

  const rows: TableRowProp<Row>[] = []
  for (const r of iRows) {
    const firstThreePositionIcons = r.iconURIs.slice(0, 3)
    const positionIcons = firstThreePositionIcons.map((iconURI, index) => (
      <PositionIcon
        key={index}
        src={iconURI}
        width={POSITION_ICON_SIZE}
        height={POSITION_ICON_SIZE}
      />
    ))

    rows.push({
      _key: r.position,
      position: {
        loaded: true,
        result: (
          <Positioner align="center">
            {positionIcons}
            {r.position}
          </Positioner>
        ),
      },
      allocation: {
        loaded: true,
        result: r.allocation,
      },
      apy: {
        loaded: true,
        result: r.apy,
      },
    })
  }

  let allocationLabel = 'ALLOC %'
  let allocationAlign = TableHeaderAlign.center
  if (is600Up) {
    allocationLabel = 'ALLOCATION %'
    allocationAlign = TableHeaderAlign.right
  }

  return (
    <TableV2<keyof Row, Row>
      header={{
        position: {
          label: 'POSITION',
        },
        allocation: {
          label: allocationLabel,
          align: allocationAlign,
        },
        apy: {
          label: 'APY %',
          align: TableHeaderAlign.right,
        },
      }}
      rows={rows}
      maxHeight="208px"
      loading={!positions}
    />
  )
}

const PositionIcon = styled.img`
  &:nth-child(1) {
    margin-right: 6px;
    &:has(+ img) {
      margin-right: -6px;
      z-index: 1;
    }
  }
  &:nth-child(2) {
    margin-right: 6px;
    &:has(+ img) {
      margin-right: -6px;
      z-index: 1;
    }
  }
  &:nth-child(3) {
    margin-right: 6px;
  }
`

const Positioner = styled(FlexRow)`
  overflow: hidden;
`
