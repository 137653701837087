import {
  AirdropOffchainState,
  AirdropResult,
  AirdropUserVesting,
} from '@/state/dao/types'
import { useEffect, useState } from 'react'
import { SwellDaoClaimAirdrop } from '@/state/dao/hooks'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import useSWRImmutable from 'swr/immutable'
import { fetchPreDepositSummary } from '@/services/V3BackendService/public'
import { useLocalStorageBoolean } from '@/hooks/useLocalStorage'
import { MerkleContractsState } from '@/types/merkle'

export function usePreDepositSummary() {
  const { v3BackendPublicUrl } = useDeploymentSetConfig()

  return useSWRImmutable(['preDepositSummary', v3BackendPublicUrl], () =>
    fetchPreDepositSummary(v3BackendPublicUrl)
  )
}

export function useAirdropLoading({
  minDuration = 1200,
  airdropResult,
  airdropContractsState,
  airdropOffchainState,
  airdropUserVesting,
}: {
  minDuration?: number
  airdropResult: AirdropResult | undefined
  airdropOffchainState: AirdropOffchainState | undefined
  airdropContractsState: MerkleContractsState | undefined
  airdropUserVesting: AirdropUserVesting | undefined
}) {
  const isLoading =
    !airdropResult ||
    !airdropContractsState ||
    !airdropOffchainState ||
    !airdropUserVesting

  const [displayIsLoading, setDisplayIsLoading] = useState(isLoading)

  // prevent flickering of loading, display it for a minimum duration
  useEffect(() => {
    if (isLoading) {
      setDisplayIsLoading(true)
    } else {
      const timeout = setTimeout(() => {
        setDisplayIsLoading(false)
      }, minDuration)

      return () => clearTimeout(timeout)
    }
  }, [isLoading, minDuration])

  return isLoading || displayIsLoading
}

export function useCachedClaimAirdropArguments({
  claimAirdrop,
}: {
  claimAirdrop: SwellDaoClaimAirdrop
}) {
  type CallArgs = Parameters<SwellDaoClaimAirdrop['call']>[0]
  const [args, setArgs] = useState<CallArgs | undefined>()

  useEffect(() => {
    if (
      claimAirdrop.status === claimAirdrop.STATUS.FULFILLED &&
      claimAirdrop.args
    ) {
      setArgs(claimAirdrop.args[0])
    }
  }, [claimAirdrop])

  return args
}

export const HAS_ACCEPTED_AIRDROP_TERMS_LS_KEY = 'airdrop:hasAcceptedTerms'

export function useHasAcceptedAirdropTerms() {
  const [hasAcceptedTerms, setHasAcceptedTerms] = useLocalStorageBoolean(
    HAS_ACCEPTED_AIRDROP_TERMS_LS_KEY,
    false
  )
  const accept = () => setHasAcceptedTerms(true)
  return {
    hasAcceptedTerms,
    accept,
  }
}
