import {
  NucleusApproveAssetForDeposit,
  NucleusApproveVaultTokenForAtomicQueue,
  NucleusCancelWithdraw,
  NucleusDeposit,
  NucleusRequestWithdraw,
} from '@/state/nucleusVault/hooks'
import { Token } from '@/types/tokens'
import { displayCryptoLocale } from '@/util/displayCrypto'
import { Web3Toast } from '../StakingConfirmationProgressWidget/Web3Toast'
import {
  vaultTokenReceivedForAssetDeposit,
  withdrawAssetReceivedFromAtomicPrice,
} from './nucleusConversions'
import { NucleusRates } from '@/types/nucleus'

export const TRANSACTION_TOAST_TITLE = {
  COMPLETED: 'Transaction completed!',
  APPROVE_PROMPTING: 'Approval pending',
  APPROVE_PENDING: 'Approval confirming',
  DEPOSIT_PROMPTING: 'Deposit pending',
  DEPOSIT_PENDING: 'Deposit confirming',
  REQUEST_WITHDRAW_PROMPTING: 'Withdraw request pending',
  REQUEST_WITHDRAW_PENDING: 'Withdraw request confirming',
  CANCEL_WITHDRAW_PROMPTING: 'Cancel withdraw pending',
  CANCEL_WITHDRAW_PENDING: 'Cancel withdraw confirming',
}

export function NucleusApproveAssetForDepositToast({
  approveAssetForDeposit,
  anyTransactionInProgress,
  depositAssets,
}: {
  approveAssetForDeposit: NucleusApproveAssetForDeposit
  anyTransactionInProgress: boolean
  depositAssets: Token[]
}) {
  const complete =
    approveAssetForDeposit.status === approveAssetForDeposit.STATUS.FULFILLED
  const confirming =
    approveAssetForDeposit.status === approveAssetForDeposit.STATUS.PROMPTING
  const pending =
    approveAssetForDeposit.status === approveAssetForDeposit.STATUS.PENDING

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.APPROVE_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.APPROVE_PENDING
  }

  let message = ''
  if (approveAssetForDeposit.args) {
    const { amount, assetAddress } = approveAssetForDeposit.args[0]
    const depositAsset = depositAssets.find(
      (asset) => asset.address === assetAddress
    )
    if (!depositAsset) {
      return null
    }

    const amountStr = displayCryptoLocale(amount, depositAsset.decimals)

    if (confirming) {
      message = `Approve ${amountStr} ${depositAsset.symbol}`
    } else if (pending) {
      message = `Approving ${amountStr} ${depositAsset.symbol}`
    } else if (complete) {
      message = `Approved ${amountStr} ${depositAsset.symbol}`
    }
  }

  return (
    <Web3Toast
      call={approveAssetForDeposit}
      title={title}
      message={message}
      anyTransactionInProgress={anyTransactionInProgress}
    />
  )
}
export function NucleusApproveVaultTokenForAtomicQueueToast({
  approveVaultTokenForAtomicQueue,
  anyTransactionInProgress,
  vaultToken,
}: {
  approveVaultTokenForAtomicQueue: NucleusApproveVaultTokenForAtomicQueue
  anyTransactionInProgress: boolean
  vaultToken: Token
}) {
  const complete =
    approveVaultTokenForAtomicQueue.status ===
    approveVaultTokenForAtomicQueue.STATUS.FULFILLED
  const confirming =
    approveVaultTokenForAtomicQueue.status ===
    approveVaultTokenForAtomicQueue.STATUS.PROMPTING
  const pending =
    approveVaultTokenForAtomicQueue.status ===
    approveVaultTokenForAtomicQueue.STATUS.PENDING

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.APPROVE_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.APPROVE_PENDING
  }

  let message = ''
  if (approveVaultTokenForAtomicQueue.args) {
    const { amount } = approveVaultTokenForAtomicQueue.args[0]
    const amountStr = displayCryptoLocale(amount, vaultToken.decimals)

    if (confirming) {
      message = `Approve ${amountStr} ${vaultToken.symbol}`
    } else if (pending) {
      message = `Approving ${amountStr} ${vaultToken.symbol}`
    } else if (complete) {
      message = `Approved ${amountStr} ${vaultToken.symbol}`
    }
  }

  return (
    <Web3Toast
      call={approveVaultTokenForAtomicQueue}
      title={title}
      message={message}
      anyTransactionInProgress={anyTransactionInProgress}
    />
  )
}
export function NucleusDepositToast({
  deposit,
  anyTransactionInProgress,
  depositAssets,
  rates,
  vaultToken,
  baseAsset,
}: {
  deposit: NucleusDeposit
  anyTransactionInProgress: boolean
  depositAssets: Token[]
  rates: NucleusRates | undefined
  vaultToken: Token
  baseAsset: Token
}) {
  const complete = deposit.status === deposit.STATUS.FULFILLED
  const confirming = deposit.status === deposit.STATUS.PROMPTING
  const pending = deposit.status === deposit.STATUS.PENDING

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.DEPOSIT_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.DEPOSIT_PENDING
  }

  let message = ''
  if (deposit.args && rates) {
    const { depositAmount, depositAsset: depositAssetAddress } = deposit.args[0]
    const { vaultTokenQuoteRates } = rates

    const depositAsset = depositAssets.find(
      (asset) => asset.address === depositAssetAddress
    )
    if (!depositAsset) {
      return null
    }
    const vaultTokenRateInQuote = vaultTokenQuoteRates[depositAssetAddress]
    if (!vaultTokenRateInQuote) {
      return null
    }

    const receivedAmount = vaultTokenReceivedForAssetDeposit({
      toSendAsset: depositAmount,
      vaultTokenRateInQuote,
      base: baseAsset,
    })

    const depositAmountStr = displayCryptoLocale(
      depositAmount,
      depositAsset.decimals
    )

    const receivedAmountStr = displayCryptoLocale(
      receivedAmount,
      vaultToken.decimals
    )

    if (confirming) {
      message = `Deposit ${depositAmountStr} ${depositAsset.symbol} for ${receivedAmountStr} ${vaultToken.symbol}`
    } else if (pending) {
      message = `Depositing ${depositAmountStr} ${depositAsset.symbol} for ${receivedAmountStr} ${vaultToken.symbol}`
    } else if (complete) {
      message = `Deposited ${depositAmountStr} ${depositAsset.symbol} for ${receivedAmountStr} ${vaultToken.symbol}`
    }
  }

  return (
    <Web3Toast
      call={deposit}
      title={title}
      message={message}
      anyTransactionInProgress={anyTransactionInProgress}
    />
  )
}
export function NucleusRequestWithdrawToast({
  requestWithdraw,
  anyTransactionInProgress,
  withdrawAssets,
  vaultToken,
  baseAsset,
}: {
  requestWithdraw: NucleusRequestWithdraw
  anyTransactionInProgress: boolean
  withdrawAssets: Token[]
  vaultToken: Token
  baseAsset: Token
}) {
  const complete = requestWithdraw.status === requestWithdraw.STATUS.FULFILLED
  const confirming = requestWithdraw.status === requestWithdraw.STATUS.PROMPTING
  const pending = requestWithdraw.status === requestWithdraw.STATUS.PENDING

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.REQUEST_WITHDRAW_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.REQUEST_WITHDRAW_PENDING
  }

  let message = ''
  if (requestWithdraw.args) {
    const { withdrawRequest, wantAssetAddress } = requestWithdraw.args[0]
    const wantAsset = withdrawAssets.find(
      (asset) => asset.address === wantAssetAddress
    )
    if (!wantAsset) {
      return null
    }
    const { atomicPrice, offerAmount } = withdrawRequest
    const wantAmount = withdrawAssetReceivedFromAtomicPrice({
      atomicPrice,
      offerAmount,
      base: baseAsset,
    })
    const wantAmountStr = displayCryptoLocale(wantAmount, wantAsset.decimals)
    const offerAmountStr = displayCryptoLocale(offerAmount, vaultToken.decimals)

    if (confirming) {
      message = `Withdraw request ${offerAmountStr} ${vaultToken.symbol} for ${wantAmountStr} ${wantAsset.symbol}`
    } else if (pending) {
      message = `Requesting withdraw of ${offerAmountStr} ${vaultToken.symbol} for ${wantAmountStr} ${wantAsset.symbol}`
    } else if (complete) {
      message = `Requested withdraw of ${offerAmountStr} ${vaultToken.symbol} for ${wantAmountStr} ${wantAsset.symbol}`
    }
  }

  return (
    <Web3Toast
      call={requestWithdraw}
      title={title}
      message={message}
      anyTransactionInProgress={anyTransactionInProgress}
    />
  )
}
export function NucleusCancelWithdrawToast({
  cancelWithdraw,
  anyTransactionInProgress,
  withdrawAssets,
}: {
  cancelWithdraw: NucleusCancelWithdraw
  anyTransactionInProgress: boolean
  withdrawAssets: Token[]
}) {
  const complete = cancelWithdraw.status === cancelWithdraw.STATUS.FULFILLED
  const confirming = cancelWithdraw.status === cancelWithdraw.STATUS.PROMPTING
  const pending = cancelWithdraw.status === cancelWithdraw.STATUS.PENDING

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.CANCEL_WITHDRAW_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.CANCEL_WITHDRAW_PENDING
  }

  let message = ''
  if (cancelWithdraw.args) {
    const { wantAssetAddress } = cancelWithdraw.args[0]
    const wantAsset = withdrawAssets.find(
      (asset) => asset.address === wantAssetAddress
    )
    if (!wantAsset) {
      return null
    }

    if (confirming) {
      message = `Cancel withdraw request for ${wantAsset.symbol}`
    } else if (pending) {
      message = `Cancelling withdraw request for ${wantAsset.symbol}`
    } else if (complete) {
      message = `Cancelled withdraw request for ${wantAsset.symbol}`
    }
  }

  return (
    <Web3Toast
      call={cancelWithdraw}
      title={title}
      message={message}
      anyTransactionInProgress={anyTransactionInProgress}
    />
  )
}
