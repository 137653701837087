import { useMemo } from 'react'
import uniswapDefaultList from '@uniswap/default-token-list'
import {
  useCoingeckoTokenListQuery,
  useUniswapDefaultTokenListQuery,
} from '@/services/TokenList'
import { isProtocolToken } from '@/util/tokens'
import { TOKEN_LIST_ETH } from '@/constants/tokens'
import { Token } from '@/types/tokens'
import { BigNumber } from 'ethers'

function filterUserTokens(userTokens: Token[], coingeckoTokens: any[]) {
  const filteredUserTokens: Token[] = []

  for (let i = 0; i < userTokens.length; i++) {
    const token = userTokens[i]
    const coingeckoToken = coingeckoTokens.find(
      (t: Token) => t.address.toLowerCase() === token.address.toLowerCase()
    )

    if (coingeckoToken && !isProtocolToken(token)) {
      filteredUserTokens.push(token)
    }
  }

  return filteredUserTokens
}

export function useVisibleTokens({
  tokenBalances,
  exactTokens,
}: {
  tokenBalances?: Token[]
  exactTokens?: Token[]
}): Token[] {
  const uniswapListQuery = useUniswapDefaultTokenListQuery({
    paused: !!exactTokens,
  })
  const coingeckoListQuery = useCoingeckoTokenListQuery({
    paused: !!exactTokens,
  })

  const tokens: Token[] = useMemo(() => {
    if (exactTokens) {
      const requestedTokenSet = new Set(exactTokens.map((t) => t.address))
      let tokensWithBalance = tokenBalances || []
      tokensWithBalance = tokensWithBalance.filter((t) =>
        requestedTokenSet.has(t.address)
      )

      for (const token of exactTokens) {
        if (!tokensWithBalance.find((t) => t.address === token.address)) {
          tokensWithBalance.push({ ...token, balance: BigNumber.from(0) })
        }
      }

      return tokensWithBalance
    }
    // show only tokens with balances if they exist
    if (tokenBalances && tokenBalances.length > 0) {
      // if coingecko data, filter user tokens to get rid of useless tokens
      if (coingeckoListQuery.data) {
        return [
          TOKEN_LIST_ETH,
          ...filterUserTokens(tokenBalances, coingeckoListQuery.data.tokens),
        ]
      }
      return [TOKEN_LIST_ETH, ...tokenBalances]
    }

    // show token list from query if exists, otherwise use data from npm package
    if (uniswapListQuery?.data) {
      return [TOKEN_LIST_ETH, ...uniswapListQuery.data.tokens]
    }

    const uniswapMainnetTokens = uniswapDefaultList.tokens.filter(
      (token: any) => token.chainId === 1 || isProtocolToken(token)
    )

    return [TOKEN_LIST_ETH, ...uniswapMainnetTokens]
  }, [
    exactTokens,
    tokenBalances,
    uniswapListQuery.data,
    coingeckoListQuery.data,
  ])

  return tokens
}
