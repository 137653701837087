import {
  INucleusFulfilledRequestsFetcher,
  INucleusPointsFetcher,
  INucleusVaultStatsFetcher,
  useNucleusVaultImpl,
} from './api'
import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import { NucleusVaultContext } from './context'
import {
  useEarnVaultDeploymentBtc,
  useEarnVaultDeploymentEth,
  useNucleusVaultBackendSettings,
} from '../deployments/hooks/nucleusSettings'
import { useMemo } from 'react'
import {
  EarnETHPointsFetcher,
  NucleusFulfilledRequestFetcher,
  NucleusStatsFetcher,
  NucleusStatsFetcherIndicative,
} from './fetchers'

const invalidChain = async () => {
  throw new Error('invalid chain')
}

const INVALID_CHAIN_API: NucleusVaultContext['write'] = {
  approveAssetForDeposit: invalidChain,
  approveAssetForDepositEstimateGas: invalidChain,
  approveVaultTokenForAtomicQueue: invalidChain,
  approveVaultTokenForAtomicQueueEstimateGas: invalidChain,
  cancelWithdrawal: invalidChain,
  cancelWithdrawalEstimateGas: invalidChain,
  deposit: invalidChain,
  depositEstimateGas: invalidChain,
  requestWithdrawal: invalidChain,
  requestWithdrawalEstimateGas: invalidChain,
}

export function useNucleusVaultBtcImpl() {
  const { nucleusBackendURL } = useNucleusVaultBackendSettings()
  const { atomicQueueAddress, valid, vault } = useEarnVaultDeploymentBtc()

  const impl = useNucleusVaultImpl({
    fulfilledRequestsFetcher: useFulfilledRequestFetcher({
      backendURL: nucleusBackendURL,
    }),
    atomicQueueAddress,
    vault,
    vaultStatsFetcher: useVaultStatsFetcher({
      backendURL: nucleusBackendURL,
      indicativeApr: true,
    }),
    pointsFetcher: useEarnBTCPtsFetcher(),
  })

  if (!valid) {
    return {
      ...impl,
      write: INVALID_CHAIN_API,
    }
  }

  return impl
}

export function useNucleusVaultEthImpl(): NucleusVaultContext {
  const { nucleusBackendURL } = useNucleusVaultBackendSettings()
  const { atomicQueueAddress, valid, vault } = useEarnVaultDeploymentEth()
  const impl = useNucleusVaultImpl({
    fulfilledRequestsFetcher: useFulfilledRequestFetcher({
      backendURL: nucleusBackendURL,
    }),
    atomicQueueAddress,
    vault,
    vaultStatsFetcher: useVaultStatsFetcher({
      backendURL: nucleusBackendURL,
      indicativeApr: false,
    }),
    pointsFetcher: useEarnETHPointsFetcher(),
  })

  if (!valid) {
    return {
      ...impl,
      write: INVALID_CHAIN_API,
    }
  }

  return impl
}

function useFulfilledRequestFetcher({
  backendURL,
}: {
  backendURL: string
}): INucleusFulfilledRequestsFetcher {
  const client = useV3BackendClient(backendURL).v3BackendClient.nucleusWallet
  return useMemo(() => {
    return new NucleusFulfilledRequestFetcher(client)
  }, [client])
}

function useVaultStatsFetcher({
  backendURL,
  indicativeApr,
}: {
  backendURL: string
  indicativeApr: boolean
}): INucleusVaultStatsFetcher {
  const client = useV3BackendClient(backendURL).v3BackendClient.nucleus
  return useMemo(() => {
    if (indicativeApr) {
      return new NucleusStatsFetcherIndicative(client)
    }
    return new NucleusStatsFetcher(client)
  }, [client, indicativeApr])
}

function useEarnETHPointsFetcher(): INucleusPointsFetcher {
  const { pointsURL } = useNucleusVaultBackendSettings()
  const client = useV3BackendClient(pointsURL).v3BackendClient.nucleusWallet
  return useMemo(() => {
    return new EarnETHPointsFetcher(client)
  }, [client])
}

function useEarnBTCPtsFetcher(): INucleusPointsFetcher {
  return useMemo(() => {
    return {
      points: async ({ account }) => {
        throw new Error('not implemented')
      },
    }
  }, [])
}
