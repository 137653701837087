import { RadialSplitVis } from '@/components/RadialSplitVis'
import { SwellDaoClaimAirdrop } from '@/state/dao/hooks'
import { AirdropResult } from '@/state/dao/types'
import { merkleClaimable } from '../../../../util/merkledrop'
import { Token } from '@/types/tokens'
import { formatUnits } from 'ethers/lib/utils'
import styled, { keyframes } from 'styled-components'
import { DecoBox } from '@/components/DecoBox'
import { FlexRow } from '@/swell-ui/FlexRow'
import { formatWithDynamicPrecision } from '@/util/number'
import { ClaimAirdropButton, SliderChipGroup } from '../../AirdropButtons'
import { AirdropSlider } from '../../AirdropSlider'
import { PreparedSwellDaoClaimAirdrop } from '../../airdropCalls'
import {
  bigValueCSS,
  medValueCSS,
  paragraphHintCSS,
  smallCapsColoredCSS,
  title1CSS,
  title2CSS,
} from '../../styles/common'
import { MerkleDropState } from '@/types/merkle'
import { TokenLogo } from '@/components/TokenLogo'
import { TOKEN_LIST_RSWELL, TOKEN_LIST_SWELL } from '@/constants/tokens'
import { AirdropClaimAccounting } from './AirdropClaimAccounting'
import { makeClaimSummary } from '../../airdropFormatting'
import { AirdropClaimSummary } from '../../types'
import { css } from 'styled-components'

export function ClaimAirdrop({
  airdropResult,
  daoToken,
  lockRatio,
  claimAirdrop,
  preparedClaimAirdrop,
  setLockRatio,
  merkleStatus,
  nextAirdropVestingUnlockUnix,
}: {
  claimAirdrop: SwellDaoClaimAirdrop
  preparedClaimAirdrop: PreparedSwellDaoClaimAirdrop
  airdropResult: AirdropResult
  daoToken: Token
  lockRatio: number
  setLockRatio: (ratio: number) => void
  nextAirdropVestingUnlockUnix: number
  merkleStatus: MerkleDropState
}) {
  if (!airdropResult.exists) {
    throw new Error('no airdrop')
  }

  const isRevisiting =
    airdropResult.claimedEvents?.length &&
    airdropResult.claimedEvents.length > 0

  const { claimableAmount: trueClaimableAmount, vestingAmount } =
    merkleClaimable({
      cumulativeAmount: airdropResult.data.cumulativeAmount,
      cumulativeClaimed: airdropResult.cumulativeClaimed,
      totalAmount: airdropResult.data.totalAmount,
    })
  const hasVestingFunds = vestingAmount.gt(0)

  let claimableAmount = trueClaimableAmount
  if (isRevisiting && hasVestingFunds) {
    claimableAmount = vestingAmount // use the vesting amount in the slider as a preview
  }

  const claimableSwellNum = Number(
    formatUnits(claimableAmount, daoToken.decimals)
  )
  const stakedNum = claimableSwellNum * lockRatio
  const stakedStr = formatWithDynamicPrecision(stakedNum)

  let claimSummary: AirdropClaimSummary | undefined
  if (nextAirdropVestingUnlockUnix !== undefined) {
    claimSummary = makeClaimSummary({
      airdropResult,
      daoToken,
      nextAirdropVestingUnlockUnix,
    })
  }

  return (
    <Layout direction="column" gap="24" align="center" maxWidth="1064px">
      <h1>Claim and Restake SWELL</h1>
      <p className="c">
        Choose between claiming SWELL to your wallet, restaking for rSWELL or
        split your claim and get the best of both worlds.
      </p>
      <div />
      <ClaimLayout>
        <div className="a-claim">
          <DecoBox gap="24">
            <FlexRow justify="space-between" align="center">
              <h4>Restake SWELL</h4>
              <span className="med">{Math.round(lockRatio * 100)}%</span>
            </FlexRow>
            <FlexRow gap="24" direction="column">
              <SliderChipGroup
                lockRatio={lockRatio}
                setLockRatio={setLockRatio}
                disabled={claimAirdrop.status !== claimAirdrop.STATUS.IDLE}
              />
              <AirdropSlider
                maxAmount={claimableSwellNum}
                disabled={claimAirdrop.status !== claimAirdrop.STATUS.IDLE}
                lockRatio={lockRatio}
                setLockRatio={setLockRatio}
                stakedStr={stakedStr}
              />
            </FlexRow>
          </DecoBox>
          <SplitContainer gap="24" align="start" textAlign="left">
            <h4>Claim split</h4>
            <div>
              <RadialSplitVis
                label0={'restake'}
                label1={'withdraw'}
                labelCenter={'total swell'}
                amount={claimableSwellNum}
                ratio={lockRatio}
              />
            </div>
          </SplitContainer>
          <div className="claim-btn">
            <ClaimAirdropButton
              prepared={preparedClaimAirdrop}
              claimAirdrop={claimAirdrop}
              merkleStatus={merkleStatus}
            />
          </div>
        </div>
        <FlexRow className="a-info" direction="column">
          <Tokens />
        </FlexRow>
        <FlexRow className="a-summary" direction="column">
          {claimSummary && (
            <FlexRow direction="column" gap="24" align="start">
              <h3>Claim Summary</h3>
              <AirdropClaimAccounting claimSummary={claimSummary} />
            </FlexRow>
          )}
        </FlexRow>
      </ClaimLayout>
    </Layout>
  )
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const fadeInCSS = css`
  opacity: 0;
  animation: ${fadeIn} 0.65s ease-out forwards;
  animation-delay: 0;
`

const Layout = styled(FlexRow)`
  ${fadeInCSS}
  margin: auto 0;

  padding: 0 8px 64px;
  @media (min-width: 460px) {
    padding: 0 24px 64px;
  }

  @media (min-width: 1120px) {
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }

  .med {
    ${medValueCSS}
  }
  .big {
    ${bigValueCSS}
  }

  h1 {
    ${title1CSS}
    text-align: center;
  }

  h2 {
    ${title2CSS}
  }

  h4 {
    ${smallCapsColoredCSS}
  }
  p {
    margin: 0;
  }
  .c {
    text-align: center;
  }

  .hint {
    ${paragraphHintCSS}
  }
`

const SplitContainer = styled(DecoBox)`
  > div {
    margin: 0 auto;
  }
`

const ClaimLayout = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    'info    '
    'summary '
    'claim   ';

  grid-row-gap: 24px;

  @media (min-width: 1120px) {
    grid-column-gap: 36px;
    grid-row-gap: 156px;
    width: unset;
    grid-template-columns: minmax(auto, 646px) auto;
    grid-template-areas:
      'info    claim'
      'summary claim';

    justify-content: space-between;
  }

  .a-claim {
    margin: 0 auto;
    grid-area: claim;
    display: flex;
    flex-direction: column;
    gap: 11px;

    button:last-child {
      width: 100%;
    }

    margin-top: 48px;
    width: 100%;
  }
  @media (min-width: 460px) {
    .a-claim {
      max-width: 357px;
    }
  }
  @media (min-width: 1120px) {
    .a-claim {
      margin-top: 0;
    }
  }

  .a-info {
    grid-area: info;

    .token-label {
      color: var(--Swell-White-50, #fff);
      /* Headlines/H7 */
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 26px */
      letter-spacing: -0.6px;
    }

    ul {
      padding: 0;
      margin: 0;
    }
  }
  .a-summary {
    grid-area: summary;
  }
`

function Tokens() {
  return (
    <TokensLayout gap="29">
      <FlexRow gap="12" direction="column" width="300px">
        <FlexRow gap="12">
          <TokenLogo token={TOKEN_LIST_RSWELL} size={27} />
          <span className="token-label">rSWELL</span>
        </FlexRow>
        <div className="hint">
          <ul>
            <li>Full 1:1 governance powers</li>
            <li>Restaking yield for securing Swell L2</li>
            <li>Symbiotic points (1x)</li>
            <li>Black pearls (3x)</li>
            <li>Boosted DeFi yield?</li>
          </ul>
        </div>
      </FlexRow>

      <FlexRow gap="12" direction="column" width="300px">
        <FlexRow gap="12">
          <TokenLogo token={TOKEN_LIST_SWELL} size={27} />
          <span className="token-label">SWELL</span>
        </FlexRow>
        <div className="hint">
          <ul>
            <li>Full 1:1 governance powers</li>
            <li>Restaking yield for securing Swell L2</li>
            <li>Symbiotic points (1x)</li>
            <li>Black pearls (3x)</li>
            <li>Boosted DeFi yield?</li>
          </ul>
        </div>
      </FlexRow>
    </TokensLayout>
  )
}
// 1120
const TokensLayout = styled(FlexRow)`
  flex-wrap: wrap;

  @media (min-width: 1120px) {
    flex-wrap: nowrap;
  }
`
