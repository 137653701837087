import useSWRImmutable from 'swr/immutable'
import { BigNumber } from 'ethers'
import { formatEther, parseEther } from 'ethers/lib/utils'
import { useSwellWeb3 } from '@swell-web3/core'
import {
  useRswETHContractView,
  useRestakingDepositManagerContractView,
} from '@/hooks/useContract'
import {
  useGetAllStatsV3Backend,
  useGetRswethAprV3Backend,
} from '@/services/V3BackendService/hooks'
import { parseWeiSafe } from '@/util/big'
import { useDeploymentSetConfig } from '../deployments/hooks'
import { SWRResponse } from 'swr'
import { usePrimaryRates } from '../prices/hooks'

interface CommissionRates {
  nodeOperatorRewardPercentage: number
  swellTreasuryRewardPercentage: number
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useCommissionRateOnChain = () => {
  const rswEth = useRswETHContractView()

  return useSWRImmutable(
    rswEth ? [rswEth.address, 'rewardPercentage'] : null,
    async () => {
      let nodeOperatorRewardPercentage: BigNumber | null = null
      let swellTreasuryRewardPercentage: BigNumber | null = null

      return Promise.all([
        rswEth!.nodeOperatorRewardPercentage().then((res) => {
          nodeOperatorRewardPercentage = res
        }),
        rswEth!.swellTreasuryRewardPercentage().then((res) => {
          swellTreasuryRewardPercentage = res
        }),
      ]).then(() => {
        // reward percentages are expressed as a fraction of ether
        // e.g. 5% = 0.05*1e18
        return {
          nodeOperatorRewardPercentage:
            parseFloat(formatEther(nodeOperatorRewardPercentage!)) * 100,
          swellTreasuryRewardPercentage: parseFloat(
            formatEther(swellTreasuryRewardPercentage!)
          ),
        } as CommissionRates
      })
    }
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useCommissionRateV3Backend = () => {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const query = useGetAllStatsV3Backend(v3BackendLrtUrl)

  return {
    ...query,
    get data(): CommissionRates | undefined {
      if (!query.data) return undefined
      const { swellTreasuryRewardPercentage, nodeOperatorRewardPercentage } =
        query.data
      // reward percentages are expressed as a fraction of ether
      // e.g. 5% = 0.05*1e18

      const oneETH = parseEther('1')

      // backend provides values as wei string, hence need to parseWei
      return {
        nodeOperatorRewardPercentage: parseWeiSafe(nodeOperatorRewardPercentage)
          .mul(100)
          .div(oneETH)
          .toNumber(),
        swellTreasuryRewardPercentage: parseWeiSafe(
          swellTreasuryRewardPercentage
        )
          .mul(100)
          .div(oneETH)
          .toNumber(),
      }
    },
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useCommissionRateHardcoded = () => {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const query = useGetAllStatsV3Backend(v3BackendLrtUrl)

  return {
    ...query,
    get data(): CommissionRates | undefined {
      if (!query.data) return undefined
      return {
        nodeOperatorRewardPercentage: 5,
        swellTreasuryRewardPercentage: 5,
      }
    },
  }
}

export { useCommissionRateHardcoded as useCommissionRate }

export function useRestakingRate() {
  const qry = usePrimaryRates()
  return {
    ...qry,
    get data() {
      if (!qry.data) return undefined
      return { rswETHToETHRate: qry.data.rswETHPrimaryRate }
    },
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useTotalEthRestakedOnChain = () => {
  const depositManager = useRestakingDepositManagerContractView()
  const { provider } = useSwellWeb3()

  return useSWRImmutable(
    depositManager && provider ? [depositManager.address, 'getBalance'] : null,
    async () => {
      return {
        value: await provider!.getBalance(depositManager!.address),
      }
    }
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useTotalEthRestakedV3Backend = () => {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const query = useGetAllStatsV3Backend(v3BackendLrtUrl)

  return {
    ...query,
    get data() {
      if (!query.data) return undefined
      return { value: parseWeiSafe(query.data.totalEthStakedWei) }
    },
  }
}

// export { useTotalEthRestakedV3Backend as useTotalEthRestaked }
export { useTotalEthRestakedV3Backend as useTotalEthRestaked }

export const useRestakerCountUsers = () => {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const query = useGetAllStatsV3Backend(v3BackendLrtUrl)
  return {
    ...query,
    get data() {
      if (!query.data) return undefined
      return { value: query.data.stakerCountUsers }
    },
  }
}

const useRestakingAprV3Backend = () => {
  const query = useGetRswethAprV3Backend()

  return {
    ...query,
    get data() {
      if (!query.data) return undefined
      return { value: query.data.toFixed(2) }
    },
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useRestakingAprHardcoded = () => {
  return {
    error: undefined,
    isLoading: false,
    isValidating: false,
    mutate: () => {},
    get data() {
      return { value: 4.5 }
    },
  } as SWRResponse<{ value: number }, any>
}

export { useRestakingAprV3Backend as useRestakingApr }
