import React, { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { createGlobalStyle } from 'styled-components/macro'
import { ThemeProvider } from '@mui/material/styles'
import {
  btcLrtTheme,
  darkTheme,
  earnTheme,
  portfolioTheme,
  restakeTheme,
  rswellTheme,
  rswethTheme,
  swethTheme,
} from './themes'
import { SwellUiThemeContext } from './SwellUiThemeContext'
import { GlobalNotificationProvider } from '../GlobalNotification'
import './fonts.css'
import { ROUTES } from '@/constants/routes'
import { useGeoRestricted } from '@/state/geofence/hooks'

interface SwellUiThemeProviderProps {
  children: React.ReactNode
}

const GlobalStyle = createGlobalStyle`
    #root {
      position: relative;
    }

    /* Variables for styling web3-onboard modal */
    :root {
      --onboard-connect-sidebar-background: #051A2B;
      --onboard-modal-backdrop: rgba(11, 20, 27, 0.7);
      --onboard-modal-background: ${({ theme }) =>
        theme.colors.oceanBlue['100']};
      --onboard-link-color: ${({ theme }) => theme.colors.lightBlue['50']};
      --onboard-gray-600: ${({ theme }) => theme.colors.white['50']};
      --onboard-primary-100: ${({ theme }) => theme.colors.lightBlue['125']};
      --onboard-primary-300: ${({ theme }) => theme.colors.lightBlue['150']};
      --onboard-primary-500: ${({ theme }) => theme.colors.lightBlue['50']};
      --onboard-warning-100: ${({ theme }) => theme.colors.lightBlue['125']};
      --onboard-warning-400: ${({ theme }) => theme.colors.lightBlue['150']};
      --onboard-warning-700: ${({ theme }) => theme.colors.white['50']};
      --onboard-modal-top: unset;
    }

    html, body {
      background-color: ${({ theme }) => theme.mainBgColor};
      color: ${({ theme }) => theme.mainColor};
      font-family: "Inter", sans-serif !important;

      a {
        color: ${({ theme }) => theme.colors.lightBlue['50']};

        &:hover {
          color: ${({ theme }) => theme.colors.lightBlue['100']};
        }
      }

      a:link {
        text-decoration: none;
      }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    /* Mui Datepicker stylings global because exists outside normal DOM structure */
    .MuiPickersLayout-root {
      background-color: rgb(25, 32, 38);
      color: white !important;

      .MuiPickersDay-root,
      .MuiDayCalendar-weekDayLabel,
      .MuiPickersArrowSwitcher-button,
      .MuiPickersCalendarHeader-switchViewButton {
        color: white;
      }

      .MuiPickersArrowSwitcher-button {
        &.Mui-disabled {
          opacity: 0.4;
        }
      }

      .MuiPickersDay-root, .MuiPickersYear-yearButton {
        &:hover {
          background-color: ${({ theme }) => theme.colors.lightBlue['150']};
        }

        &.Mui-disabled:not(.Mui-selected) {
          color: white;
          opacity: 0.4;
        }
      }

      .MuiPickersYear-yearButton {
        &.Mui-disabled {
          color: white;
          opacity: 0.4;
        }
      }

      .Mui-selected {
        background-color: ${({ theme }) => theme.colors.lightBlue['100']};
      }
    }
`

function SwellUiThemeProvider({ children }: SwellUiThemeProviderProps) {
  const [theme, setTheme] = useState<any>(restakeTheme)
  const [themeName, setThemeName] = useState<string>('Dark Theme')
  const location = useLocation()
  const isGeoRestricted = useGeoRestricted().shouldRestrict

  useMemo(() => {
    if (isGeoRestricted) {
      setTheme(darkTheme)
      return
    }

    if (location.pathname.toLowerCase().includes('restaking-operators')) {
      setTheme(restakeTheme)
    } else if (location.pathname.toLowerCase().includes(ROUTES.StakeRswETH)) {
      setTheme(rswethTheme)
    } else if (location.pathname.toLowerCase().includes(ROUTES.StakeSwETH)) {
      setTheme(swethTheme)
    } else if (location.pathname.toLowerCase().includes(ROUTES.StakeSwBTC)) {
      setTheme(btcLrtTheme)
    } else if (location.pathname.toLowerCase().includes(ROUTES.EarnPortfolio)) {
      setTheme(portfolioTheme)
    } else if (location.pathname.toLowerCase().includes(ROUTES.StakeSwell)) {
      setTheme(rswellTheme)
    } else if (location.pathname.toLowerCase().includes(ROUTES.EarnVaults)) {
      setTheme(earnTheme)
    } else {
      setTheme(darkTheme)
    }
  }, [isGeoRestricted, location.pathname])

  return (
    <SwellUiThemeContext.Provider value={{ themeName, theme }}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <GlobalNotificationProvider>{children}</GlobalNotificationProvider>
      </ThemeProvider>
    </SwellUiThemeContext.Provider>
  )
}

export { SwellUiThemeProvider }
