/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  YearnDelayedWithdrawV2,
  YearnDelayedWithdrawV2Interface,
} from "../YearnDelayedWithdrawV2";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "_lrtVault",
        type: "address",
      },
      {
        internalType: "address",
        name: "_feeAddress",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__AlreadySetup",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__BadAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__ExceedsMaxWithdrawPerUser",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__MaxLossExceeded",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__MaxLossTooLarge",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__NoSharesToWithdraw",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__Paused",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__SharesIs0",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__ThirdPartyCompletionNotAllowed",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__WithdrawFeeTooHigh",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__WithdrawNotMatured",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__WithdrawsNotAllowed",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__WrongAsset",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__WrongVaultStrategy",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__transferNotAllowed",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: true,
        internalType: "contract Authority",
        name: "newAuthority",
        type: "address",
      },
    ],
    name: "AuthorityUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newFeeAddress",
        type: "address",
      },
    ],
    name: "FeeAddressSet",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint16",
        name: "newMaxLoss",
        type: "uint16",
      },
    ],
    name: "MaxLossUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newMaxWithdrawPerUser",
        type: "uint256",
      },
    ],
    name: "MaxWithdrawPerUserUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "Paused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint64",
        name: "withdrawDelay",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "uint16",
        name: "withdrawFee",
        type: "uint16",
      },
      {
        indexed: false,
        internalType: "uint16",
        name: "maxLoss",
        type: "uint16",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "maxWithdrawPerUser",
        type: "uint256",
      },
    ],
    name: "SetupWithdrawalsInAsset",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "allowed",
        type: "bool",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "withdrawalIdx",
        type: "uint256",
      },
    ],
    name: "ThirdPartyCompletionChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "Unpaused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint96",
        name: "shares",
        type: "uint96",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "withdrawalIdx",
        type: "uint256",
      },
    ],
    name: "WithdrawCancelled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "shares",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "assets",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "withdrawalIdx",
        type: "uint256",
      },
    ],
    name: "WithdrawCompleted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newWithdrawDelay",
        type: "uint256",
      },
    ],
    name: "WithdrawDelayUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint16",
        name: "newWithdrawFee",
        type: "uint16",
      },
    ],
    name: "WithdrawFeeUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint96",
        name: "shares",
        type: "uint96",
      },
      {
        indexed: false,
        internalType: "uint40",
        name: "maturity",
        type: "uint40",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "allowThirdPartyToComplete",
        type: "bool",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "withdrawalIdx",
        type: "uint256",
      },
    ],
    name: "WithdrawRequested",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "asset",
        type: "address",
      },
    ],
    name: "WithdrawalsStopped",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "withdrawalIdx",
        type: "uint256",
      },
    ],
    name: "WithrawalCompleted",
    type: "event",
  },
  {
    inputs: [],
    name: "authority",
    outputs: [
      {
        internalType: "contract Authority",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "withdrawalIdx",
        type: "uint256",
      },
    ],
    name: "cancelUserWithdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "withdrawalIdx",
        type: "uint256",
      },
    ],
    name: "cancelWithdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "uint16",
        name: "maxLoss",
        type: "uint16",
      },
    ],
    name: "changeMaxLoss",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "uint32",
        name: "withdrawDelay",
        type: "uint32",
      },
    ],
    name: "changeWithdrawDelay",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "uint16",
        name: "withdrawFee",
        type: "uint16",
      },
    ],
    name: "changeWithdrawFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "withdrawalIdx",
        type: "uint256",
      },
    ],
    name: "completeUserWithdraw",
    outputs: [
      {
        internalType: "uint256",
        name: "assetsOut",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "withdrawalIdx",
        type: "uint256",
      },
    ],
    name: "completeWithdraw",
    outputs: [
      {
        internalType: "uint256",
        name: "assetsOut",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "feeAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
    ],
    name: "getAllWithdrawRequests",
    outputs: [
      {
        components: [
          {
            internalType: "bool",
            name: "allowThirdPartyToComplete",
            type: "bool",
          },
          {
            internalType: "uint40",
            name: "maturity",
            type: "uint40",
          },
          {
            internalType: "uint96",
            name: "shares",
            type: "uint96",
          },
          {
            internalType: "uint256",
            name: "assetsAtTimeOfRequest",
            type: "uint256",
          },
        ],
        internalType: "struct DelayedWithdraw.WithdrawRequest[]",
        name: "requests",
        type: "tuple[]",
      },
      {
        internalType: "uint256[]",
        name: "keys",
        type: "uint256[]",
      },
      {
        internalType: "uint256",
        name: "lastIdx",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "withdrawalIdx",
        type: "uint256",
      },
    ],
    name: "getWithdrawRequest",
    outputs: [
      {
        components: [
          {
            internalType: "bool",
            name: "allowThirdPartyToComplete",
            type: "bool",
          },
          {
            internalType: "uint40",
            name: "maturity",
            type: "uint40",
          },
          {
            internalType: "uint96",
            name: "shares",
            type: "uint96",
          },
          {
            internalType: "uint256",
            name: "assetsAtTimeOfRequest",
            type: "uint256",
          },
        ],
        internalType: "struct DelayedWithdraw.WithdrawRequest",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
    ],
    name: "getWithdrawRequestKeys",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
    ],
    name: "getWithdrawRequestLastIdx",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "isPaused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "uint96",
        name: "shares",
        type: "uint96",
      },
      {
        internalType: "bool",
        name: "allowThirdPartyToComplete",
        type: "bool",
      },
    ],
    name: "requestWithdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "shares",
        type: "uint256",
      },
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
    ],
    name: "safeLockedShares",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "bool",
        name: "allow",
        type: "bool",
      },
      {
        internalType: "uint256",
        name: "withdrawalIdx",
        type: "uint256",
      },
    ],
    name: "setAllowThirdPartyToComplete",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract Authority",
        name: "newAuthority",
        type: "address",
      },
    ],
    name: "setAuthority",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_feeAddress",
        type: "address",
      },
    ],
    name: "setFeeAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "maxWithdraw",
        type: "uint256",
      },
    ],
    name: "setMaxWithdrawPerUser",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "uint32",
        name: "withdrawDelay",
        type: "uint32",
      },
      {
        internalType: "uint16",
        name: "withdrawFee",
        type: "uint16",
      },
      {
        internalType: "uint16",
        name: "maxLoss",
        type: "uint16",
      },
      {
        internalType: "uint256",
        name: "maxWithdrawPerUser",
        type: "uint256",
      },
    ],
    name: "setupWithdrawAsset",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
    ],
    name: "stopWithdrawalsInAsset",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
    ],
    name: "transferDustToStrategy",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "unpause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
    ],
    name: "viewOutstandingDebt",
    outputs: [
      {
        internalType: "uint256",
        name: "debt",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20[]",
        name: "assets",
        type: "address[]",
      },
    ],
    name: "viewOutstandingDebts",
    outputs: [
      {
        internalType: "uint256[]",
        name: "debts",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "",
        type: "address",
      },
    ],
    name: "withdrawAssets",
    outputs: [
      {
        internalType: "bool",
        name: "allowWithdraws",
        type: "bool",
      },
      {
        internalType: "uint32",
        name: "withdrawDelay",
        type: "uint32",
      },
      {
        internalType: "uint128",
        name: "outstandingShares",
        type: "uint128",
      },
      {
        internalType: "uint16",
        name: "withdrawFee",
        type: "uint16",
      },
      {
        internalType: "uint16",
        name: "maxLoss",
        type: "uint16",
      },
      {
        internalType: "uint256",
        name: "maxWithdrawPerUser",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class YearnDelayedWithdrawV2__factory {
  static readonly abi = _abi;
  static createInterface(): YearnDelayedWithdrawV2Interface {
    return new utils.Interface(_abi) as YearnDelayedWithdrawV2Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): YearnDelayedWithdrawV2 {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as YearnDelayedWithdrawV2;
  }
}
