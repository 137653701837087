export const ROUTES = {
  StakeSwETH: '/stake/sweth',
  StakeRswETH: '/stake/rsweth',
  StakeSwBTC: '/stake/swbtc',
  StakeSwell: '/stake/swell',
  Layer2SwellL2: '/layer2/swell-l2',
  DaoVoyage: '/dao/voyage',
  DaoSwellCity: '/dao/swell-city',
  EarnPortfolio: '/earn/portfolio',
  EarnVaults: '/earn/vaults',
}
