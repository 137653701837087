import { BigNumber } from 'ethers'

export type MerkleClaims = {
  claimableAmount: BigNumber
  vestingAmount: BigNumber
}

export function merkleClaimable({
  cumulativeAmount,
  totalAmount,
  cumulativeClaimed,
}: {
  cumulativeAmount: BigNumber
  totalAmount: BigNumber
  cumulativeClaimed: BigNumber
}): MerkleClaims {
  let claimableAmount = cumulativeAmount.sub(cumulativeClaimed)
  if (claimableAmount.lt(0)) {
    claimableAmount = BigNumber.from(0)
  }
  let vestingAmount = totalAmount.sub(cumulativeAmount)
  if (vestingAmount.lt(0)) {
    vestingAmount = BigNumber.from(0)
  }

  return {
    claimableAmount,
    vestingAmount,
  }
}
