import styled from 'styled-components/macro'
import { BigNumber } from 'ethers'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Skeleton } from '@/swell-ui/Skeleton'
import { Typography } from '@/swell-ui/Typography'
import { AccountInfoButton } from './AccountInfoButton'
import { displayCrypto } from '@/util/displayCrypto'
import { useBtcLrtVault } from '@/state/yearnVault/hooks'
import { TokenLogo } from '../TokenLogo'

interface SymbioticAccountButtonProps {
  vaultTokenBalance: BigNumber | undefined
}

const Btn = styled(AccountInfoButton)`
  pointer-events: none;
  padding: 6px 12px;
`

const FieldContainer = styled.div`
  display: flex;
  min-width: 78px;
`

function SymbioticAccountButton({
  vaultTokenBalance,
}: SymbioticAccountButtonProps) {
  const { vaultToken } = useBtcLrtVault()

  let sswETHBalanceDisplay = ''
  if (vaultTokenBalance) {
    sswETHBalanceDisplay = displayCrypto(
      vaultTokenBalance,
      vaultToken.decimals,
      {
        precision: 4,
        localize: true,
      }
    )
  }

  return (
    <Btn color="accent">
      <FlexRow gap="12">
        <TokenLogo size={32} token={vaultToken} />
        <AsyncDiv
          loading={!sswETHBalanceDisplay}
          Container={FieldContainer}
          loader={() => <Skeleton width={100} />}
        >
          {() => {
            return (
              <Typography variant="body" size="medium" letterSpacing="small">
                {sswETHBalanceDisplay} {vaultToken.symbol}
              </Typography>
            )
          }}
        </AsyncDiv>
      </FlexRow>
    </Btn>
  )
}

export { SymbioticAccountButton }
