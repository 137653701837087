const SymbioticThemeData = {
  symbioticLime: '#C0FD5C',
  symbioticTitleGrey: '#F1F1F1',
  symbioticBackgroundSolid: '#080808',
  symbioticDarkGrey: '#4A4A4A',
  symbioticBlack: '#0B0B0B',
  symbioticLightGrey: '#B0B0B0',
  symbioticBorderRadius: '16px',
  symbioticBackdropColor: 'rgba(11, 11, 11, 0.60)',
  SymbioticBackdropFilter: 'blur(30px)',
  symbioticBodyFontFamily: 'Inter',
  symbioticButtonPrimaryTextColor: '#000',
  symbioticEthBlue: '#88AAF1',
  symbioticBtcOrange: '#F7931A',
  symbioticBitcoinDark: '#090D14',
  symbioticBitcoinNavy: '#14161F',
}

const SwellThemeData = {
  SwellOceanblue125: '#0B141B',
  SwellVaultTurquoise: '#00FFFF',
  SwellVaultDarkTurqoise: '#01C6C6',
  SwellVaultDarkCyan: '#002225',
  SwellOceanBlue: '#010508',
  SwellOceanBlue150: '#010508',
  SwellLightBlue75: '#6E79E9',
  SwellLightBlue100: '#2F43EC',
  SwellLightBlue: '#2f43ec',
  SwellVaultGreenCharcoal: '#001214',
}

const EigenThemeData = {
  // Eigen Layer/Yellow
  EigenLayerYellow: '#FFD700',
}

export const ThemeData = {
  Symbiotic: SymbioticThemeData,
  Swell: SwellThemeData,
  Eigen: EigenThemeData,
}
