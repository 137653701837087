import React, { ReactNode, useState } from 'react'
import { Box } from '@/swell-ui/Box'
import { Tabs } from '@/swell-ui/Tabs'
import styled from 'styled-components'
import { YearnBalances, YearnVaultStats } from '@/state/yearnVault/types'
import { YearnAeraStatistics } from './YearnAeraStatistics'
import { YearnAeraContracts } from './YearnAeraContracts'
import { hScrollStyle } from './common'
import {
  YearnAeraVaultPoints,
  YearnAeraVault,
  YearnAeraUserPoints,
} from '@/types/yearnAera'
import { YearnAeraVaultDescription } from './YearnAeraVaultDescription'
import { YearnAeraPointsCampaigns } from '../types'
import { PriceReading } from '@/state/prices/types'

const Tab = {
  Statistics: 'Statistics' as const,
  Description: 'Description' as const,
  Contracts: 'Contracts' as const,
}
type Tab = (typeof Tab)[keyof typeof Tab]

export function YearnAeraDetails({
  vaultStats,
  balances,
  vault,
  depositAssetUsdRate,
  isBtc,
  vaultDescription,
  pointsCampaigns,
  userPoints,
  vaultPoints,
}: {
  vaultDescription: ReactNode
  vault: YearnAeraVault
  vaultStats: YearnVaultStats | undefined
  userPoints: YearnAeraUserPoints | undefined
  vaultPoints: YearnAeraVaultPoints | undefined
  balances: YearnBalances | undefined
  depositAssetUsdRate: PriceReading | undefined
  pointsCampaigns: YearnAeraPointsCampaigns
  isBtc?: boolean
}) {
  const [selectedTab, setTab] = useState(Tab.Statistics as string)

  let content: ReactNode = null
  if (selectedTab === Tab.Statistics) {
    content = (
      <YearnAeraStatistics
        vaultStats={vaultStats}
        vault={vault}
        userPoints={userPoints}
        vaultPoints={vaultPoints}
        balances={balances}
        depositAssetUsdRate={depositAssetUsdRate}
        pointsCampaigns={pointsCampaigns}
        isBtc={isBtc}
      />
    )
  } else if (selectedTab === Tab.Description) {
    content = <YearnAeraVaultDescription vaultDescription={vaultDescription} />
  } else if (selectedTab === Tab.Contracts) {
    content = <YearnAeraContracts vault={vault} />
  }

  return (
    <DetailsBox>
      <Tabs
        selectedTab={selectedTab}
        tabs={[
          { id: Tab.Statistics, label: 'Statistics' },
          { id: Tab.Description, label: 'Description' },
          { id: Tab.Contracts, label: 'Contracts' },
        ]}
        setTab={setTab as (tab: string) => void}
      />
      <div className="positioner">
        <div className="content">
          <div className="scrollcontent">{content}</div>
        </div>
      </div>
    </DetailsBox>
  )
}

const DetailsBox = styled(Box)`
  width: 100%;
  padding: 24px;
  height: 312px;
  display: flex;
  flex-direction: column;

  .positioner,
  .content,
  .scrollcontent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 900px) {
    height: 542px;

    .positioner {
      position: relative;
    }
    .content {
      position: absolute;
      inset: 0;
    }
    .scrollcontent {
      overflow-x: auto;
      ${hScrollStyle};
    }
  }
`
