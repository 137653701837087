import { DelayedWithdraw } from '@/abis/types/YearnDelayedWithdrawV2'
import { YearnWithdrawRequestResult } from '@/state/yearnVault/types'
import { BigNumber } from 'ethers'
import partition from 'lodash/partition'

type IntermediateRequest = {
  keyIdx: number
  idx: number
  allowThirdPartyToComplete: boolean
  maturityUnix: number
  shares: BigNumber
  assetsAtTimeOfRequest: BigNumber
}

function toWithdrawV2Result(
  req: IntermediateRequest
): YearnWithdrawRequestResult {
  return {
    exists: true,
    request: {
      version: 'v2',
      assetsAtTimeOfRequest: req.assetsAtTimeOfRequest,
      maturityUnix: req.maturityUnix,
      shares: req.shares,
      withdrawalIdx: req.idx,
    },
  }
}

export function yearnAeraChooseWithdrawRequestV2({
  nowUnix,
  onchainRequests,
}: {
  nowUnix: number
  onchainRequests: {
    requests: DelayedWithdraw.WithdrawRequestStructOutput[]
    keys: BigNumber[]
    lastIdx: BigNumber
  }
}): YearnWithdrawRequestResult {
  const { requests, keys } = onchainRequests

  const reqs = requests.map<IntermediateRequest>((r, idx) => ({
    keyIdx: idx,
    idx: keys[idx].toNumber(),
    allowThirdPartyToComplete: r.allowThirdPartyToComplete,
    shares: r.shares,
    assetsAtTimeOfRequest: r.assetsAtTimeOfRequest,
    maturityUnix: r.maturity,
  }))

  // if there are no requests, return false
  // if there are claimable requests return the oldest one
  // if all requests are pending, return the oldest one

  const noRequests = reqs.length === 0
  if (noRequests) {
    return { exists: false }
  }

  const [maturedRequests, pendingRequests] = partition(
    reqs,
    (r) => r.maturityUnix <= nowUnix
  )

  if (maturedRequests.length > 0) {
    let oldestRequest = maturedRequests[0]
    for (let i = 1; i < maturedRequests.length; i++) {
      if (maturedRequests[i].maturityUnix < oldestRequest.maturityUnix) {
        oldestRequest = maturedRequests[i]
      }
    }

    return toWithdrawV2Result(oldestRequest)
  }

  if (pendingRequests.length === 0) {
    return { exists: false }
  }

  let oldestRequest = pendingRequests[0]
  for (let i = 1; i < pendingRequests.length; i++) {
    if (pendingRequests[i].idx < oldestRequest.idx) {
      oldestRequest = pendingRequests[i]
    }
  }

  return toWithdrawV2Result(oldestRequest)
}
