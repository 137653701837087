// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file swell/v3/wallet.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AirdropCheckerRequest, AirdropCheckerResponse, AirdropRequest, AirdropResponse, BtcLrtUserRequest, BtcLrtUserResponse, CheckIPRequest, CheckIPResponse, EarnCampaignsRequest, EarnCampaignsResponse, EarnPositionsRequest, EarnPositionsResponse, EigenLayerAirdropRequest, EigenLayerAirdropResponse, IsGeoRestrictedRequest, IsGeoRestrictedResponse, RswellUserRequest, RswellUserResponse, SwellL2EcosystemUserRequest, SwellL2EcosystemUserResponse, TokenBalancesRequest, TokenBalancesResponse, TokenMetadataRequest, TokenMetadataResponse, UserPortfolioHoldingsRequest, UserPortfolioHoldingsResponse, Wavedrop2UserRequest, Wavedrop2UserResponse, WavedropUserRequest, WavedropUserResponse } from "./wallet_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service swell.v3.WalletService
 */
export const WalletService = {
  typeName: "swell.v3.WalletService",
  methods: {
    /**
     * @generated from rpc swell.v3.WalletService.EigenlayerAirdrop
     */
    eigenlayerAirdrop: {
      name: "EigenlayerAirdrop",
      I: EigenLayerAirdropRequest,
      O: EigenLayerAirdropResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.WalletService.Airdrop
     */
    airdrop: {
      name: "Airdrop",
      I: AirdropRequest,
      O: AirdropResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.WalletService.AirdropChecker
     */
    airdropChecker: {
      name: "AirdropChecker",
      I: AirdropCheckerRequest,
      O: AirdropCheckerResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.WalletService.CheckIP
     */
    checkIP: {
      name: "CheckIP",
      I: CheckIPRequest,
      O: CheckIPResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.WalletService.TokenBalances
     */
    tokenBalances: {
      name: "TokenBalances",
      I: TokenBalancesRequest,
      O: TokenBalancesResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.WalletService.TokenMetadata
     */
    tokenMetadata: {
      name: "TokenMetadata",
      I: TokenMetadataRequest,
      O: TokenMetadataResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.WalletService.IsGeoRestricted
     */
    isGeoRestricted: {
      name: "IsGeoRestricted",
      I: IsGeoRestrictedRequest,
      O: IsGeoRestrictedResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.WalletService.SwellL2EcosystemUser
     */
    swellL2EcosystemUser: {
      name: "SwellL2EcosystemUser",
      I: SwellL2EcosystemUserRequest,
      O: SwellL2EcosystemUserResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.WalletService.WavedropUser
     */
    wavedropUser: {
      name: "WavedropUser",
      I: WavedropUserRequest,
      O: WavedropUserResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.WalletService.Wavedrop2User
     */
    wavedrop2User: {
      name: "Wavedrop2User",
      I: Wavedrop2UserRequest,
      O: Wavedrop2UserResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.WalletService.BtcLrtUser
     */
    btcLrtUser: {
      name: "BtcLrtUser",
      I: BtcLrtUserRequest,
      O: BtcLrtUserResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.WalletService.RswellUser
     */
    rswellUser: {
      name: "RswellUser",
      I: RswellUserRequest,
      O: RswellUserResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.WalletService.UserPortfolioHoldings
     */
    userPortfolioHoldings: {
      name: "UserPortfolioHoldings",
      I: UserPortfolioHoldingsRequest,
      O: UserPortfolioHoldingsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.WalletService.EarnCampaigns
     */
    earnCampaigns: {
      name: "EarnCampaigns",
      I: EarnCampaignsRequest,
      O: EarnCampaignsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.WalletService.EarnPositions
     */
    earnPositions: {
      name: "EarnPositions",
      I: EarnPositionsRequest,
      O: EarnPositionsResponse,
      kind: MethodKind.ServerStreaming,
      idempotency: MethodIdempotency.NoSideEffects,
    },
  }
} as const;

