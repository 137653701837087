import { Token } from '@/types/tokens'
import {
  displayCryptoLocale,
  displayCryptoNoLocale,
} from '@/util/displayCrypto'
import { BigNumber } from 'ethers'
import { merkleClaimable } from '../../util/merkledrop'
import { formatUnits } from 'ethers/lib/utils'
import { displayPercent } from '@/util/displayNumbers'
import { MerkleClaimedEvent, MerkleDropData } from '@/types/merkle'
import { SwellDaoClaimAirdrop } from '@/state/dao/hooks'
import { AirdropResult } from '@/state/dao/types'
import {
  AirdropClaimSummary,
  AirdropCompletionSummary,
  AirdropVestingUnlockSchedule,
} from './types'

export type AirdropRewardSummary = {
  baseReward: string
  loyaltyBonus: string
  totalClaimable: string
}

export function makeRewardSummary({
  daoToken,
  airdropResult,
}: {
  daoToken: Token
  airdropResult: AirdropResult
}): AirdropRewardSummary {
  let baseRewardStr: string | undefined
  let loyaltyBonusStr: string | undefined
  let totalClaimableStr: string | undefined

  if (airdropResult && airdropResult.exists) {
    const {
      data: { totalAmount },
      loyaltyAmount,
    } = airdropResult

    const baseReward = totalAmount.sub(loyaltyAmount)

    baseRewardStr = displayCryptoNoLocale(baseReward, daoToken.decimals, {
      precision: 2,
      roundLarge: true,
    })
    loyaltyBonusStr = displayCryptoNoLocale(loyaltyAmount, daoToken.decimals, {
      precision: 2,
      roundLarge: true,
    })
    totalClaimableStr = displayCryptoNoLocale(totalAmount, daoToken.decimals, {
      precision: 2,
      roundLarge: true,
    })
  }

  return {
    baseReward: baseRewardStr ?? '0',
    loyaltyBonus: loyaltyBonusStr ?? '0',
    totalClaimable: totalClaimableStr ?? '0',
  }
}

export function displayVestingClaimableDate(timeUnix: number) {
  const date = new Date(timeUnix * 1000)
  const day = String(date.getUTCDate()).padStart(2, '0')
  const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are zero-indexed
  const year = date.getUTCFullYear()

  return `${day}.${month}.${year}`
}

export function displayVestingClaimableDateTime(timeUnix: number) {
  const date = new Date(timeUnix * 1000)
  const day = String(date.getUTCDate()).padStart(2, '0')
  const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are zero-indexed
  const year = date.getUTCFullYear()
  const hours = String(date.getUTCHours()).padStart(2, '0')
  const minutes = String(date.getUTCMinutes()).padStart(2, '0')

  return `${day}.${month}.${year} ${hours}:${minutes} UTC`
}

type VestingBreakdown = {
  vestedPercent: string
  initialClaimableAmount: string
  initialClaimablePercent: string
  firstUnlockClaimableAmount: string
  firstUnlockClaimablePercent: string
  secondUnlockClaimableAmount: string
  secondUnlockClaimablePercent: string
  thirdUnlockClaimableAmount: string
  thirdUnlockClaimablePercent: string
}

export function displayVestingBreakdown({
  daoToken,
  data,
  initialCumulativeAmount,
}: {
  daoToken: Token
  data: MerkleDropData
  initialCumulativeAmount: BigNumber
}): VestingBreakdown {
  const { claimableAmount: claimableAmountOG, vestingAmount: vestingAmountOG } =
    merkleClaimable({
      cumulativeAmount: initialCumulativeAmount,
      cumulativeClaimed: BigNumber.from(0),
      totalAmount: data.totalAmount,
    })

  const oneThirdOfVestingAmount = vestingAmountOG.div(3)

  let ratio = 0
  if (data.totalAmount.gt(0)) {
    const cumulativeAmountNum = Number(
      formatUnits(initialCumulativeAmount, daoToken.decimals)
    )
    const totalAmountNum = Number(
      formatUnits(data.totalAmount, daoToken.decimals)
    )

    ratio = cumulativeAmountNum / totalAmountNum
  }

  const vestedRatio = 1 - ratio
  const initialClaimablePercent = displayPercent(ratio, { precision: 0 })
  const vestedPercent = displayPercent(vestedRatio, { precision: 0 })

  const firstUnlockRatio = vestedRatio / 3
  const secondUnlockRatio = vestedRatio / 3
  const thirdUnlockRatio = vestedRatio / 3

  const firstUnlockClaimablePercent = displayPercent(firstUnlockRatio, {
    precision: 0,
  })
  const secondUnlockClaimablePercent = displayPercent(secondUnlockRatio, {
    precision: 0,
  })
  const thirdUnlockClaimablePercent = displayPercent(thirdUnlockRatio, {
    precision: 0,
  })

  const initialClaimableAmountStr = displayCryptoLocale(
    claimableAmountOG,
    daoToken.decimals,
    { precision: 2, roundLarge: true }
  )
  const firstUnlockClaimableAmountStr = displayCryptoLocale(
    oneThirdOfVestingAmount,
    daoToken.decimals,
    { precision: 2, roundLarge: true }
  )
  const secondUnlockClaimableAmountStr = displayCryptoLocale(
    oneThirdOfVestingAmount,
    daoToken.decimals,
    { precision: 2, roundLarge: true }
  )

  // Calculate the third unlock amount, accounting for rounding errors
  const thirdUnlockClaimableAmount = vestingAmountOG
    .sub(oneThirdOfVestingAmount)
    .sub(oneThirdOfVestingAmount)

  const thirdUnlockClaimableAmountStr = displayCryptoLocale(
    thirdUnlockClaimableAmount,
    daoToken.decimals,
    { precision: 2, roundLarge: true }
  )

  return {
    vestedPercent,
    initialClaimableAmount: initialClaimableAmountStr,
    firstUnlockClaimableAmount: firstUnlockClaimableAmountStr,
    firstUnlockClaimablePercent,
    initialClaimablePercent,
    secondUnlockClaimableAmount: secondUnlockClaimableAmountStr,
    secondUnlockClaimablePercent,
    thirdUnlockClaimableAmount: thirdUnlockClaimableAmountStr,
    thirdUnlockClaimablePercent,
  }
}

export function makeClaimSummary({
  airdropResult,
  daoToken,
  nextAirdropVestingUnlockUnix,
}: {
  airdropResult: AirdropResult
  daoToken: Token
  nextAirdropVestingUnlockUnix: number
}): AirdropClaimSummary {
  const rewardSummary = makeRewardSummary({ daoToken, airdropResult })
  let vestingAmountStr: string | undefined
  let vestedAmountStr: string | undefined

  if (airdropResult && airdropResult.exists) {
    const {
      cumulativeClaimed,
      data: { cumulativeAmount, totalAmount },
    } = airdropResult
    const { claimableAmount, vestingAmount } = merkleClaimable({
      cumulativeAmount,
      cumulativeClaimed,
      totalAmount,
    })
    const vested = claimableAmount

    vestingAmountStr = displayCryptoLocale(vestingAmount, daoToken.decimals, {
      precision: 2,
      roundLarge: true,
    })
    vestedAmountStr = displayCryptoLocale(vested, daoToken.decimals, {
      precision: 2,
      roundLarge: true,
    })
  }

  let claimableDateStr: string | undefined
  if (nextAirdropVestingUnlockUnix) {
    claimableDateStr = displayVestingClaimableDate(nextAirdropVestingUnlockUnix)
  }

  return {
    baseReward: rewardSummary.baseReward,
    loyaltyBonus: rewardSummary.loyaltyBonus,
    totalClaimable: rewardSummary.totalClaimable,
    vestingAmount: vestingAmountStr ?? '0',
    vestedAmount: vestedAmountStr ?? '0',
    claimableDate: claimableDateStr ?? 'N/A',
  }
}

// creates summary data to be rendered in the Completion component
export function makeCompletionSummary({
  daoToken,
  restakedDaoToken,
  airdropResult,
  cachedClaimAirdropArguments,
}: {
  daoToken: Token
  restakedDaoToken: Token
  airdropResult: AirdropResult

  // data that the user submitted successfully. Not available if they're revisiting the page.
  cachedClaimAirdropArguments?: Parameters<SwellDaoClaimAirdrop['call']>[0]
}): AirdropCompletionSummary {
  if (!airdropResult.exists) {
    return {
      claimedToWalletAmount: '0',
      totalClaimedAmount: '0',
      totalRestakedAmount: '0',
      totalClaimedTokenSymbols: [daoToken.symbol],
    }
  }

  let claimedToWalletAmount = ''
  let totalRestakedAmount = ''
  let totalClaimedAmount = ''
  let totalClaimedTokenSymbols: string[] = []

  let syntheticLatestEvent: MerkleClaimedEvent | undefined
  if (cachedClaimAirdropArguments) {
    syntheticLatestEvent = {
      cumulativeAmount: cachedClaimAirdropArguments.cumulativeAmount,
      amountLocked: cachedClaimAirdropArguments.amountToLock,
      timestampUnix: Number.MAX_SAFE_INTEGER,
    }
  }

  let latestOnChainEvent: MerkleClaimedEvent | undefined
  {
    let t = 0
    for (const event of airdropResult.claimedEvents) {
      if (event.timestampUnix > t) {
        t = event.timestampUnix
        latestOnChainEvent = event
      }
    }
  }

  const claimedEvents = [...airdropResult.claimedEvents]

  if (latestOnChainEvent && syntheticLatestEvent) {
    if (
      syntheticLatestEvent.cumulativeAmount.gt(
        latestOnChainEvent.cumulativeAmount
      )
    ) {
      claimedEvents.push(syntheticLatestEvent)
    }
  } else if (syntheticLatestEvent) {
    claimedEvents.push(syntheticLatestEvent)
  }

  let amountToLockTotal = BigNumber.from(0)
  let cumulativeAmount = BigNumber.from(0)
  for (const event of claimedEvents) {
    amountToLockTotal = amountToLockTotal.add(event.amountLocked)
    if (event.cumulativeAmount.gt(cumulativeAmount)) {
      cumulativeAmount = event.cumulativeAmount
    }
  }

  claimedToWalletAmount = displayCryptoLocale(
    cumulativeAmount.sub(amountToLockTotal),
    daoToken.decimals,
    { precision: 2, roundLarge: true }
  )
  totalRestakedAmount = displayCryptoLocale(
    amountToLockTotal,
    restakedDaoToken.decimals,
    { precision: 2, roundLarge: true }
  )
  totalClaimedAmount = displayCryptoLocale(
    cumulativeAmount,
    daoToken.decimals,
    { precision: 2, roundLarge: true }
  )
  totalClaimedTokenSymbols = []
  if (amountToLockTotal.eq(0)) {
    totalClaimedTokenSymbols = [daoToken.symbol]
  } else if (cumulativeAmount.eq(amountToLockTotal)) {
    totalClaimedTokenSymbols = [restakedDaoToken.symbol]
  } else {
    totalClaimedTokenSymbols = [daoToken.symbol, restakedDaoToken.symbol]
  }

  return {
    claimedToWalletAmount,
    totalRestakedAmount,
    totalClaimedAmount,
    totalClaimedTokenSymbols,
  }
}
