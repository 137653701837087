import React from 'react'
import { title1CSS } from '../styles/common'
import { CircularProgress } from '@/swell-ui/CircularProgress'
import styled from 'styled-components'
import { FlexRow } from '@/swell-ui/FlexRow'

export function Loading() {
  return (
    <Layout
      direction="column"
      margin="auto"
      textAlign="center"
      maxWidth="620px"
      gap="12"
    >
      <h1>Checking eligibility</h1>
      <FlexRow direction="column" margin="24px 0 0 0" align="center">
        <CircularProgress size={76} />
        <p>Verifying</p>
      </FlexRow>
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  h1 {
    ${title1CSS};
  }
`
