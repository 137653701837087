import React from 'react'
import styled from 'styled-components/macro'
import MuiMenuItem from '@mui/material/MenuItem'
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select'
import { useSwellUiTheme } from '@swell-ui/theme'

export interface SelectOption {
  key: any
  label: string | React.ReactNode
  disabled?: boolean
}

interface SelectProps extends MuiSelectProps {
  options: SelectOption[]
}

const StyledMuiSelect = styled<any>(MuiSelect)`
  width: 100%;
  color: ${({ theme }) => theme.colors.white['50']};

  .MuiOutlinedInput-notchedOutline {
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 12px;
  }

  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.colors.lightBlue['75']};
    }
  }

  &.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.colors.lightBlue['100']};
    }
  }

  &.Mui-disabled {
    .MuiOutlinedInput-notchedOutline {
      background-color: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.05);
      border-radius: 12px;
    }

    color: ${({ theme }) => theme.colors.white['50']};
    opacity: 0.7;
  }
`

const StyledMuiMenuItem = styled(MuiMenuItem)`
  padding: 6px 16px;

  &:hover {
    background: ${({ theme }) => theme.colors.oceanBlue['125']};
    opacity: 0.7;
  }

  &.Mui-selected,
  &.Mui-selected:hover {
    background: ${({ theme }) => theme.colors.oceanBlue['125']};
    opacity: 0.7;
  }
`

export function SelectInput({ options, ...props }: SelectProps) {
  const { theme } = useSwellUiTheme()

  const menuStyles = {
    '& .MuiMenu-paper': {
      padding: `0 0`,
      borderRadius: `16px`,
      border: `1px solid ${theme.colors.lightBlue['50']}`,
      marginTop: '5px',
      background: `${theme.colors.oceanBlue['125']}`,
      color: `${theme.colors.white['50']}`,
    },
    '& .MuiList-root': {
      padding: '0',
    },
  }

  return (
    <StyledMuiSelect
      {...props}
      MenuProps={{
        sx: menuStyles,
      }}
    >
      {options.map((option: SelectOption) => {
        return (
          <StyledMuiMenuItem
            key={option.key}
            value={option.key}
            disabled={option.disabled}
          >
            {option.label}
          </StyledMuiMenuItem>
        )
      })}
    </StyledMuiSelect>
  )
}
