import { LifiService } from '@v3-backend/lifi_connect'
import { OperatorService } from '@v3-backend/operator_connect'
import { PreDepositService } from '@v3-backend/predeposit_connect'
import { RatesService } from '@v3-backend/rates_connect'
import { StakerService } from '@v3-backend/staker_connect'
import { StatsService } from '@v3-backend/stats_connect'
import { TwitterService } from '@v3-backend/twitter_connect'
import { VoyageService } from '@v3-backend/voyage_connect'
import { WalletService } from '@v3-backend/wallet_connect'
import { EigenPointsService } from '@v3-backend/eigenpoints_connect'
import { ParaswapService } from '@v3-backend/paraswap_connect'
import {
  NucleusService,
  NucleusWalletService,
} from '@/submodules/v3-shared/ts/connect/swell/v3/nucleus_connect'

export const V3_BACKEND_SERVICES = {
  LifiService,
  RatesService,
  OperatorService,
  PreDepositService,
  StakerService,
  StatsService,
  TwitterService,
  VoyageService,
  EigenPointsService,
  WalletService,
  ParaswapService,
  NucleusService,
  NucleusWalletService,
}
