import styled, { css } from 'styled-components'
import { title1CSS } from '@/components/AirdropCarousel/styles/common'
import { DecoBox } from '@/components/DecoBox'
import { SwellDaoClaimAirdrop } from '@/state/dao/hooks'
import { Token } from '@/types/tokens'
import { AirdropResult } from '@/state/dao/types'
import { FlexRow } from '@/swell-ui/FlexRow'
import { ColoredHeadingTypography } from '@/swell-ui/Typography/typographyPresets'
import { ThemeData } from '@/swell-ui/theme/branding'
import { AirdropTwitterShareButton } from '../../AirdropButtons'
import { makeCompletionSummary } from '../../airdropFormatting'
import { keyframes } from 'styled-components'
import { useMediaQuery } from '@mui/material'

const bodyMediumCSS = css`
  color: var(--Swell-White-50, #fff);
  text-align: right;
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
`
const bodyLargeBoldCSS = css`
  color: var(--Swell-White-50, #fff);
  /* Body/large bold */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
`

const tokenSymbolTextCSS = css`
  color: var(--Swell-White-50, #fff);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`

// Renders the completion screen of the airdrop carousel, showing a summary of the user's claimed/restaked tokens
export function Completion({
  daoToken,
  restakedDaoToken,
  airdropResult,
  cachedClaimAirdropArguments,
}: {
  daoToken: Token
  restakedDaoToken: Token
  airdropResult: AirdropResult

  // data that the user submitted successfully when claiming the airdrop. Not available if they're revisiting the page.
  cachedClaimAirdropArguments?: Parameters<SwellDaoClaimAirdrop['call']>[0]
}) {
  const summary = makeCompletionSummary({
    daoToken,
    restakedDaoToken,
    airdropResult,
    cachedClaimAirdropArguments,
  })

  const claimedTokens: Token[] = []
  if (summary.totalClaimedTokenSymbols.includes(daoToken.symbol)) {
    claimedTokens.push(daoToken)
  }
  if (summary.totalClaimedTokenSymbols.includes(restakedDaoToken.symbol)) {
    claimedTokens.push(restakedDaoToken)
  }

  const claimedToWalletRow = (
    <DataRow
      label="Claimed to Wallet"
      value={summary.claimedToWalletAmount}
      tokens={[daoToken]}
    />
  )
  const totalRestakedRow = (
    <DataRow
      label="Total Restaked"
      value={summary.totalRestakedAmount}
      tokens={[restakedDaoToken]}
    />
  )
  const totalClaimedRow = (
    <DataRow
      label="Total Claimed"
      value={summary.totalClaimedAmount}
      tokens={claimedTokens}
    />
  )

  return (
    <Layout
      direction="column"
      margin="0 auto"
      justify="center"
      align="center"
      gap="16"
      textAlign="center"
    >
      <h1>Congratulations!</h1>
      <p>You have claimed your airdrop!</p>
      <div className="spacer no-mob" />
      <ClaimSummaryBox
        align="center"
        justify="center"
        gap="12"
        textAlign="center"
        width="auto"
      >
        <ColoredHeadingTypography>Claimed Swell</ColoredHeadingTypography>
        <Grid>
          {claimedToWalletRow}
          {totalRestakedRow}
          <HDivider />
          {totalClaimedRow}
        </Grid>
      </ClaimSummaryBox>
      <div className="btn-wide">
        <AirdropTwitterShareButton summary={summary} />
      </div>
      <div className="spacer only-mob" />
      <div className="spacer only-mob" />
    </Layout>
  )
}
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const fadeInCSS = css`
  opacity: 0;
  animation: ${fadeIn} 0.65s ease-out forwards;
  animation-delay: 0;
`

const Layout = styled(FlexRow)`
  ${fadeInCSS}
  h1 {
    margin: 0;
    ${title1CSS}
  }
  .body-medium {
    ${bodyMediumCSS}
  }
  .large-bold {
    ${bodyLargeBoldCSS}
  }
  .token-symbol {
    ${tokenSymbolTextCSS}
  }
  .btn-wide {
    max-width: 431px;
    width: 100%;
    > a,
    button {
      width: 100%;
    }
  }
  .left {
    flex: 1;
    text-align: left;
  }
  .spacer {
    height: 16px;
  }
  .no-mob {
    display: none;
  }
  .only-mob {
    display: inherit;
  }
  @media (min-width: 460px) {
    .no-mob {
      display: inherit;
    }
    .only-mob {
      display: none;
    }
  }
`

function DataRow({
  label,
  tokens,
  value,
}: {
  label: string
  value: string
  tokens: Token[]
}) {
  const isXS = useMediaQuery('(max-width: 400px)')

  const tokenSymbolStr = tokens.map((token) => token.symbol).join(' & ')
  const hasTwoSymbols = tokens.length === 2

  const becomeColXS = isXS && hasTwoSymbols

  return (
    <>
      <span className="body-medium label">{label}</span>
      <FlexRow
        gap="6"
        className="info"
        direction={becomeColXS ? 'column' : 'row'}
      >
        <TokenGroup>
          {tokens.map((token) => (
            <img
              key={token.address}
              src={token.logoURI}
              className="token-logo"
              width="32"
              height="32"
            />
          ))}
        </TokenGroup>

        <span className="large-bold left">
          {value}
          <span className="token-symbol"> {tokenSymbolStr}</span>
        </span>
      </FlexRow>
    </>
  )
}

const ClaimSummaryBox = styled(DecoBox)`
  ${ColoredHeadingTypography} {
    font-size: 14px;
    align-self: flex-start;
  }

  width: 100%;
  padding: 16px 12px !important;

  @media (min-width: 460px) {
    gap: 24px;
    max-width: 431px;
    padding: 16px !important;
  }
`

const Grid = styled.div`
  width: 100%;
  white-space: nowrap;
  display: grid;
  grid-template-columns: 85px 1fr;
  align-items: center;
  grid-column-gap: 24px;
  grid-row-gap: 12px;

  .info {
    justify-self: end;
    margin-left: auto;
    width: auto;
  }
  @media (min-width: 460px) {
    grid-template-columns: auto 1fr;
    max-width: 431px;

    .info {
      justify-self: start;
      width: unset;
      margin-left: 0;
    }
  }
`

const TokenGroup = styled(FlexRow)`
  flex: 0 1 max-content;

  img.token-logo {
    width: 32px;
    height: 32px;
    border-radius: 50%;

    &:not(:last-child) {
      margin-right: -10px;
      z-index: 1;
    }
  }
`

const HDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${ThemeData.Swell.SwellLightBlue};
  grid-column: 1 / -1;
`
