import { NucleusVault } from '@/types/nucleus'
import {
  TOKEN_LIST_APXETH,
  TOKEN_LIST_EARN_BTC,
  TOKEN_LIST_EARN_ETH,
  TOKEN_LIST_ETH,
  TOKEN_LIST_PXETH,
  TOKEN_LIST_RSWETH,
  TOKEN_LIST_SWETH,
  TOKEN_LIST_WBTC,
  TOKEN_LIST_WETH,
  TOKEN_LIST_WSTETH,
} from './tokens'
import { StrategyProvider } from '@/types/vaults'
import nucleusLogo from '@/assets/images/nucleus_logo_64x64.png'

export const NUCLEUS_ATOMIC_QUEUE_ADDRESS =
  '0xc7287780bfa0c5d2dd74e3e51e238b1cd9b221ee'

export const NUCLEUS_VAULT_EARN_BTC: NucleusVault = {
  name: 'BTC Earn Vault',
  vaultToken: TOKEN_LIST_EARN_BTC,
  baseAsset: TOKEN_LIST_WBTC,
  depositAssets: [TOKEN_LIST_WBTC],
  withdrawAssets: [TOKEN_LIST_WBTC],
  accountantAddress: '0xf242ab602cbf29cf9b5f4c3d90ca6eef947ba6f1',
  rolesAuthorityAddress: '0xC90a97451c4C7550812694DE1DADeCc87bcAE8c7',
  tellerAddress: '0x83ede55dc738d4c2e65c4b55172fbe3e14d83a4e',
}

export const NUCLEUS_VAULT_EARN_ETH: NucleusVault = {
  name: 'ETH Earn Vault',
  vaultToken: TOKEN_LIST_EARN_ETH,
  baseAsset: TOKEN_LIST_ETH,
  depositAssets: [
    TOKEN_LIST_WETH,
    TOKEN_LIST_SWETH,
    TOKEN_LIST_RSWETH,
    TOKEN_LIST_WSTETH,
    TOKEN_LIST_PXETH,
    TOKEN_LIST_APXETH,
  ],
  withdrawAssets: [
    TOKEN_LIST_WETH,
    TOKEN_LIST_SWETH,
    TOKEN_LIST_RSWETH,
    TOKEN_LIST_WSTETH,
    TOKEN_LIST_PXETH,
    TOKEN_LIST_APXETH,
  ],
  accountantAddress: '0x411c78BC8c36c3c66784514f28c56209e1DF2755',
  rolesAuthorityAddress: '0x1F5dddF627C3796a589c6271b36A570f18d3a016',
  tellerAddress: '0x685aDb4797fb38D4Fc4a69750aa048B398160429',
}

export const NUCLEUS_STRATEGY_PROVIDER_NUCLEUS_EARN: StrategyProvider = {
  name: 'Nucleus Earn',
  link: 'https://www.nucleusearn.io/',
  logoURI: nucleusLogo,
}
