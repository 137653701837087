import { DecoBox } from '@/components/DecoBox'
import { medValueCSS } from '@/components/AirdropCarousel/styles/common'
import { Token } from '@/types/tokens'
import styled from 'styled-components'
import { AirdropResult } from '@/state/dao/types'
import { displayVestingBreakdown } from '../../airdropFormatting'
import { FlexRow } from '@/swell-ui/FlexRow'
import { useMediaQuery } from '@mui/material'

const LINK_TO_VESTING_FORM = 'https://9zz8dr00fbt.typeform.com/swell'

export function VestingSummary({
  daoToken,
  airdropResult,
}: {
  airdropResult: AirdropResult
  daoToken: Token
}) {
  const isDesktop = useMediaQuery('(min-width: 990px)')
  if (!airdropResult.exists) return null
  const pt1CumulativeAmount = airdropResult.data.cumulativeAmount

  const { data } = airdropResult
  const {
    firstUnlockClaimableAmount,
    firstUnlockClaimablePercent,
    initialClaimableAmount,
    initialClaimablePercent,
    secondUnlockClaimableAmount,
    secondUnlockClaimablePercent,
    thirdUnlockClaimableAmount,
    thirdUnlockClaimablePercent,
    vestedPercent,
  } = displayVestingBreakdown({
    daoToken,
    data,
    initialCumulativeAmount: pt1CumulativeAmount,
  })

  const innerContent = (
    <>
      <FlexRow direction="column">
        <h4 className="sm-header">TGE ({initialClaimablePercent})</h4>
        <span className="value">{initialClaimableAmount} SWELL</span>
      </FlexRow>
      <FlexRow direction="column">
        <h4 className="sm-header">
          TGE + 30 Days ({firstUnlockClaimablePercent})
        </h4>
        <span className="value">{firstUnlockClaimableAmount} SWELL</span>
      </FlexRow>
      <FlexRow direction="column">
        <h4 className="sm-header">
          TGE + 60 Days ({secondUnlockClaimablePercent})
        </h4>
        <span className="value">{secondUnlockClaimableAmount} SWELL</span>
      </FlexRow>
      <FlexRow direction="column">
        <h4 className="sm-header">
          TGE + 90 Days ({thirdUnlockClaimablePercent})
        </h4>
        <span className="value">{thirdUnlockClaimableAmount} SWELL</span>
      </FlexRow>
    </>
  )

  const desktopContent = (
    <DesktopLayout gap="24" align="stretch">
      {innerContent}
    </DesktopLayout>
  )

  const mobileContent = (
    <MobileLayout direction="column" gap="24">
      {innerContent}
    </MobileLayout>
  )

  let content = mobileContent
  if (isDesktop) {
    content = desktopContent
  }

  let option1Text = ''
  if (airdropResult.vestingTier === 1) {
    option1Text =
      '30% vested at TGE, 30% vesting 30 days after, 20% vesting 60 days after and 20% vesting 90 days after TGE.'
  } else if (airdropResult.vestingTier === 2) {
    option1Text =
      '35% vested at TGE, 25% vesting 30 days after, 20% vesting 60 days after and 20% vesting 90 days after TGE.'
  } else if (airdropResult.vestingTier === 3) {
    option1Text =
      '40% vested at TGE, 20% vesting 30 days after, 20% vesting 60 days after and 20% vesting 90 days after TGE.'
  } else if (airdropResult.vestingTier === 4) {
    option1Text =
      '45% vested at TGE, 20% vesting 30 days after, 20% vesting 60 days after and 15% vesting 90 days after TGE.'
  } else if (airdropResult.vestingTier === 5) {
    option1Text =
      '50% vested at TGE, 20% vesting 30 days after, 15% vesting 60 days after and 15% vesting 90 days after TGE.'
  } else {
    console.error('Invalid vesting tier')
    return null
  }

  return (
    <StyledDecoBox gap="24" align="start">
      <h4>Vested allocation</h4>
      <p>
        After extensive feedback and consultation. We are pleased to offer two
        vesting options.
      </p>
      <p>
        <span className="b">Option 1 (default):</span> {option1Text}
        <br />
        <span className="b">Option 2:</span> 50% vested at TGE, the remaining
        will be restaked as rSWELL with 25% vesting 3 months after and 25% 6
        months after TGE.
      </p>
      <p>
        If you wish to choose Option 2 please sign a message below with your
        claimable wallet indicating your choice, otherwise you will be subject
        to receiving your tokens as Option 1.{' '}
        <span className="b">Deadline to choose is November 5th.</span>{' '}
        Signatures after that date will revert to Option 1.
      </p>
    </StyledDecoBox>
  )
}

const InnerBox = styled(FlexRow)`
  border: 1px solid var(--lightblue-100, #2f43ec);
  border-radius: 12px;
  justify-content: space-around;
  padding: 24px 62px;
  width: 100%;
  text-align: center;

  &:first-child {
    width: 432px;
    white-space: nowrap;
  }

  color: #fff;
`

const DesktopLayout = styled(InnerBox)`
  white-space: nowrap;
`
const MobileLayout = styled(InnerBox)`
  padding: 16px;
`

const StyledDecoBox = styled(DecoBox)`
  span.b {
    font-weight: bold;
  }

  h4 {
    margin: 0;
    color: var(--Swell-Lightblue-50, #a4abf1);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 22.4px */
    letter-spacing: 2.52px;
    text-transform: uppercase;
  }

  h4.sm-header {
    color: var(--Swell-Lightblue-50, #a4abf1);
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 16px */
    letter-spacing: 1.8px;
    text-transform: uppercase;
  }

  .value {
    ${medValueCSS}
  }
`
