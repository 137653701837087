import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { FlexRow } from '@/swell-ui/FlexRow'
import { useMediaQuery } from '@mui/material'
import styled from 'styled-components'
import { makeNucleusVaultStatsSummary } from './nucleusFormatting'
import { NucleusVaultStats } from '@/state/nucleusVault/types'

export function NucleusVaultStatsRow({
  vaultStats,
}: {
  vaultStats: NucleusVaultStats | undefined
}) {
  const is900Up = useMediaQuery('(min-width:900px)')
  const summary = makeNucleusVaultStatsSummary({ vaultStats })

  let justify: 'start' | 'end' = 'start'
  if (is900Up) {
    justify = 'end'
  }

  return (
    <Layout>
      <FlexRow
        gap="12"
        justify={justify}
        align="center"
        className="metric small-loading"
      >
        <span className="s-label">Est. APY</span>
        <AsyncDiv loading={!summary.apy}>
          {() => <span className="s-value">{summary.apy}</span>}
        </AsyncDiv>
      </FlexRow>
      <FlexRow gap="12" justify={justify} align="center" className="metric">
        <span className="s-label">TVL</span>
        <AsyncDiv loading={!summary.tvl}>
          {() => <span className="s-value">{summary.tvl}</span>}
        </AsyncDiv>
      </FlexRow>
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  .s-label {
    ${(props) => props.theme.typographyCSS.coloredHeading}
    white-space: nowrap;
  }

  .s-value {
    color: var(--Swell-White-50, #fff);
    /* Headlines/H6 */
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 33.8px */
    letter-spacing: -0.78px;
  }

  flex-wrap: wrap;
  gap: 12px;
  @media (min-width: 430px) {
    flex-wrap: nowrap;
    gap: 24px;
  }

  .metric {
    [aria-busy='true'] {
      height: 33.8px;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
  }

  .metric.small-loading {
    [aria-busy='true'] {
      min-width: 50px;
    }
  }

  .metric:not(.small-loading) {
    [aria-busy='true'] {
      min-width: 160px;
    }
  }

  flex: 0 1 max-content;
  > div {
    flex: 0 1 max-content;
  }
`
