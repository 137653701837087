import { useYearnVaultImplV1 } from './api/apiV1'
import { YearnVaultContext } from './context'
import { useYearnVaultImplV2 } from './api/apiV2'
import {
  useBtcLrtPointsFetcher,
  useBtcLrtStatsFetcher,
  useRswellPointsFetcher,
  useRswellStatsFetcher,
} from './fetchers'
import {
  useRswellVaultDeployment,
  useSwBTCVaultDeployment,
} from '../deployments/hooks/yearnSettings'

const invalidChain = async () => {
  throw new Error('invalid chain')
}

const INVALID_CHAIN_API: YearnVaultContext['write'] = {
  approveAssetForDeposit: invalidChain,
  approveAssetForDepositEstimateGas: invalidChain,
  approveVaultTokenForWithdraw: invalidChain,
  approveVaultTokenForWithdrawEstimateGas: invalidChain,
  cancelWithdraw: invalidChain,
  cancelWithdrawEstimateGas: invalidChain,
  deposit: invalidChain,
  depositEstimateGas: invalidChain,
  completeWithdraw: invalidChain,
  completeWithdrawEstimateGas: invalidChain,
  requestWithdraw: invalidChain,
  requestWithdrawEstimateGas: invalidChain,
}

export function useBtcLrtVaultApiImpl(): YearnVaultContext {
  const { valid, vault } = useSwBTCVaultDeployment()

  const statsFetcher = useBtcLrtStatsFetcher()
  const pointsFetcher = useBtcLrtPointsFetcher()

  const ctx = useYearnVaultImplV1({
    statsFetcher,
    vault,
    pointsFetcher,
  })

  if (!valid) {
    return {
      ...ctx,
      write: INVALID_CHAIN_API,
    }
  }

  return ctx
}

export function useRswellVaultApiImpl(): YearnVaultContext {
  const { valid, vault } = useRswellVaultDeployment()

  const pointsFetcher = useRswellPointsFetcher()
  const statsFetcher = useRswellStatsFetcher()

  const ctx = useYearnVaultImplV2({
    statsFetcher,
    vault,
    pointsFetcher,
  })

  if (!valid) {
    return {
      ...ctx,
      write: INVALID_CHAIN_API,
    }
  }

  return ctx
}
