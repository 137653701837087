import { Badge } from '@/swell-ui/Badge'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Pill } from '@/swell-ui/Pill'
import { ReactNode } from 'react'
import styled from 'styled-components'

export function ActiveFiltersPill({
  numFilters,
  onClearAll,
  compact,
}: {
  numFilters: number
  onClearAll: () => void
  compact?: boolean
}) {
  const badge: ReactNode = (
    <Badge
      variant="info"
      style={{
        visibility: numFilters === 0 ? 'hidden' : 'visible',
      }}
    >
      {numFilters}
    </Badge>
  )

  const disabled = numFilters === 0

  return (
    <SPill variant="button" aria-disabled={disabled}>
      <FlexRow gap="12" align="center">
        <FlexRow gap="6">
          {badge}
          <span onClick={onClearAll} role="button">
            {!compact && 'Clear All'}
            {compact && 'Clear'}
          </span>
        </FlexRow>
        <div role="button" onClick={onClearAll}>
          <X />
        </div>
      </FlexRow>
    </SPill>
  )
}

const SPill = styled(Pill)`
  *[role='button'] {
    cursor: pointer;
    user-select: none;
  }

  &[aria-disabled='true'] {
    opacity: 0.7;
    pointer-events: none;
  }
`

function X() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      style={{ marginTop: '2px' }}
    >
      <g clip-path="url(#clip0_11158_3935)">
        <path
          d="M13 3.5L4 12.5"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13 12.5L4 3.5"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11158_3935">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
