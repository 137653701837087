import styled from 'styled-components'
import { css } from 'styled-components'

export type TypographyProps = {
  nobreak?: boolean
}

export const ColoredHeadingTypography = styled.span<TypographyProps>`
  ${(props) => props.theme.typographyCSS.coloredHeading}
  ${(props) =>
    props.nobreak &&
    css`
      white-space: nowrap;
    `}
`
