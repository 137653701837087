import {
  AccountantWithRateProviders__factory,
  AtomicQueue__factory,
  Multicall3,
  TellerWithMultiAssetSupport__factory,
} from '@/abis/types'
import { BigNumber } from 'ethers'
import {
  NucleusRates,
  NucleusSupportedTokenMap,
  NucleusTokens,
  NucleusVault,
  NucleusWithdrawRequestResultMap,
} from '@/types/nucleus'

export class NucleusVaultService {
  private multicall: Multicall3
  private atomicQueueAddress: string
  private vault: NucleusVault

  constructor({
    atomicQueueAddress,
    multicall,
    vault,
  }: {
    multicall: Multicall3
    atomicQueueAddress: string
    vault: NucleusVault
  }) {
    this.multicall = multicall
    this.atomicQueueAddress = atomicQueueAddress
    this.vault = vault
  }

  supportedTokens = async (): Promise<NucleusTokens> => {
    const indexToTokenAddress = new Map<number, string>()
    const calls: Multicall3.Call3Struct[] = []

    const tellerIface = TellerWithMultiAssetSupport__factory.createInterface()

    this.vault.depositAssets.forEach((token, index) => {
      indexToTokenAddress.set(index, token.address)
      calls.push({
        target: this.vault.tellerAddress,
        callData: tellerIface.encodeFunctionData('isSupported', [
          token.address,
        ]),
        allowFailure: false,
      })
    })

    const results = await this.multicall.callStatic.tryAggregate(true, calls)

    const addressToSupportedDeposit: Record<string, boolean> = {}
    results.forEach((result, index) => {
      const address = indexToTokenAddress.get(index)
      if (!address) throw new Error(`missing address for index ${index}`)
      addressToSupportedDeposit[address] = tellerIface.decodeFunctionResult(
        'isSupported',
        result.returnData
      )[0]
    })

    const deposit: NucleusSupportedTokenMap = {}
    for (const token of this.vault.depositAssets) {
      deposit[token.address] = {
        ...token,
        isSupported: addressToSupportedDeposit[token.address],
      }
    }

    const withdraw: NucleusSupportedTokenMap = {}
    for (const token of this.vault.withdrawAssets) {
      withdraw[token.address] = { ...token, isSupported: true }
    }
    return {
      deposit,
      withdraw,
    }
  }

  withdrawalRequestsByWantToken = async ({
    wantTokens,
    user,
  }: {
    wantTokens: string[]
    user: string
  }): Promise<NucleusWithdrawRequestResultMap> => {
    const resultMap: NucleusWithdrawRequestResultMap = {}
    for (const asset of wantTokens) {
      resultMap[asset] = { exists: false }
    }

    const aqIface = AtomicQueue__factory.createInterface()

    // https://github.com/Ion-Protocol/nucleus-boring-vault/blob/14186bdc7618825308354ad1f94b14d80e8c4453/src/atomic-queue/AtomicQueue.sol#L114
    // function getUserAtomicRequest(address user, ERC20 offer, ERC20 want) external view returns (AtomicRequest memory) {
    const calls: Multicall3.Call3Struct[] = []
    for (const wantToken of wantTokens) {
      const _user = user
      const _offer = this.vault.vaultToken.address
      const _want = wantToken

      const target = this.atomicQueueAddress

      calls.push({
        target,
        callData: aqIface.encodeFunctionData('getUserAtomicRequest', [
          _user,
          _offer,
          _want,
        ]),
        allowFailure: false,
      })
    }

    const results = await this.multicall.callStatic.tryAggregate(true, calls)

    for (let i = 0; i < results.length; i++) {
      const wantToken = wantTokens[i]
      const result = results[i]
      const atomicRequest = aqIface.decodeFunctionResult(
        'getUserAtomicRequest',
        result.returnData
      )[0]

      if (atomicRequest.inSolve) {
        resultMap[wantToken] = { exists: false }
        continue
      }

      resultMap[wantToken] = {
        exists: true,
        request: {
          deadlineUnix: atomicRequest.deadline.toNumber(),
          atomicPrice: atomicRequest.atomicPrice,
          offerAmount: atomicRequest.offerAmount,
        },
      }
    }

    return resultMap
  }

  rates = async ({
    assetAddresses,
  }: {
    assetAddresses: string[]
  }): Promise<NucleusRates> => {
    const indexToTokenAddress = new Map<number, string>()
    const calls: Multicall3.Call3Struct[] = []

    const accountantIface =
      AccountantWithRateProviders__factory.createInterface()

    calls.push({
      target: this.vault.accountantAddress,
      callData: accountantIface.encodeFunctionData('getRate'),
      allowFailure: false,
    })

    assetAddresses.forEach((addr, index) => {
      indexToTokenAddress.set(index, addr)
      calls.push({
        target: this.vault.accountantAddress,
        callData: accountantIface.encodeFunctionData('getRateInQuoteSafe', [
          addr,
        ]),
        allowFailure: true,
      })
    })

    const [primaryResult, ...quoteResults] =
      await this.multicall.callStatic.tryAggregate(false, calls)

    const vaultTokenPrimaryRate = accountantIface.decodeFunctionResult(
      'getRate',
      primaryResult.returnData
    )[0]

    const quotes: Record<string, BigNumber> = {}
    for (let i = 0; i < quoteResults.length; i++) {
      const address = indexToTokenAddress.get(i)
      if (!address) throw new Error(`missing address for index ${i}`)
      const result = quoteResults[i]
      if (!result.success) continue
      quotes[address] = accountantIface.decodeFunctionResult(
        'getRateInQuoteSafe',
        result.returnData
      )[0]
    }

    return {
      vaultTokenPrimaryRate,
      vaultTokenQuoteRates: quotes,
    }
  }
}
