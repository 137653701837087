import React from 'react'
import styled from 'styled-components'
import { Chip } from '@swell-ui/Chip'

const AvailableChipContainer = styled.div<any>`
  height: 32px;
  ${({ disabled }) => disabled && 'opacity: 0.3;'}
`

const StyledChip = styled(Chip)`
  border-color: ${({ theme }) => theme.chip.color};
`

export function AvailableChipV2({ available = '-' }: { available: string }) {
  return (
    <AvailableChipContainer disabled={available === '-'}>
      <StyledChip
        variant="outlined"
        label={<span>{available} Available</span>}
      />
    </AvailableChipContainer>
  )
}
