import axios, { AxiosResponse } from 'axios'
import { IVoyageSignaturesService, VoyageSignatureOption } from './types'
import { getAddress } from 'ethers/lib/utils'

type SelectAirdropOptionRequest = {
  address: string
  signature: string
}

type GetAirdropOptionResponse = {
  success: boolean
  chosen_option: number
}

export class VoyageSignaturesService implements IVoyageSignaturesService {
  private backendURL: string
  constructor({ backendURL }: { backendURL: string }) {
    this.backendURL = backendURL
  }

  public static deadlineUnix = 1730851199 // Tuesday,  5 November 2024 23:59:59 UTC

  private selectAirdropOptionURL = () => {
    return new URL(
      '/api/voyagesignatures/select_airdrop_option',
      this.backendURL
    ).toString()
  }

  private getAirdropOptionURL = ({ user }: { user: string }) => {
    const u = new URL(
      '/api/voyagesignatures/get_airdrop_option',
      this.backendURL
    )

    const params = new URLSearchParams()
    params.append('address', user)
    u.search = params.toString()

    return u.toString()
  }

  private getOptionMessagesURL = () => {
    const u = new URL(
      '/api/voyagesignatures/get_option_messages',
      this.backendURL
    )
    return u.toString()
  }

  getMessage = async (option: VoyageSignatureOption) => {
    // get_option_messages

    const resp = await axios.get<string[]>(this.getOptionMessagesURL())
    if (!resp.data || !Array.isArray(resp.data)) {
      throw new Error('invalid response')
    }
    if (resp.data.length !== 2) {
      throw new Error('invalid response length')
    }

    if (option === VoyageSignatureOption.Option1) {
      return resp.data[0]
    }
    if (option === VoyageSignatureOption.Option2) {
      return resp.data[1]
    }

    throw new Error('invalid option')
  }

  selectAirdropOption: IVoyageSignaturesService['selectAirdropOption'] =
    async ({ user, signature }) => {
      user = getAddress(user)

      const req: SelectAirdropOptionRequest = {
        address: user,
        signature,
      }
      await axios.post(this.selectAirdropOptionURL(), req)
    }

  getSelectedOption = async (user: string): Promise<VoyageSignatureOption> => {
    user = getAddress(user)

    let resp: AxiosResponse<GetAirdropOptionResponse>
    try {
      resp = await axios.get<GetAirdropOptionResponse>(
        this.getAirdropOptionURL({ user })
      )
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.warn(e)
        if (/no airdrop option selected/i.test(e.response?.data)) {
          return VoyageSignatureOption.Unspecified
        }
      }

      console.error('get selected option', e)
      throw e
    }

    if (!resp.data.success) {
      throw new Error('failed to get option')
    }

    const option = resp.data.chosen_option

    switch (option) {
      case VoyageSignatureOption.Option1:
        return VoyageSignatureOption.Option1
      case VoyageSignatureOption.Option2:
        return VoyageSignatureOption.Option2
      default:
        return VoyageSignatureOption.Unspecified
    }
  }
}
