import {
  EarnCampaigns,
  EarnHoldings,
  EarnPortfolioPositionWithoutBalance,
  EarnStats,
  EarnPositionName,
  EarnPositionBalanceType,
} from '@/types/earn'

export const EarnPositionsOrderBy = {
  unspecified: 'unspecified' as const,
  protocol: 'protocol' as const,
  asset: 'asset' as const,
  category: 'category' as const,
  tvl: 'tvl' as const,
  apr: 'apr' as const,
  multiplier: 'multiplier' as const,
  pearls: 'pearls' as const,
  balance: 'balance' as const,
}
export type EarnPositionsOrderBy =
  (typeof EarnPositionsOrderBy)[keyof typeof EarnPositionsOrderBy]

export type EarnFilters = {
  searchTerm?: string
  chainIds?: number[]
  includeTokenSymbols?: string[] // if not included, fetches all tokens
  hideZeroBalances?: boolean
}
export type PositionBalanceMessage = {
  balanceType: EarnPositionBalanceType
  positionName: EarnPositionName
  balance: number
}

export interface IEarnApi {
  campaigns: () => Promise<EarnCampaigns>
  positions: () => Promise<
    Record<EarnPositionName, EarnPortfolioPositionWithoutBalance>
  >
  positionBalances: (
    signal: AbortSignal
  ) => AsyncIterable<PositionBalanceMessage>
}
