import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import noiseUrl from '@/assets/images/noise.png'
import { useMediaQuery } from '@mui/material'
import { StakingBackground } from '../common/StakingBackground'

const BG = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #020202;
`

const HeroBG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
`

// left-most
const EllipseOne = styled.div`
  display: none;
  @media (min-width: 1033px) {
    display: inherit;
  }

  position: absolute;
  width: 1034.887px;
  height: 642.705px;

  top: 9em;
  left: 50%;

  border-radius: 1034.887px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(89, 78, 200, 0.5) 0%,
    rgba(89, 78, 200, 0) 100%
  );
  /* background: cyan; */
  filter: blur(100px);

  transform: translateX(-50%) translateY(-55%) translateX(-45%) translateY(42%)
    rotate(12.535deg) scale(0.9);
`

// right-most
const EllipseTwo = styled.div`
  position: absolute;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(89, 78, 200, 0.5) 0%,
    rgba(89, 78, 200, 0) 100%
  );

  transform: translateX(50%) translateY(-50%) rotate(-17.91deg);
  top: 4em;
  right: 4em;
  width: 512.695px;
  height: 355.042px;

  border-radius: 512.695px;
  filter: blur(55px);

  @media (min-width: 1033px) {
    top: 12em;
    right: 50%;
    transform: translateY(-50%) translateX(80%) translateY(18%)
      rotate(-17.91deg);
    width: 900.037px;
    height: 623.277px;

    border-radius: 900.037px;
    filter: blur(100px);
  }
`

const Noise = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.4;

  background-image: url(${noiseUrl});
  object-fit: cover;
`

const ThirdMiddleEllipse = styled.div`
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  height: 100%;

  overflow: hidden;

  ::after {
    content: '';
    position: absolute;
    top: 553.140625px;
    left: 50%;
    width: 1456.168px;
    height: 304.763px;
    transform: translate(-50%, 66%) rotate(-1.284deg);
    border-radius: 1456.168px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(89, 78, 200, 0.5) 0%,
      rgba(89, 78, 200, 0) 100%
    );
    filter: blur(100px);
  }

  @media (min-width: 1033px) {
    ::after {
      content: '';
      position: absolute;
      top: 1113.140625px;
      left: 50%;
      width: 1456.168px;
      height: 304.763px;
      transform: translate(-50%, 66%) rotate(-1.284deg);
      border-radius: 1456.168px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(89, 78, 200, 0.5) 0%,
        rgba(89, 78, 200, 0) 100%
      );
      filter: blur(100px);
    }
  }
`

const BottomEllipse = styled.div`
  position: absolute;

  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;

  overflow: hidden;

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 1456.168px;
    height: 304.763px;
    transform: translate(-50%, 50%) translateY(0%) rotate(-1.284deg);
    border-radius: 1456.168px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(89, 78, 200, 0.5) 0%,
      rgba(89, 78, 200, 0) 100%
    );
    filter: blur(100px);
  }
`

export function PortfolioBackground() {
  // const isDesktop = useMediaQuery('(min-width: 1033px)')

  // const [pageHeight, setPageHeight] = useState(0)
  // useEffect(() => {
  //   let done = false

  //   const update = () => {
  //     setPageHeight(document.body.scrollHeight)
  //     if (!done) {
  //       return requestAnimationFrame(update)
  //     }
  //   }

  //   requestAnimationFrame(update)
  //   return () => {
  //     done = true
  //   }
  // }, [])

  // const [lockedHeight, setLockedHeight] = useState(0)
  // useEffect(() => {
  //   if (lockedHeight !== 0) {
  //     if (pageHeight < lockedHeight) {
  //       setLockedHeight(pageHeight)
  //     }

  //     return
  //   }

  //   setLockedHeight(pageHeight)
  // }, [lockedHeight, pageHeight])

  // const isMdUpRef = useRef(isDesktop)

  // useEffect(() => {
  //   if (isMdUpRef.current !== isDesktop) {
  //     setLockedHeight(0) // reset height if breakpoint changes majorly
  //   }

  //   isMdUpRef.current = isDesktop
  // }, [isDesktop])

  // return (
  //   <>
  //     <BG />
  //     <HeroBG>
  //       <EllipseOne />
  //       <EllipseTwo />
  //       <ThirdMiddleEllipse />
  //       <BottomEllipse />
  //     </HeroBG>
  //     <Noise />
  //   </>
  // )

  return <StakingBackground />
}
