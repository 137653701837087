import { useSwellWeb3 } from '@swell-web3/core'
import { useAlchemyTokenBalancesQuery } from '@/services/AlchemyService'
import { useCoingeckoTokenListQuery } from '@/services/TokenList'
import { Token } from '@/types/tokens'
import { TOKEN_LIST_SWETH } from '@/constants/tokens'
import cloneDeep from 'lodash/cloneDeep'
import { BigNumber } from 'ethers'
import useSWRImmutable from 'swr/immutable'
import { useSwEthBalance } from '@/state/user/hooks'

type TokenBalancesData = {
  tokenBalances: Token[]
}

// export function useTokenBalances() {
//   const { account } = useSwellWeb3()
//   const coingeckoTokenListQuery = useCoingeckoTokenListQuery()
//   const tokenBalancesQuery = useAlchemyTokenBalancesQuery(
//     account,
//     coingeckoTokenListQuery.data?.tokenMap
//   )

//   return tokenBalancesQuery
// }
// export function useTokenBalances() {
//   const { account } = useSwellWeb3()
//   const coingeckoTokenListQuery = useCoingeckoTokenListQuery()
//   const tokenBalancesQuery = useAlchemyTokenBalancesQuery(
//     account,
//     coingeckoTokenListQuery.data?.tokenMap
//   )

//   return tokenBalancesQuery
// }

function curriedFetchTokenBalances(balance?: BigNumber) {
  return async () => {
    const sweth = cloneDeep(TOKEN_LIST_SWETH)
    return { tokenBalances: [{ ...sweth, balance }] } as TokenBalancesData
  }
}

export function useTokenBalances() {
  const swETHBalance = useSwEthBalance()

  return useSWRImmutable(
    'tokenBalances',
    curriedFetchTokenBalances(swETHBalance.data?.balance)
  )
}

export function useTokenBalancesAlchemy() {
  const { account } = useSwellWeb3()
  const coingeckoTokenListQuery = useCoingeckoTokenListQuery()
  const tokenBalancesQuery = useAlchemyTokenBalancesQuery(
    account,
    coingeckoTokenListQuery.data?.tokenMap
  )

  return tokenBalancesQuery
}
