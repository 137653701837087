import { Dialog } from '@/swell-ui/Dialog'
import { FlexRow } from '@/swell-ui/FlexRow'
import { StyledButtonThin } from '../../AirdropButtons'
import styled from 'styled-components'
import { TokenLogo } from '@/components/TokenLogo'
import { TOKEN_LIST_RSWELL } from '@/constants/tokens'
import { paragraphHintCSS } from '../../styles/common'
import { DecoBox } from '@/components/DecoBox'

export function ConsiderRestakingModal({
  onCancel,
  onConfirm,
}: {
  onConfirm: () => void
  onCancel: () => void
}) {
  return (
    <MuiDialog open onClose={onCancel}>
      <Layout
        direction="column"
        gap="16"
        padding="42px !important"
        align="start"
        opaque
      >
        <h2>Are you sure you do not want to restake SWELL?</h2>
        <Content>
          <FlexRow gap="12" direction="column" align="start">
            <FlexRow gap="6">
              <TokenLogo token={TOKEN_LIST_RSWELL} size={27} />
              <span>rSWELL benefits</span>
            </FlexRow>
            <div className="hint">
              <ul>
                <li>Full 1:1 governance powers</li>
                <li>Restaking yield for securing Swell L2</li>
                <li>Symbiotic points (1x)</li>
                <li>Black pearls (3x)</li>
              </ul>
            </div>
          </FlexRow>
        </Content>
        <Btns gap="16">
          <StyledButtonThin variant="secondary" onClick={onCancel}>
            Cancel
          </StyledButtonThin>
          <StyledButtonThin onClick={onConfirm}>Yes</StyledButtonThin>
        </Btns>
      </Layout>
    </MuiDialog>
  )
}

const MuiDialog = styled(Dialog)`
  .MuiPaper-root {
    /* border-radius: 12px; */
    border: none;
    background: none;
    padding: none;
    overflow: visible;
  }
`

const Content = styled.div`
  height: auto;
  width: 100%;
  padding: 12px 0;
`

const Btns = styled(FlexRow)`
  button {
    flex: 1 1 auto;
  }
`

const Layout = styled(DecoBox)`
  h2 {
    margin: 0 auto;
    text-align: center;
    max-width: 80%;
    color: var(--Swell-White-50, #fff);
    /* Headlines/H7 */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 26px */
    letter-spacing: -0.6px;
  }

  button {
    width: 100%;
  }

  .hint {
    ${paragraphHintCSS}
  }

  ul {
    padding: 0;
    margin: 0;
    margin-left: 29px;
  }
`
