import { IMetricsTransport } from './types'
import { enrichMetricsData } from './util'
import * as Sentry from '@sentry/react'

export class ConsoleMetricsTransport implements IMetricsTransport {
  onEvent: IMetricsTransport['onEvent'] = async (event, data) => {
    let enrichedData: any
    try {
      enrichedData = await enrichMetricsData(data)
    } catch (e) {
      console.error('enrich metrics', { e, data, event })
      return
    }

    console.info('Metrics:', event, enrichedData)
  }
}
export class SilentMetricsTransport implements IMetricsTransport {
  onEvent = async () => {}
}

export class SentryMetricsTransport implements IMetricsTransport {
  onEvent: IMetricsTransport['onEvent'] = async (event, data) => {
    let enrichedData: any
    try {
      enrichedData = await enrichMetricsData(data)
    } catch (e) {
      console.error('enrich metrics', { e, data, event })
      return
    }

    Sentry.captureMessage(event, { extra: enrichedData })
  }
}
