import React, { ReactNode, useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components/macro'
import MuiMenuItem from '@mui/material/MenuItem'
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select'
import { FlexRow } from '@/swell-ui/FlexRow'
import { useNowMsAnimation } from '@/hooks/useTimeCountdown'

export type MultiSelectOption = {
  key: string
  label: string | React.ReactNode
  selected?: boolean
}

const defaultRenderValue = (options: MultiSelectOption[]) => (s: string[]) => {
  const selectedKeys = new Set(s as string[])
  const selectedOptions = options.filter((option) =>
    selectedKeys.has(option.key)
  )
  if (selectedOptions.length === 0) {
    return 'None selected'
  }
  if (selectedOptions.length === options.length) {
    return 'All selected'
  }
  const numSelected = selectedOptions.length
  if (numSelected > 1) {
    return `${numSelected} selected`
  }
  return selectedOptions[0].label
}

export function MultiSelectInput({
  options,
  selected,
  onSelect,
  anchorEl,
  renderLabel = defaultRenderValue(options),
}: {
  options: MultiSelectOption[]
  selected: string[]
  onSelect: (selected: string[]) => void
  anchorEl?: HTMLElement | null
  renderLabel?: (selected: string[]) => ReactNode
}) {
  const selectTheme = useTheme().select
  const nowMs = useNowMsAnimation()
  const [lastScrolledMs, setLastScrolledMs] = useState(0)
  useEffect(() => {
    const handleScroll = () => {
      setLastScrolledMs(Date.now())
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const renderValue = (s: unknown) => {
    if (!Array.isArray(s)) {
      return renderLabel([])
    }
    return renderLabel(s as string[])
  }

  const MenuProps: React.ComponentProps<typeof MuiSelect>['MenuProps'] = {
    disableScrollLock: true,
    PaperProps: {
      style: {
        backgroundColor: selectTheme.menu.bgColor,
        borderRadius: selectTheme.menu.borderRadius,
      },
    },
  }
  if (anchorEl) {
    MenuProps.anchorEl = anchorEl
  }

  const recentlyScrolled = nowMs - lastScrolledMs < 230
  if (recentlyScrolled) {
    MenuProps.open = false // force it to re-position
  }

  return (
    <StyledMuiSelect
      multiple
      value={selected}
      onChange={(e: SelectChangeEvent<unknown>) => {
        onSelect(e.target.value as string[])
      }}
      renderValue={renderValue}
      MenuProps={MenuProps}
    >
      {options.map((option: MultiSelectOption) => {
        return (
          <StyledMuiMenuItem
            key={option.key}
            value={option.key}
            aria-selected={option.selected}
          >
            <FlexRow gap="4">
              <Check selected={option.selected} /> {option.label}
            </FlexRow>
          </StyledMuiMenuItem>
        )
      })}
    </StyledMuiSelect>
  )
}
const StyledMuiSelect = styled(MuiSelect)`
  font-size: 14px;
  width: 100%;
  min-width: 218px;
  height: ${({ theme }) => theme.select.input.height};
  color: ${({ theme }) => theme.colors.white['50']};

  background-color: ${({ theme }) => theme.select.input.bgColor};
  height: ${({ theme }) => theme.select.input.height};
  border: ${({ theme }) => theme.select.input.border};
  border-radius: ${({ theme }) => theme.select.input.borderRadius};
  padding: ${({ theme }) => theme.select.input.padding};

  .MuiOutlinedInput-notchedOutline {
    height: ${({ theme }) => theme.select.input.height};
    border: ${({ theme }) => theme.select.input.border};
    border-radius: ${({ theme }) => theme.select.input.borderRadius};
    padding: ${({ theme }) => theme.select.input.padding};
  }

  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border: ${({ theme }) => theme.select.input.hoverBorder};
    }
  }

  &.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border: ${({ theme }) => theme.select.input.focusedBorder};
    }
  }

  &.Mui-disabled {
    .MuiOutlinedInput-notchedOutline {
      border: ${({ theme }) => theme.select.input.disabledBorder};
      background-color: ${({ theme }) => theme.select.input.disabledBgColor};
    }

    color: ${({ theme }) => theme.select.input.disabledColor};
  }

  .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.select.input.color};
  }
`

const StyledMuiMenuItem = styled(MuiMenuItem)`
  padding: 6px 16px;
  color: ${({ theme }) => theme.colors.white['50']};
  font-size: 14px;

  &:hover {
    /* background: */
  }

  &.Mui-selected,
  &.Mui-selected:hover {
    background: ${({ theme }) => theme.colors.oceanBlue['125']};
  }
`

function Check({ selected }: { selected?: boolean }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect
        x="2.25"
        y="2.25"
        width="11.5"
        height="11.5"
        rx="1.75"
        stroke="white"
        stroke-width="0.5"
      />
      {selected && (
        <path
          d="M5.5 8.8L6.76923 10L11 6"
          stroke="white"
          stroke-width="0.665"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      )}
    </svg>
  )
}
