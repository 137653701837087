import styled, { css } from 'styled-components'
import { PortfolioCampaignsTableBox } from './PortfolioCampaignsTableBox'
import { PortfolioDefiPositions } from './PortfolioDefiPositions'
import {
  usePortfolioPositions,
  usePortfolioCampaigns,
  usePortfolioSettings,
} from '@/state/earn/hooks'
import {
  makePortfolioDefiPosition,
  makeUserCampaignsSummary,
  makeUserPortfolioSummary,
} from './portfolioFormatting'
import { useSwellWeb3 } from '@/swell-web3/core'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { useDefiPositionsFilters, usePortfolioPositionsOrderBy } from './hooks'
import { useMediaQuery } from '@mui/material'
import { PortfolioCampaignsCards } from './PortfolioCampaignsCards'
import { Box } from '@/swell-ui/Box'
import { PortfolioDefiPositionSummary } from './types'
import { makeEarnHoldings } from './util'

// https://www.figma.com/design/1trLPK52KCe6Nko8e2NCbU/Swell-Network-Design?node-id=11139-2289&node-type=frame&m=dev

const headlinesH3 = css`
  color: var(--Swell-White-50, #fff);
  /* Headlines/H3 */
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 57.6px */
  letter-spacing: -1.44px;
`

const headlinesH6 = css`
  color: var(--Swell-White-50, #fff);
  /* Headlines/H6 */
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 33.8px */
  letter-spacing: -0.78px;
`

const bodyUppercaseMedium = css`
  color: var(--Swell-Lightblue-75, #6e79e9);
  /* Body/uppercase medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: 1.05px;
  text-transform: uppercase;
`

const bodySmall = css`
  color: var(--Swell-White-50, #fff);
  /* Body/small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.42px;
`

export function PortfolioV2() {
  const isMobile = useMediaQuery('(max-width: 699px)')
  const { account } = useSwellWeb3()
  const { filterChainOptions, filterTokenOptions } = usePortfolioSettings()
  const {
    clearFilters,
    activeFilters,
    clearSearchTerm,
    setSelectedTokens,
    filters,
    setChainFilter,
    setSearchTermFilter,
    setHideZeroBalances: setZeroBalances,
  } = useDefiPositionsFilters({
    filterTokenOptions,
    filterChainOptions,
  })

  const portfolioOrderBy = usePortfolioPositionsOrderBy()

  const campaignsQuery = usePortfolioCampaigns()
  const positionsQuery = usePortfolioPositions({
    filters: filters,
    orderBy: portfolioOrderBy.orderBy,
    orderDirection: portfolioOrderBy.direction,
  })

  const campaigns = campaignsQuery.data
  const positions = positionsQuery.data

  const holdings = makeEarnHoldings({ campaigns, positions })

  const holdingSummary = makeUserPortfolioSummary({ holdings, account })
  const campaignsSummary = makeUserCampaignsSummary({ account, campaigns })

  const defiPositions: PortfolioDefiPositionSummary[] = []
  for (const position of positions ?? []) {
    defiPositions.push(makePortfolioDefiPosition({ position, account }))
  }

  return (
    <PageStyles>
      <div className="a-title">
        <PageTitle />
      </div>
      <div className="a-description">
        <p>
          Monitor your balances, staking rewards and browse DeFi opportunities.
        </p>
      </div>
      <div className="a-summary">
        <span className="section-heading mob-hidden">Summary</span>
        <Box className="summary-box">
          <div className="summary-stack">
            <span className="body-uppercase-medium">Total asset balance</span>
            <AsyncDiv loading={!holdingSummary.totalAssetBalance}>
              {() => (
                <span className="headlines-h6-summary">
                  {holdingSummary.totalAssetBalance}
                </span>
              )}
            </AsyncDiv>
          </div>
          <div className="summary-stack">
            <span className="body-uppercase-medium">Number of assets held</span>
            <AsyncDiv loading={!holdingSummary.numberOfAssetsHeld}>
              {() => (
                <span className="headlines-h6-summary">
                  {holdingSummary.numberOfAssetsHeld}
                </span>
              )}
            </AsyncDiv>
          </div>
          <div className="summary-stack">
            <span className="body-uppercase-medium">
              Campaign participation
            </span>
            <AsyncDiv loading={!holdingSummary.campaignParticipation}>
              {() => (
                <span className="headlines-h6-summary">
                  {holdingSummary.campaignParticipation}
                </span>
              )}
            </AsyncDiv>
          </div>
        </Box>
      </div>
      <div className="a-campaigns">
        <span className="section-heading">Points campaigns</span>
        {!isMobile && (
          <PortfolioCampaignsTableBox campaignsSummary={campaignsSummary} />
        )}
        {isMobile && (
          <PortfolioCampaignsCards campaignsSummary={campaignsSummary} />
        )}
      </div>
      <div className="a-positions">
        <PortfolioDefiPositions
          positions={defiPositions}
          filters={filters}
          setSearchTermFilter={setSearchTermFilter}
          clearSearchTerm={clearSearchTerm}
          setHideZeroBalances={setZeroBalances}
          orderBy={portfolioOrderBy.orderBy}
          setOrderBy={portfolioOrderBy.setOrderBy}
          orderDirection={portfolioOrderBy.direction}
          setDirection={portfolioOrderBy.setDirection}
          filterTokenOptions={filterTokenOptions}
          setSelectedTokens={setSelectedTokens}
          setChainFilter={setChainFilter}
          activeFilters={activeFilters}
          clearFilters={clearFilters}
          filterChainOptions={filterChainOptions}
          loading={positionsQuery.isLoading}
        />
      </div>
    </PageStyles>
  )
}

function PageTitle() {
  return <span className="title">Portfolio</span>
}

const pageStyles = css`
  .title {
    ${headlinesH3}
  }
  .section-heading {
    ${headlinesH6}
  }
  .body-uppercase-medium {
    ${bodyUppercaseMedium}
  }
  .headlines-h6-summary {
    ${headlinesH6}
    color: var(--Swell-White-75, #F1F1F1);
  }
  .body-small {
    ${bodySmall}
  }
  .nobreak {
    white-space: nowrap;
  }
`

const mobileLayout = css`
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: auto;
  grid-template-areas:
    'title'
    'description'
    'summary'
    'campaigns'
    'positions';

  .a-title {
    margin-bottom: 12px;
    margin-top: 24px;
    span.title {
      margin: 0;
    }
  }

  .a-description {
    p {
      margin: 0;
      margin-bottom: 24px;
    }
  }

  .a-summary {
    align-self: end;
    display: flex;
    flex-flow: column nowrap;
    white-space: nowrap;

    .summary-stack {
      display: flex;
      flex-flow: column nowrap;
      gap: 8px;
      margin-bottom: 12px;

      > [aria-busy='false'] {
        min-width: unset;
      }
      > [aria-busy='true'] {
        height: 33.8px;
        max-width: 80px;
      }
    }

    span.mob-hidden {
      visibility: hidden;
    }
    .summary-box {
      background: none;
    }
  }

  .a-campaigns {
    margin-top: 44px;
    gap: 24px;
    padding-bottom: 60px;

    display: flex;
    flex-flow: column nowrap;
  }

  .a-positions {
    display: flex;
    flex-flow: column nowrap;
    .p-heading {
      white-space: nowrap;
    }
    min-height: calc(100vh - 64px);
  }
`
const tabletLayout = css``
const desktopLayout = css`
  grid-template-columns: 1fr 2fr;
  grid-auto-rows: auto;
  grid-template-areas:
    'title        title'
    'description  description'
    'summary      campaigns'
    'positions    positions';

  .a-description {
    p {
      margin-bottom: 0;
    }
  }
  .a-summary {
    margin-top: 56px;
    align-self: stretch;
    margin-right: 48px;
    padding-bottom: 60px;

    flex-flow: column nowrap;
    gap: 24px;
    .summary-stack {
      align-items: center;
      margin-bottom: unset;
    }
    .summary-box {
      margin: 0 0;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      gap: 24px;
      padding: 24px;
      width: auto;
      flex: 1 0 auto;
      background: ${({ theme }) => theme.box.bgColor};
    }

    span.mob-hidden {
      visibility: unset;
    }
  }
  .a-campaigns {
    margin-top: 56px;
  }
`
const layout = css`
  overflow: visible;
  display: grid;

  .a-title {
    grid-area: title;
  }
  .a-description {
    grid-area: description;
  }
  .a-summary {
    grid-area: summary;
  }
  .a-campaigns {
    grid-area: campaigns;
  }
  .a-positions {
    grid-area: positions;
  }

  ${mobileLayout}
  @media (min-width: 699px) {
    ${tabletLayout}
  }
  @media (min-width: 1033px) {
    ${desktopLayout}
  }
`

const PageStyles = styled.div`
  ${layout};
  ${pageStyles};
`
