import {
  NucleusVault,
  NucleusWithdrawRequest,
  NucleusWithdrawRequestResult,
  NucleusWithdrawRequestResultMap,
} from '@/types/nucleus'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'

// latestNucleusRequest returns the latest withdrawal request from a collection of withdrawal requests, keyed by asset address (wantToken).
// it attaches additional data (wantToken)
export function latestNucleusWithdrawRequest(
  withdrawalRequests: NucleusWithdrawRequestResultMap,
  withdrawAssetAddresses: string[]
): NucleusWithdrawRequestResult {
  let latestRequest: NucleusWithdrawRequest | undefined = undefined
  let wantToken: string | undefined = undefined
  for (const assetAddress of withdrawAssetAddresses) {
    const withdrawalRequest = withdrawalRequests[assetAddress]
    if (!withdrawalRequest?.request) continue
    if (!latestRequest) {
      latestRequest = withdrawalRequest.request
      wantToken = assetAddress
      continue
    }
    const latestDeadline = latestRequest.deadlineUnix
    const deadline = withdrawalRequest.request.deadlineUnix
    if (deadline > latestDeadline) {
      latestRequest = withdrawalRequest.request
      wantToken = assetAddress
    }
  }

  if (!latestRequest || !wantToken) {
    return { exists: false }
  }

  return {
    exists: true,
    request: latestRequest,
    wantToken,
  }
}

export type NucleusVaultTvl = {
  vaultToken: string
  baseTokenUsdCents: string
  totalAssetsWei: string
  baseToken: string
}
export function calculateNucleusGlobalTVL(
  vaults: NucleusVault[],
  data: NucleusVaultTvl[]
) {
  const baseTokenToDecimals: Record<string, number> = {}
  for (const vault of vaults) {
    baseTokenToDecimals[vault.baseAsset.address] = vault.baseAsset.decimals
  }

  let tvlCents = 0
  for (const vault of vaults) {
    const record = data.find((v) => v.vaultToken === vault.vaultToken.address)
    if (record) {
      const { baseTokenUsdCents, totalAssetsWei } = record

      const decimals = baseTokenToDecimals[vault.baseAsset.address]
      if (decimals === undefined) {
        console.error('calculateNucleusGlobalTVL: decimals not found')
        continue
      }

      let baseTokenCentsNum: number
      if (baseTokenUsdCents) {
        baseTokenCentsNum = parseInt(baseTokenUsdCents, 10)
      } else {
        console.error('calculateNucleusGlobalTVL: baseTokenUsdCents not found')
        continue
      }

      let totalAssets: BigNumber
      try {
        totalAssets = BigNumber.from(totalAssetsWei)
      } catch (e) {
        console.error('calculateNucleusGlobalTVL: BigNumber.from failed', e)
        continue
      }

      const totalAssetsNum = parseFloat(formatUnits(totalAssets, decimals))
      if (isNaN(totalAssetsNum)) {
        console.error('calculateNucleusGlobalTVL: totalAssetsNum is NaN')
        continue
      }

      const totalAssetsCents = totalAssetsNum * baseTokenCentsNum

      tvlCents += totalAssetsCents
    }
  }

  return tvlCents
}
