import { useCoingeckoTokenListQuery } from '@/services/TokenList'
import { Token } from '@/types/tokens'

export function useImportableTokens({
  paused,
}: { paused?: boolean } = {}): Token[] {
  const coingeckoListQuery = useCoingeckoTokenListQuery({ paused })

  return coingeckoListQuery?.data?.tokens || []
}
