import { DaoMerkleDrop, DaoMerkleDropKind } from '@/types/airdrop'
import { ethers } from 'ethers'
import { hexZeroPad } from 'ethers/lib/utils'
import { ChainId } from './chains'

export const MERKLE_DROP_SWELL_AIRDROP_MAINNET: DaoMerkleDrop = {
  address: ethers.utils.getAddress(hexZeroPad('0xffff02', 20)), // TODO - not deployed yet
  chainId: ChainId.MAINNET,
  kind: DaoMerkleDropKind.Airdrop,
}

export const MERKLE_DROP_SWELL_AIRDROP_SEPOLIA: DaoMerkleDrop = {
  address: '0xd1e72eB0E533e7a0597bB55B1A508FD6E2f41358',
  chainId: ChainId.SEPOLIA,
  kind: DaoMerkleDropKind.Airdrop,
}
