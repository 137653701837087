import {
  EarnBlackPearlsWavedrop,
  EarnCampaigns,
  EarnPortfolioPosition,
} from '@/types/earn'
import {
  PortfolioDefiPositionSummary,
  UserCampaignsSummary,
  UserPortfolioSummary,
} from './types'
import { displayFiat } from '@/util/displayFiat'
import { formatWithConfig } from '@/util/number'
import {
  abbreviateNumber,
  displayPercentFromPercent,
} from '@/util/displayNumbers'
import { getChainIcon, getChainName } from '@/constants/chainInfo'
import { EarnHoldingsResults } from './util'

export function makeUserPortfolioSummary({
  holdings,
  account,
}: {
  holdings: EarnHoldingsResults
  account?: string
}): UserPortfolioSummary {
  if (!account) {
    return {
      campaignParticipation: '-',
      numberOfAssetsHeld: '-',
      totalAssetBalance: '-',
    }
  }
  const resp: UserPortfolioSummary = {
    campaignParticipation: '',
    numberOfAssetsHeld: '',
    totalAssetBalance: '',
  }

  const { numAssetsHeld, numParticipatingCampaigns, totalAssetBalanceUsd } =
    holdings

  if (numAssetsHeld.loaded) {
    resp.numberOfAssetsHeld = formatWithConfig(numAssetsHeld.result, {
      localize: true,
      precision: 0,
    })
  }
  if (numParticipatingCampaigns.loaded) {
    resp.campaignParticipation = formatWithConfig(
      numParticipatingCampaigns.result,
      {
        localize: true,
        precision: 0,
      }
    )
  }
  if (totalAssetBalanceUsd.loaded) {
    resp.totalAssetBalance = displayFiat(totalAssetBalanceUsd.result)
  }

  return resp
}

export function makeUserCampaignsSummary({
  account,
  campaigns,
}: {
  account: string | undefined
  campaigns: EarnCampaigns | undefined
}): UserCampaignsSummary {
  if (!campaigns) {
    if (!account) {
      return {
        swellL2ClaimableNum: -1,
        symbioticClaimableNum: -1,
        voyageClaimableNum: 0,
        swellL2PointsNum: 0,
        symbioticPointsNum: 0,
        voyagePointsNum: 0,
        swellL2Claimable: 'N/A',
        swellL2Points: '-',
        symbioticClaimable: `N/A`,
        symbioticPoints: '-',
        voyageClaimable: '-',
        voyagePoints: '-',
        wavedropOneWaveNumber: '1',
        wavedropOneClaimableNum: 0,
        wavedropOnePointsNum: 0,
        wavedropOneClaimable: '-',
        wavedropOnePoints: '-',
        wavedropOneEndTime: '',
        wavedropTwoWaveNumber: '2',
        wavedropTwoClaimableNum: 0,
        wavedropTwoPointsNum: 0,
        wavedropTwoClaimable: '-',
        wavedropTwoPoints: '-',
        wavedropTwoEndTime: '',
      }
    }

    return {
      swellL2ClaimableNum: -1,
      symbioticClaimableNum: -1,
      voyageClaimableNum: 0,
      swellL2PointsNum: 0,
      symbioticPointsNum: 0,
      voyagePointsNum: 0,
      swellL2Claimable: 'N/A',
      swellL2Points: '',
      symbioticClaimable: `N/A`,
      symbioticPoints: '',
      voyageClaimable: '',
      voyagePoints: '',
      wavedropOneWaveNumber: '1',
      wavedropOneClaimableNum: 0,
      wavedropOnePointsNum: 0,
      wavedropOneClaimable: '',
      wavedropOnePoints: '',
      wavedropOneEndTime: '',
      wavedropTwoWaveNumber: '2',
      wavedropTwoClaimableNum: 0,
      wavedropTwoPointsNum: 0,
      wavedropTwoClaimable: '',
      wavedropTwoPoints: '',
      wavedropTwoEndTime: '',
    }
  }

  const { blackPearlsWavedrops, swellL2PreLaunch, symbiotic, voyage } =
    campaigns

  const swellL2Points = formatWithConfig(swellL2PreLaunch.points, {
    localize: true,
    precision: 0,
  })

  const symbioticPoints = formatWithConfig(symbiotic.points, {
    localize: true,
    precision: 0,
  })
  const voyageClaimable = `${abbreviateNumber(voyage.claimableAssets)} ${
    voyage.claimableAssetSymbol
  }`
  const voyagePoints = formatWithConfig(voyage.points, {
    localize: true,
    precision: 0,
  })

  let wavedropOne: EarnBlackPearlsWavedrop | undefined
  if (blackPearlsWavedrops) {
    wavedropOne = blackPearlsWavedrops.find((w) => w.waveNumber === 1)
  }

  let wavedropOneClaimable = ''
  let wavedropOnePoints = ''
  let wavedropOneEndTime = ''
  if (wavedropOne) {
    wavedropOneClaimable = `${abbreviateNumber(wavedropOne.claimableAssets)} ${
      wavedropOne.claimableAssetSymbol
    }`
    wavedropOnePoints = formatWithConfig(wavedropOne.points, {
      localize: true,
      precision: 0,
    })

    // (08.10.2024)
    const endDte = new Date(wavedropOne.endTimeMs)
    const endDay = endDte.getDate()
    const endMonth = endDte.getMonth() + 1
    const endYear = endDte.getFullYear()
    wavedropOneEndTime = `${endMonth}.${endDay}.${endYear}`
  }

  let wavedropTwo: EarnBlackPearlsWavedrop | undefined
  if (blackPearlsWavedrops) {
    wavedropTwo = blackPearlsWavedrops.find((w) => w.waveNumber === 2)
  }

  let wavedropTwoClaimable = ''
  let wavedropTwoPoints = ''
  let wavedropTwoEndTime = ''
  if (wavedropTwo) {
    wavedropTwoClaimable = `${abbreviateNumber(wavedropTwo.claimableAssets)} ${
      wavedropTwo.claimableAssetSymbol
    }`
    wavedropTwoPoints = formatWithConfig(wavedropTwo.points, {
      localize: true,
      precision: 0,
    })

    const endDte = new Date(wavedropTwo.endTimeMs)
    const endDay = endDte.getDate()
    const endMonth = endDte.getMonth() + 1
    const endYear = endDte.getFullYear()
    wavedropTwoEndTime = `${endMonth}.${endDay}.${endYear}`
  }

  return {
    swellL2PointsNum: swellL2PreLaunch.points,
    swellL2ClaimableNum: -1,
    swellL2Claimable: 'N/A',
    swellL2Points,
    symbioticPointsNum: symbiotic.points,
    symbioticClaimableNum: -1,
    symbioticClaimable: 'N/A',
    symbioticPoints,
    voyagePointsNum: voyage.points,
    voyageClaimableNum: voyage.claimableAssets,
    voyageClaimable,
    voyagePoints,
    wavedropOnePointsNum: wavedropOne?.points ?? 0,
    wavedropOneClaimableNum: wavedropOne?.claimableAssets ?? 0,
    wavedropOneClaimable,
    wavedropOnePoints,
    wavedropOneEndTime,
    wavedropOneWaveNumber: '1',
    wavedropTwoPointsNum: wavedropTwo?.points ?? 0,
    wavedropTwoClaimableNum: wavedropTwo?.claimableAssets ?? 0,
    wavedropTwoClaimable,
    wavedropTwoPoints,
    wavedropTwoEndTime,
    wavedropTwoWaveNumber: '2',
  }
}

export function makePortfolioDefiPosition({
  position,
  account,
}: {
  position: EarnPortfolioPosition
  account: string | undefined
}): PortfolioDefiPositionSummary {
  type UserComponent = {
    pearls: string
    pearlsNum: number
    balance: string
    balanceNum: number
  }
  type StaticComponent = Omit<PortfolioDefiPositionSummary, keyof UserComponent>

  const chainName = getChainName(position.chainId)
  const chainIcon = getChainIcon(position.chainId)

  let apr: string
  let aprNum: number
  if (position.apr.type === 'scalar') {
    apr = displayPercentFromPercent(position.apr.aprPercent)
    aprNum = position.apr.aprPercent
  } else {
    apr = `${displayPercentFromPercent(
      position.apr.basePercent
    )}-${displayPercentFromPercent(position.apr.maxPercent)}`
    aprNum = position.apr.basePercent
  }

  let assetSymbol = ''
  let assetLogoURI = ''
  if (position.assets.length > 0) {
    assetSymbol = position.assets[0].symbol
    assetLogoURI = position.assets[0].logoURI
  }

  const staticComponent: StaticComponent = {
    protocolName: position.protocol.name,
    protocolLogoURI: position.protocol.logoURI,
    chainId: position.chainId,
    chainName: chainName,
    chainLogoURI: chainIcon.uri,
    positionName: position.positionName,
    assetSymbol: assetSymbol,
    assetLogoURI: assetLogoURI,
    category: position.category,
    apr: apr,
    aprNum: aprNum,
    boost: `${formatWithConfig(position.boostMultiplier, {
      localize: true,
      precision: 2,
    })}x`,
    boostNum: position.boostMultiplier,
    tvl: displayFiat(position.tvlUsd, true),
    tvlNum: position.tvlUsd,
    link: position.link,
  }

  const userComponent: UserComponent = {
    pearls: '-',
    pearlsNum: 0,
    balance: '-',
    balanceNum: 0,
  }
  if (account) {
    userComponent.pearls = ''
    userComponent.balance = ''
  }
  if (account && position.balanceUsd.exists) {
    userComponent.balance = displayFiat(position.balanceUsd.balance)
    userComponent.balanceNum = position.balanceUsd.balance
  }
  if (account && position.pearls.exists) {
    userComponent.pearls = formatWithConfig(position.pearls.balance, {
      localize: true,
      precision: 0,
    })
    userComponent.pearlsNum = position.pearls.balance
  }

  return {
    ...staticComponent,
    ...userComponent,
  }
}
