import { useEthUsdRate } from '@/state/prices/hooks'
import { useGasPrice } from '@/state/gas/hooks'
import { BigNumber } from 'ethers'

export interface IUseTransactionFeeArgs {
  gasAmount: number
}

export type TransactionFeeResult = {
  feeWei: BigNumber
  ethUsdRate: number
}
const useTransactionFee = ({ gasAmount }: IUseTransactionFeeArgs) => {
  const gasPrice = useGasPrice()
  const ethUsd = useEthUsdRate()

  return {
    get data(): TransactionFeeResult | undefined {
      if (!gasPrice.data || !ethUsd.data) {
        return undefined
      }

      const {
        data: { rate },
      } = ethUsd

      return {
        feeWei: gasPrice.data.gasPriceWei.mul(gasAmount),
        ethUsdRate: rate,
      }
    },
    error: ethUsd.error ?? gasPrice.error,
    isLoading: ethUsd.isLoading || gasPrice.isLoading,
    isValidating: ethUsd.isValidating || gasPrice.isValidating,
  }
}

export default useTransactionFee
