import { Paper } from '@mui/material'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import MuiTable from '@mui/material/Table'
import MuiTableBody from '@mui/material/TableBody'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableContainer from '@mui/material/TableContainer'
import MuiTableHead from '@mui/material/TableHead'
import MuiTableRow from '@mui/material/TableRow'
import { AsyncDiv } from '../AsyncDiv'
import { FlexRow } from '../FlexRow'
import { SortingDirection } from '@/types/sorting'

export type TableHeaderAlign = 'left' | 'center' | 'right'
export const TableHeaderAlign = {
  left: 'left' as TableHeaderAlign,
  center: 'center' as TableHeaderAlign,
  right: 'right' as TableHeaderAlign,
}
const DEFAULT_HEADER_ALIGN = TableHeaderAlign.left

type HeaderConfig = {
  label: string
  align?: TableHeaderAlign
  onClickSort?: (direction: SortingDirection) => void
  width?: string
  minWidth?: string
  maxWidth?: string
}

type Resultify<T> = {
  _key: string
} & (T extends Record<infer Keys, any>
  ? {
      [key in Keys]:
        | { loaded: true; result: T[key] }
        | { loaded: false; result?: undefined }
    }
  : never)

export type TableRowProp<Row> = Resultify<Row>
export type TableHeaderConfigProp<Row> = {
  [key in keyof Row]: HeaderConfig
}

export function TableV2<
  Col extends string,
  Row extends {
    [key in Col]?: ReactNode
  }
>({
  header,
  rows,
  onScrollBottom = () => {},
  maxHeight,
  loading,
  sortingBy,
  fetchingMore,
  loadingHeight,
  noHeader,
  onlyHeader,
  gradientRows,
  fixedLayout,
}: {
  header: { [key in Col]: HeaderConfig }
  rows: Resultify<Row>[]
  onScrollBottom?: () => void
  maxHeight?: string
  loading?: boolean
  fetchingMore?: boolean
  sortingBy?: {
    [key in Col]?: 'asc' | 'desc'
  }
  loadingHeight?: string
  noHeader?: boolean
  onlyHeader?: boolean
  gradientRows?: boolean
  fixedLayout?: boolean
}) {
  const containerRef = useRef<HTMLDivElement>(null)

  // on scroll bottom effect for infinite scroll
  const onReachedBottomMs = useRef(0)
  useEffect(() => {
    let done = false

    function animate() {
      if (done) return
      if (!containerRef.current) return requestAnimationFrame(animate)

      const { scrollHeight, scrollTop, clientHeight } = containerRef.current

      if (scrollHeight - scrollTop <= clientHeight) {
        if (Date.now() - onReachedBottomMs.current > 1000) {
          onReachedBottomMs.current = Date.now()

          const alreadyActing = fetchingMore || loading
          if (!alreadyActing) {
            onScrollBottom()
          }
        }
      }
      requestAnimationFrame(animate)
    }

    animate()

    return () => {
      done = true
    }
  }, [fetchingMore, loading, onScrollBottom])

  const [rowWidth, setRowWidth] = useState<number | undefined>(undefined)
  useEffect(() => {
    let done = false

    function animate() {
      if (done) return
      if (!containerRef.current) return requestAnimationFrame(animate)

      const firstTR = containerRef.current.querySelector('tr')
      if (!firstTR) return requestAnimationFrame(animate)
      const { width } = firstTR.getBoundingClientRect()
      setRowWidth(width)
      requestAnimationFrame(animate)
    }

    animate()

    return () => {
      done = true
    }
  }, [])

  function renderHeader() {
    const headerCells: ReactNode[] = []
    for (const key in header) {
      const {
        label,
        align = DEFAULT_HEADER_ALIGN,
        onClickSort,
        width,
        maxWidth,
        minWidth,
      } = header[key]

      const arrowStatus: 'up' | 'down' | 'none' | 'both' = (() => {
        const hSortingBy = sortingBy?.[key]
        if (hSortingBy === 'asc') return 'up'
        if (hSortingBy === 'desc') return 'down'
        return 'none'
      })()

      const onClickLabel = () => {
        if (onClickSort) {
          onClickSort('unspecified')
        }
      }

      headerCells.push(
        <MuiTableCell
          key={key}
          align={align}
          style={{ width, maxWidth, minWidth }}
        >
          <FlexRow gap="4" align="center" justify={align}>
            <span
              onClick={onClickLabel}
              style={{ cursor: onClickSort ? 'pointer' : 'default' }}
              role={onClickSort ? 'button' : undefined}
            >
              {label}
            </span>
            {onClickSort && (
              <ArrowUpDown onClickSort={onClickSort} status={arrowStatus} />
            )}
          </FlexRow>
        </MuiTableCell>
      )
    }

    return (
      <StyledMuiTableHead>
        <MuiTableRow>{headerCells}</MuiTableRow>
      </StyledMuiTableHead>
    )
  }

  function renderBody() {
    const cells: ReactNode[] = []
    for (const row of rows) {
      let i = 0
      const rowCells: ReactNode[] = []
      for (const key in header) {
        const isFirstInRow = i === 0
        i++

        const {
          align = DEFAULT_HEADER_ALIGN,
          maxWidth,
          minWidth,
          width,
        } = header[key]
        const cell = row[key]
        if (cell.loaded) {
          rowCells.push(
            <DataCell
              key={key}
              h={header[key]}
              isFirstInRow={isFirstInRow}
              gradientRows={gradientRows}
              rowWidth={rowWidth}
            >
              {cell.result}
            </DataCell>
          )
        } else {
          rowCells.push(
            <LoadingCell
              key={key}
              align={align}
              style={{ minWidth, width, maxWidth }}
            >
              <AsyncDiv loading={true}>{() => null}</AsyncDiv>
            </LoadingCell>
          )
        }
      }

      const { _key } = row

      cells.push(
        <TableRow key={_key} gradient={gradientRows}>
          {rowCells}
        </TableRow>
      )
    }
    return <MuiTableBody>{cells}</MuiTableBody>
  }

  function renderBodyLoading() {
    const cells: ReactNode[] = []
    for (const key in header) {
      const {
        align = DEFAULT_HEADER_ALIGN,
        width,
        maxWidth,
        minWidth,
      } = header[key]
      cells.push(
        <LoadingCell
          key={key}
          align={align}
          style={{ width, maxWidth, minWidth }}
        >
          <FlexRow height={loadingHeight} align="center" justify={align}>
            <AsyncDiv loading={true}>{() => null}</AsyncDiv>
          </FlexRow>
        </LoadingCell>
      )
    }

    return (
      <MuiTableBody>
        <MuiTableRow>{cells}</MuiTableRow>
        <MuiTableRow>{cells}</MuiTableRow>
        <MuiTableRow>{cells}</MuiTableRow>
        <MuiTableRow>{cells}</MuiTableRow>
      </MuiTableBody>
    )
  }

  function renderBodyNoContent() {
    const cells: ReactNode[] = []
    for (const key in header) {
      const {
        align = DEFAULT_HEADER_ALIGN,
        width,
        maxWidth,
        minWidth,
      } = header[key]
      cells.push(
        <LoadingCell
          key={key}
          align={align}
          style={{ width, maxWidth, minWidth }}
        />
      )
    }

    return (
      <MuiTableBody>
        <MuiTableRow>{cells}</MuiTableRow>
      </MuiTableBody>
    )
  }

  let tHeader: ReactNode = null
  if (!noHeader) {
    tHeader = renderHeader()
  }

  let tBody: ReactNode
  if (loading) {
    tBody = renderBodyLoading()
  } else if (rows.length === 0) {
    tBody = renderBodyNoContent()
  } else {
    tBody = renderBody()
  }

  if (onlyHeader) {
    tBody = null
  }

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}
    >
      <StyledMuiTableContainer
        fixedLayout={fixedLayout}
        sx={{
          maxHeight: maxHeight ?? '100%', // if not defined, tends to cause parent to grow with table contents (breaks scroll)
          overflow: onlyHeader ? 'visible' : 'auto',
        }}
        ref={containerRef}
      >
        <MuiTable stickyHeader={!onlyHeader}>
          {tHeader}
          {tBody}
        </MuiTable>
      </StyledMuiTableContainer>
    </Paper>
  )
}

const StyledMuiTableContainer = styled(MuiTableContainer)<{
  fixedLayout?: boolean
}>`
  width: 100%;
  overscroll-behavior: contain;

  ${({ fixedLayout }) =>
    fixedLayout &&
    css`
      table {
        table-layout: fixed;
      }
    `}

  ${({ theme }) => css`
    .MuiTableCell-root {
      color: ${theme.mainColor};
      background: ${theme.table.cell.bgColor};
      font-family: 'Inter', sans-serif;
      border: none;
      border-bottom: ${theme.table.cell.borderColor
        ? `1px solid ${theme.table.cell.borderColor}`
        : 'none'};
      padding: ${theme.table.cell.padding ?? '12px 16px'};
    }

    .MuiTableCell-head {
      color: ${theme.table.head.color};
      background: ${theme.table.head.bgColor ?? 'transparent'};
      padding: ${theme.table.head.padding ?? '12px 16px'};
      border: none;
      border-bottom: ${theme.table.head.borderColor
        ? `1px solid ${theme.table.head.borderColor}`
        : 'none'};
      font-size: 12px;
      white-space: nowrap;
      ${theme.table.head.typography && theme.table.head.typography};
    }
  `}

  ${({ theme }) =>
    theme.scrollbar &&
    css`
      padding-right: 24px;

      &::-webkit-scrollbar {
        width: ${theme.scrollbar.width};
      }

      &::-webkit-scrollbar-track {
        background: ${theme.scrollbar.trackBg};
        border-radius: ${theme.scrollbar.borderRadius};
      }

      &::-webkit-scrollbar-thumb {
        background: ${theme.scrollbar.thumbBg};
        border-radius: ${theme.scrollbar.borderRadius};
      }
    `}
`

function DataCell({
  h: { align, minWidth, width, maxWidth },
  isFirstInRow,
  gradientRows,
  children,
  rowWidth,
}: {
  h: HeaderConfig
  children?: ReactNode
  isFirstInRow: boolean
  gradientRows?: boolean
  rowWidth: number | undefined
}) {
  return (
    <DataCellLayout
      align={align}
      style={{ minWidth, width, maxWidth }}
      isFirstInRow={isFirstInRow && gradientRows}
      rowWidth={rowWidth}
    >
      {isFirstInRow && gradientRows && <div className="row-decoration" />}
      {children}
    </DataCellLayout>
  )
}

const DataCellLayout = styled(MuiTableCell)<{
  isFirstInRow?: boolean
  rowWidth?: number
}>`
  ${({ isFirstInRow, rowWidth, theme }) => {
    if (!isFirstInRow) return null
    if (!rowWidth) return null
    if (!theme.table.row.rowGradientStops) return null

    return css`
      position: relative;
      .row-decoration {
        pointer-events: none;
        position: absolute;
        inset: 0;
        right: unset;
        width: ${rowWidth}px;

        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;

        ::after {
          content: '';
          width: 100%;
          height: 1px;
          background: linear-gradient(
            to right,
            ${theme.table.row.rowGradientStops
              .map((stop) => `${stop.color} ${stop.offset}`)
              .join(',')}
          );
        }
      }
    `
  }}
`

const StyledMuiTableHead = styled(MuiTableHead)`
  .MuiTableCell-root {
    transform: translateY(-1px); // account for border
  }
`

const LoadingCell = styled(MuiTableCell)`
  [aria-busy='true'] {
    max-width: 64px;
    align-self: unset;
    display: inline-block;
  }
`

const TableRow = styled(MuiTableRow)<{ gradient?: boolean }>`
  position: relative;

  ${({
    gradient,
    theme: {
      table: {
        row: { rowGradientStops },
      },
    },
  }) =>
    gradient &&
    rowGradientStops &&
    css`
      border-image: linear-gradient(
        to right,
        ${rowGradientStops
          .map((stop) => `${stop.color} ${stop.offset}`)
          .join(',')}
      );
    `}
`

function ArrowUpDown({
  status,
  onClickSort,
}: {
  status: 'up' | 'down' | 'none' | 'both'
  onClickSort: (direction: SortingDirection) => void
}) {
  let strokeOpacityUp = 0
  if (status === 'up' || status === 'both') {
    strokeOpacityUp = 1
  }

  let strokeOpacityDown = 0
  if (status === 'down' || status === 'both') {
    strokeOpacityDown = 1
  }

  // strokeOpacityUp = 1
  // strokeOpacityDown = 1

  const up = (visible: boolean) => {
    return (
      <>
        <path
          d="M2.1665 5.33333L4.83317 2.66667L7.49984 5.33333"
          stroke={'currentColor'}
          stroke-opacity={visible ? strokeOpacityUp : 0}
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.83301 2.66667V13.3333"
          stroke={'currentColor'}
          stroke-opacity={visible ? strokeOpacityUp : 0}
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </>
    )
  }

  const down = (visible: boolean) => {
    return (
      <>
        <path
          d="M14.1663 10.6667L11.4997 13.3333L8.83301 10.6667"
          stroke={'currentColor'}
          stroke-opacity={visible ? strokeOpacityDown : 0}
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.5 13.3333V2.66667"
          stroke={'currentColor'}
          stroke-opacity={visible ? strokeOpacityDown : 0}
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </>
    )
  }

  return (
    <ArrowUDLayout>
      <button onClick={() => onClickSort('asc')}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          overflow="visible"
        >
          {up(true)}
          {down(false)}
        </svg>
      </button>
      <button onClick={() => onClickSort('desc')}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          overflow="visible"
        >
          {up(false)}
          {down(true)}
        </svg>
      </button>
    </ArrowUDLayout>
  )
}
const ArrowUDLayout = styled.div`
  margin-bottom: 1px;

  width: 16px;
  height: 16px;

  svg {
    path {
      transition: all 0.2s;
    }
  }

  cursor: pointer;

  overflow: visible;
  position: relative;
  button {
    min-width: unset;
    position: absolute;
    padding: 0;
    margin: 0;
    height: unset;

    ${(props) => props.theme.table.head.typography};

    width: 8px;

    &:first-child {
      top: 0;
      left: 0;
      bottom: 0;
    }
    &:last-child {
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      flex-flow: row-reverse;
      transform: translate(2px);
    }

    background: transparent;
    border: none;
    cursor: pointer;
  }
`
