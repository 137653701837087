import { useChainInfo } from '@/state/deployments/hooks'
import { NucleusVaultState } from '@/state/nucleusVault/types'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { ExternalLinkIcon } from '@/swell-ui/icons/ExternalLinkIcon'
import { ColoredHeadingTypography } from '@/swell-ui/Typography/typographyPresets'
import { Token } from '@/types/tokens'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { makeNucleusVaultDetailsSummary } from '../nucleusFormatting'
import { ScrollableYArea } from '@/swell-ui/ScrollableArea'
import { NUCLEUS_STRATEGY_PROVIDER_NUCLEUS_EARN } from '@/constants/nucleus'
import { FlexRow } from '@/swell-ui/FlexRow'

export function NucleusDetailsView({
  description,
  strategyProvider,
  vaultState,
  vaultToken,
}: {
  description: ReactNode
  strategyProvider: string
  vaultState: NucleusVaultState | undefined
  vaultToken: Token
}) {
  return (
    <Layout>
      <ScrollableYArea containerType="flex">
        <div className="custom">{description}</div>
      </ScrollableYArea>
      <Details
        strategyProvider={strategyProvider}
        vaultState={vaultState}
        vaultToken={vaultToken}
      />
    </Layout>
  )
}

const Layout = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;
  justify-content: stretch;
  align-items: stretch;
  gap: 24px;
  font-size: 14px;

  @media (min-width: 900px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: unset;
    column-gap: 62px;
    justify-content: unset;
    align-items: unset;
  }

  height: 100%;

  > div {
    flex: 1;
  }

  .scroll-area-y {
    background-color: rgba(0, 34, 37, 0.5);
    border-radius: 8px;

    > div {
      right: 2px;
    }
  }

  .custom {
    padding: 0 12px 12px 12px;
    h3 {
      margin-top: 0;
      padding-top: 12px;

      color: var(--Swell-White-50, #fff);
      /* Body/medium bold */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%; /* 25.6px */
    }
  }
`

function Details({
  strategyProvider,
  vaultState,
  vaultToken,
}: {
  strategyProvider: string
  vaultState: NucleusVaultState | undefined
  vaultToken: Token
}) {
  const { explorer } = useChainInfo()

  const summary = makeNucleusVaultDetailsSummary({
    explorer,
    vaultState,
    vaultToken,
  })

  return (
    <DetailsGrid>
      <ColoredHeadingTypography>Strategy provider</ColoredHeadingTypography>
      <div>
        <FlexRow gap={'6'} align="center">
          <img
            src={NUCLEUS_STRATEGY_PROVIDER_NUCLEUS_EARN.logoURI}
            width="24"
            height="24"
          />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={NUCLEUS_STRATEGY_PROVIDER_NUCLEUS_EARN.link}
          >
            {NUCLEUS_STRATEGY_PROVIDER_NUCLEUS_EARN.name}
            <ExternalLinkIcon className="ext" />
          </a>
        </FlexRow>
      </div>
      <ColoredHeadingTypography>Solver fee</ColoredHeadingTypography>
      <AsyncDiv loading={!summary.solverFee}>
        {() => <span>{summary.solverFee}</span>}
      </AsyncDiv>
      <ColoredHeadingTypography>Performance fee</ColoredHeadingTypography>
      <AsyncDiv loading={!summary.performanceFee}>
        {() => <span>{summary.performanceFee}</span>}
      </AsyncDiv>
      <ColoredHeadingTypography>Platform fee</ColoredHeadingTypography>
      <AsyncDiv loading={!summary.platformFee}>
        {() => <span>{summary.platformFee}</span>}
      </AsyncDiv>
      <ColoredHeadingTypography>Contract address</ColoredHeadingTypography>
      <div>
        <span>
          <a
            href={summary.contractAddressLink}
            target="_blank"
            rel="noreferrer noopener"
          >
            {summary.contractAddressShort} <ExternalLinkIcon />
          </a>
        </span>
      </div>
    </DetailsGrid>
  )
}

const DetailsGrid = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-rows: 24px;
  row-gap: 16px;
  column-gap: 32px;
  color: #fff;
  align-items: center;

  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }

  ${ColoredHeadingTypography} {
    font-size: 12px;
    text-align: right;
  }

  svg.ext {
    margin-left: 8px;
    stroke: currentColor;
    &.ext {
      transform: translateY(2px);
    }
  }
`
