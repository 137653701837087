import MarkedSlider from '@/components/MarkedSlider'
import { niceRange } from '@/util/charts'
import { formatAbbreviatedNumber } from '@/util/number'
import { useMemo } from 'react'

const CLOSENESS_THRESHOLD = 0.86 // hide the last tick if it's too close to the edge

export function AirdropSlider({
  maxAmount,
  disabled,
  lockRatio,
  setLockRatio,
  stakedStr,
}: {
  maxAmount: number
  disabled: boolean
  lockRatio: number
  setLockRatio: (value: number) => void
  stakedStr: string
}) {
  const sliderMarks = useMemo<Record<number, string>>(() => {
    const sliderTickRange = niceRange([0, maxAmount], 5)
    sliderTickRange.pop() // we have hardcoded labels for start/end
    sliderTickRange.shift()

    const sliderMarks: Record<number, string> = {
      0: '0',
      1: formatAbbreviatedNumber(maxAmount, {
        precision: 1,
        localize: true,
      }),
    }
    const ratio = (tick: number) => tick / maxAmount

    for (const tick of sliderTickRange) {
      sliderMarks[ratio(tick)] = formatAbbreviatedNumber(tick, {
        precision: 1,
        localize: true,
        unscaled: {
          precision: 0,
          localize: true,
        },
      })
    }

    if (!sliderTickRange.length) return sliderMarks

    let shouldHideLast = false
    const last = sliderTickRange[sliderTickRange.length - 1]
    const closeness = 1 - (maxAmount - last) / maxAmount
    if (closeness > CLOSENESS_THRESHOLD) {
      shouldHideLast = true
    }
    if (shouldHideLast) {
      // invisible space char U+3164, so the label still receives pointer events
      sliderMarks[ratio(last)] = 'ㅤㅤㅤ'
    }

    return sliderMarks
  }, [maxAmount])

  return (
    <MarkedSlider
      min={0}
      max={1}
      step={0.01}
      disabled={disabled}
      value={lockRatio}
      onChange={(value) => {
        setLockRatio(value)
      }}
      marks={sliderMarks}
      valueLabel={stakedStr}
    />
  )
}
