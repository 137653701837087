import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import MuiMenu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Button } from '../Button'
import { Link } from 'react-router-dom'
import { Divider } from '../Divider'
import { FlexRow } from '../FlexRow'
import { PopoverOrigin } from '@mui/material'

export type MenuItemType = 'outsideLink' | 'insideLink' | 'heading'
export type MenuAlign = 'middle' | 'right'

const middleOrigin: PopoverOrigin = {
  horizontal: 0,
  vertical: -11,
}
const rightOrigin: PopoverOrigin = {
  horizontal: 'right',
  vertical: -11,
}
const middleAnchor: PopoverOrigin | undefined = {
  horizontal: 'center',
  vertical: 'bottom',
}
const rightAnchor: PopoverOrigin = {
  horizontal: 'right',
  vertical: 'bottom',
}

export interface MenuConfigItem {
  label: string | React.ReactNode
  type: string | MenuItemType
  href?: string
  to?: string
  onClick?: () => void
  noIndent?: boolean
}

export interface MenuConfig {
  label?: string | React.ReactNode
  items: MenuConfigItem[]
  notificationDot?: boolean
}

const StyledMenu = styled(MuiMenu)`
  .MuiMenu-paper {
    background-color: ${({ theme }) => theme.menu.bgColor};
    color: ${({ theme }) => theme.menu.color};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: ${({ theme }) => theme.menu.borderRadius};
    padding: 6px 16px;
    border: 0.8px solid ${({ theme }) => theme.menu.borderColor};
  }

  .MuiButtonBase-root {
    &:hover {
      background: transparent;
    }
  }
`

const MenuButton = styled(Button)<{ notificationDot: boolean }>`
  padding-left: 0;
  padding-right: 0;

  position: relative;

  ::after {
    content: '';
    display: ${(props) => (props.notificationDot ? 'block' : 'none')};
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: red; /* TODO: theme */

    position: absolute;
    top: 0.85em;
    right: 0.5em;
  }
`

const MenuLink = styled.a<{ noIndent?: boolean }>`
  color: inherit;
  display: block;
  text-decoration: none;
  padding: ${({ noIndent }) => (noIndent ? '6px 0px' : '6px 16px')};

  &:hover {
    color: ${({ theme }) => theme.menu.color};
    opacity: 0.7;
  }
`

const MenuLinkInternal = styled(Link)<{ noIndent?: boolean }>`
  color: inherit;
  display: block;
  text-decoration: none;
  padding: ${({ noIndent }) => (noIndent ? '6px 0px' : '6px 16px')};

  &:hover {
    color: ${({ theme }) => theme.menu.color};
    opacity: 0.7;
  }
`

const MenuAction = styled.div<{ noIndent?: boolean }>`
  padding: ${({ noIndent }) => (noIndent ? '6px 0px' : '6px 16px')};

  &:hover {
    opacity: 0.7;
  }
`

const StyledMenuItem = styled(MenuItem)`
  padding: 0 0;
`

const HeaderMenuItem = styled(StyledMenuItem)`
  color: ${({ theme }) => theme.menu.color} !important;
  opacity: 1 !important;
`

export function Menu({
  config,
  disableScrollLock,
  align,
  minWidth,
  caret,
}: {
  config?: MenuConfig
  disableScrollLock?: boolean
  align?: MenuAlign
  minWidth?: string
  caret?: boolean
}) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  if (!config) {
    console.error('Menu requires config prop')
    return null
  }

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const label = config.label || 'Menu'
  const items = config.items

  const [pageScrollY, setPageScrollY] = useState(0)
  useEffect(() => {
    let done = false
    function animate() {
      if (done) return
      setPageScrollY(window.scrollY)
      requestAnimationFrame(animate)
    }
    animate()
    return () => {
      done = true
    }
  }, [])

  // hide menu on scroll
  useEffect(() => {
    setAnchorEl(null)
  }, [pageScrollY])

  let transformOrigin: PopoverOrigin
  let anchorOrigin: PopoverOrigin | undefined
  if (align === 'middle') {
    transformOrigin = middleOrigin
    anchorOrigin = middleAnchor
  } else if (align === 'right') {
    transformOrigin = rightOrigin
    anchorOrigin = rightAnchor
  } else {
    transformOrigin = middleOrigin
    anchorOrigin = middleAnchor
  }

  return (
    <div>
      <MenuButton
        notificationDot={!!config.notificationDot}
        variant="tertiary"
        onClick={handleClick}
      >
        {label} {caret && <Caret />}
      </MenuButton>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
        disableScrollLock={disableScrollLock}
        MenuListProps={{ style: { minWidth } }}
      >
        {items.map((item: MenuConfigItem, index: number) => {
          if (item.type === 'heading') {
            return (
              <HeaderMenuItem key={index} disableGutters={true} disabled>
                <FlexRow direction="column" align="start">
                  {item.label}
                  <Divider margin="12" />
                </FlexRow>
              </HeaderMenuItem>
            )
          }

          if (item.type === 'outsideLink') {
            return (
              <StyledMenuItem key={index} disableGutters={true}>
                <MenuLink
                  key={index}
                  target="_blank"
                  href={item.href}
                  noIndent={item.noIndent}
                >
                  {item.label}
                </MenuLink>
              </StyledMenuItem>
            )
          }

          if (item.type === 'insideLink') {
            return (
              <StyledMenuItem key={index} disableGutters={true}>
                <MenuLinkInternal to={item.to!} noIndent={item.noIndent}>
                  {item.label}
                </MenuLinkInternal>
              </StyledMenuItem>
            )
          }

          return (
            <StyledMenuItem
              key={index}
              disableGutters={true}
              onClick={() => {
                item.onClick?.()
                handleClose()
              }}
            >
              <MenuAction noIndent={item.noIndent}>{item.label}</MenuAction>
            </StyledMenuItem>
          )
        })}
      </StyledMenu>
    </div>
  )
}

function Caret() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      style={{ marginLeft: '-6px' }}
    >
      <path d="M0 0.5L5 5.5L10 0.5H0Z" fill="white" />
    </svg>
  )
}
