import React, { ReactNode, useState } from 'react'
import { Box } from '@/swell-ui/Box'
import styled from 'styled-components'
import { UserCampaignsSummary } from './types'
import { TableV2 } from '@/swell-ui/TableV2/TableV2'
import { ROUTES } from '@/constants/routes'
import { ClaimLinkButton } from './PortfolioButtons'
import { FlexRow } from '@/swell-ui/FlexRow'
import blackPearlLogo from '@/assets/images/black-pearl-80x80.png'
import whitePearlLogo from '@/assets/svg/voyage/pearl.svg'
import swellL2PointsLogo from '@/assets/images/swell-l2-logo-56x56.png'

const symbioticLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66693e5d00dc4d1921a9395e_Symbiotic.svg'

type Col = 'campaignName' | 'points' | 'claimable' | 'link'
type Row = { [key in Col]: ReactNode }
type TableProps = React.ComponentProps<typeof TableV2<Col, Row>>

export function PortfolioCampaignsTableBox({
  campaignsSummary,
}: {
  campaignsSummary: UserCampaignsSummary
}) {
  const [sortBy, setSortBy] = useState<keyof Row | undefined>()
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')

  type Intermediate = {
    campaignName: string
    logoURI: string
    squareLogo?: boolean
    points: string | null
    pointsNum: number | null
    pointsName: string
    claimable: string | null
    claimableNum: number | null
    link: string
  }
  let partialRows: Intermediate[] = []
  partialRows.push({
    campaignName: 'Voyage',
    logoURI: whitePearlLogo,
    points: campaignsSummary.voyagePoints,
    claimable: campaignsSummary.voyageClaimable,
    link: ROUTES.DaoVoyage,
    pointsNum: campaignsSummary.voyagePointsNum,
    claimableNum: campaignsSummary.voyageClaimableNum,
    pointsName: 'White Pearls',
  })
  partialRows.push({
    campaignName: 'Wavedrop 1',
    logoURI: blackPearlLogo,
    points: campaignsSummary.wavedropOnePoints,
    claimable: campaignsSummary.wavedropOneClaimable,
    link: ROUTES.DaoSwellCity,
    pointsNum: campaignsSummary.wavedropOnePointsNum,
    claimableNum: campaignsSummary.wavedropOneClaimableNum,
    pointsName: 'Black Pearls',
  })
  partialRows.push({
    campaignName: 'Wavedrop 2',
    logoURI: blackPearlLogo,
    points: campaignsSummary.wavedropTwoPoints,
    claimable: campaignsSummary.wavedropTwoClaimable,
    link: ROUTES.DaoSwellCity,
    pointsNum: campaignsSummary.wavedropTwoPointsNum,
    claimableNum: campaignsSummary.wavedropTwoClaimableNum,
    pointsName: 'Black Pearls',
  })
  partialRows.push({
    campaignName: 'Swell L2',
    squareLogo: true,
    logoURI: swellL2PointsLogo,
    points: campaignsSummary.swellL2Points,
    claimable: campaignsSummary.swellL2Claimable,
    link: '',
    pointsNum: campaignsSummary.swellL2PointsNum,
    claimableNum: campaignsSummary.swellL2ClaimableNum,
    pointsName: 'Ecosystem Points',
  })
  partialRows.push({
    campaignName: 'Symbiotic',
    logoURI: symbioticLogo,
    points: campaignsSummary.symbioticPoints,
    claimable: campaignsSummary.symbioticClaimable,
    link: '',
    pointsNum: campaignsSummary.symbioticPointsNum,
    claimableNum: campaignsSummary.symbioticClaimableNum,
    pointsName: 'Points',
  })

  if (sortBy) {
    if (sortBy === 'campaignName') {
      partialRows = partialRows.sort((a, b) => {
        const v = a.campaignName.localeCompare(b.campaignName)
        return sortDirection === 'asc' ? v : -v
      })
    } else if (sortBy === 'points') {
      partialRows = partialRows.sort((a, b) => {
        let v = 0
        if (a.pointsNum === null) v = -1
        else if (b.pointsNum === null) v = 1
        else v = a.pointsNum - b.pointsNum

        return sortDirection === 'asc' ? v : -v
      })
    } else if (sortBy === 'claimable') {
      partialRows = partialRows.sort((a, b) => {
        let v = 0
        if (a.claimableNum === null) v = -1
        else if (b.claimableNum === null) v = 1
        else v = a.claimableNum - b.claimableNum

        return sortDirection === 'asc' ? v : -v
      })
    }
  }

  const rows: TableProps['rows'] = []
  for (const row of partialRows) {
    const link = row.link ? (
      <ClaimLinkButton internal to={row.link} />
    ) : (
      <ClaimLinkButton internal to={'#'} disabled label="N/A" />
    )

    let pointsStr = row.points
    if (pointsStr && pointsStr !== '-') {
      pointsStr = `${pointsStr} ${row.pointsName}`
    }

    rows.push({
      _key: row.campaignName,
      campaignName: {
        loaded: true,
        result: (
          <CampaignTitle
            title={row.campaignName}
            logoURI={row.logoURI}
            sq={row.squareLogo}
          />
        ),
      },
      points: pointsStr
        ? {
            loaded: true,
            result: pointsStr,
          }
        : {
            loaded: false,
          },
      claimable: row.claimable
        ? {
            loaded: true,
            result: row.claimable,
          }
        : {
            loaded: false,
          },
      link: {
        loaded: true,
        result: link,
      },
    })
  }

  const tableSortingBy: TableProps['sortingBy'] = sortBy
    ? {
        [sortBy]: sortDirection,
      }
    : undefined

  const onClickSortCampaignName = () => {
    const wasSortingByCampaignName = sortBy === 'campaignName'

    setSortBy('campaignName')

    let nextSortDirection: typeof sortDirection =
      sortDirection === 'asc' ? 'desc' : 'asc'
    if (!wasSortingByCampaignName) nextSortDirection = 'asc'

    setSortDirection(nextSortDirection)
  }
  const onClickSortPoints = () => {
    const wasSortingByPoints = sortBy === 'points'

    setSortBy('points')

    let nextSortDirection: typeof sortDirection =
      sortDirection === 'asc' ? 'desc' : 'asc'
    if (!wasSortingByPoints) nextSortDirection = 'desc'

    setSortDirection(nextSortDirection)
  }
  const onClickSortClaimable = () => {
    const wasSortingByClaimable = sortBy === 'claimable'

    setSortBy('claimable')

    let nextSortDirection: typeof sortDirection =
      sortDirection === 'asc' ? 'desc' : 'asc'
    if (!wasSortingByClaimable) nextSortDirection = 'desc'

    setSortDirection(nextSortDirection)
  }

  return (
    <SBox padding="24px">
      <TableV2
        rows={rows}
        loading={false}
        header={{
          campaignName: {
            label: 'Campaign',
            onClickSort: onClickSortCampaignName,
          },
          points: { label: 'Points', onClickSort: onClickSortPoints },
          claimable: { label: 'Claimable', onClickSort: onClickSortClaimable },
          link: { label: 'Claim Link', width: '100px', align: 'center' },
        }}
        sortingBy={tableSortingBy}
      />
    </SBox>
  )
}

const SBox = styled(Box)``

function CampaignTitle({
  title,
  logoURI,
  sq,
}: {
  title: string
  logoURI: string
  sq?: boolean
}) {
  return (
    <FlexRow gap="12">
      <img
        src={logoURI}
        alt={title}
        width="32"
        height="32"
        style={{ borderRadius: sq ? '0' : '50%' }}
      />
      <span>{title}</span>
    </FlexRow>
  )
}
