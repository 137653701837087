import { SortingDirection } from '@/types/sorting'

export function nextSortingDirection<Key extends string>({
  action,
  defaultSortingDirectionForThis,
  currentDirection,
  currentlyOrderingBy,
  thisKey,
}: {
  action: SortingDirection // user clicked
  currentDirection: SortingDirection
  currentlyOrderingBy: Key
  thisKey: Key
  defaultSortingDirectionForThis: Exclude<SortingDirection, 'unspecified'> // how is this column sorted by default?
}): Exclude<SortingDirection, 'unspecified'> {
  const currentlyOrderingByThis = currentlyOrderingBy === thisKey
  const commandForSameDirection = action === currentDirection

  if (action === 'unspecified') {
    if (currentlyOrderingByThis) {
      const oppositeDirection =
        currentDirection === SortingDirection.asc
          ? SortingDirection.desc
          : SortingDirection.asc

      return oppositeDirection
    }

    return defaultSortingDirectionForThis
  }

  if (currentlyOrderingByThis && commandForSameDirection) {
    const oppositeDirection =
      action === SortingDirection.asc
        ? SortingDirection.desc
        : SortingDirection.asc
    return oppositeDirection
  }

  return action
}
