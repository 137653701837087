import coinbaseWalletModule from '@web3-onboard/coinbase'
import injectedModule from '@web3-onboard/injected-wallets'
import walletConnect from '@web3-onboard/walletconnect'
import ledgerModule from '@web3-onboard/ledger'
import bitgetWalletModule from '@web3-onboard/bitget'
import binanceModule from '@binance/w3w-blocknative-connector'
import trustModule from '@web3-onboard/trust'
import safeModule from '@web3-onboard/gnosis'

interface BuildWalletOptions {
  walletConnectV2ProjectId: string
}

function buildWallets({ walletConnectV2ProjectId }: BuildWalletOptions) {
  // https://onboard.blocknative.com/docs/wallets/injected
  const injected = injectedModule()

  // https://onboard.blocknative.com/docs/wallets/coinbase
  const coinbase = coinbaseWalletModule({ darkMode: true })

  const binance = binanceModule({ options: { lng: 'en' } })

  const bitget = bitgetWalletModule()

  const trust = trustModule()

  const safe = safeModule()

  const walletConnectV2 = walletConnect({
    // should be based on deployment set
    version: 2,
    projectId: walletConnectV2ProjectId,
    requiredChains: [1],
    optionalChains: [5],
    // dappUrl equired by some wallets (i.e. MetaMask)
    dappUrl: 'https://app.swellnetwork.io',
  })

  const ledger = ledgerModule({
    walletConnectVersion: 2,
    projectId: walletConnectV2ProjectId,
    requiredChains: [1],
  })

  return [
    injected,
    safe,
    binance,
    bitget,
    walletConnectV2,
    coinbase,
    ledger,
    trust,
  ]
}

export { buildWallets }
