import useSWRImmutable from 'swr/immutable'
import useSWRSubscription from 'swr/subscription'
import { useEarnApi } from './context'
import { EarnFilters, EarnPositionsOrderBy } from './types'
import { useSwellWeb3 } from '@/swell-web3/core'
import { SortingDirection } from '@/types/sorting'
import { EarnPortfolioPosition } from '@/types/earn'
import { applyPortfolioSortingAndFilters } from '../../util/earn'
import { useMemo } from 'react'
import { stableHash } from 'swr/_internal'
import { cloneDeep } from 'lodash'

export function usePortfolioSettings() {
  const api = useEarnApi()
  const { filterChainOptions, filterTokenOptions } = api

  return {
    filterChainOptions,
    filterTokenOptions,
  }
}

// export function usePortfolioStats() {
//   const api = useEarnApi()

//   return useSWRImmutable(['portfoliov2', 'stats'], () => {
//     return api.stats()
//   })
// }
// export function usePortfolioHoldings() {
//   const { account } = useSwellWeb3()
//   const api = useEarnApi()
//   return useSWRImmutable(
//     account ? ['portfoliov2', 'holdings', account] : null,
//     () => {
//       return api.holdings()
//     }
//   )
// }
export function usePortfolioCampaigns() {
  const { account } = useSwellWeb3()
  const api = useEarnApi()
  return useSWRImmutable(
    account ? ['portfoliov2', 'campaigns', account] : null,
    () => {
      return api.campaigns()
    }
  )
}

export function usePortfolioPositions({
  filters,
  orderBy,
  orderDirection,
}: {
  filters: EarnFilters
  orderBy: EarnPositionsOrderBy
  orderDirection: SortingDirection
}) {
  const { account } = useSwellWeb3()
  const api = useEarnApi()

  const globalPositionsQuery = useSWRImmutable(
    ['portfoliov2', 'positions'],
    api.positions
  )

  const balanceSubscription = useSWRSubscription(
    account ? ['portfoliov2', 'positionBalances', account] : null,
    (__, { next }) => {
      const ac = new AbortController()
      const stream = api.positionBalances(ac.signal)
      ;(async () => {
        for await (const message of stream) {
          next(null, (prev: any) => {
            if (!prev) {
              return [message]
            }
            return [...prev, message]
          })
        }
      })()
      return () => ac.abort()
    }
  )

  const globalPositionMap = globalPositionsQuery.data
  const balanceMessages = cloneDeep(balanceSubscription.data) ?? []

  const hash = stableHash([
    filters,
    orderBy,
    orderDirection,
    globalPositionMap,
    balanceMessages,
  ])

  const data = useMemo<EarnPortfolioPosition[] | undefined>(() => {
    if (!globalPositionMap) {
      return undefined
    }

    return applyPortfolioSortingAndFilters({
      balanceMessages,
      filters,
      globalPositionMap,
      orderBy,
      orderDirection,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- manually computed
  }, [hash])

  return {
    error: globalPositionsQuery.error || balanceSubscription.error,
    isLoading: globalPositionsQuery.isLoading,
    data,
    isValidating: globalPositionsQuery.isValidating,
  }
}
