import { NucleusVault } from '@/types/nucleus'
import { NucleusGlobalContext } from './context'
import { INucleusGlobalApiRead, NucleusGlobalStats } from './types'
import { NucleusClient } from '@/services/V3BackendService/types'
import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import { NUCLEUS_ATOMIC_QUEUE_ADDRESS } from '@/constants/nucleus'
import { useNucleusVaultBackendSettings } from '../deployments/hooks/nucleusSettings'
import { BigNumber } from 'ethers'

export function useNucleusApiGlobalImpl({
  vaults,
}: {
  vaults: NucleusVault[]
}): NucleusGlobalContext {
  const { nucleusBackendURL } = useNucleusVaultBackendSettings()
  return {
    atomicQueueAddress: NUCLEUS_ATOMIC_QUEUE_ADDRESS,
    vaults,
    read: useNucleusApiGlobalImplRead({ backendURL: nucleusBackendURL }),
    write: {},
  }
}

async function fetchStats({
  n,
}: {
  n: NucleusClient
}): Promise<NucleusGlobalStats> {
  const { totalTvlCents } = await n.globalStats({})
  return {
    tvlCents: BigNumber.from(totalTvlCents).toNumber(),
  }
}

function useNucleusApiGlobalImplRead({
  backendURL,
}: {
  backendURL: string
}): INucleusGlobalApiRead {
  const n = useV3BackendClient(backendURL).v3BackendClient.nucleus

  return {
    stats: async () => {
      return fetchStats({ n })
    },
  }
}
