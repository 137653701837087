import { AirdropCarousel } from '@/components/AirdropCarousel'
import {
  useRecentDaoTokenClaim,
  useRestakedSwellDaoToken,
  useSwellDaoAirdrop,
  useSwellDaoAirdropContractsState,
  useSwellDaoAirdropOffchainState,
  useSwellDaoAirdropUserVesting,
  useSwellDaoClaimAirdrop,
  useSwellDaoSelectOption1,
  useSwellDaoSelectOption2,
  useSwellDaoToken,
} from '@/state/dao/hooks'
import { useEveryNthBlock } from '@/hooks/useBlockNumber'
import { useWeb3CallErrorNotify } from '@/hooks/useWeb3CallErrorNotify'
import { useNavigate } from 'react-router'
import { useEffect, useRef } from 'react'
import { ROUTES } from '@/constants/routes'

const MIN_PEARLS_THRESHOLD = -1 // view disabled
const PLANKTON_THRESHOLD = 500
const CHECKER_MODE = true

export function AirdropPage() {
  const navigate = useNavigate()
  const { amountRestakedDaoToken, amountWithdrawnDaoToken, onClaimDaoToken } =
    useRecentDaoTokenClaim()

  useEffect(() => {
    if (!amountWithdrawnDaoToken || !amountRestakedDaoToken) {
      return
    }
    if (amountRestakedDaoToken.gt(0) || amountWithdrawnDaoToken.eq(0)) {
      return
    }
    navigate(ROUTES.StakeSwell)
  }, [amountRestakedDaoToken, amountWithdrawnDaoToken, navigate])

  const daoToken = useSwellDaoToken()
  const restakedDaoToken = useRestakedSwellDaoToken()
  const airdropResultQuery = useSwellDaoAirdrop()
  const airdropOffchainStateQuery = useSwellDaoAirdropOffchainState()
  const airdropContractsStateQuery = useSwellDaoAirdropContractsState()
  const airdropUserVestingQuery = useSwellDaoAirdropUserVesting()

  const airdropResult = airdropResultQuery.data
  const airdropContractsState = airdropContractsStateQuery.data
  const airdropOffchainState = airdropOffchainStateQuery.data
  const airdropUserVesting = airdropUserVestingQuery.data

  // calls
  const claimAirdrop = useSwellDaoClaimAirdrop()
  const selectOption1 = useSwellDaoSelectOption1()
  const selectOption2 = useSwellDaoSelectOption2()
  useWeb3CallErrorNotify(claimAirdrop)
  useWeb3CallErrorNotify(selectOption1)
  useWeb3CallErrorNotify(selectOption2)

  useEffect(() => {
    if (claimAirdrop.status !== claimAirdrop.STATUS.FULFILLED) {
      return
    }
    if (!claimAirdrop.args) {
      return
    }
    const { amountToLock, cumulativeAmount } = claimAirdrop.args[0]
    const withdrawn = cumulativeAmount.sub(amountToLock)
    onClaimDaoToken(withdrawn, amountToLock)
  }, [claimAirdrop, onClaimDaoToken])

  const lastPostedSignature = useRef<number>(0)
  useEffect(() => {
    if (selectOption1.status !== selectOption1.STATUS.FULFILLED) {
      return
    }
    const now = Date.now()
    if (now - lastPostedSignature.current < 5000) {
      return
    }
    lastPostedSignature.current = now
    airdropUserVestingQuery.mutate()

    setTimeout(() => {
      selectOption1.clear()
    }, 5000)
  }, [airdropUserVestingQuery, selectOption1])

  useEffect(() => {
    if (selectOption2.status !== selectOption2.STATUS.FULFILLED) {
      return
    }
    const now = Date.now()
    if (now - lastPostedSignature.current < 5000) {
      return
    }
    lastPostedSignature.current = now
    airdropUserVestingQuery.mutate()

    setTimeout(() => {
      selectOption2.clear()
    }, 5000)
  }, [airdropUserVestingQuery, selectOption2])

  return (
    <AirdropCarousel
      airdropResult={airdropResult}
      minimumPearls={MIN_PEARLS_THRESHOLD}
      claimAirdrop={claimAirdrop}
      daoToken={daoToken}
      airdropContractsState={airdropContractsState}
      airdropOffchainState={airdropOffchainState}
      restakedDaoToken={restakedDaoToken}
      planktonPearlsThreshold={PLANKTON_THRESHOLD}
      checkerMode={CHECKER_MODE}
      selectOption1={selectOption1}
      selectOption2={selectOption2}
      airdropUserVesting={airdropUserVesting}
    />
  )
}
