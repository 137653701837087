import React from 'react'
import { StakingBackground } from '../common/StakingBackground'

export function BtcLrtBackground() {
  return (
    <>
      <StakingBackground />
    </>
  )
}
