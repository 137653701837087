import { tryGetAccount } from '@/util/wallet'
import { IErrorTransport } from './types'

export async function enrichError(
  err: Parameters<IErrorTransport['onError']>[0]
) {
  const { name, code, message, stack, type, data = {} } = err

  const { account, wallet } = await tryGetAccount()
  const enriched = {
    type,
    name,
    message,
    stack,
    code,
    account,
    wallet,
  } as any
  for (const key in data) {
    const nsKey = `data.${key}`
    enriched[nsKey] = data[key]
  }
  return enriched
}
