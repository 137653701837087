import { SwellDaoSelectOption1, SwellDaoSelectOption2 } from '@/state/dao/hooks'
import styled from 'styled-components'
import React from 'react'
import {
  AirdropErrors,
  prepareSelectOption1,
  prepareSelectOption2,
} from './airdropCalls'
import {
  AirdropOffchainState,
  AirdropResult,
  AirdropUserVesting,
} from '@/state/dao/types'
import { SelectOptionOneButton, SelectOptionTwoButton } from './AirdropButtons'
import { useNowMs } from '@/hooks/useTimeCountdown'

export function WhaleOptionSelect({
  selectOption2,
  airdropResult,
  airdropUserVesting,
  selectOption1,
  airdropOffchainState,
}: {
  selectOption2: SwellDaoSelectOption2
  selectOption1: SwellDaoSelectOption1
  airdropResult: AirdropResult
  airdropUserVesting: AirdropUserVesting
  airdropOffchainState: AirdropOffchainState
}) {
  const nowMs = useNowMs()
  const preparedSelectOption2 = prepareSelectOption2({
    airdropResult,
    airdropUserVesting,
    airdropOffchainState,
    nowMs,
  })
  const preparedSelectOption1 = prepareSelectOption1({
    airdropResult,
    airdropUserVesting,
  })

  const alreadySelectedOption2 =
    preparedSelectOption2.error === AirdropErrors.AlreadySelectedOption2

  let btn = (
    <SelectOptionTwoButton
      preparedSelectOption2={preparedSelectOption2}
      selectOption2={selectOption2}
    />
  )
  if (alreadySelectedOption2) {
    btn = (
      <SelectOptionTwoButton
        preparedSelectOption2={preparedSelectOption2}
        selectOption2={selectOption2}
        selected
      />
    )
  }

  if (!airdropResult.exists) {
    return null
  }
  if (airdropResult.vestingTier <= 0) {
    return null
  }

  return <Layout>{btn}</Layout>
}

const Layout = styled.div`
  display: flex;
  width: 230px;
  button {
    width: 100%;
  }
`
