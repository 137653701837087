import { createContext, useContext } from 'react'
import { IMetricsTransport } from '@/services/MetricsService/types'

const MISSING_PROVIDER = Symbol()

export type MetricsContext = IMetricsTransport

export const MetricsContext = createContext<
  MetricsContext | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

export function useMetrics() {
  const ctx = useContext(MetricsContext)
  if (ctx === MISSING_PROVIDER) {
    throw new Error(
      'MetricsContext hooks must be wrapped in a <MetricsContext.Provider>'
    )
  }
  return ctx
}
