import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { KeyValueList } from '@/swell-ui/KeyValueList'
import { YearnAeraTransactionFees, YearnWithdrawRequestSummary } from '../types'

export function YearnRequestWithdrawExchangeInfo({
  transactionFees,
  withdrawRequestSummary,
}: {
  transactionFees: Partial<YearnAeraTransactionFees>
  withdrawRequestSummary: YearnWithdrawRequestSummary
}) {
  const withdrawFeeConfig = {
    label: 'Withdrawal fee',
    value: (
      <AsyncDiv loading={!withdrawRequestSummary.withdrawFee}>
        {() => withdrawRequestSummary.withdrawFee}
      </AsyncDiv>
    ),
  }

  const processingTimeConfig = {
    label: 'Processing time',
    value: (
      <AsyncDiv loading={!withdrawRequestSummary.processingTime}>
        {() => withdrawRequestSummary.processingTime}
      </AsyncDiv>
    ),
  }

  const gasFeeConfig = {
    label: 'Transaction fee',
    value: (
      <AsyncDiv loading={!transactionFees.requestWithdraw}>
        {() => transactionFees.requestWithdraw}
      </AsyncDiv>
    ),
  }

  const exchangeInfoListConfig = {
    list: [withdrawFeeConfig, processingTimeConfig, gasFeeConfig],
  }

  return <KeyValueList config={exchangeInfoListConfig} />
}
