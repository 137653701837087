import { ROUTES } from '@/constants/routes'
import { TOKEN_LIST_EARN_ETH } from '@/constants/tokens'
import { ThemeData } from '@/swell-ui/theme/branding'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { css } from 'styled-components'
import earn_eth_icons_banner from '@/assets/images/earn_eth_icons_banner.png'
import { CloseIcon } from '@/swell-ui/icons/CloseIcon'

const typographyTitle = css`
  color: var(--Swell-Vault-Dark-Turquoise, #01c6c6);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
  letter-spacing: -0.342px;
`

const typographyBody = css`
  color: var(--Swell-Vault-Dark-Turquoise, #01c6c6);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.342px;
`

const typographyLink = css`
  color: var(--Swell-Vault-Dark-Turquoise, #01c6c6);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.342px;
  text-decoration-line: underline !important;
  :hover {
    text-decoration-line: underline;
    color: var(--Swell-Vault-Dark-Turquoise, #01c6c6);
  }
`

export function BannerEarnETH() {
  const [open, setOpen] = useState<boolean>(true)

  const handleClose = () => {
    setOpen(false)
  }

  if (!open) {
    return null
  }

  return (
    <Layout>
      <StyledCloseIcon onClick={handleClose} />

      <img
        className="tokenlogo"
        src={TOKEN_LIST_EARN_ETH.logoURI}
        width="96"
        height="96"
      />
      <div className="b-content">
        <p className="b">earnETH vault is here!</p>
        <p>
          Deposit your ETH denominated asset in Swell earnETH vault to get the
          best automated, risk adjusted returns from DeFi.{' '}
          <Link to={ROUTES.EarnVaults}>Deposit here</Link>.
        </p>
      </div>
      <img
        className="multilogo"
        src={earn_eth_icons_banner}
        width="115"
        height="80"
      />
    </Layout>
  )
}

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 8px;
  right: 10px;

  path {
    stroke: ${ThemeData.Swell.SwellVaultDarkTurqoise};
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const Layout = styled.div`
  margin: 0 auto;
  max-width: 897px;
  display: flex;
  flex-flow: row nowrap;
  padding: 24px;
  border-radius: 24px;
  align-items: center;
  gap: 24px;

  background: ${ThemeData.Swell.SwellVaultGreenCharcoal};
  border: 1px solid ${ThemeData.Swell.SwellVaultDarkTurqoise};

  position: relative;

  ${typographyBody};

  .b {
    ${typographyTitle};
  }

  .b-content {
    display: flex;
    flex-flow: column nowrap;
  }

  p {
    padding: 0;
    margin: 0;
  }

  a {
    ${typographyLink};
  }

  .multilogo {
    display: none;

    ${({ theme }) => css`
      ${theme.breakpoints.up('md')} {
        display: block;
      }
    `}
  }
`
