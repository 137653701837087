import { createContext, useContext } from 'react'
import { SwellDaoApi } from './types'
import { Token } from '@/types/tokens'
import { DaoMerkleDrop } from '@/types/airdrop'
import { BigNumber } from 'ethers'

const MISSING_PROVIDER = Symbol()

export type SwellDaoContext = SwellDaoApi & {
  daoToken: Token
  restakedDaoToken: Token
  merkleDropAirdrop: DaoMerkleDrop
  amountWithdrawnDaoToken?: BigNumber
  amountRestakedDaoToken?: BigNumber
  onClaimDaoToken: (
    amountWithdrawn: BigNumber,
    amountRestaked: BigNumber
  ) => void
  clearRecentDaoTokenClaim: () => void
}

export const SwellDaoContext = createContext<
  SwellDaoContext | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

export function useSwellDaoApi() {
  const ctx = useContext(SwellDaoContext)
  if (ctx === MISSING_PROVIDER) {
    throw new Error(
      'SwellDaoContext hooks must be wrapped in a <SwellDaoContext.Provider>'
    )
  }
  return ctx
}
