import {
  YearnApproveAssetForDeposit,
  YearnApproveVaultTokenForWithdraw,
  YearnCancelWithdraw,
  YearnCompleteWithdraw,
  YearnDeposit,
  YearnRequestWithdraw,
} from '@/state/yearnVault/hooks'
import { YearnAuthResult, YearnPausedResult } from '@/state/yearnVault/types'
import { BigNumber } from 'ethers'
import {
  YearnActiveWithdrawalResult,
  YearnWithdrawalStatus,
} from './yearnWithdraw'
import { YearnWithdrawAsset } from '@/types/yearnAera'

export const YearnErrors = {
  AmountMustBeGreaterThanZero: 'Amount must be greater than 0',
  InsufficientBalance: 'Insufficient balance',
  InsufficientAllowance: 'Insufficient allowance',
  UnstakeAmountTooLow: 'Unstake amount too low',
  UnstakeAmountTooHigh: 'Unstake amount too high',
  ActiveWithdrawRequest: 'Another withdraw request is active',
  NoActiveWithdrawRequest: 'No active withdraw request',
  DepositIsPaused: 'Deposits are paused',
  WithdrawIsPaused: 'Withdrawals are paused',
  NotAuthorized: 'Not authorized',
  WithdrawRequestNotClaimable: 'Withdraw request is not claimable',
  UnsupportedWithdrawVersion: 'Unsupported withdraw version',
  InvalidWithdrawalIdx: 'Invalid withdrawal index',
}

type ValidatedArgs<T> =
  | {
      args?: undefined
      error: string | null
    }
  | {
      args: T
      error?: undefined
    }

export function prepareYearnDeposit({
  assetAmount,
  assetBalance,
  auth,
  assetAllowance,
  paused,
}: {
  assetAmount: BigNumber | undefined
  assetBalance: BigNumber | undefined
  assetAllowance: BigNumber | undefined
  auth: YearnAuthResult | undefined
  paused: YearnPausedResult | undefined
}): ValidatedArgs<Parameters<YearnDeposit['call']>> {
  if (!assetAmount || !assetBalance || !assetAllowance || !auth || !paused) {
    return {
      error: null,
    }
  }

  if (paused.depositPaused) {
    return {
      error: YearnErrors.DepositIsPaused,
    }
  }

  if (!auth.canDeposit) {
    return {
      error: YearnErrors.NotAuthorized,
    }
  }

  if (assetAmount.lte(0)) {
    return {
      error: YearnErrors.AmountMustBeGreaterThanZero,
    }
  }

  if (assetAmount.gt(assetBalance)) {
    return {
      error: YearnErrors.InsufficientBalance,
    }
  }

  if (assetAmount.gt(assetAllowance)) {
    return {
      error: YearnErrors.InsufficientAllowance,
    }
  }

  const amount = assetAmount

  return {
    args: [{ amount }],
  }
}
export type PreparedYearnDeposit = ReturnType<typeof prepareYearnDeposit>

export function prepareYearnApproveAssetForDeposit({
  assetAmount,
  assetBalance,
}: {
  assetAmount: BigNumber | undefined
  assetBalance: BigNumber | undefined
}): ValidatedArgs<Parameters<YearnApproveAssetForDeposit['call']>> {
  if (!assetAmount || !assetBalance) {
    return {
      error: null,
    }
  }

  if (assetAmount.lt(0)) {
    return {
      error: YearnErrors.AmountMustBeGreaterThanZero,
    }
  }

  if (assetAmount.gt(assetBalance)) {
    return {
      error: YearnErrors.InsufficientBalance,
    }
  }

  const amount = assetAmount

  return {
    args: [{ amount }],
  }
}
export type PreparedYearnApproveAssetForDeposit = ReturnType<
  typeof prepareYearnApproveAssetForDeposit
>

export function prepareYearnRequestWithdraw({
  overrideMaxLoss,
  activeWithdrawal,
  maxLossBasisPoints: maxLossBasisPointsOverride,
  shares,
  vaultTokenAllowanceForWithdraw,
  vaultTokenBalance,
  paused,
  withdrawAsset,
  auth,
}: {
  overrideMaxLoss: boolean
  maxLossBasisPoints: number
  shares: BigNumber | undefined
  activeWithdrawal: YearnActiveWithdrawalResult | undefined
  vaultTokenAllowanceForWithdraw: BigNumber | undefined
  vaultTokenBalance: BigNumber | undefined
  paused: YearnPausedResult | undefined
  auth: YearnAuthResult | undefined
  withdrawAsset: YearnWithdrawAsset | undefined
}): ValidatedArgs<Parameters<YearnRequestWithdraw['call']>> {
  if (
    !shares ||
    !activeWithdrawal ||
    !vaultTokenAllowanceForWithdraw ||
    !vaultTokenBalance ||
    !paused ||
    !auth ||
    !withdrawAsset
  ) {
    return {
      error: null,
    }
  }

  let maxLossBasisPoints: number
  if (overrideMaxLoss) {
    maxLossBasisPoints = maxLossBasisPointsOverride
  } else {
    maxLossBasisPoints = withdrawAsset.maxLossBasisPoints
  }

  if (maxLossBasisPoints < 0 || maxLossBasisPoints > 1e4) {
    return {
      error: 'Invalid max loss basis points', // internal
    }
  }

  if (paused.withdrawPaused) {
    return {
      error: YearnErrors.WithdrawIsPaused,
    }
  }

  if (!auth.canRequestWithdraw) {
    return {
      error: YearnErrors.NotAuthorized,
    }
  }

  if (!withdrawAsset.allowWithdraws) {
    return {
      error: 'Withdrawals are not allowed for this asset', // internal
    }
  }

  if (activeWithdrawal.status !== YearnWithdrawalStatus.NoActiveWithdrawal) {
    return {
      error: YearnErrors.ActiveWithdrawRequest,
    }
  }

  if (shares.lte(0)) {
    return {
      error: YearnErrors.AmountMustBeGreaterThanZero,
    }
  }

  if (shares.gt(vaultTokenBalance)) {
    return {
      error: YearnErrors.InsufficientBalance,
    }
  }

  if (shares.gt(vaultTokenAllowanceForWithdraw)) {
    return {
      error: YearnErrors.InsufficientAllowance,
    }
  }

  return {
    args: [{ shares, maxLossBasisPoints }],
  }
}
export type PreparedYearnRequestWithdraw = ReturnType<
  typeof prepareYearnRequestWithdraw
>

export function prepareYearnApproveVaultTokenForWithdraw({
  vaultTokenAmount,
  vaultTokenBalance,
}: {
  vaultTokenAmount: BigNumber | undefined
  vaultTokenBalance: BigNumber | undefined
}): ValidatedArgs<Parameters<YearnApproveVaultTokenForWithdraw['call']>> {
  if (!vaultTokenAmount || !vaultTokenBalance) {
    return {
      error: null,
    }
  }

  if (vaultTokenAmount.lte(0)) {
    return {
      error: YearnErrors.AmountMustBeGreaterThanZero,
    }
  }

  if (vaultTokenAmount.gt(vaultTokenBalance)) {
    return {
      error: YearnErrors.InsufficientBalance,
    }
  }

  return {
    args: [{ amount: vaultTokenAmount }],
  }
}
export type PreparedYearnApproveVaultTokenForWithdraw = ReturnType<
  typeof prepareYearnApproveVaultTokenForWithdraw
>

export function prepareYearnCancelWithdraw({
  activeWithdrawal,
  paused,
  auth,
  withdrawAsset,
}: {
  activeWithdrawal: YearnActiveWithdrawalResult | undefined
  withdrawAsset: YearnWithdrawAsset | undefined
  paused: YearnPausedResult | undefined
  auth: YearnAuthResult | undefined
}): ValidatedArgs<Parameters<YearnCancelWithdraw['call']>> {
  if (!activeWithdrawal || !paused || !auth || !withdrawAsset) {
    return {
      error: null,
    }
  }

  if (withdrawAsset.version !== 'v1') {
    return {
      error: YearnErrors.UnsupportedWithdrawVersion,
    }
  }

  if (paused.withdrawPaused) {
    return {
      error: YearnErrors.WithdrawIsPaused,
    }
  }

  if (!auth.canCancelWithdraw) {
    return {
      error: YearnErrors.NotAuthorized,
    }
  }

  if (activeWithdrawal.status === YearnWithdrawalStatus.NoActiveWithdrawal) {
    return {
      error: YearnErrors.NoActiveWithdrawRequest,
    }
  }

  return {
    args: [{ version: 'v1' }],
  }
}
export type PreparedYearnCancelWithdraw = ReturnType<
  typeof prepareYearnCancelWithdraw
>

export function prepareYearnCompleteWithdraw({
  activeWithdrawal,
  paused,
  auth,
  withdrawAsset,
}: {
  activeWithdrawal: YearnActiveWithdrawalResult | undefined
  paused: YearnPausedResult | undefined
  auth: YearnAuthResult | undefined
  withdrawAsset: YearnWithdrawAsset | undefined
}): ValidatedArgs<Parameters<YearnCompleteWithdraw['call']>> {
  if (!activeWithdrawal || !paused || !auth || !withdrawAsset) {
    return {
      error: null,
    }
  }

  if (paused.withdrawPaused) {
    return {
      error: YearnErrors.WithdrawIsPaused,
    }
  }

  if (!auth.canCompleteWithdraw) {
    return {
      error: YearnErrors.NotAuthorized,
    }
  }

  if (activeWithdrawal.status !== YearnWithdrawalStatus.Claimable) {
    return {
      error: YearnErrors.WithdrawRequestNotClaimable,
    }
  }

  if (withdrawAsset.version === 'v1') {
    if (activeWithdrawal.request.version !== 'v1') {
      return {
        error: YearnErrors.UnsupportedWithdrawVersion,
      }
    }

    return {
      args: [{ version: 'v1' }],
    }
  }

  if (activeWithdrawal.request.version !== 'v2') {
    return {
      error: YearnErrors.UnsupportedWithdrawVersion,
    }
  }

  const { withdrawalIdx } = activeWithdrawal.request
  if (!withdrawalIdx) {
    return {
      error: YearnErrors.InvalidWithdrawalIdx,
    }
  }

  return {
    args: [{ version: 'v2', withdrawalIdx }],
  }
}
export type PreparedYearnCompleteWithdraw = ReturnType<
  typeof prepareYearnCompleteWithdraw
>
