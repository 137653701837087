import { tryGetAccount } from '@/util/wallet'
import { IMetricsTransport } from './types'

export async function enrichMetricsData(
  data: Parameters<IMetricsTransport['onEvent']>[1]
) {
  const { account, wallet } = await tryGetAccount()
  const enriched = {
    account,
    wallet,
    ...data,
  }
  return enriched
}
