import React, { useEffect, useRef } from 'react'
import { v4 as uuid } from 'uuid'

const STEP_WIDTH = 32
const STEP_HEIGHT = 6
const GAP = 6
const COLOR_SELECTED = '#2F43EC'
const COLOR_UNSELECTED = '#FFF'
const OPACITY_UNSELECTED = 0.2

export function StepperProgress({
  currentStep,
  numSteps,
  animationSpeed = 0.2,
  disabled,
}: {
  numSteps: number
  currentStep: number
  animationSpeed?: number
  disabled?: boolean
}) {
  const numGaps = numSteps - 1
  const totalWidth = numSteps * STEP_WIDTH + numGaps * GAP
  const ID = useRef({
    shape: uuid(),
    mask: uuid(),
  })

  const [stepContinuous, setStepContinuous] = React.useState(currentStep)

  useEffect(() => {
    let done = false
    const animate = () => {
      if (done) return
      setStepContinuous((prev) => {
        if (prev === currentStep) return prev
        const diff = currentStep - prev
        const next = prev + diff * animationSpeed
        // clamp
        return Math.min(Math.max(next, 0), numSteps - 1)
      })
      requestAnimationFrame(animate)
    }
    requestAnimationFrame(animate)
    return () => {
      done = true
    }
  }, [animationSpeed, currentStep, numSteps])

  return (
    <svg
      width={totalWidth}
      height={STEP_HEIGHT}
      opacity={disabled ? OPACITY_UNSELECTED : 1}
    >
      <defs>
        <rect
          id={ID.current.shape}
          width={STEP_WIDTH}
          height={STEP_HEIGHT}
          rx={2}
          ry={2}
        />
        <mask id={ID.current.mask}>
          {Array.from({ length: numSteps }).map((_, i) => {
            const x = i * (STEP_WIDTH + GAP)
            return (
              <use key={i} href={`#${ID.current.shape}`} x={x} fill={'white'} />
            )
          })}
        </mask>
      </defs>
      <rect
        width={totalWidth}
        height={STEP_HEIGHT}
        fill={COLOR_UNSELECTED}
        fillOpacity={OPACITY_UNSELECTED}
        mask={`url(#${ID.current.mask})`}
      />
      <rect
        width={STEP_WIDTH}
        height={STEP_HEIGHT}
        mask={`url(#${ID.current.mask})`}
        x={stepContinuous * (STEP_WIDTH + GAP)}
        fill={COLOR_SELECTED}
      />
    </svg>
  )
}
