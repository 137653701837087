import {
  YearnWithdrawRequest,
  YearnWithdrawRequestResult,
} from '@/state/yearnVault/types'

export const YearnWithdrawalStatus = {
  Requesting: 'Requesting' as const,
  Claimable: 'Claimable' as const,
  Expired: 'Expired' as const, // v1 only
  NoActiveWithdrawal: 'NoActiveWithdrawal' as const,
}
export type YearnWithdrawalStatus =
  (typeof YearnWithdrawalStatus)[keyof typeof YearnWithdrawalStatus]

export type YearnActiveWithdrawalResult =
  | {
      status:
        | (typeof YearnWithdrawalStatus)['Requesting']
        | (typeof YearnWithdrawalStatus)['Claimable']
        | (typeof YearnWithdrawalStatus)['Expired']
      request: YearnWithdrawRequest
    }
  | {
      status: (typeof YearnWithdrawalStatus)['NoActiveWithdrawal']
      request?: undefined
    }

// exported for hooks, not intended to be consumed directly in components
// calculates UI state given info about user withdrawals
// applicable for v1 withdrawals
export function yearnActiveWithdrawalMemoV1({
  withdrawRequest,
  nowUnix,
  completionWindowSeconds,
}: {
  withdrawRequest: YearnWithdrawRequestResult
  nowUnix: number
  completionWindowSeconds: number
}): YearnActiveWithdrawalResult {
  if (!withdrawRequest.exists) {
    return {
      status: YearnWithdrawalStatus.NoActiveWithdrawal,
    }
  }

  const { request } = withdrawRequest

  if (request.shares.eq(0)) {
    return {
      status: YearnWithdrawalStatus.NoActiveWithdrawal,
    }
  }

  const requestMaturityInFuture = nowUnix < request.maturityUnix
  const requestExpiryInFuture =
    nowUnix <= request.maturityUnix + completionWindowSeconds

  const isRequesting = requestMaturityInFuture && requestExpiryInFuture
  const isClaimable = !requestMaturityInFuture && requestExpiryInFuture
  const isExpired = !requestMaturityInFuture && !requestExpiryInFuture

  if (isRequesting) {
    return {
      status: YearnWithdrawalStatus.Requesting,
      request: request,
    }
  }

  if (isClaimable) {
    return {
      status: YearnWithdrawalStatus.Claimable,
      request: request,
    }
  }

  if (isExpired) {
    return {
      status: YearnWithdrawalStatus.Expired,
      request: request,
    }
  }

  return {
    status: YearnWithdrawalStatus.NoActiveWithdrawal,
  }
}

// exported for hooks, not intended to be consumed directly in components
// calculates UI state given info about user withdrawals
// applicable for v2 withdrawals
export function yearnActiveWithdrawalMemoV2({
  withdrawRequest,
  nowUnix,
}: {
  withdrawRequest: YearnWithdrawRequestResult
  nowUnix: number
}): YearnActiveWithdrawalResult {
  if (!withdrawRequest.exists) {
    return {
      status: YearnWithdrawalStatus.NoActiveWithdrawal,
    }
  }

  const { request } = withdrawRequest

  if (request.shares.eq(0)) {
    return {
      status: YearnWithdrawalStatus.NoActiveWithdrawal,
    }
  }

  const requestMaturityInFuture = nowUnix < request.maturityUnix

  const isRequesting = requestMaturityInFuture
  const isClaimable = !requestMaturityInFuture

  if (isRequesting) {
    return {
      status: YearnWithdrawalStatus.Requesting,
      request: request,
    }
  }

  if (isClaimable) {
    return {
      status: YearnWithdrawalStatus.Claimable,
      request: request,
    }
  }

  return {
    status: YearnWithdrawalStatus.NoActiveWithdrawal,
  }
}
