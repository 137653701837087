import { ReactNode } from 'react'
import {
  AirdropOffchainState,
  AirdropResult,
  AirdropUserVesting,
} from '@/state/dao/types'
import { Token } from '@/types/tokens'
import { FlexRow } from '@/swell-ui/FlexRow'
import styled from 'styled-components'
import { StartAirdropButton } from '../../AirdropButtons'
import { title1CSS, paragraphHintCSS } from '../../styles/common'
import { VestingSummary } from './VestingSummary'
import { RewardBreakdown } from './RewardBreakdown'
import { MerkleContractsState } from '@/types/merkle'
import { SwellDaoSelectOption1, SwellDaoSelectOption2 } from '@/state/dao/hooks'
import { WhaleOptionSelect } from '../../WhaleOptionSelect'

const whitePearlUrl =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66ac75489816f0a5806aaeed_White%20Pearl.svg'

const PEARL_SIZE = 20

export function Intro({
  airdropResult,
  daoToken,
  pearlsStr,
  isPlankton,
  start,
  checkerMode,
  airdropContractsState,
  airdropUserVesting,
  selectOption1,
  selectOption2,
  airdropOffchainState,
}: {
  airdropResult: AirdropResult
  daoToken: Token
  pearlsStr: string
  isPlankton: boolean
  start: () => void
  checkerMode: boolean
  airdropContractsState: MerkleContractsState
  airdropUserVesting: AirdropUserVesting
  selectOption1: SwellDaoSelectOption1
  selectOption2: SwellDaoSelectOption2
  airdropOffchainState: AirdropOffchainState
}) {
  if (!airdropResult.exists) {
    return null
  }
  const { data } = airdropResult

  const unvested = data.cumulativeAmount.lt(data.totalAmount)

  const whaleNote = (
    <>
      <FlexRow maxWidth="1040px">
        <VestingSummary airdropResult={airdropResult} daoToken={daoToken} />
      </FlexRow>
      <WhaleOptionSelect
        airdropResult={airdropResult}
        selectOption1={selectOption1}
        selectOption2={selectOption2}
        airdropUserVesting={airdropUserVesting}
        airdropOffchainState={airdropOffchainState}
      />
    </>
  )

  const normieContinueNote = (
    <>
      <p className="hint body" style={{ textAlign: 'center' }}>
        Click next to learn more about Swell's journey, vision for the future,
        utility for SWELL and how to claim.
      </p>
    </>
  )

  const planktonNote = (
    <>
      <p className="hint body" style={{ textAlign: 'center' }}>
        <span className="em">Please note:</span> The value of your claimable
        SWELL may be less than the estimated gas cost of claiming on mainnet.
        Alternatively, you will be able to claim almost gas-free on Swell L2 at
        launch.
      </p>
    </>
  )

  const canBegin = !checkerMode && airdropContractsState.merkleDrop.claimIsOpen

  let note: ReactNode = null
  let nextButton: ReactNode = (
    <>
      <div className="btn">
        <StartAirdropButton start={start} />
      </div>
      <div style={{ height: '24px' }} />
    </>
  )

  if (canBegin) {
    note = normieContinueNote
  } else {
    nextButton = null
  }

  // update note based on user size
  // takes precedence over the above note
  if (unvested) {
    note = whaleNote
  } else if (isPlankton) {
    note = planktonNote
  }

  return (
    <Layout direction="column" gap="24" justify="center">
      <h1>Congratulations, you're eligible!</h1>
      <p className="major">
        You earned{' '}
        <img
          className="pearl"
          src={whitePearlUrl}
          width={PEARL_SIZE}
          height={PEARL_SIZE}
        />{' '}
        {pearlsStr} White Pearls
      </p>
      <RewardBreakdown airdropResult={airdropResult} daoToken={daoToken} />
      {note}
      {nextButton}
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  flex: 1 0 auto;
  h1 {
    ${title1CSS};
    margin: 0;
    text-align: center;
  }
  .hint {
    ${paragraphHintCSS}
  }
  span.hint {
    font-size: 14px;
  }
  p {
    margin: 0;
  }

  p.body {
    max-width: 618px;
  }

  .btn {
    max-width: 343px;
    width: 100%;
    margin: 0 auto;

    > * {
      width: 100%;
    }
  }

  .em {
    font-weight: 600;
    color: var(--Swell-White-100, #e7e7e7);
    text-align: center;
    /* Body/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    letter-spacing: -0.48px;
  }

  p.major {
    text-align: center;

    color: var(--Swell-White-50, #fff);
    /* Body/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    letter-spacing: -0.48px;

    @media (min-width: 900px) {
      color: var(--Swell-White-50, #fff);
      /* Body/large */
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 38.4px */
      letter-spacing: -0.72px;
    }
  }

  img.pearl {
    margin-left: 2px;
    transform: translateY(4px);

    @media (min-width: 900px) {
      transform: translateY(2px);
    }
  }
`
