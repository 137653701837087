import { TextField as MuiTextField } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

export function SearchInput({
  searchTerm,
  onClear,
  setSearchTerm,
}: {
  searchTerm: string
  onClear: () => void
  setSearchTerm: (term: string) => void
}) {
  const [value, setValue] = useState(searchTerm)
  const [thinking, setThinking] = useState(false)

  const timeout = useRef<NodeJS.Timeout | null>(null)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
    setThinking(true)
    const t = setTimeout(() => {
      setSearchTerm(e.target.value)
      setThinking(false)
    }, 300)
    timeout.current = t
    return () => {
      clearTimeout(t)
    }
  }

  useEffect(() => {
    if (searchTerm === '') {
      setValue(searchTerm)
    }
  }, [searchTerm])

  const clearButton = value ? (
    <X
      onClick={() => {
        setValue('')
        onClear()
      }}
    />
  ) : null

  return (
    <Input
      variant="outlined"
      value={value}
      onChange={handleChange}
      placeholder="Search"
      InputProps={{
        endAdornment: thinking ? '' : clearButton,
        startAdornment: <MagnifyingGlass />,
      }}
      autoComplete="off"
    />
  )
}

const Input = styled(MuiTextField)<{ thinking?: boolean }>`
  border: 1px solid rgba(129, 129, 129, 0.3);

  &,
  .MuiInputBase-formControl {
    border-radius: 100px;
    width: 262px;
    height: 42px;

    color: white;
    input {
      padding-left: 8px;
    }

    svg {
      path {
        stroke: currentColor;
      }
    }
  }

  color: #fff;
  box-shadow: none;
`

function MagnifyingGlass() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clip-path="url(#clip0_11149_1794)">
        <path
          d="M7.5 12C10.2614 12 12.5 9.76142 12.5 7C12.5 4.23858 10.2614 2 7.5 2C4.73858 2 2.5 4.23858 2.5 7C2.5 9.76142 4.73858 12 7.5 12Z"
          stroke="#F1F1F1"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.0356 10.5356L14.5 14"
          stroke="#F1F1F1"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11149_1794">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

function X({ onClick }: { onClick: () => void }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      role="button"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <g clip-path="url(#clip0_11221_608)">
        <path
          d="M13 3.5L4 12.5"
          stroke="#E7E7E7"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13 12.5L4 3.5"
          stroke="#E7E7E7"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11221_608">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
