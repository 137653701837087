import { FlexRow } from '@/swell-ui/FlexRow'
import React from 'react'
import styled from 'styled-components'

const HeadingTypography = styled.span`
  color: var(--white-125, #b0b0b0);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.9px;
  text-transform: uppercase;
`
const BodyTypography = styled.p`
  color: var(--white-125, #b0b0b0);
  /* Body/xsmall */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  letter-spacing: -0.36px;
`

export function NucleusWithdrawNote() {
  return (
    <FlexRow direction="column" gap="6px" align="flex-start">
      <HeadingTypography>NOTE</HeadingTypography>
      <BodyTypography>
        Only one withdrawal may be initiated, points will not accrue while you
        are in the withdrawal queue. If your share value is lower than it was at
        the time requested, it will not fulfil due to IL. Please re-queue your
        withdraw at the new rate.
      </BodyTypography>
    </FlexRow>
  )
}
