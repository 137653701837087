import { forwardRef, HTMLProps, ReactNode, Ref } from 'react'
import styled, { css } from 'styled-components'

type ContainerType = 'flex' | 'block'

type Props = HTMLProps<typeof HTMLDivElement> & {
  containerType?: ContainerType
  children?: ReactNode
}

function _ScrollableYArea(
  { children, ...rest }: Props,
  ref: Ref<HTMLDivElement>
) {
  let className = 'scroll-area-y'
  if (rest.className) {
    className += ` ${rest.className}`
  }
  rest.className = className

  if (!rest.containerType) {
    rest.containerType = 'block'
  }

  return (
    <Positioner {...(rest as any)} ref={ref}>
      <div>{children}</div>
    </Positioner>
  )
}

export const ScrollableYArea = forwardRef<HTMLDivElement, Props>(
  _ScrollableYArea
)

const Positioner = styled.div<{ containerType?: ContainerType }>`
  ${({ containerType }) =>
    containerType === 'flex' &&
    css`
      flex: 1;
      align-self: stretch;
    `}
  ${({ containerType }) =>
    containerType === 'block' &&
    css`
      width: 100%;
      height: 100%;
    `}

  position: relative;
  overflow: hidden;

  > div {
    position: absolute;
    inset: 0;
    overflow-y: auto;
    overflow-x: hidden;

    ${({ theme }) =>
      theme.scrollbar &&
      css`
        padding-right: 24px;

        &::-webkit-scrollbar {
          width: ${theme.scrollbar.width};
        }

        &::-webkit-scrollbar-track {
          background: ${theme.scrollbar.trackBg};
          border-radius: ${theme.scrollbar.borderRadius};
        }

        &::-webkit-scrollbar-thumb {
          background: ${theme.scrollbar.thumbBg};
          border-radius: ${theme.scrollbar.borderRadius};
        }
      `}
  }
`
