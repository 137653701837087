import { useSwellWeb3 } from '@/swell-web3/core'
import { EarnContext } from './context'
import { useEarnSettings } from '../deployments/hooks/useEarnSettings'
import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import {
  parseBlackPearlsCampaign,
  parseCampaignOrDefault,
  parseEarnPortfolioPosition,
} from '../../util/earn'
import {
  EarnBlackPearlsWavedrop,
  EarnPortfolioPositionWithoutBalance,
  EarnPositionBalanceType,
} from '@/types/earn'
import { PositionBalanceMessage } from './types'
import { EarnBalanceType } from '@/submodules/v3-shared/ts/connect/swell/v3/wallet_pb'

export function useEarnApiImpl(): EarnContext {
  const { backendURL, filterChainOptions, filterTokenOptions } =
    useEarnSettings()
  const { account } = useSwellWeb3()
  const walletClient = useV3BackendClient(backendURL).v3BackendClient.wallet
  const statsClient = useV3BackendClient(backendURL).v3BackendClient.stats

  return {
    filterChainOptions,
    filterTokenOptions,
    campaigns: async () => {
      if (!account) {
        throw new Error('no account')
      }
      const resp = await walletClient.earnCampaigns({
        walletAddress: account,
      })
      const blackPearlsWavedrops: EarnBlackPearlsWavedrop[] = []
      for (const wavedrop of resp?.blackPearlWavedrops ?? []) {
        blackPearlsWavedrops.push(parseBlackPearlsCampaign(wavedrop))
      }

      return {
        blackPearlsWavedrops,
        eigenLayerSeason2: parseCampaignOrDefault(resp.eigenlayerSeason2),
        swellL2PreLaunch: parseCampaignOrDefault(resp.swellL2Prelaunch),
        symbiotic: parseCampaignOrDefault(resp.symbiotic),
        voyage: parseCampaignOrDefault(resp.voyage),
      }
    },
    positions: async () => {
      const resp = await statsClient.earnAPYs({})
      const mapping: Record<string, EarnPortfolioPositionWithoutBalance> = {}
      for (const position of resp.available) {
        mapping[position.positionName] = parseEarnPortfolioPosition(position)
      }
      return mapping
    },
    positionBalances: (signal) =>
      (async function* () {
        while (true) {
          const stream = walletClient.earnPositions(
            { walletAddress: account },
            { signal }
          )

          try {
            for await (const resp of stream) {
              let balanceType: EarnPositionBalanceType =
                EarnPositionBalanceType.unspecified
              if (resp.balanceType === EarnBalanceType.USER_TVL) {
                balanceType = EarnPositionBalanceType.balanceUsd
              } else if (resp.balanceType === EarnBalanceType.PEARLS) {
                balanceType = EarnPositionBalanceType.pearls
              }

              yield {
                balanceType,
                positionName: resp.positionName,
                balance: resp.balance,
              } as PositionBalanceMessage
            }
          } catch (e) {
            console.error('stream earn positions', e)
            await new Promise((resolve) => setTimeout(resolve, 200000000))
            continue
          }
          break
        }
      })(),
  }
}
