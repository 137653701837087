import React from 'react'
import { title1CSS } from '../styles/common'
import { ConnectWalletButton } from '../AirdropButtons'
import { FlexRow } from '@/swell-ui/FlexRow'
import styled from 'styled-components'
import { useMediaQuery } from '@mui/material'

const BLOCK_LINK = 'https://etherscan.io/block/20422895'
const DEFLAMMA_LINK = 'https://defillama.com/airdrops'

export function Unconnected() {
  const desktop = useMediaQuery('(min-width: 900px)')
  return (
    <Layout
      direction="column"
      gap="24"
      margin="auto"
      textAlign="center"
      maxWidth={desktop ? '620px' : '353px'}
    >
      <h1>Check your eligibility</h1>
      <p>
        White Pearl snapshot taken at block number:{' '}
        <a href={BLOCK_LINK} target="_blank" rel="noopener noreferrer">
          20422895
        </a>{' '}
        (~30 July 2024 23:59:59)
      </p>
      <p>Please connect your wallet to see if you're eligible.</p>
      {desktop && <div style={{ height: '18px' }} />}
      <ConnectWalletButton />
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  h1 {
    ${title1CSS};
    margin: 0;
  }
  p {
    margin: 0;
    text-align: center;

    color: var(--Swell-White-125, #b0b0b0);
    /* Body/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    letter-spacing: -0.48px;
  }
  button {
    min-width: 186px;
  }
`
