import { FlexRow } from '@/swell-ui/FlexRow'
import { ThemeData } from '@/swell-ui/theme/branding'
import { ColoredHeadingTypography } from '@/swell-ui/Typography/typographyPresets'
import styled from 'styled-components'
import { TokenLogo } from '../../../TokenLogo'
import { paragraphHintCSS } from '../../styles/common'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { TOKEN_LIST_SWELL } from '@/constants/tokens'
import { AirdropClaimSummary } from '../../types'

const LOGO_SIZE_PX = 27

export function AirdropClaimAccounting({
  claimSummary,
}: {
  claimSummary: AirdropClaimSummary
}) {
  return (
    <Layout gap="48">
      <TotalAllocation claimSummary={claimSummary} />
      <VestedAllocation claimSummary={claimSummary} />
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  flex-flow: row wrap;

  color: var(--Swell-White-50, #fff);
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;

  .value {
    color: var(--Swell-White-50, #fff);
    /* Body/large bold */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
  }

  .hint {
    ${paragraphHintCSS}
    height: 29px;
  }

  > * + * {
    margin-right: 48px;
  }
`

function TotalAllocation({
  claimSummary,
}: {
  claimSummary: AirdropClaimSummary
}) {
  const { baseReward, loyaltyBonus, totalClaimable } = claimSummary

  return (
    <AllocLayout>
      <ColoredHeadingTypography>Total allocation</ColoredHeadingTypography>
      <span>Base Reward</span>
      <SwellAmount amount={baseReward} />
      <span>Loyalty Bonus</span>
      <SwellAmount amount={loyaltyBonus} />
      <HDivider />
      <span>Total Claimable</span>
      <SwellAmount amount={totalClaimable} />
    </AllocLayout>
  )
}

function VestedAllocation({
  claimSummary,
}: {
  claimSummary: AirdropClaimSummary
}) {
  const { vestedAmount, vestingAmount, claimableDate } = claimSummary

  let visibility: 'hidden' | 'visible' = 'hidden'
  if (vestedAmount && vestingAmount) {
    if (vestedAmount !== '0' || vestingAmount !== '0') {
      visibility = 'visible'
    }
  }

  return (
    <AllocLayout style={{ visibility }}>
      <ColoredHeadingTypography>Vested allocation</ColoredHeadingTypography>
      <span>Vesting</span>
      <SwellAmount amount={vestingAmount} />
      <span className="hint">Claimable ({claimableDate})</span>
      <HDivider />
      <span>Claimable now</span>
      <SwellAmount amount={vestedAmount} />
    </AllocLayout>
  )
}

const AllocLayout = styled.div`
  width: 100%;
  @media (min-width: 500px) {
    width: 256px;
  }

  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-rows: auto;
  align-items: center;

  ${ColoredHeadingTypography} {
    margin-bottom: 24px;
    grid-column: 1 / -1;
  }

  .hint {
    grid-column: 1 / -1;
    white-space: nowrap;
  }

  grid-column-gap: 23px;
  grid-row-gap: 9px;

  white-space: nowrap;
`

const HDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${ThemeData.Swell.SwellLightBlue};
  grid-column: 1 / -1;
`

function SwellAmount({ amount }: { amount: string | undefined }) {
  return (
    <FlexRow gap="6" justify="start">
      <TokenLogo token={TOKEN_LIST_SWELL} size={LOGO_SIZE_PX} />
      <AsyncDiv loading={!amount}>
        {() => <span className="value">{amount}</span>}
      </AsyncDiv>
    </FlexRow>
  )
}
