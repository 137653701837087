import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import { useGasSettings } from '../deployments/hooks/gasSettings'
import { GasContext } from './context'
import { BigNumber } from 'ethers'

export function useGasApiImpl(): GasContext {
  const { gasBaseURL } = useGasSettings()
  const statsClient = useV3BackendClient(gasBaseURL).v3BackendClient.stats

  return {
    gasPrice: async () => {
      const { suggestedGasPriceWei } = await statsClient.suggestedGasPrice({})
      const gasPriceWei = BigNumber.from(suggestedGasPriceWei)
      return { gasPriceWei }
    },
  }
}
