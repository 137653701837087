export const DaoMerkleDropKind = {
  Airdrop: 'Airdrop' as const,
}
export type DaoMerkleDropKind =
  (typeof DaoMerkleDropKind)[keyof typeof DaoMerkleDropKind]

export type DaoMerkleDrop = {
  address: string
  chainId: number
  kind: DaoMerkleDropKind
}
