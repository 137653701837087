import styled from 'styled-components/macro'
import { BigNumber } from 'ethers'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Skeleton } from '@/swell-ui/Skeleton'
import { Typography } from '@/swell-ui/Typography'
import { AccountInfoButton } from './AccountInfoButton'
import { displayCryptoLocale } from '@/util/displayCrypto'
import { useRswellBalances, useRswellVault } from '@/state/yearnVault/hooks'
import { TokenLogo } from '../TokenLogo'

const Btn = styled(AccountInfoButton)`
  pointer-events: none;
  padding: 6px 12px;
`

const FieldContainer = styled.div`
  display: flex;
  min-width: 78px;
`

function RswellAccountButton() {
  const rswellBalances = useRswellBalances()
  const { vaultToken } = useRswellVault()
  const vaultTokenBalance = rswellBalances.data?.vaultToken

  let balanceStr = ''
  if (vaultTokenBalance) {
    balanceStr = displayCryptoLocale(vaultTokenBalance, vaultToken.decimals, {
      precision: 4,
    })
  }

  return (
    <Btn color="accent">
      <FlexRow gap="12">
        <TokenLogo size={32} token={vaultToken} />
        <AsyncDiv
          loading={!balanceStr}
          Container={FieldContainer}
          loader={() => <Skeleton width={100} />}
        >
          {() => {
            return (
              <Typography variant="body" size="medium" letterSpacing="small">
                {balanceStr} {vaultToken.symbol}
              </Typography>
            )
          }}
        </AsyncDiv>
      </FlexRow>
    </Btn>
  )
}

export { RswellAccountButton }
