import React from 'react'
import { paragraphHintCSS, title1CSS } from '../styles/common'
import styled from 'styled-components'
import { FlexRow } from '@/swell-ui/FlexRow'

const whitePearlUrl =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66ac75489816f0a5806aaeed_White%20Pearl.svg'

const PEARL_SIZE = 20

export function NotEligible() {
  return (
    <Layout
      direction="column"
      margin="0 auto"
      maxWidth="575px"
      gap="24"
      align="center"
    >
      <h1>You are not eligible</h1>
      <p className="major">
        You earned{' '}
        <img
          className="pearl"
          src={whitePearlUrl}
          width={PEARL_SIZE}
          height={PEARL_SIZE}
        />{' '}
        0 White Pearls
      </p>
      <p className="hint">
        Unfortunately you have not qualified for any SWELL tokens as your wallet
        address has 0 White Pearls.
      </p>
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  margin: auto 0;
  max-width: 353px;

  @media (min-width: 900px) {
    max-width: unset;
  }

  display: flex;

  h1 {
    ${title1CSS}
    margin: 0;
    text-align: center;
    width: 100%;
  }

  p,
  ul {
    margin: 0;
  }

  a,
  button {
    align-self: center;
    min-width: 186px;
  }

  p.major {
    text-align: center;

    color: var(--Swell-White-50, #fff);
    /* Body/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    letter-spacing: -0.48px;

    @media (min-width: 900px) {
      color: var(--Swell-White-50, #fff);
      /* Body/large */
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 38.4px */
      letter-spacing: -0.72px;
    }
  }

  p {
    text-align: center;
  }

  img.pearl {
    margin-left: 2px;
    transform: translateY(4px);

    @media (min-width: 900px) {
      transform: translateY(2px);
    }
  }

  .hint {
    ${paragraphHintCSS}
  }
`
