import { Token } from '@/types/tokens'
import { YearnVaultContext } from '../context'
import {
  IYearnVaultApiRead,
  IYearnVaultApiWrite,
  YearnVaultStats,
  YearnAllowances,
  YearnBalances,
  YearnAuthResult,
  YearnAssets,
  YearnWithdrawRequestResult,
  YearnVaultState,
} from '../types'
import {
  Multicall3,
  YearnDelayedWithdraw,
  YearnDelayedWithdraw__factory,
  YearnV3Vault,
  YearnV3Vault__factory,
} from '@/abis/types'
import {
  useIERC20Contract,
  useMulticallContract,
  useYearnDelayedWithdrawContract,
  useYearnV3VaultContract,
} from '@/hooks/useContract'
import { useSwellWeb3 } from '@/swell-web3/core'
import { SolmateRolesAuthorityService } from '@/services/SolmateRolesAuthority'
import { TokenMulticalls } from '@/services/Tokens'
import { YearnAeraVault } from '@/types/yearnAera'
import { IPointsFetcher, IVaultStatsFetcher } from './deps'

/*
API V1 uses V1 of the DelayedWithdraw contract. The yearn vault is yearn V3.
It supports request cancelation.
Users can only request one withdrawal at a time per asset.

(So far) vaults only have one withdraw asset.
*/

const REQUEST_WITHDRAW_SIGNATURE_V1 = '0xb75fa7b3' // keccak256("requestWithdraw(address,uint96,uint16,bool)")
const COMPLETE_WITHDRAW_SIGNATURE_V1 = '0x4953cdbe' // keccak256("completeWithdraw(address,address)")
const CANCEL_WITHDRAW_SIGNATURE_V1 = '0xe9919629' // keccak256("cancelWithdraw(address)")

const ALLOW_THIRD_PARTY_TO_COMPLETE = false
const DEFAULT_COMPLETION_WINDOW_SECONDS = 60 * 60 * 24 * 7 // 7 days

export function useYearnVaultImplV1({
  statsFetcher,
  vault,
  pointsFetcher,
}: {
  pointsFetcher: IPointsFetcher
  vault: YearnAeraVault
  statsFetcher: IVaultStatsFetcher
}): YearnVaultContext {
  return {
    ...vault,
    read: useYearnVaultReadApi({
      vault,
      statsFetcher,
      pointsFetcher,
    }),
    write: useYearnVaultWriteImpl({
      vault,
    }),
  }
}

async function fetchVaultStateV1({
  multicall,
  vault,
}: {
  multicall: Multicall3
  vault: YearnAeraVault
}): Promise<YearnVaultState> {
  const calls: Multicall3.Call3Struct[] = [
    {
      target: vault.delayedWithdrawAddress,
      callData:
        YearnDelayedWithdraw__factory.createInterface().encodeFunctionData(
          'withdrawAssets',
          [vault.depositAsset.address]
        ),
      allowFailure: false,
    },
    {
      allowFailure: false,
      callData:
        YearnV3Vault__factory.createInterface().encodeFunctionData(
          'pricePerShare'
        ),
      target: vault.vaultToken.address,
    },
    {
      target: vault.vaultToken.address,
      callData:
        YearnV3Vault__factory.createInterface().encodeFunctionData(
          'isShutdown'
        ),
      allowFailure: false,
    },
    {
      target: vault.delayedWithdrawAddress,
      callData:
        YearnDelayedWithdraw__factory.createInterface().encodeFunctionData(
          'isPaused'
        ),
      allowFailure: false,
    },
  ]

  const results = await multicall.callStatic.tryAggregate(true, calls)
  const withdrawAssetStruct =
    YearnDelayedWithdraw__factory.createInterface().decodeFunctionResult(
      'withdrawAssets',
      results[0].returnData
    )
  const pricePerShare =
    YearnV3Vault__factory.createInterface().decodeFunctionResult(
      'pricePerShare',
      results[1].returnData
    )[0]
  const depositPaused =
    YearnV3Vault__factory.createInterface().decodeFunctionResult(
      'isShutdown',
      results[2].returnData
    )[0]
  const withdrawPaused =
    YearnDelayedWithdraw__factory.createInterface().decodeFunctionResult(
      'isPaused',
      results[3].returnData
    )[0]

  const depositAsset = vault.depositAsset
  const allowWithdraws = withdrawAssetStruct.allowWithdraws
  let completionWindowSeconds = withdrawAssetStruct.completionWindow
  if (completionWindowSeconds === 0) {
    completionWindowSeconds = DEFAULT_COMPLETION_WINDOW_SECONDS
  }
  const maxLossBasisPoints = withdrawAssetStruct.maxLoss
  const withdrawDelaySeconds = withdrawAssetStruct.withdrawDelay
  const withdrawFeeBasisPoints = withdrawAssetStruct.withdrawFee

  const withdrawAsset: YearnAssets['withdrawAsset'] = {
    ...vault.depositAsset,
    version: 'v1',
    allowWithdraws,
    completionWindowSeconds,
    maxLossBasisPoints,
    withdrawDelaySeconds,
    withdrawFeeBasisPoints,
  }

  return {
    depositAsset,
    depositPaused,
    pricePerShare,
    withdrawAsset,
    withdrawPaused,
  }
}

async function fetchAllowances(
  multicall: Multicall3,
  vaultTokenAddress: string,
  depositAssetAddress: string,
  delayedWithdrawalsAddress: string,
  account: string
): Promise<YearnAllowances> {
  const tmc = new TokenMulticalls(multicall)

  const [
    { allowance: depositAssetForVault },
    { allowance: vaultTokenForWithdrawals },
  ] = await tmc.fetchAllowances(
    [
      {
        spender: vaultTokenAddress,
        token: depositAssetAddress,
      },
      {
        spender: delayedWithdrawalsAddress,
        token: vaultTokenAddress,
      },
    ],
    account
  )

  return {
    depositAssetForVault,
    vaultTokenForWithdrawals,
  }
}

async function fetchBalances(
  multicall: Multicall3,
  vaultTokenAddress: string,
  depositAssetAddress: string,
  account: string
): Promise<YearnBalances> {
  const tmc = new TokenMulticalls(multicall)

  const [{ balance: vaultTokenBalance }, { balance: depositAssetBalance }] =
    await tmc.fetchBalances([vaultTokenAddress, depositAssetAddress], account)

  return {
    vaultToken: vaultTokenBalance,
    depositAsset: depositAssetBalance,
  }
}

async function fetchVaultStatsV1(
  statsFetcher: IVaultStatsFetcher,
  vaultContract: YearnV3Vault
): Promise<YearnVaultStats> {
  const totalAssets = await vaultContract.totalAssets()
  const { apr, totalDepositors } = await statsFetcher.stats()

  return {
    apr,
    totalAssets,
    totalDepositors,
    feePercent: 0,
  }
}

async function fetchYearnAuthV1(
  multicall: Multicall3,
  rolesAuthorityAddress: string,
  delayedWithdrawalsAddress: string,
  account: string
): Promise<YearnAuthResult> {
  const rolesAuth = new SolmateRolesAuthorityService(
    multicall,
    rolesAuthorityAddress
  )

  const [
    { canCall: canRequestWithdraw },
    { canCall: canCompleteWithdraw },
    { canCall: canCancelWithdraw },
  ] = await rolesAuth.fetchCanCall([
    {
      user: account,
      target: delayedWithdrawalsAddress,
      sig: REQUEST_WITHDRAW_SIGNATURE_V1,
    },
    {
      user: account,
      target: delayedWithdrawalsAddress,
      sig: COMPLETE_WITHDRAW_SIGNATURE_V1,
    },
    {
      user: account,
      target: delayedWithdrawalsAddress,
      sig: CANCEL_WITHDRAW_SIGNATURE_V1,
    },
  ])

  return {
    canDeposit: true,
    canRequestWithdraw,
    canCompleteWithdraw,
    canCancelWithdraw,
  }
}

async function fetchYearnWithdrawRequestV1(
  delayedWithdrawal: YearnDelayedWithdraw,
  depositAsset: Token,
  account: string
): Promise<YearnWithdrawRequestResult> {
  const withdrawRequest = await delayedWithdrawal.withdrawRequests(
    account,
    depositAsset.address
  )

  if (withdrawRequest.shares.eq(0)) {
    return {
      exists: false,
    }
  }

  return {
    exists: true,
    request: {
      version: 'v1',
      maxLossBasisPoints: withdrawRequest.maxLoss,
      maturityUnix: withdrawRequest.maturity,
      shares: withdrawRequest.shares,
      assetsAtTimeOfRequest: withdrawRequest.assetsAtTimeOfRequest,
    },
  }
}

function useYearnVaultReadApi({
  vault,
  statsFetcher,
  pointsFetcher,
}: {
  vault: YearnAeraVault
  statsFetcher: IVaultStatsFetcher
  pointsFetcher: IPointsFetcher
}): IYearnVaultApiRead {
  const { account: maybeAccount } = useSwellWeb3()
  const multicall = useMulticallContract()
  const vaultContract = useYearnV3VaultContract(vault.vaultToken.address)
  const delayedWithdrawal = useYearnDelayedWithdrawContract(
    vault.delayedWithdrawAddress
  )

  const account = maybeAccount!

  return {
    userPoints: async () => {
      return pointsFetcher.userPoints()
    },
    vaultPoints: async () => {
      return pointsFetcher.vaultPoints()
    },
    vaultStats: async () => {
      if (vault.withdrawType !== 'v1') {
        throw new Error('not supported')
      }

      return fetchVaultStatsV1(statsFetcher, vaultContract)
    },
    vaultState: async () => {
      if (vault.withdrawType !== 'v1') {
        throw new Error('not supported')
      }
      return fetchVaultStateV1({
        multicall,
        vault,
      })
    },
    allowances: async () => {
      return fetchAllowances(
        multicall,
        vault.vaultToken.address,
        vault.depositAsset.address,
        vault.delayedWithdrawAddress,
        account
      )
    },
    balances: async () => {
      return fetchBalances(
        multicall,
        vault.vaultToken.address,
        vault.depositAsset.address,
        account
      )
    },
    auth: async () => {
      if (vault.withdrawType !== 'v1') {
        throw new Error('not supported')
      }
      return fetchYearnAuthV1(
        multicall,
        vault.delayedWithdrawAuthAddress,
        vault.delayedWithdrawAddress,
        account
      )
    },
    withdrawRequest: async () => {
      if (vault.withdrawType !== 'v1') {
        throw new Error('not supported')
      }
      return fetchYearnWithdrawRequestV1(
        delayedWithdrawal,
        vault.depositAsset,
        account
      )
    },

    checkDeposit: async (params) => {
      // const [assets, receiver] = params
      return true // TODO
    },
    checkRequestWithdraw: async (params) => {
      // const [asset, shares, maxLoss, allowThirdPartyToComplete] = params
      return true // TODO
    },
  }
}

function useYearnVaultWriteImpl({
  vault,
}: {
  vault: YearnAeraVault
}): IYearnVaultApiWrite {
  const { account: maybeAccount } = useSwellWeb3()
  const vaultContract = useYearnV3VaultContract(vault.vaultToken.address)
  const depositAssetContract = useIERC20Contract(vault.depositAsset.address)!
  const delayedWithdrawalV1 = useYearnDelayedWithdrawContract(
    vault.delayedWithdrawAddress
  )

  const account = maybeAccount!
  const allowThirdPartyToComplete = ALLOW_THIRD_PARTY_TO_COMPLETE

  return {
    // deposit
    deposit: async ({ amount }, opts) => {
      return vaultContract.deposit(amount, account, opts)
    },
    depositEstimateGas: async ({ amount }) => {
      return vaultContract.estimateGas.deposit(amount, account)
    },
    approveAssetForDeposit: async ({ amount }, opts) => {
      return depositAssetContract.approve(
        vault.vaultToken.address,
        amount,
        opts
      )
    },
    approveAssetForDepositEstimateGas: async ({ amount }) => {
      return depositAssetContract.estimateGas.approve(
        vault.vaultToken.address,
        amount
      )
    },
    // request withdraw
    requestWithdraw: async ({ shares, maxLossBasisPoints }, opts) => {
      return delayedWithdrawalV1.requestWithdraw(
        vault.depositAsset.address,
        shares,
        maxLossBasisPoints,
        allowThirdPartyToComplete,
        opts
      )
    },
    requestWithdrawEstimateGas: async ({ shares, maxLossBasisPoints }) => {
      return delayedWithdrawalV1.estimateGas.requestWithdraw(
        vault.depositAsset.address,
        shares,
        maxLossBasisPoints,
        allowThirdPartyToComplete
      )
    },
    approveVaultTokenForWithdraw: async ({ amount }, opts) => {
      return vaultContract.approve(vault.delayedWithdrawAddress, amount, opts)
    },
    approveVaultTokenForWithdrawEstimateGas: async ({ amount }) => {
      return vaultContract.estimateGas.approve(
        vault.delayedWithdrawAddress,
        amount
      )
    },
    // cancel withdraw
    cancelWithdraw: async (params, opts) => {
      if (params.version !== 'v1') {
        throw new Error('not supported')
      }
      return delayedWithdrawalV1.cancelWithdraw(
        vault.depositAsset.address,
        opts
      )
    },
    cancelWithdrawEstimateGas: async (params) => {
      if (params.version !== 'v1') {
        throw new Error('not supported')
      }
      return delayedWithdrawalV1.estimateGas.cancelWithdraw(
        vault.depositAsset.address
      )
    },
    // complete withdraw
    completeWithdraw: async (params, opts) => {
      if (params.version !== 'v1') {
        throw new Error('not supported')
      }
      return delayedWithdrawalV1.completeWithdraw(
        vault.depositAsset.address,
        account,
        opts
      )
    },
    completeWithdrawEstimateGas: async () => {
      if (vault.withdrawType !== 'v1') {
        throw new Error('not supported')
      }
      return delayedWithdrawalV1.estimateGas.completeWithdraw(
        vault.depositAsset.address,
        account
      )
    },
  }
}
