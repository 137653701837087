import styled, { css } from 'styled-components'
import { Typography } from '../Typography'
import { FlexRow } from '../FlexRow'

export type TabConfig = {
  id: string
  label: string
  disabled?: boolean
}

export function Tabs({
  selectedTab,
  setTab,
  tabs,
}: {
  tabs: TabConfig[]
  setTab: (tab: string) => void
  selectedTab: string
}) {
  return (
    <TabsWrapper>
      <div className="rowscroll">
        <Typography variant="body" size="small" letterSpacing="small">
          <FlexRow align="center" gap="16">
            {tabs.map(({ id, label, disabled }) => {
              return (
                <StyledTab
                  key={id}
                  onClick={() => setTab(id)}
                  aria-selected={id === selectedTab}
                  aria-disabled={disabled}
                >
                  {label}
                </StyledTab>
              )
            })}
          </FlexRow>
        </Typography>
      </div>
    </TabsWrapper>
  )
}

const TabsWrapper = styled.div`
  padding-bottom: 18px;
  border-bottom: 1px solid ${({ theme }) => theme.divider.darkColor};
  margin-bottom: 16px !important;
  height: 40.4px;

  position: relative;
  .rowscroll {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-x: auto;

    ${({ theme }) =>
      theme.scrollbar &&
      css`
        padding-right: 24px;

        &::-webkit-scrollbar {
          height: 2px;
        }

        &::-webkit-scrollbar-track {
          background: ${theme.scrollbar.trackBg};
          border-radius: ${theme.scrollbar.borderRadius};
        }

        &::-webkit-scrollbar-thumb {
          background: ${theme.scrollbar.thumbBg};
          border-radius: ${theme.scrollbar.borderRadius};
        }
      `}
  }
`

const StyledTab = styled.div`
  color: ${({ theme }) => theme.tabs.color};

  &[aria-selected='true'] {
    color: ${({ theme }) => theme.tabs.hoverColor};
  }

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.tabs.hoverColor};
  }

  &[aria-disabled='true'] {
    opacity: 0.5;
    pointer-events: none;
  }
`
