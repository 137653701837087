import { Multicall3, SwETH__factory } from '@/abis/types'
import { Prices, PrimaryRates } from './types'
import { StatsClient } from '@/services/V3BackendService/types'

export async function fetchPrimaryRates({
  rswETHAddress,
  multicall,
  swETHAddress,
}: {
  swETHAddress: string
  rswETHAddress: string
  multicall: Multicall3
}): Promise<PrimaryRates> {
  const iface = SwETH__factory.createInterface()
  const getRateCalldata = iface.encodeFunctionData('getRate')

  const calls: Multicall3.Call3Struct[] = [
    {
      target: rswETHAddress,
      callData: getRateCalldata,
      allowFailure: false,
    },
    {
      target: swETHAddress,
      callData: getRateCalldata,
      allowFailure: false,
    },
  ]

  const results = await multicall.callStatic.tryAggregate(true, calls)

  const rswETHRate = iface.decodeFunctionResult(
    'getRate',
    results[0].returnData
  )[0]
  const swETHRate = iface.decodeFunctionResult(
    'getRate',
    results[1].returnData
  )[0]

  return {
    rswETHPrimaryRate: rswETHRate,
    swETHPrimaryRate: swETHRate,
  }
}

export async function fetchPrices({
  statsClient,
}: {
  statsClient: StatsClient
}): Promise<Prices> {
  const resp = await statsClient.prices({})

  const ethUsdPrice = parseFloat(resp.ethUsdPrice)
  if (isNaN(ethUsdPrice)) {
    throw new Error('Invalid ethUsdPrice')
  }

  const btcUsdPrice = parseFloat(resp.wbtcUsdPrice)
  if (isNaN(btcUsdPrice)) {
    throw new Error('Invalid btcUsdPrice')
  }

  return {
    ethUsd: {
      priceUsd: ethUsdPrice,
      timestamp: Date.now(),
    },
    btcUsd: {
      priceUsd: btcUsdPrice,
      timestamp: Date.now(),
    },
    btcEth: {
      priceUsd: 0, // TODO: for earnBTC
      timestamp: 0, // TODO: for earnBTC
    },
    swellUsd: {
      priceUsd: 0, // TODO: for rSWELL
      timestamp: 0, // TODO: for rSWELL
    },
  }
}
