import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { OceanBackground } from '../Backgrounds/OceanBackground'

const GlobalStyle = createGlobalStyle`
#root {
  position: relative;
}

html, body {
  background-color: #051A2B;
  overflow: hidden;
  color: #fff;
  font-family: "Inter", sans-serif !important;

  a {
    color: #A4ABF1;

    &:hover {
      color: #2F43EC;
    }
  }

  a:link {
    text-decoration: none;
  }
}
`

export function ErrorPage({
  onClickForceReload,
}: {
  onClickForceReload: () => void
}) {
  return (
    <>
      <GlobalStyle />
      <Layout>
        <OceanBackground />
        <div>
          <h1>An error occurred</h1>
          <a role="button" onClick={onClickForceReload}>
            Reload the page
          </a>
        </div>
      </Layout>
    </>
  )
}

const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  z-index: 10;

  a[role='button'] {
    cursor: pointer;
  }
  > div:last-child {
    z-index: 11;
  }
`
