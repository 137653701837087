import React from 'react'
import styled from 'styled-components'
import { css } from 'styled-components'

export type PillVariant = 'success' | 'warning' | 'info' | 'error' | 'button'

export function Pill({
  children,
  variant,
  ...rest
}: {
  variant: PillVariant
} & React.HTMLProps<HTMLDivElement>) {
  return (
    <Layout {...(rest as any)} variant={variant}>
      {children}
    </Layout>
  )
}

const Layout = styled.div<{ variant: PillVariant }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.pill.padding || '9px 12px'};
  border-radius: ${({ theme }) => theme.pill.borderRadius || '100px'};
  width: max-content;
  line-height: 1;

  height: ${({ theme }) => theme.pill.height || 'auto'};
  ${({ theme, variant }) => {
    const { borderColor, color, bgColor = 'transparent' } = theme.pill[variant]

    return css`
      color: ${color};
      border: 1px solid ${borderColor};
      background-color: ${bgColor};
    `
  }};

  /* Body/small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.42px;
`
