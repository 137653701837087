/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  ArcticArchitectureLens,
  ArcticArchitectureLensInterface,
} from "../ArcticArchitectureLens";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "contract BoringVault",
        name: "boringVault",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "shares",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "contract BoringVault",
        name: "boringVault",
        type: "address",
      },
      {
        internalType: "contract AccountantWithRateProviders",
        name: "accountant",
        type: "address",
      },
    ],
    name: "balanceOfInAssets",
    outputs: [
      {
        internalType: "uint256",
        name: "assets",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "contract ERC20",
        name: "depositAsset",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "depositAmount",
        type: "uint256",
      },
      {
        internalType: "contract BoringVault",
        name: "boringVault",
        type: "address",
      },
      {
        internalType: "contract TellerWithMultiAssetSupport",
        name: "teller",
        type: "address",
      },
    ],
    name: "checkUserDeposit",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "contract ERC20",
        name: "depositAsset",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "depositAmount",
        type: "uint256",
      },
      {
        internalType: "contract TellerWithMultiAssetSupport",
        name: "teller",
        type: "address",
      },
    ],
    name: "checkUserDepositWithPermit",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract AccountantWithRateProviders",
        name: "accountant",
        type: "address",
      },
    ],
    name: "exchangeRate",
    outputs: [
      {
        internalType: "uint256",
        name: "rate",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract TellerWithMultiAssetSupport",
        name: "teller",
        type: "address",
      },
    ],
    name: "isTellerPaused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "depositAsset",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "depositAmount",
        type: "uint256",
      },
      {
        internalType: "contract BoringVault",
        name: "boringVault",
        type: "address",
      },
      {
        internalType: "contract AccountantWithRateProviders",
        name: "accountant",
        type: "address",
      },
    ],
    name: "previewDeposit",
    outputs: [
      {
        internalType: "uint256",
        name: "shares",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract BoringVault",
        name: "boringVault",
        type: "address",
      },
      {
        internalType: "contract AccountantWithRateProviders",
        name: "accountant",
        type: "address",
      },
    ],
    name: "totalAssets",
    outputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "assets",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "contract TellerWithMultiAssetSupport",
        name: "teller",
        type: "address",
      },
    ],
    name: "userUnlockTime",
    outputs: [
      {
        internalType: "uint256",
        name: "time",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class ArcticArchitectureLens__factory {
  static readonly abi = _abi;
  static createInterface(): ArcticArchitectureLensInterface {
    return new utils.Interface(_abi) as ArcticArchitectureLensInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ArcticArchitectureLens {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as ArcticArchitectureLens;
  }
}
