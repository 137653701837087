import React, { useEffect, useRef, useState } from 'react'
import { TimeSeries } from '@/types/series'
import { niceRange, scaleLinear } from '@/util/charts'
import styled from 'styled-components'
import { ThemeData } from '../theme/branding'
import { v4 as uuid } from 'uuid'
import { useDimensions } from '@/hooks/useDimensions'

const X_AXIS_WIDTH = 50
const Y_AXIS_HEIGHT = 24

function formatDateTick(t: number) {
  const d = new Date(t)
  const dayStr = d.toLocaleDateString('en-US', { day: '2-digit' })
  const monthStr = d.toLocaleDateString('en-US', { month: 'short' })
  return `${dayStr} ${monthStr}`
}

export function SeriesChart({
  series,
  formatY,
}: {
  series: TimeSeries | undefined
  formatY: (value: number) => string
}) {
  if (!series || series.length === 0) {
    return <SeriesChartLoading />
  }

  return <SeriesChartLoaded series={series} formatY={formatY} />
}

function SeriesChartLoading() {
  return <></>
}

function SeriesChartLoaded({
  series,
  formatY,
}: {
  series: TimeSeries
  formatY: (value: number) => string
}) {
  const { containerRef, dimensions } = useDimensions()
  const lineColor = ThemeData.Swell.SwellVaultTurquoise
  const axisStrokeWidth = 0.375
  const axisStrokeColor = '#818181'

  type Point = {
    x: number
    y: number
  }
  const values = series.map((point) => point.value)
  const yTicks = niceRange(values, 7)

  const [minValueVisual, maxValueVisual] = [
    Math.min(...yTicks),
    Math.max(...yTicks),
  ]
  const [minTimestamp, maxTimestamp] = [
    Math.min(...series.map((point) => point.timestamp)),
    Math.max(...series.map((point) => point.timestamp)),
  ]

  const pointsFromBase: Point[] = series.map((point) => {
    const availHeight = dimensions.height - Y_AXIS_HEIGHT
    const availWidth = dimensions.width - X_AXIS_WIDTH

    const y = scaleLinear(
      point.value,
      [minValueVisual, maxValueVisual],
      [availHeight, 0]
    )
    const x = scaleLinear(
      point.timestamp,
      [minTimestamp, maxTimestamp],
      [0, availWidth]
    )

    return {
      x,
      y,
    }
  })

  function mapPointToSpace(point: Point): Point {
    return {
      x: point.x + X_AXIS_WIDTH,
      y: point.y,
    }
  }
  const seriesPoints: Point[] = pointsFromBase.map(mapPointToSpace)
  let seriesPath = `M ${seriesPoints[0].x} ${seriesPoints[0].y}`
  for (let i = 1; i < seriesPoints.length; i++) {
    seriesPath += ` L ${seriesPoints[i].x} ${seriesPoints[i].y}`
  }

  const seriesShapePoints: Point[] = [
    { x: 0, y: dimensions.height - Y_AXIS_HEIGHT },
    ...pointsFromBase,
    {
      x: dimensions.width - X_AXIS_WIDTH,
      y: dimensions.height - Y_AXIS_HEIGHT,
    },
  ].map(mapPointToSpace)

  let seriesShapePath = `M ${seriesShapePoints[0].x} ${seriesShapePoints[0].y}`
  for (let i = 1; i < seriesShapePoints.length; i++) {
    seriesShapePath += ` L ${seriesShapePoints[i].x} ${seriesShapePoints[i].y}`
  }
  seriesShapePath += ' Z'

  const ids = useRef({
    seriesPathId: `series-path-${uuid()}`,

    seriesShapePathId: `series-shape-path-${uuid()}`,
    seriesShapeClipId: `series-shape-clip-${uuid()}`,
    seriesShapeGradientId: `series-shape-gradient-${uuid()}`,
  })

  type Annotation = {
    x: number
    y: number
    text: string
  }

  const tickYAnnots: Annotation[] = yTicks.map((tick) => {
    const availHeight = dimensions.height - Y_AXIS_HEIGHT
    const y = scaleLinear(
      tick,
      [minValueVisual, maxValueVisual],
      [availHeight, 0]
    )
    return { x: X_AXIS_WIDTH, y, text: formatY(tick) }
  })
  tickYAnnots.shift()

  const tickYAnnotsRendered = tickYAnnots.map((annot) => {
    return (
      <text
        x={annot.x}
        y={annot.y}
        key={annot.y}
        fontSize="12px"
        fill="#fff"
        textAnchor="end"
        alignmentBaseline="hanging"
        dx={-8}
        dy={2}
      >
        {annot.text}
      </text>
    )
  })

  const numXTicks = 5
  const xTickTimestamps: number[] = []
  xTickTimestamps.push(minTimestamp)
  const durationPerTick = (maxTimestamp - minTimestamp) / numXTicks
  while (xTickTimestamps[xTickTimestamps.length - 1] < maxTimestamp) {
    xTickTimestamps.push(
      xTickTimestamps[xTickTimestamps.length - 1] + durationPerTick
    )
  }
  xTickTimestamps.pop()
  xTickTimestamps.push(maxTimestamp)
  xTickTimestamps.shift()

  const tickXAnnots = xTickTimestamps.map((timestamp) => {
    const availWidth = dimensions.width - X_AXIS_WIDTH
    const x = scaleLinear(
      timestamp,
      [minTimestamp, maxTimestamp],
      [0, availWidth]
    )
    return {
      x,
      y: dimensions.height - Y_AXIS_HEIGHT,
      text: formatDateTick(timestamp),
    }
  })
  const tickXAnnotsRendered = tickXAnnots.map((annot) => {
    return (
      <g
        key={annot.x}
        transform={`translate(${annot.x + X_AXIS_WIDTH}, ${annot.y})`}
      >
        <text
          fontSize="12px"
          fill="#fff"
          textAnchor="middle"
          alignmentBaseline="hanging"
          dy={8}
        >
          {annot.text}
        </text>
        <line
          x1={0}
          x2={0}
          y1={-dimensions.height + Y_AXIS_HEIGHT}
          y2={0}
          stroke="#4A4A4A"
          strokeWidth="0.375469"
          strokeMiterlimit="10"
        />
      </g>
    )
  })

  return (
    <Layout ref={containerRef}>
      <ChartSVG
        width={dimensions.width}
        height={dimensions.height}
        overflow="visible"
      >
        <defs>
          <path id={ids.current.seriesPathId} d={seriesPath} fill="none" />
          <path id={ids.current.seriesShapePathId} d={seriesShapePath} />
          <mask id={ids.current.seriesShapeClipId}>
            <use href={`#${ids.current.seriesShapePathId}`} />
          </mask>
          <linearGradient
            id={ids.current.seriesShapeGradientId}
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor={lineColor} stopOpacity={0.6} />
            <stop offset="50%" stopColor={lineColor} stopOpacity={0} />
            <stop offset="100%" stopColor={lineColor} stopOpacity={0} />
          </linearGradient>
        </defs>
        <g className="x-axis">
          <path
            d={`M ${X_AXIS_WIDTH} ${dimensions.height - Y_AXIS_HEIGHT} L ${
              dimensions.width
            } ${dimensions.height - Y_AXIS_HEIGHT}`}
            stroke={axisStrokeColor}
            strokeWidth={axisStrokeWidth}
          />
          {tickXAnnotsRendered}
        </g>
        <g className="y-axis">
          <path
            d={`M ${X_AXIS_WIDTH} 0 L ${X_AXIS_WIDTH} ${
              dimensions.height - Y_AXIS_HEIGHT
            }`}
            stroke={axisStrokeColor}
            strokeWidth={axisStrokeWidth}
          />
          {tickYAnnotsRendered}
        </g>
        <g className="series">
          <use
            href={`#${ids.current.seriesShapePathId}`}
            fill={`url(#${ids.current.seriesShapeGradientId})`}
          />
          <use
            href={`#${ids.current.seriesPathId}`}
            filter="blur(3px)"
            stroke={lineColor}
            strokeWidth={3.5}
          />
          <use
            href={`#${ids.current.seriesPathId}`}
            stroke={lineColor}
            strokeWidth={2}
          />
        </g>
      </ChartSVG>
    </Layout>
  )
}
const Layout = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  > svg {
    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`
const ChartSVG = styled.svg`
  user-select: none;
`
