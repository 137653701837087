import { DurationBreakdown, getDurationBreakdown } from '@/util/datetime'
import { useEffect, useState } from 'react'

export function useNowMs() {
  const [nowMs, setNowMs] = useState(new Date().getTime())

  useEffect(() => {
    const interval = setInterval(() => {
      setNowMs(new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return nowMs
}

export function useNowMsAnimation() {
  const [nowMs, setNowMs] = useState(new Date().getTime())

  useEffect(() => {
    let done = false

    function animate() {
      if (done) return
      setNowMs(new Date().getTime())
      requestAnimationFrame(animate)
    }

    animate()

    return () => {
      done = true
    }
  }, [])

  return nowMs
}

function toTargetDuration(now: number, targetDateMs: number) {
  const durationMs = targetDateMs - now
  return durationMs
}

export function useTimeCountdown(
  targetDateArg: string | Date | number | undefined
) {
  const nowMs = useNowMs()
  let targetDate: Date | undefined
  if (typeof targetDateArg === 'string') {
    targetDate = new Date(targetDateArg)
  } else if (typeof targetDateArg === 'number') {
    targetDate = new Date(targetDateArg)
  } else {
    targetDate = targetDateArg
  }

  const targetDateMs = targetDate?.getTime()

  const [countDownStats, setCountdownStats] = useState<
    DurationBreakdown | undefined
  >(() => {
    if (!targetDateMs) return undefined
    const durationMs = toTargetDuration(nowMs, targetDateMs)
    return getDurationBreakdown(durationMs)
  })

  useEffect(() => {
    if (!targetDateMs) return
    const durationMs = toTargetDuration(nowMs, targetDateMs)
    setCountdownStats(getDurationBreakdown(durationMs))
  }, [nowMs, targetDateMs])

  return countDownStats
}
