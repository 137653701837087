import useSWRImmutable from 'swr/immutable'
import { useSwellWeb3 } from '@swell-web3/core'
import { useGasApi } from './context'
import useSWR from 'swr'
import ms from 'ms.macro'

export function useGasPrice() {
  const api = useGasApi()
  const { chainId } = useSwellWeb3()
  return useSWR([chainId, 'gas'], () => api.gasPrice(), {
    refreshInterval: ms`300s`,
  })
}
