import { scaleLinear } from '@/util/charts'
import React, {
  useMemo,
  useEffect,
  useRef,
  useState,
  ReactNode,
  MouseEvent,
} from 'react'
import styled from 'styled-components'
import { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

function MarkedSlider({
  max,
  min,
  step,
  value,
  onChange,
  marks,
  disabled,
  valueLabel,
}: {
  min: number
  max: number
  value: number
  onChange: (value: number) => void
  step?: number
  marks?: Record<number, string>
  valueLabel?: string
  disabled?: boolean
}) {
  const layoutRef = useRef<HTMLDivElement>(null)

  const [width, setWidth] = useState(0)

  const [focused, setFocused] = useState(false)
  const [thumbFocusIntensity, setThumbFocusIntensity] = useState(0)

  let ratio = 0
  if (min - max !== 0) {
    ratio = (value - min) / (max - min)
  }

  const shouldHideThumbLabel = hideThumbLabel(ratio, marks)
  const [thumbLabelVisibility, setThumbLabelVisibility] = useState(
    shouldHideThumbLabel ? 0 : 1
  )

  const ID = useMemo(
    () => ({
      tracksAndLineShape: `${uuid()}-track-n-lines`,
    }),
    []
  )

  // determine width based on input size
  useEffect(() => {
    let done = false
    const animate = () => {
      if (layoutRef.current && !done) {
        setWidth(layoutRef.current.clientWidth)
        requestAnimationFrame(animate)
      }
    }
    animate()
    return () => {
      done = true
    }
  }, [])

  // control radius of the glow around the thumb when focused
  useEffect(() => {
    let done = false
    const animate = () => {
      if (done) return

      let trueIntensity = 0
      if (focused) {
        trueIntensity = 1
      }

      setThumbFocusIntensity((p) => {
        const diff = trueIntensity - p
        return p + diff * 0.1
      })
      requestAnimationFrame(animate)
    }
    animate()
    return () => {
      done = true
    }
  }, [focused])

  // control display of the thumb label (hidden when thumb is on a mark; slide up on mount)
  useEffect(() => {
    let done = false
    const animate = () => {
      if (done) return

      let trueVisibility = 0
      if (!shouldHideThumbLabel) {
        trueVisibility = 1
      }

      setThumbLabelVisibility((p) => {
        if (trueVisibility === 0) return 0

        const diff = trueVisibility - p
        return p + diff * 0.1
      })
      requestAnimationFrame(animate)
    }
    animate()
    return () => {
      done = true
    }
  }, [shouldHideThumbLabel])

  const toX = (value: number) => {
    if (width) {
      return scaleLinear(value, [min, max], [0, width])
    }
    return 0
  }

  const renderMarks = () => {
    if (!marks || !layoutRef.current) return null
    const nodes: ReactNode[] = []
    for (const [vS] of Object.entries(marks)) {
      const v = parseFloat(vS)
      if (v < min || v > max) continue

      nodes.push(
        <g key={v} transform={`translate(${toX(v)}, 0)`}>
          <line x1="0" y1="0" x2="0" y2="-18" stroke="white" strokeWidth={2} />
        </g>
      )
    }

    return <>{nodes}</>
  }

  const renderLabels = () => {
    if (!marks || !layoutRef.current) return null
    // sort to enforce DOM order so clicks register on right-most label if overlapping
    const markPairs = Object.entries(marks).sort(
      ([a], [b]) => parseFloat(a) - parseFloat(b)
    )

    const nodes: ReactNode[] = []
    for (const [vS, label] of markPairs) {
      const v = parseFloat(vS)
      if (v < min || v > max) continue

      const highlight = Math.abs(v - value) < 0.001

      const onClickLabel = (e: MouseEvent<SVGTextElement>) => {
        onChange(v)
        e.stopPropagation()
      }

      nodes.push(
        <g key={v} transform={`translate(${toX(v)}, 0)`}>
          <LabelText
            x="0"
            y="15"
            textAnchor="middle"
            fill="white"
            onClick={disabled ? undefined : onClickLabel}
            highlight={highlight}
          >
            <tspan dy="12">{label}</tspan>
          </LabelText>
        </g>
      )
    }

    return <>{nodes}</>
  }

  const renderThumb = () => {
    if (!layoutRef.current) return null
    return (
      <g transform={`translate(${toX(value)}, 0)`}>
        <circle
          cx="0"
          cy="0"
          r="13"
          opacity="0.4"
          fill="#2F43EC"
          fillOpacity={thumbFocusIntensity * 0.4}
        />
        <circle cx="0" cy="0" r="8" fill="#2F43EC" />
      </g>
    )
  }

  const renderValueLabel = () => {
    if (!layoutRef.current || !valueLabel || shouldHideThumbLabel || disabled)
      return null
    const y = -28 - thumbLabelVisibility * 4
    return (
      <g transform={`translate(${toX(value)}, ${y})`}>
        <text
          x="0"
          y="15"
          textAnchor="middle"
          fill="white"
          fontSize="12"
          fontWeight="bold"
          fillOpacity={thumbLabelVisibility}
        >
          {valueLabel}
        </text>
      </g>
    )
  }

  return (
    <Layout ref={layoutRef} disabled={disabled}>
      <svg width={width} height={1} overflow="visible">
        <defs>
          <mask id={ID.tracksAndLineShape}>
            <line
              x1="0"
              y1="0"
              x2="100%"
              y2="0"
              stroke="white"
              strokeWidth={2}
              strokeLinecap="square"
            />
            {renderMarks()}
          </mask>
        </defs>
        <rect
          x={-2}
          y={-20}
          width={width + 4}
          height={50}
          fill="#818181"
          mask={`url(#${ID.tracksAndLineShape})`}
        />
        <rect
          x={-2}
          y={-20}
          width={width * ratio + 4}
          height={50}
          fill={value > 0 ? '#2F43EC' : 'transparent'}
          mask={`url(#${ID.tracksAndLineShape})`}
        />

        {renderLabels()}
        {renderThumb()}
        {renderValueLabel()}
      </svg>
      <Slider
        type="range"
        disabled={disabled}
        max={max}
        min={min}
        step={step}
        value={value}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={(e) => {
          onChange?.(parseFloat(e.currentTarget.value))
        }}
      />
    </Layout>
  )
}

export default MarkedSlider

const Slider = styled.input`
  cursor: pointer;
  opacity: 0;

  width: calc(100% + 8px);
  height: 16px;
  outline: 0;
  margin: 0 -4px;

  ::-webkit-slider-runnable-track {
    background: transparent;
    height: 32px;
  }

  ::-moz-range-track {
    background: transparent;
    height: 32px;
  }

  ::-webkit-slider-thumb {
    appearance: none;
  }
`

const Layout = styled.div<{ disabled?: boolean }>`
  padding-top: 8px;
  margin-bottom: 24px;

  display: flex;
  align-items: center;
  width: 100%;

  position: relative;
  > svg {
    margin-top: 16px;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      filter: grayscale(0.5);
      * {
        cursor: default;
      }
    `}
`

const LabelText = styled.text<{ highlight: boolean }>`
  cursor: pointer;
  user-select: none;

  tspan {
    color: var(--white-50, #fff);
    /* Body/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    letter-spacing: -0.48px;

    opacity: ${({ highlight }) => (highlight ? 1 : 0.3)};
    transition: opacity 0.2s;
  }
`

// hide the thumb label if there is a visible mark with a non-empty label
function hideThumbLabel(
  ratio: number,
  marks?: Partial<Record<number, string>>
) {
  if (!marks) return false
  const valueLabel = marks[ratio]
  return !!valueLabel?.trim().replace(/\u3164/g, '') // remove whitespace / invisible whitespace
}
