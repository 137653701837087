import useSWRImmutable from 'swr/immutable'
import { useNucleusGlobalApi } from './context'

export function useNucleusGlobalContracts() {
  const { atomicQueueAddress, vaults } = useNucleusGlobalApi()
  return { atomicQueueAddress, vaults }
}

export function useNucleusGlobalStats() {
  const api = useNucleusGlobalApi()

  return useSWRImmutable('nucleusGlobalStats', async () => {
    return api.read.stats()
  })
}
