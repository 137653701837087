import { createContext, useContext } from 'react'
import { IGasApi } from './types'

const MISSING_PROVIDER = Symbol()

export type GasContext = IGasApi

export const GasContext = createContext<GasContext | typeof MISSING_PROVIDER>(
  MISSING_PROVIDER
)

export function useGasApi() {
  const connectors = useContext(GasContext)
  if (connectors === MISSING_PROVIDER) {
    throw new Error('GasContext hooks must be wrapped in a <GasProvider>')
  }
  return connectors
}
