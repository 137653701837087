import { createContext, useContext } from 'react'

const MISSING_PROVIDER = Symbol()

export type TransactionContext = {
  anyTransactionInProgress: boolean
}

export const TransactionContext = createContext<
  TransactionContext | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

export function useTransactionContext() {
  const ctx = useContext(TransactionContext)
  if (ctx === MISSING_PROVIDER) {
    throw new Error(
      'TransactionContext hooks must be wrapped in a <TransactionContext.Provider>'
    )
  }
  return ctx
}
