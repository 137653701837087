import React from 'react'
import styled from 'styled-components'
import { css } from 'styled-components'

export type BadgeVariant = 'success' | 'warning' | 'info' | 'error'

export function Badge({
  children,
  variant,
  ...rest
}: {
  variant: BadgeVariant
} & React.HTMLProps<HTMLDivElement>) {
  return (
    <Layout {...(rest as any)} variant={variant}>
      {children}
    </Layout>
  )
}

const Layout = styled.div<{ variant: BadgeVariant }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.badge.borderRadius || '50%'};
  width: max-content;
  min-width: ${({ theme }) => theme.badge.height || 'auto'};
  line-height: 1;

  height: ${({ theme }) => theme.badge.height || 'auto'};

  ${({ theme, variant }) => {
    const { borderColor, color, bgColor = 'transparent' } = theme.badge[variant]

    return css`
      color: ${color};
      border: 1px solid ${borderColor};
      background-color: ${bgColor};
    `
  }};
`
