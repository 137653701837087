import { IErrorTransport } from '@/services/ErrorService/types'
import { createContext, useContext } from 'react'

const MISSING_PROVIDER = Symbol()

export type ErrorsContext = IErrorTransport

export const ErrorsContext = createContext<
  ErrorsContext | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

export function useErrors() {
  const ctx = useContext(ErrorsContext)
  if (ctx === MISSING_PROVIDER) {
    throw new Error(
      'ErrorsContext hooks must be wrapped in a <ErrorsContext.Provider>'
    )
  }
  return ctx
}
