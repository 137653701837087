import { useGetAllStatsV3Backend } from '@/services/V3BackendService/hooks'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useRswEthMarketCapV3Backend = () => {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const { data, ...query } = useGetAllStatsV3Backend(v3BackendLrtUrl)

  return {
    ...query,
    get data() {
      if (!data) return undefined
      // TODO: update to rswEthMarketCapCents when available
      const { swEthMarketCapCents } = data
      return {
        marketCapUsd: parseFloat(swEthMarketCapCents) / 100,
      }
    },
  }
}

export { useRswEthMarketCapV3Backend as useRswEthMarketCap }
