import { Token } from '@/types/tokens'
import { NucleusActiveWithdrawalResult } from './nucleusWithdrawals'
import {
  NucleusClaimSummary,
  NucleusDepositSummary,
  NucleusHoldingsSummary,
  NucleusPositionSummary,
  NucleusVaultDetailsSummary,
  NucleusVaultStatsSummary,
  NucleusVaultWithdrawalsSummary,
  NucleusWithdrawRequestSummary,
} from './types'
import { BigNumber } from 'ethers'
import {
  atomicPriceFromFulfilledEvent,
  nucleusAssetValueUsd,
  nucleusVaultTokenToUsd,
  withdrawAssetReceivedFromAtomicPrice,
} from './nucleusConversions'
import { displayCryptoLocale } from '@/util/displayCrypto'
import {
  NucleusBalances,
  NucleusVaultPoints,
  NucleusVaultState,
  NucleusVaultStateWithdraw,
  NucleusVaultStats,
} from '@/state/nucleusVault/types'
import { displayPercentFromPercent } from '@/util/displayNumbers'
import { formatWithConfig } from '@/util/number'
import { displayFiat } from '@/util/displayFiat'
import { formatDelay } from '@/util/displayTime'
import { shortenAddress } from '@/util/hexStrings'
import { NucleusRates, NucleusPosition } from '@/types/nucleus'
import { displayRangeAPR } from '@/util/apr'

export function makeNucleusDepositSummary({
  assetAmount,
  balances,
  vaultToken,
  baseAssetRateUsd,
  rates,
  selectedToken,
  base,
}: {
  assetAmount: BigNumber | undefined
  balances: NucleusBalances | undefined
  vaultToken: Token
  baseAssetRateUsd: number | undefined
  base: { decimals: number }
  rates: NucleusRates | undefined
  selectedToken: Token
}): NucleusDepositSummary {
  let depositUsd = ''
  if (baseAssetRateUsd && assetAmount && rates) {
    const vaultTokenPrimaryRate = rates.vaultTokenPrimaryRate
    const vaultTokenRateInQuote =
      rates.vaultTokenQuoteRates?.[selectedToken.address]

    if (vaultTokenRateInQuote && vaultTokenPrimaryRate) {
      const usd = nucleusAssetValueUsd({
        assetAmount,
        baseAssetRateUsd,
        vaultTokenPrimaryRate,
        vaultTokenRateInQuote,
        base,
      })
      depositUsd = displayFiat(usd)
    }
  }

  let availableBalance = ''
  if (balances && balances.assets[selectedToken.address]) {
    availableBalance = displayCryptoLocale(
      balances.assets[selectedToken.address],
      vaultToken.decimals,
      { abbreviateAbove: '100k' }
    )
    availableBalance = `${availableBalance} ${selectedToken.symbol}`
  } else {
    availableBalance = '-'
  }

  return {
    availableBalance,
    depositUsd,
  }
}

export function nucleusWithdrawRequestSummary({
  fromAmount,
  rates,
  baseAsset,
  baseAssetRateUsd,
  balances,
  vaultToken,
}: {
  fromAmount: BigNumber | undefined
  rates: NucleusRates | undefined
  baseAssetRateUsd: number | undefined
  baseAsset: Token
  balances: NucleusBalances | undefined
  vaultToken: Token
}): NucleusWithdrawRequestSummary {
  let withdrawUsd = ''
  if (rates && fromAmount) {
    const vaultTokenPrimaryRate = rates.vaultTokenPrimaryRate
    if (vaultTokenPrimaryRate && baseAssetRateUsd) {
      const usdAmount = nucleusVaultTokenToUsd({
        baseAssetRateUsd,
        vaultTokenPrimaryRate,
        base: baseAsset,
        vaultTokenAmount: fromAmount,
      })
      withdrawUsd = displayFiat(usdAmount)
    }
  }

  let availableBalance = ''
  if (balances) {
    availableBalance = displayCryptoLocale(
      balances.vaultToken,
      vaultToken.decimals,
      { abbreviateAbove: '100k' }
    )
    availableBalance = `${availableBalance} ${vaultToken.symbol}`
  } else {
    availableBalance = '-'
  }

  return {
    availableBalance,
    withdrawUsd,
  }
}

export function makeNucleusHoldingsSummary({
  balances,
  vaultToken,
  points,
  account,
}: {
  balances: NucleusBalances | undefined
  vaultToken: Token
  points: NucleusVaultPoints | undefined
  account: string | undefined
}): NucleusHoldingsSummary {
  let vaultTokenHoldings = ''
  if (balances) {
    vaultTokenHoldings = displayCryptoLocale(
      balances.vaultToken,
      vaultToken.decimals,
      {
        abbreviateAbove: '100k',
      }
    )
  }
  let nucleusPtsHoldings = ''
  let ecosystemPtsHoldings = ''
  let blackPearlsHoldings = ''

  let blackPearlsMultiplier = ''
  let ecosystemPtsMultiplier = ''
  let nucleusPtsMultiplier = ''

  if (points) {
    nucleusPtsHoldings = formatWithConfig(points.nucleusPts, {
      localize: true,
      precision: 0,
    })
    nucleusPtsMultiplier = formatWithConfig(points.nucleusPtsMultiplier, {
      localize: true,
      precision: 1,
    })
    ecosystemPtsHoldings = formatWithConfig(points.ecosystemPts, {
      localize: true,
      precision: 0,
    })
    ecosystemPtsMultiplier = formatWithConfig(points.ecosystemPtsMultiplier, {
      localize: true,
      precision: 1,
    })
    blackPearlsHoldings = formatWithConfig(points.blackPearls, {
      localize: true,
      precision: 0,
    })
    blackPearlsMultiplier = formatWithConfig(points.blackPearlsMultiplier, {
      localize: true,
      precision: 1,
    })
  }

  if (!account) {
    vaultTokenHoldings = '-'
    nucleusPtsHoldings = '-'
    ecosystemPtsHoldings = '-'
    blackPearlsHoldings = '-'
  }

  return {
    vaultToken: vaultTokenHoldings,
    blackPearls: blackPearlsHoldings,
    nucleusPts: nucleusPtsHoldings,
    ecosystemPts: ecosystemPtsHoldings,
    blackPearlsMultiplier,
    ecosystemPtsMultiplier,
    nucleusPtsMultiplier,
  }
}

export function makeNucleusVaultWithdrawalsSummary({
  vaultState,
}: {
  vaultState: NucleusVaultStateWithdraw | undefined
}): NucleusVaultWithdrawalsSummary {
  let solverFeeStr = ''
  if (vaultState) {
    solverFeeStr = displayPercentFromPercent(vaultState.solverFeePercent, {
      precision: 2,
    })
  }
  let processingTimeStr = ''
  if (vaultState) {
    processingTimeStr = formatDelay(
      vaultState.withdrawalProcessingDurationUnix * 1000
    )
  }

  return {
    solverFee: solverFeeStr,
    processingTime: processingTimeStr,
  }
}

export function makeNucleusClaimSummary({
  activeWithdrawal,
  withdrawAssets,
  vaultToken,
  baseAsset,
}: {
  activeWithdrawal: NucleusActiveWithdrawalResult | undefined
  withdrawAssets: Token[]
  vaultToken: Token
  baseAsset: Token
}): NucleusClaimSummary {
  if (!activeWithdrawal || activeWithdrawal?.status === 'NoActiveWithdrawal') {
    return {
      receiving: '',
      receivingLogoURI: '',
      targetTokenPrice: '',
      withdrawing: '',
      withdrawingLogoURI: '',
      receivingSymbol: '',
      withdrawingSymbol: '',
    }
  }

  const { wantToken, request, failedRequest, fulfilledEvent } = activeWithdrawal
  const wantAsset = withdrawAssets.find((t) => t.address === wantToken)

  let atomicPrice: BigNumber | undefined
  let offerAmount: BigNumber | undefined
  if (request) {
    atomicPrice = request.atomicPrice
    offerAmount = request.offerAmount
  } else if (failedRequest) {
    atomicPrice = failedRequest.atomicPrice
    offerAmount = failedRequest.offerAmount
  } else if (fulfilledEvent && wantAsset) {
    atomicPrice = atomicPriceFromFulfilledEvent({
      fulfilledEvent,
      vaultToken,
      wantAsset,
    })
    offerAmount = fulfilledEvent.offerAmountSpent
  } else {
    // invalid state
    return {
      receiving: '',
      receivingLogoURI: '',
      targetTokenPrice: '',
      withdrawing: '',
      withdrawingLogoURI: '',
      receivingSymbol: '',
      withdrawingSymbol: '',
    }
  }

  const withdrawAsset = withdrawAssets.find((t) => t.address === wantToken)

  const withdrawingVaultTokenStr = `${displayCryptoLocale(
    offerAmount,
    vaultToken.decimals,
    { abbreviateAbove: '100k' }
  )} ${vaultToken.symbol}`

  let wantTokenDecimals: number | undefined
  if (withdrawAsset) {
    wantTokenDecimals = withdrawAsset.decimals
  }

  const receivingLogoURI = withdrawAsset?.logoURI ?? ''
  const receivingSymbol = withdrawAsset?.symbol ?? ''
  let receivingWantTokenStr = ''
  if (withdrawAsset && wantTokenDecimals) {
    const receiveAmount = withdrawAssetReceivedFromAtomicPrice({
      atomicPrice,
      offerAmount,
      base: baseAsset,
    })

    receivingWantTokenStr = `${displayCryptoLocale(
      receiveAmount,
      wantTokenDecimals,
      { abbreviateAbove: '100k' }
    )} ${withdrawAsset.symbol}`
  }
  let targetTokenPriceStr = ''
  if (wantAsset) {
    targetTokenPriceStr = displayCryptoLocale(atomicPrice, wantAsset.decimals)
  }

  return {
    receiving: receivingWantTokenStr,
    receivingLogoURI: receivingLogoURI,
    targetTokenPrice: targetTokenPriceStr,
    withdrawing: withdrawingVaultTokenStr,
    withdrawingLogoURI: vaultToken.logoURI,
    receivingSymbol: receivingSymbol,
    withdrawingSymbol: vaultToken.symbol,
  }
}

export function makeNucleusVaultStatsSummary({
  vaultStats,
}: {
  vaultStats: NucleusVaultStats | undefined
}): NucleusVaultStatsSummary {
  const apr = vaultStats?.apr
  const tvlUsd = vaultStats?.tvlUsd

  let apyStr = ''
  if (apr !== undefined) {
    if (apr.indicative) {
      apyStr = 'N/A'
    } else {
      apyStr = displayPercentFromPercent(apr.aprPercent)
    }
  }
  let tvlStr = ''
  if (tvlUsd !== undefined) {
    tvlStr = displayFiat(tvlUsd)
  }

  return {
    apy: apyStr,
    tvl: tvlStr,
  }
}

export function makeNucleusPositionSummary({
  position,
}: {
  position: NucleusPosition
}): NucleusPositionSummary {
  const aprStr = displayRangeAPR(position.apr)

  const allocationStr = displayPercentFromPercent(position.allocationPercent, {
    precision: 1,
  })

  return {
    apr: aprStr,
    allocation: allocationStr,
  }
}

export function makeNucleusVaultDetailsSummary({
  vaultState,
  explorer,
  vaultToken,
}: {
  vaultState: NucleusVaultState | undefined
  explorer: string
  vaultToken: Token
}): NucleusVaultDetailsSummary {
  let solverFee = ''
  let performanceFee = ''
  let platformFee = ''
  if (vaultState) {
    solverFee = displayPercentFromPercent(vaultState.solverFeePercent)
    performanceFee = displayPercentFromPercent(vaultState.performanceFeePercent)
    platformFee = displayPercentFromPercent(vaultState.platformFeePercent)
  }

  const contractAddress = vaultToken.address
  const contractAddressShort = shortenAddress(contractAddress)

  let explorerURL: URL | undefined
  try {
    explorerURL = new URL(explorer)
  } catch (e) {
    console.error('explorer URL', explorer, e)
  }

  let contractAddressLink = ''
  if (explorerURL) {
    explorerURL.pathname = `/address/${contractAddress}`
    contractAddressLink = explorerURL.toString()
  }

  return {
    contractAddress,
    contractAddressLink,
    contractAddressShort,
    performanceFee,
    solverFee,
    platformFee,
  }
}
