import { createContext, useContext } from 'react'
import { IEarnApi } from './types'
import { EarnSettings } from '../deployments/hooks/useEarnSettings'

const MISSING_PROVIDER = Symbol()

export type EarnContext = IEarnApi & Omit<EarnSettings, 'backendURL'>

export const EarnContext = createContext<EarnContext | typeof MISSING_PROVIDER>(
  MISSING_PROVIDER
)

export function useEarnApi() {
  const ctx = useContext(EarnContext)
  if (ctx === MISSING_PROVIDER) {
    throw new Error(
      'EarnContext hooks must be wrapped in a <EarnContext.Provider>'
    )
  }
  return ctx
}
