export type VoyageSignatureOption = 0 | 1 | 2
export const VoyageSignatureOption = {
  Unspecified: 0 as VoyageSignatureOption,
  Option1: 1 as VoyageSignatureOption,
  Option2: 2 as VoyageSignatureOption,
}

export interface IVoyageSignaturesService {
  selectAirdropOption: (args: {
    user: string
    signature: string
  }) => Promise<void>
  getSelectedOption: (user: string) => Promise<VoyageSignatureOption>
  getMessage: (option: VoyageSignatureOption) => Promise<string>
}
