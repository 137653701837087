import { useSwellWeb3 } from '@/swell-web3/core'
import { useEffect } from 'react'

export function useAutoConnectGnosis() {
  const { connect } = useSwellWeb3()

  useEffect(() => {
    const isIframe = window.self !== window.top
    if (!isIframe) {
      return
    }

    ;(async () => {
      const { default: SafeAppsSDK } = await import(
        '@safe-global/safe-apps-sdk'
      )
      const sdk = new SafeAppsSDK()

      const safe = await Promise.race([
        sdk.safe.getInfo(),
        new Promise<undefined>((resolve) => setTimeout(resolve, 200)),
      ])

      if (!safe) {
        console.error('No Safe detected')
        return
      }

      connect({
        autoSelect: {
          disableModals: true,
          label: 'Safe',
        },
      })
    })()
  }, [connect])
}
