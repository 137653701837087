import React, { ReactNode } from 'react'
import { SeriesChart } from '@/swell-ui/chartsv2/SeriesChart'
import { ColoredHeadingTypography } from '@/swell-ui/Typography/typographyPresets'
import { TimeSeries } from '@/types/series'
import { displayPercentFromPercent } from '@/util/displayNumbers'
import styled, { css } from 'styled-components'
import { AprResult } from '@/types/apr'
import { ScrollableYArea } from '@/swell-ui/ScrollableArea'

export function NucleusPerformanceView({
  performance,
  performanceData,
  apr,
}: {
  performance: ReactNode
  performanceData: TimeSeries | undefined
  apr: AprResult | undefined
}) {
  const notEnoughDataAPY = apr && apr.indicative

  return (
    <Layout>
      <ScrollableYArea containerType="flex">
        <div className="custom">{performance}</div>
      </ScrollableYArea>
      <div className="chart">
        {notEnoughDataAPY && <ComingSoon>Coming Soon</ComingSoon>}
        {!notEnoughDataAPY && (
          <SeriesChart
            series={performanceData}
            formatY={(v) => displayPercentFromPercent(v)}
          />
        )}
      </div>
    </Layout>
  )
}

const ComingSoon = styled(ColoredHeadingTypography)`
  opacity: 0.6;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Layout = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;
  justify-content: stretch;
  align-items: stretch;
  gap: 24px;
  font-size: 14px;

  .chart {
    max-width: 600px;
    width: 100%;
    align-self: center;
    position: relative;
  }

  @media (min-width: 900px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: unset;
    column-gap: 62px;
    justify-content: unset;
    align-items: unset;

    .chart {
      align-self: stretch;
    }
  }

  height: 100%;

  > div {
    flex: 1;
  }

  .scroll-area-y {
    background-color: rgba(0, 34, 37, 0.5);
    border-radius: 8px;

    > div {
      right: 2px;
    }
  }

  .custom {
    padding: 0 12px 12px 12px;
    h3 {
      margin-top: 0;
      padding-top: 12px;

      color: var(--Swell-White-50, #fff);
      /* Body/medium bold */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%; /* 25.6px */
    }
  }
`
