import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { KeyValueList } from '@/swell-ui/KeyValueList'
import { YearnAeraVault } from '@/types/yearnAera'
import { YearnAeraTransactionFees, YearnDepositSummary } from '../types'

export function YearnDepositExchangeInfo({
  summary,
  vault,
  transactionFees,
}: {
  summary: YearnDepositSummary
  vault: YearnAeraVault
  transactionFees: Partial<YearnAeraTransactionFees>
}) {
  const aprConfig = {
    label: `${vault.vaultToken.symbol} APR`,
    value: <AsyncDiv loading={!summary.apr}>{() => summary.apr}</AsyncDiv>,
    infoText: <span>7 day moving average</span>,
    hasInfo: true,
  }

  const exchangeRateConfig = {
    label: 'Exchange rate',
    value: (
      <AsyncDiv loading={!summary.exchangeRate}>
        {() => summary.exchangeRate}
      </AsyncDiv>
    ),
    hasInfo: true,
    infoText: `Represents the accruing value of ${vault.vaultToken.symbol} vs ${vault.depositAsset.symbol}`,
  }

  const exchangeInfoConfig = {
    label: 'Transaction fee',
    value: (
      <AsyncDiv loading={!transactionFees.deposit}>
        {() => {
          return transactionFees.deposit
        }}
      </AsyncDiv>
    ),
  }

  const exchangeInfoListConfig = {
    list: [aprConfig, exchangeRateConfig, exchangeInfoConfig],
  }

  return <KeyValueList config={exchangeInfoListConfig} />
}
