import { SwellDaoClaimAirdrop, SwellDaoSelectOption2 } from '@/state/dao/hooks'
import {
  AirdropOffchainState,
  AirdropResult,
  AirdropUserVesting,
} from '@/state/dao/types'
import { BigNumber } from 'ethers'
import { merkleClaimable } from '../../util/merkledrop'
import { MerkleDropState, MerkleStakingState } from '@/types/merkle'

export const AirdropErrors = {
  IneligiblePearls: 'Ineligible pearls',
  NoAirdropData: 'No airdrop data',
  InvalidMerkleRoot: 'Invalid merkle root',
  InvalidMerkleProof: 'Invalid merkle proof',
  ClaimingNotOpen: 'Claiming is not open',
  AlreadyClaimed: 'Already claimed',
  AmountToLockNegative: 'Amount to lock must be non-negative',
  AmountToLockExceedsClaimable: 'Amount to lock exceeds claimable amount',
  StakingPaused: 'Staking is paused',
  NoStakingContract: 'No staking contract',
  SybilDetected: 'Sybil detected',
  AlreadySelectedOption1: 'Option 1 already selected',
  AlreadySelectedOption2: 'Option 2 already selected',
  NotVesting: 'Not vesting',
  AfterOptionDeadline: 'After option deadline',
}

type ValidatedArgs<T> =
  | { args?: undefined; error: string | null }
  | { args: T; error?: undefined }

export function prepareSwellDaoClaimAirdrop({
  lockRatio,
  airdropResult,
  minimumPearls,
  merkleStatus,
  stakingStatus,
}: {
  lockRatio: number
  airdropResult: AirdropResult | undefined
  minimumPearls: number
  stakingStatus: MerkleStakingState | undefined
  merkleStatus: MerkleDropState | undefined
}): ValidatedArgs<Parameters<SwellDaoClaimAirdrop['call']>> {
  if (!airdropResult || !merkleStatus || !stakingStatus) {
    return { error: null }
  }

  if (airdropResult.isSybil) {
    return { error: AirdropErrors.SybilDetected }
  }

  if (airdropResult.pearls > 0 && airdropResult.pearls < minimumPearls) {
    return { error: AirdropErrors.IneligiblePearls }
  }

  if (!airdropResult.exists || airdropResult.data.totalAmount.eq(0)) {
    return { error: AirdropErrors.NoAirdropData }
  }

  if (!merkleStatus.claimIsOpen) {
    return { error: AirdropErrors.ClaimingNotOpen }
  }

  const airdropData = airdropResult.data
  if (airdropData.merkleRoot !== merkleStatus.merkleRoot) {
    return { error: AirdropErrors.InvalidMerkleRoot }
  }
  if (!airdropData.merkleProof.length) {
    return { error: AirdropErrors.InvalidMerkleProof }
  }

  const { claimableAmount } = merkleClaimable({
    cumulativeAmount: airdropData.cumulativeAmount,
    cumulativeClaimed: airdropResult.cumulativeClaimed,
    totalAmount: airdropData.totalAmount,
  })
  if (claimableAmount.eq(0)) {
    return { error: AirdropErrors.AlreadyClaimed }
  }

  const amountToLock = BigNumber.from(claimableAmount)
    .mul(Math.round(lockRatio * 1e9))
    .div(1e9)

  if (amountToLock.lt(0)) {
    return { error: AirdropErrors.AmountToLockNegative }
  }

  if (amountToLock.gt(claimableAmount)) {
    return { error: AirdropErrors.AmountToLockExceedsClaimable }
  }

  if (amountToLock.gt(0)) {
    if (!stakingStatus.exists) {
      return { error: AirdropErrors.NoStakingContract }
    }
    if (stakingStatus.isPaused) {
      return { error: AirdropErrors.StakingPaused }
    }
  }

  return {
    args: [
      {
        amountToLock,
        cumulativeAmount: airdropData.cumulativeAmount,
        merkleProof: airdropData.merkleProof,
      },
    ],
  }
}
export type PreparedSwellDaoClaimAirdrop = ReturnType<
  typeof prepareSwellDaoClaimAirdrop
>

export function prepareSelectOption1({
  airdropResult,
  airdropUserVesting,
}: {
  airdropResult: AirdropResult | undefined
  airdropUserVesting: AirdropUserVesting | undefined
}): ValidatedArgs<Parameters<SwellDaoSelectOption2['call']>> {
  if (!airdropResult || !airdropUserVesting) {
    return { error: null }
  }

  if (!airdropResult.exists) {
    return { error: AirdropErrors.NoAirdropData }
  }

  if (!airdropUserVesting.selectedOption2) {
    return { error: AirdropErrors.AlreadySelectedOption1 }
  }

  const { vestingAmount } = merkleClaimable({
    cumulativeAmount: airdropResult.data.cumulativeAmount,
    cumulativeClaimed: airdropResult.cumulativeClaimed,
    totalAmount: airdropResult.data.totalAmount,
  })

  if (vestingAmount.eq(0)) {
    return { error: AirdropErrors.NotVesting }
  }

  return { args: [] }
}
export type PreparedSwellDaoSelectOption1 = ReturnType<
  typeof prepareSelectOption1
>

export function prepareSelectOption2({
  airdropResult,
  airdropUserVesting,
  airdropOffchainState,
  nowMs,
}: {
  airdropResult: AirdropResult | undefined
  airdropUserVesting: AirdropUserVesting | undefined
  airdropOffchainState: AirdropOffchainState | undefined
  nowMs: number
}): ValidatedArgs<Parameters<SwellDaoSelectOption2['call']>> {
  if (!airdropResult || !airdropUserVesting || !airdropOffchainState) {
    return { error: null }
  }

  if (!airdropResult.exists) {
    return { error: AirdropErrors.NoAirdropData }
  }

  if (airdropUserVesting.selectedOption2) {
    return { error: AirdropErrors.AlreadySelectedOption2 }
  }

  const deadlineMs = airdropOffchainState.voyageSignatureDeadlineUnix * 1000
  if (nowMs > deadlineMs) {
    return { error: AirdropErrors.AfterOptionDeadline }
  }

  const { vestingAmount } = merkleClaimable({
    cumulativeAmount: airdropResult.data.cumulativeAmount,
    cumulativeClaimed: airdropResult.cumulativeClaimed,
    totalAmount: airdropResult.data.totalAmount,
  })

  if (vestingAmount.eq(0)) {
    return { error: AirdropErrors.NotVesting }
  }

  return { args: [] }
}
export type PreparedSwellDaoSelectOption2 = ReturnType<
  typeof prepareSelectOption2
>
