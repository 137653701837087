export async function tryGetAccount(): Promise<{
  account: string | undefined
  wallet: string | undefined
}> {
  let account: string | undefined
  try {
    const ethereum = window.ethereum as Window['ethereum']
    if (!ethereum || typeof ethereum.request !== 'function') {
      return { account, wallet: undefined }
    }

    // https://docs.metamask.io/wallet/reference/eth_accounts/
    // Returns a list of addresses for the accounts owned by the user. This method requires calling wallet_requestPermissions for permission. We recommend using eth_requestAccounts, which internally calls wallet_requestPermission.
    account = await ethereum.request({ method: 'eth_accounts' })
    if (Array.isArray(account)) {
      account = account[0]
    } else {
      account = undefined
      console.warn('requested accounts is not an array', account)
    }
  } catch (e) {
    console.warn('Failed to get account', e)
  }

  // TODO: get wallet name
  return { account, wallet: 'unknown' }
}
