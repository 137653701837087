import { Token } from '@/types/tokens'
import {
  TOKEN_LIST_EARN_BTC,
  TOKEN_LIST_EARN_ETH,
  TOKEN_LIST_RSWELL,
  TOKEN_LIST_RSWETH,
  TOKEN_LIST_SWBTC,
  TOKEN_LIST_SWELL,
  TOKEN_LIST_SWETH,
} from '@/constants/tokens'
import { ChainId } from '@/constants/chains'
import { useDeploymentSetConfig } from './useDeploymentSetConfig'

export type EarnSettings = {
  backendURL: string
  filterTokenOptions: Token[]
  filterChainOptions: number[]
}

export function useEarnSettings() {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  // const v3BackendLrtUrl = 'http://localhost:8081'
  return {
    backendURL: v3BackendLrtUrl,
    filterTokenOptions: [
      TOKEN_LIST_RSWETH,
      TOKEN_LIST_SWBTC,
      TOKEN_LIST_SWETH,
      TOKEN_LIST_SWELL,
      TOKEN_LIST_RSWELL,
      TOKEN_LIST_EARN_ETH,
    ],
    filterChainOptions: [ChainId.MAINNET],
  }
}
