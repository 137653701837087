import React from 'react'
import styled, { css } from 'styled-components'
import { UserCampaignsSummary } from './types'
import { ROUTES } from '@/constants/routes'
import { ClaimLinkButton } from './PortfolioButtons'
import { FlexRow } from '@/swell-ui/FlexRow'
import blackPearlLogo from '@/assets/images/black-pearl-80x80.png'
import whitePearlLogo from '@/assets/svg/voyage/pearl.svg'
import swellL2PointsLogo from '@/assets/images/swell-l2-logo-56x56.png'
import { AccordionV2 } from '@/swell-ui/AccordionV2'

const bodySmall = css`
  /* Body/small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.42px;
`
const bodyUppercaseSmall = css`
  /* Body/uppercase small */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.9px;
  text-transform: uppercase;
`
const symbioticLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66693e5d00dc4d1921a9395e_Symbiotic.svg'

export function PortfolioCampaignsCards({
  campaignsSummary,
}: {
  campaignsSummary: UserCampaignsSummary
}) {
  return (
    <FlexRow direction="column" gap="12">
      <PointsCampaignAccordion
        title="Voyage"
        pointsName="White Pearls"
        logoURI={whitePearlLogo}
        points={campaignsSummary.voyagePoints}
        claimable={campaignsSummary.voyageClaimable}
        link={ROUTES.DaoVoyage}
      />
      <PointsCampaignAccordion
        title="Wavedrop 1 (08.10.2024)"
        pointsName="Black Pearls"
        logoURI={blackPearlLogo}
        points={campaignsSummary.wavedropOnePoints}
        claimable={campaignsSummary.wavedropOneClaimable}
        link={ROUTES.DaoSwellCity}
      />
      <PointsCampaignAccordion
        title="Wavedrop 2 (17.12.2024)"
        pointsName="Black Pearls"
        logoURI={blackPearlLogo}
        points={campaignsSummary.wavedropTwoPoints}
        claimable={campaignsSummary.wavedropTwoClaimable}
        link={ROUTES.DaoSwellCity}
      />
      <PointsCampaignAccordion
        title="Swell L2"
        pointsName="Ecosystem Points"
        logoURI={swellL2PointsLogo}
        points={campaignsSummary.swellL2Points}
        claimable={campaignsSummary.swellL2Claimable}
        link=""
        logoSquare
      />
      <PointsCampaignAccordion
        title="Symbiotic"
        pointsName="Symbiotic Points"
        logoURI={symbioticLogo}
        points={campaignsSummary.symbioticPoints}
        claimable={campaignsSummary.symbioticClaimable}
        link=""
      />
    </FlexRow>
  )
}

function PointsCampaignAccordion({
  claimable,
  link,
  logoURI,
  points,
  pointsName,
  title,
  logoSquare,
}: {
  title: string
  pointsName: string
  logoURI: string
  logoSquare?: boolean
  points: string
  claimable: string
  link: string
}) {
  const claim = link ? (
    <ClaimLinkButton internal wide to={link} />
  ) : (
    <ClaimLinkButton internal wide to={'#'} disabled label="N/A" />
  )

  return (
    <AccordionV2
      summary={
        <FlexRow gap="12">
          <img
            src={logoURI}
            width="32"
            height="32"
            style={{ borderRadius: logoSquare ? '0' : '50%' }}
          />
          <span>{title}</span>
        </FlexRow>
      }
      details={
        <AccordionDetails direction="column" gap="16">
          <FlexRow direction="column" align="start">
            <span className="body-uppercase-small">Points</span>
            <span className="body-small">
              {points} {points === '-' ? null : pointsName}
            </span>
          </FlexRow>
          <FlexRow direction="column" align="start">
            <span className="body-uppercase-small">Claimable</span>
            <span className="body-small">{claimable}</span>
          </FlexRow>
          {claim}
        </AccordionDetails>
      }
    />
  )
}

const AccordionDetails = styled(FlexRow)`
  .body-small {
    ${bodySmall};
  }

  .body-uppercase-small {
    color: var(--Swell-Lightblue-75, #6e79e9);
    ${bodyUppercaseSmall};
  }
`
