import {
  EarnCampaigns,
  EarnHoldings,
  EarnPortfolioPosition,
} from '@/types/earn'

type Resultify<T> = T extends Record<infer Keys, any>
  ? {
      [key in Keys]:
        | { loaded: true; result: T[key] }
        | { loaded: false; result?: undefined }
    }
  : never

export type EarnHoldingsResults = Resultify<EarnHoldings>

export function makeEarnHoldings({
  campaigns,
  positions,
}: {
  campaigns: EarnCampaigns | undefined
  positions: EarnPortfolioPosition[] | undefined
}): EarnHoldingsResults {
  const res: EarnHoldingsResults = {
    numAssetsHeld: { loaded: false },
    numParticipatingCampaigns: { loaded: false },
    totalAssetBalanceUsd: { loaded: false },
  }

  if (positions) {
    const allBalancesLoaded = positions.every((position) => {
      return position.balanceUsd.exists
    })
    if (allBalancesLoaded) {
      let totalAssetBalance = 0
      let numAssetsHeld = 0
      for (const position of positions) {
        if (position.balanceUsd.exists && position.balanceUsd.balance > 0) {
          totalAssetBalance += position.balanceUsd.balance
          numAssetsHeld++
        }
      }
      res.numAssetsHeld = { loaded: true, result: numAssetsHeld }
      res.totalAssetBalanceUsd = { loaded: true, result: totalAssetBalance }
    }
  }

  if (campaigns) {
    const isParticipatingVoyage = campaigns.voyage.points > 0
    const isParticipatingSwellL2PreLaunch =
      campaigns.swellL2PreLaunch.points > 0
    const isParticipatingBlackPearlsWavedrops =
      campaigns.blackPearlsWavedrops.some((wavedrop) => wavedrop.points > 0)
    const isParticipatingSymbiotic = campaigns.symbiotic.points > 0
    const isParticipatingEigenLayerSeason2 =
      campaigns.eigenLayerSeason2.points > 0

    const numParticipatingCampaigns = [
      isParticipatingVoyage,
      isParticipatingSwellL2PreLaunch,
      isParticipatingBlackPearlsWavedrops,
      isParticipatingSymbiotic,
      isParticipatingEigenLayerSeason2,
    ].filter((participating) => participating).length

    res.numParticipatingCampaigns = {
      loaded: true,
      result: numParticipatingCampaigns,
    }
  }

  return res
}
