import { useNowMs } from '@/hooks/useTimeCountdown'
import { useMemo } from 'react'
import {
  YearnActiveWithdrawalResult,
  yearnActiveWithdrawalMemoV1,
  yearnActiveWithdrawalMemoV2,
} from './yearnWithdraw'
import { YearnWithdrawRequestResult } from '@/state/yearnVault/types'
import { MAX_LOSS_BASIS_POINTS, OVERRIDE_MAX_LOSS } from '@/constants/yearnAera'
import { YearnWithdrawAsset } from '@/types/yearnAera'

export function useYearnMaxLossSettings() {
  const overrideMaxLoss = OVERRIDE_MAX_LOSS
  const maxLossBasisPointsOverride = MAX_LOSS_BASIS_POINTS

  if (!overrideMaxLoss) {
    return { overrideMaxLoss, maxLossBasisPoints: 0 }
  }

  return {
    overrideMaxLoss,
    maxLossBasisPoints: maxLossBasisPointsOverride,
  }
}

export type IYearnActiveWithdrawal = YearnActiveWithdrawalResult & {
  // nothing extra yet
}

export function useYearnActiveWithdrawal({
  withdrawRequest,
  withdrawAsset,
}: {
  withdrawRequest: YearnWithdrawRequestResult | undefined
  withdrawAsset: YearnWithdrawAsset | undefined
}): IYearnActiveWithdrawal | undefined {
  const nowMs = useNowMs()
  const nowUnix = Math.floor(nowMs / 1000)

  const awm = useMemo(() => {
    if (!withdrawRequest || !withdrawAsset) {
      return undefined
    }
    if (withdrawAsset.version === 'v1') {
      const completionWindowSeconds = withdrawAsset.completionWindowSeconds

      return yearnActiveWithdrawalMemoV1({
        completionWindowSeconds,
        nowUnix,
        withdrawRequest,
      })
    }

    return yearnActiveWithdrawalMemoV2({
      nowUnix,
      withdrawRequest,
    })
  }, [nowUnix, withdrawAsset, withdrawRequest])

  if (!awm) {
    return undefined
  }

  return {
    ...awm,
  }
}
