import React, { useMemo, useState } from 'react'
import { PageTitle } from './PageTitle'
import styled, { css } from 'styled-components'
import { FaqSection } from '@/components/SwelL2PreLaunchDeposit/FaqSection'
import { TotalDeposited } from '@/components/SwelL2PreLaunchDeposit/TotalDeposited'
import { EcosystemSection } from '@/components/SwelL2PreLaunchDeposit/EcosystemSection'
import { DepositAndWithdraw } from '@/components/SwelL2PreLaunchDeposit/DepositWidget/DepositSection/DepositAndWithdraw'
import { Tldr } from '@/components/SwelL2PreLaunchDeposit/DepositWidget/DepositSection/Tldr'
import {
  useGetPreDepositRatesUsd,
  useGetPreDepositStats,
  usePreDepositStakes,
  useTokenAllowancesForStaking,
  usePreDepositTokenBalances,
  usePreDepositTokenSupportedStates,
  useTokenAllowancesForZap,
  usePreDepositTokenList,
  useNewPreDepositTokens,
  usePreDepositEcosystemPoints,
} from '@/state/predeposit/hooks'
import { TokenTypeFilter } from '@/components/SwelL2PreLaunchDeposit/DepositWidget/TokensSection/TokenTypeFilter'
import { useMediaQuery } from '@mui/material'
import { useSwellUiTheme } from '@/swell-ui/theme'
import { TitleTypography } from '@/components/SwelL2PreLaunchDeposit/common/TitleTypography'
import { TokenListWidget } from '@/components/SwelL2PreLaunchDeposit/DepositWidget/TokensSection/TokenListWidget'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import { usePreDepositZapContext } from '@/state/predeposit/zap/context'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import {
  PreDepositView,
  VIEWS,
} from '@/components/SwelL2PreLaunchDeposit/DepositWidget/DepositSection/constants'
import { useSwellWeb3 } from '@/swell-web3/core'
import { BigNumber, ethers } from 'ethers'
import { useGetEtherFiLUsdShareUnlockTimeMs } from './hooks'
import { etherFiLiquidUsdAddress } from '@/state/predeposit/constants'
import { useTimeCountdown } from '@/hooks/useTimeCountdown'
import { formatWithConfig } from '@/util/number'
import { Typography } from '@/swell-ui/Typography'
import { FlexRow } from '@/swell-ui/FlexRow'
import swellL2PointsIcon from '@/assets/images/swell-l2-logo-56x56.png'

export function SwelL2() {
  const tokenListQuery = usePreDepositTokenList()
  const tokenList = tokenListQuery.data
  const tokens = tokenList.tokens
  const tags = tokenList.tags

  if (!tokens.length) {
    return null // invalid/irrecoverable state. query should always return at least one token
  }
  const newPreDepositTokensQuery = useNewPreDepositTokens()
  const newPreDepositTokens = newPreDepositTokensQuery.data

  const { account } = useSwellWeb3()
  const { addresses } = useDeploymentSetConfig()

  const [view, setView] = useState<PreDepositView>(VIEWS.DEPOSIT)
  const [selectedTokenAddress, setSelectedTokenAddress] = useState(
    tokens[0].address
  )
  const [selectedTag, setSelectedTag] = useState<string | undefined>()

  const token = useMemo(
    () =>
      tokens.find(
        (t) =>
          ethers.utils.getAddress(t.address) ===
          ethers.utils.getAddress(selectedTokenAddress)
      )!,
    [tokens, selectedTokenAddress]
  )

  const { zapInterfaces, zapTokenToAcceptedToken } = usePreDepositZapContext()
  const zap = zapInterfaces[token.address]

  const tokenSupportedQuery = usePreDepositTokenSupportedStates()
  const tokenSupportedMap = tokenSupportedQuery.data?.tokenSupported

  const tokenAddresses = useMemo<string[]>(() => {
    if (!tokens) {
      return []
    }
    return tokens.map((token) => token.address)
  }, [tokens])

  const balancesQuery = usePreDepositTokenBalances(tokenAddresses)
  const balancesMap = balancesQuery.data?.balances

  const allowancesForStakingQuery = useTokenAllowancesForStaking(tokenAddresses)
  const allowancesForStakingMap = allowancesForStakingQuery.data?.allowances

  const allowancesForZapQuery = useTokenAllowancesForZap(tokenAddresses)
  const allowancesForZapMap = allowancesForZapQuery.data?.allowances

  const allowancesMap = zap ? allowancesForZapMap : allowancesForStakingMap
  const allowance = allowancesMap ? allowancesMap[token.address] : undefined

  const stakesQuery = usePreDepositStakes(tokenAddresses)
  const stakesMap = stakesQuery.data?.stakes

  const statsQuery = useGetPreDepositStats()
  const tokenStats = statsQuery.data?.tokenStats

  const ratesQuery = useGetPreDepositRatesUsd()
  const rates = ratesQuery.data?.rates

  const ecosystemPointsQuery = usePreDepositEcosystemPoints()
  const ecosystemPointsResult = ecosystemPointsQuery.data

  const etherFiLUsdShareUnlockTimeMsQuery =
    useGetEtherFiLUsdShareUnlockTimeMs(account)

  const lusdUnlockDurationBreakdown = useTimeCountdown(
    etherFiLUsdShareUnlockTimeMsQuery.data ?? undefined
  )

  const refresh = () => {
    balancesQuery.mutate()
    allowancesForStakingQuery.mutate()
    allowancesForZapQuery.mutate()
    stakesQuery.mutate()
    etherFiLUsdShareUnlockTimeMsQuery.mutate()
  }
  const afterApprove = refresh
  const afterDeposit = refresh
  const afterWithdraw = refresh

  const { theme } = useSwellUiTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  let widgetMaxAmount: BigNumber | undefined = undefined
  switch (view) {
    case VIEWS.DEPOSIT:
      widgetMaxAmount = balancesMap ? balancesMap[token.address] : undefined
      break
    case VIEWS.WITHDRAW:
      widgetMaxAmount = stakesMap ? stakesMap[token.address] : undefined
      break
    default:
      break
  }

  const acceptedToken = zapTokenToAcceptedToken[token.address] ?? token.address
  const depositSupported = tokenSupportedMap
    ? tokenSupportedMap[acceptedToken]?.depositSupported ?? false
    : undefined
  const withdrawSupported = tokenSupportedMap
    ? tokenSupportedMap[token.address]?.withdrawSupported ?? false
    : undefined

  // the provided custom error overrides any other error messages and prevents submission
  let customError: string | undefined = undefined
  if (
    lusdUnlockDurationBreakdown &&
    view === VIEWS.DEPOSIT &&
    token.address === etherFiLiquidUsdAddress
  ) {
    const { days, hours, minutes, seconds } = lusdUnlockDurationBreakdown
    customError = `You will be able to deposit in ${days}d ${hours}h ${minutes}m ${seconds}s`
  }

  let ecosystemPointsStr = ''
  if (ecosystemPointsResult) {
    ecosystemPointsStr = formatWithConfig(ecosystemPointsResult.points, {
      localize: true,
      precision: 0,
    })
  } else if (!account) {
    ecosystemPointsStr = '-'
  }

  return (
    <PageLayout>
      <PageTitleSpacer />
      <TitleLayout>
        <PageTitle />
      </TitleLayout>
      <Layout>
        <Cell area="deposited" centered={isMdUp}>
          <TotalDepositSpacerAbove />
          <TitleTypography>Total Deposited</TitleTypography>
          <AsyncDiv loading={!tokenListQuery.data || !tokenStats || !rates}>
            {() => (
              <TotalDeposited
                tokenList={tokenList!}
                tokenStats={tokenStats!}
                rates={rates!}
              />
            )}
          </AsyncDiv>
        </Cell>
        <Cell area="eco-points" centered={isMdUp}>
          {isMdUp && <TotalDepositSpacerAbove />}
          <TitleTypography>Ecosystem Points Earned</TitleTypography>
          <EcoPointsLayout gap={'16'} justify={isMdUp ? 'center' : 'start'}>
            <img src={swellL2PointsIcon} width={28} height={28} />
            <AsyncDiv loading={!ecosystemPointsStr}>
              {() => (
                <Typography variant="headline" size="h3">
                  {ecosystemPointsStr}
                </Typography>
              )}
            </AsyncDiv>
          </EcoPointsLayout>
          <TotalDepositSpacerBelow />
        </Cell>
        <Cell area="tokens" grow={isMdUp} vSize={isMdUp ? undefined : '500px'}>
          <TitleTypography>Token type</TitleTypography>
          <TokenTypeFilter
            tokens={tokens}
            tags={tags}
            selected={selectedTag}
            onSelect={(t) => setSelectedTag(t)}
            onClear={() => setSelectedTag(undefined)}
          />
          <TitleTypography>
            {view === VIEWS.DEPOSIT
              ? 'Tokens to deposit'
              : 'Tokens to withdraw'}
          </TitleTypography>
          <TokenListWidget
            tokens={tokens}
            newTokenAddresses={newPreDepositTokens}
            balances={view === VIEWS.DEPOSIT ? balancesMap : stakesMap}
            selectedToken={selectedTokenAddress}
            selectedTag={selectedTag}
            onTokenSelect={(address) => setSelectedTokenAddress(address)}
            isFetching={tokenListQuery.isFetching}
          />
        </Cell>
        <Cell area="deposit">
          <TitleTypography>Deposit</TitleTypography>
          <DepositAndWithdraw
            view={view}
            setView={setView}
            token={token}
            maxAmount={widgetMaxAmount}
            allowance={allowance}
            rate={rates ? rates[token.address] : undefined}
            depositSupported={depositSupported}
            withdrawSupported={withdrawSupported}
            afterApprove={afterApprove}
            afterDeposit={afterDeposit}
            afterWithdraw={afterWithdraw}
            contractToApprove={
              zap ? addresses.preDepositZap : addresses.preDepositStaking
            }
            zap={zap}
            customError={customError}
          />
          <TitleTypography>Depositors tl;dr</TitleTypography>
          <Tldr />
        </Cell>
        <Cell area="ecosystem">
          <EcosystemSection />
        </Cell>
        <Cell area="faq">
          <FaqSection />
        </Cell>
      </Layout>
    </PageLayout>
  )
}

const EcoPointsLayout = styled(FlexRow)`
  /* center content in loading wrappers */
  *[aria-busy='false'] {
    min-width: unset;
  }
`

const Cell = styled.div<{
  area: string
  grow?: boolean
  centered?: boolean
  vSize?: string
}>`
  display: flex;
  flex-flow: column nowrap;
  grid-area: ${(props) => props.area};
  height: ${(props) => props.vSize || 'auto'};

  ${TitleTypography} {
    /* all titles have common margin-bottom */
    margin-bottom: 12px;

    /* on desktop, first-child titles don't have margin-top */
    :not(:first-child) {
      margin-top: 42px;
    }
  }

  /* grow to fit the size of sibling layout cells */
  ${({ grow }) =>
    grow &&
    css`
      align-self: stretch;
    `}

  /* center the content of this cell */
  ${({ centered }) =>
    centered &&
    css`
      align-items: center;
    `}

  /* full width on mobile */
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      width: 100%;
    }
  `}
`

const Layout = styled.div`
  z-index: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  max-width: 419px;
  width: 100%;
  gap: 42px;

  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      max-width: unset;
      gap: unset;

      display: grid;
      grid-template-areas:
        'deposited eco-points'
        'tokens deposit'
        'ecosystem ecosystem'
        'faq faq';
      justify-content: space-between;
      align-items: start;
      grid-template-columns: 355px 355px;
      grid-row-gap: 42px;
      width: 829px;
    }
  `}
`

const TitleLayout = styled.div`
  max-width: 419px;
  width: 100%;

  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      max-width: unset;
      width: unset;
    }
  `}
`

const PageLayout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

const PageTitleSpacer = styled.div`
  height: 50px;
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      height: 88px;
    }
  `}
`

const TotalDepositSpacerAbove = styled.div`
  height: 100px;
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      height: 12px;
    }
  `}
`

const TotalDepositSpacerBelow = styled.div`
  height: 0px;
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      height: 132px;
    }
  `}
`
