import { MaybeRangeAPR } from './apr'

export type EarnStats = {
  totalTvlUsd: number
}
export type EarnHoldings = {
  numAssetsHeld: number
  numParticipatingCampaigns: number
  totalAssetBalanceUsd: number
}
export type UserEarnCampaign = {
  claimableAssets: number
  claimableAssetSymbol: string
  points: number
  isClaimable: boolean
}
export type EarnBlackPearlsWavedrop = UserEarnCampaign & {
  waveNumber: number
  endTimeMs: number
}
export type EarnCampaigns = {
  voyage: UserEarnCampaign
  swellL2PreLaunch: UserEarnCampaign
  blackPearlsWavedrops: EarnBlackPearlsWavedrop[]
  symbiotic: UserEarnCampaign
  eigenLayerSeason2: UserEarnCampaign
}

export type EarnProtocol = {
  name: string
  logoURI: string
}
export type EarnAsset = {
  symbol: string
  logoURI: string
}

export type EarnPortfolioPositionWithoutBalance = {
  protocol: EarnProtocol
  assets: EarnAsset[]
  category: string
  tvlUsd: number
  apr: MaybeRangeAPR
  boostMultiplier: number
  chainId: number
  link: string
  positionName: string
}
export type EarnPositionBalanceType = 'unspecified' | 'pearls' | 'balanceUsd'
export const EarnPositionBalanceType = {
  unspecified: 'unspecified' as const,
  pearls: 'pearls' as const,
  balanceUsd: 'balanceUsd' as const,
}
export type EarnPositionName = string
export type GlobalEarnPositionsMap = Record<
  EarnPositionName,
  EarnPortfolioPositionWithoutBalance
>

export type EarnPositionBalance =
  | {
      exists: true
      balance: number
    }
  | {
      exists: false
      balance?: undefined
    }

export type EarnPortfolioPosition = EarnPortfolioPositionWithoutBalance & {
  pearls: EarnPositionBalance
  balanceUsd: EarnPositionBalance
}

// for deprecated DeFi page; remove soon
export type TokenPoolExternal = {
  id: number
  apy: number[]
  category: string
  chainId: number
  chainName?: string
  chainLogo?: string
  includeTvl: boolean
  link: string
  logo: string
  positionName: string
  positionTokens: string[]
  protocol: string
  tokenIconList: string[]
  tvl: number
}

// for deprecated DeFi page; remove soon
export type AppEarnAPRsResponse = {
  featured: TokenPoolExternal[]
  topApy: TokenPoolExternal[]
  pools: TokenPoolExternal[]
}
