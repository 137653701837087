import { applyRateBigNumberToFloat } from '@/util/big'
import { BigNumber } from 'ethers'
import { parseEther, parseUnits } from 'ethers/lib/utils'

// --
// internal functions
// use case specific APIs below

// converts a specified amount of vault tokens to deposit assets
function vaultTokenToAsset({
  vaultTokenAmount,
  pricePerShare,
  vaultTokenDecimals,
}: {
  vaultTokenAmount: BigNumber
  pricePerShare: BigNumber
  vaultTokenDecimals: number
}) {
  const oneETH = parseEther('1')
  const vaultTokenAmountETH = vaultTokenAmount.mul(
    parseUnits('1', 18 - vaultTokenDecimals)
  )

  let assetAmount = vaultTokenAmountETH.mul(pricePerShare).div(oneETH)
  if (assetAmount.eq(0)) {
    return assetAmount
  }

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const vaultToken = assetToVaultToken({
      assetAmount,
      pricePerShare,
      vaultTokenDecimals,
    })
    if (vaultToken.gte(vaultTokenAmount)) {
      break
    }
    assetAmount = assetAmount.add(1)
  }
  return assetAmount
}

// converts a specified amount of deposit assets to vault tokens
function assetToVaultToken({
  assetAmount,
  pricePerShare,
  vaultTokenDecimals,
}: {
  assetAmount: BigNumber
  pricePerShare: BigNumber
  vaultTokenDecimals: number
}) {
  const oneETH = parseEther('1')
  const numSharesETH = assetAmount.mul(oneETH).div(pricePerShare)
  const numShares = numSharesETH.div(parseUnits('1', 18 - vaultTokenDecimals))
  return numShares
}

// applies the withdraw fee to the pricePerShare
function _pricePerShareWithWithdrawFee({
  pricePerShare,
  withdrawFeeBasisPoints,
}: {
  pricePerShare: BigNumber
  withdrawFeeBasisPoints: number
}): BigNumber {
  const oneHundredPercentBP = 10000
  const unit = parseEther('1')
  return pricePerShare
    .mul(
      unit.sub(
        parseEther((withdrawFeeBasisPoints / oneHundredPercentBP).toString())
      )
    )
    .div(unit)
}

// calculates the exchange rate for a withdrawal
// solverDiscount is a ratio of the solver's fee, 0.0001 means 0.01% fee
// returns a result which includes the atomic price and intermediate values
function withdrawalExchange({
  shares,
  pricePerShare,
  withdrawFeeBasisPoints,
  maxLossBasisPoints,
  vaultTokenDecimals,
}: {
  shares: BigNumber
  pricePerShare: BigNumber
  withdrawFeeBasisPoints: number
  maxLossBasisPoints: number
  vaultTokenDecimals: number
}) {
  const pricePerShareWithFees = _pricePerShareWithWithdrawFee({
    pricePerShare,
    withdrawFeeBasisPoints,
  })

  const receivedWithoutSlippage = vaultTokenToAsset({
    vaultTokenAmount: shares,
    pricePerShare: pricePerShareWithFees,
    vaultTokenDecimals,
  })

  // apply slippage
  const oneHundredPercentBP = 10000
  const receiveAssets = receivedWithoutSlippage
    .mul(oneHundredPercentBP - maxLossBasisPoints)
    .div(oneHundredPercentBP)

  return {
    shares,
    receiveAssets,
    pricePerShareWithFees,
  }
}

// --
// deposit conversions

// converts an amount of deposit assets to vault tokens
export function vaultTokenReceivedForDepositAsset({
  toSendAsset,
  pricePerShare,
  vaultTokenDecimals,
}: {
  toSendAsset: BigNumber
  pricePerShare: BigNumber
  vaultTokenDecimals: number
}): BigNumber {
  return assetToVaultToken({
    assetAmount: toSendAsset,
    pricePerShare,
    vaultTokenDecimals,
  })
}

// converts an amount of vault tokens to deposit assets
export function depositAssetRequiredForVaultToken({
  toReceiveVaultToken,
  pricePerShare,
  vaultTokenDecimals,
}: {
  toReceiveVaultToken: BigNumber
  pricePerShare: BigNumber
  vaultTokenDecimals: number
}): BigNumber {
  return vaultTokenToAsset({
    vaultTokenAmount: toReceiveVaultToken,
    pricePerShare,
    vaultTokenDecimals,
  })
}

// --
// withdrawal conversions

export { _pricePerShareWithWithdrawFee as pricePerShareWithWithdrawFee }

// converts an amount of vault tokens to withdrawal assets
export function withdrawAssetReceivedForVaultToken({
  toSendVaultToken,
  pricePerShare,
  withdrawFeeBasisPoints,
  vaultTokenDecimals,
}: {
  toSendVaultToken: BigNumber
  pricePerShare: BigNumber
  withdrawFeeBasisPoints: number
  vaultTokenDecimals: number
}): BigNumber {
  const exchange = withdrawalExchange({
    shares: toSendVaultToken,
    pricePerShare,
    withdrawFeeBasisPoints,
    maxLossBasisPoints: 0,
    vaultTokenDecimals,
  })
  return exchange.receiveAssets
}

// Given an atomic price, calculates the amount of assets that will be received from a withdrawal
export function minAssetsReceivedFromWithdraw({
  pricePerShare,
  shares,
  withdrawFeeBasisPoints,
  maxLossBasisPoints,
  vaultTokenDecimals,
}: {
  pricePerShare: BigNumber
  shares: BigNumber
  withdrawFeeBasisPoints: number
  maxLossBasisPoints: number
  vaultTokenDecimals: number
}): BigNumber {
  const exchange = withdrawalExchange({
    shares,
    pricePerShare,
    withdrawFeeBasisPoints,
    maxLossBasisPoints,
    vaultTokenDecimals,
  })
  return exchange.receiveAssets
}

// converts an amount of withdrawal assets to vault tokens
export function vaultTokenRequiredForAsset({
  toReceiveAsset,
  pricePerShare,
  withdrawFeeBasisPoints,
  vaultTokenDecimals,
}: {
  toReceiveAsset: BigNumber
  pricePerShare: BigNumber
  withdrawFeeBasisPoints: number
  vaultTokenDecimals: number
}): BigNumber {
  return assetToVaultToken({
    assetAmount: toReceiveAsset,
    pricePerShare: _pricePerShareWithWithdrawFee({
      pricePerShare,
      withdrawFeeBasisPoints,
    }),
    vaultTokenDecimals,
  })
}

export function assetsReceivedFromWithdrawal({
  assetsAtTimeOfRequest,
  shares,
  pricePerShare,
  withdrawFeeBasisPoints,
  vaultTokenDecimals,
}: {
  assetsAtTimeOfRequest: BigNumber
  shares: BigNumber
  pricePerShare: BigNumber
  withdrawFeeBasisPoints: number
  vaultTokenDecimals: number
}): BigNumber {
  const maybeLower = minAssetsReceivedFromWithdraw({
    pricePerShare,
    shares,
    withdrawFeeBasisPoints,
    maxLossBasisPoints: 0,
    vaultTokenDecimals,
  })

  if (maybeLower.lt(assetsAtTimeOfRequest)) {
    return maybeLower
  }

  return assetsAtTimeOfRequest
}

// --
// USD conversions

// converts depositAsset -> USD
export function yearnDepositAssetValueUsd({
  depositAssetUsdMarketRate,
  base,
  depositAssetAmount,
}: {
  depositAssetAmount: BigNumber
  depositAssetUsdMarketRate: number
  base: { decimals: number }
}) {
  return applyRateBigNumberToFloat(
    depositAssetAmount,
    depositAssetUsdMarketRate,
    { base }
  )
}

// converts vaultToken -> depositAsset -> USD
export function yearnVaultTokenToUsd({
  vaultTokenAmount,
  depositAssetUsdMarketRate,
  pricePerShare,
  base,
  vaultTokenDecimals,
}: {
  depositAssetUsdMarketRate: number
  vaultTokenAmount: BigNumber
  pricePerShare: BigNumber
  base: { decimals: number }
  vaultTokenDecimals: number
}) {
  const depositAssetAmount = vaultTokenToAsset({
    vaultTokenAmount,
    pricePerShare,
    vaultTokenDecimals,
  })
  return yearnDepositAssetValueUsd({
    depositAssetAmount,
    depositAssetUsdMarketRate,
    base,
  })
}
