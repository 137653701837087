import { Button } from '@/swell-ui/Button'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

type InternalOrExternalLink =
  | {
      internal: true
      to: string
      href?: undefined
    }
  | {
      internal: false
      href: string
      to?: undefined
    }

export function ClaimLinkButton(
  props: InternalOrExternalLink & {
    disabled?: boolean
    label?: string
    wide?: boolean
  }
) {
  const inner = (
    <RoundButton disabled={props.disabled} variant="primary" wide={props.wide}>
      {props.label ?? 'Claim'}
    </RoundButton>
  )

  if (props.disabled) {
    return inner
  }

  if (props.internal) {
    return (
      <Link
        to={props.to}
        style={{
          width: props.wide ? '100%' : 'auto',
        }}
      >
        {inner}
      </Link>
    )
  }

  return (
    <a
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        width: props.wide ? '100%' : 'auto',
      }}
    >
      {inner}
    </a>
  )
}

const RoundButton = styled(Button)<{ wide?: boolean }>`
  display: flex;
  width: ${({ wide }) => (wide ? '100%' : '140px')};
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  height: 38px;
  font-size: 14px;
`

export function AddMoreButton(props: InternalOrExternalLink) {
  const { internal } = props

  const btn = (
    <GhostButton>
      <PlusCircle />
    </GhostButton>
  )

  return (
    <>
      {internal ? (
        <Link to={props.to}>{btn}</Link>
      ) : (
        <a
          href={props.href}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#fff' }}
        >
          {btn}
        </a>
      )}
    </>
  )
}

const GhostButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 100px;
  background: transparent;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`

function PlusCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <g clip-path="url(#clip0_11149_1910)">
        <path
          d="M8.5 2C7.21442 2 5.95772 2.38122 4.8888 3.09545C3.81988 3.80968 2.98676 4.82484 2.49479 6.01256C2.00282 7.20028 1.87409 8.50721 2.1249 9.76809C2.3757 11.029 2.99477 12.1872 3.90381 13.0962C4.81285 14.0052 5.97104 14.6243 7.23192 14.8751C8.49279 15.1259 9.79973 14.9972 10.9874 14.5052C12.1752 14.0132 13.1903 13.1801 13.9046 12.1112C14.6188 11.0423 15 9.78558 15 8.5C14.9979 6.77675 14.3123 5.1247 13.0938 3.90618C11.8753 2.68766 10.2233 2.00215 8.5 2ZM11 9H9V11C9 11.1326 8.94732 11.2598 8.85356 11.3536C8.75979 11.4473 8.63261 11.5 8.5 11.5C8.36739 11.5 8.24022 11.4473 8.14645 11.3536C8.05268 11.2598 8 11.1326 8 11V9H6C5.86739 9 5.74022 8.94732 5.64645 8.85355C5.55268 8.75979 5.5 8.63261 5.5 8.5C5.5 8.36739 5.55268 8.24021 5.64645 8.14645C5.74022 8.05268 5.86739 8 6 8H8V6C8 5.86739 8.05268 5.74021 8.14645 5.64645C8.24022 5.55268 8.36739 5.5 8.5 5.5C8.63261 5.5 8.75979 5.55268 8.85356 5.64645C8.94732 5.74021 9 5.86739 9 6V8H11C11.1326 8 11.2598 8.05268 11.3536 8.14645C11.4473 8.24021 11.5 8.36739 11.5 8.5C11.5 8.63261 11.4473 8.75979 11.3536 8.85355C11.2598 8.94732 11.1326 9 11 9Z"
          fill="#6E79E9"
        />
      </g>
      <defs>
        <clipPath id="clip0_11149_1910">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
