import {
  NucleusAllowances,
  NucleusBalances,
  NucleusVaultAuth,
  NucleusVaultState,
} from '@/state/nucleusVault/types'
import { FlexRow } from '@/swell-ui/FlexRow'
import { useSwellWeb3 } from '@/swell-web3/core'
import { Token } from '@/types/tokens'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DepositAssetInput } from '../NucleusInputs'
import {
  NucleusApproveAssetForDepositButton,
  NucleusDepositButton,
} from '../NucleusButtons'
import {
  NucleusErrors,
  prepareNucleusApproveAssetForDeposit,
  prepareNucleusDeposit,
} from '../nucleusCalls'
import {
  NucleusApproveAssetForDeposit,
  NucleusDeposit,
} from '@/state/nucleusVault/hooks'
import { BigNumber } from 'ethers'
import { trimDecimalPlaces } from '@/util/number'
import { parseUnits } from 'ethers/lib/utils'
import { TOKEN_LIST_ETH } from '@/constants/tokens'
import { useTransactionContext } from '@/state/transactions/context'
import { TokenSelectMenu } from '@/components/TokenSelectMenu'
import { useMediaQuery } from '@mui/material'
import { NucleusPositionsTable } from '../NucleusPositionsTable'
import {
  NucleusPosition,
  NucleusRates,
  NucleusSupportedTokenMap,
  NucleusVault,
} from '@/types/nucleus'
import { makeNucleusDepositSummary } from '../nucleusFormatting'
import { AvailableChipV2 } from '@/components/StakingWidget/AvailableChipV2'

const DEPOSIT_SLIPPAGE = 0.01 // implies minMint=0
const SELECT_ONLY_SUPPORTED_TOKENS = true

export function NucleusDepositView({
  balances,
  allowances,
  positions,
  deposit,
  approveAssetForDeposit,
  auth,
  supportedAssets,
  rates,
  vaultState,
  baseAssetRateUsd,
  vault,
}: {
  vault: NucleusVault
  deposit: NucleusDeposit
  approveAssetForDeposit: NucleusApproveAssetForDeposit

  balances: NucleusBalances | undefined
  allowances: NucleusAllowances | undefined
  positions: NucleusPosition[] | undefined
  auth: NucleusVaultAuth | undefined
  supportedAssets: NucleusSupportedTokenMap | undefined
  vaultState: NucleusVaultState | undefined
  rates: NucleusRates | undefined
  baseAssetRateUsd: number | undefined
}) {
  const is900Up = useMediaQuery('(min-width:900px)')

  const { anyTransactionInProgress } = useTransactionContext()
  const { account } = useSwellWeb3()
  const [touched, setTouched] = useState(false)
  const [selectedToken, setSelectedToken] = useState<Token>(
    vault.depositAssets[0]
  )
  const [inputValue, setInputValue] = useState('')

  // clear input when tx is fulfilled
  useEffect(() => {
    if (deposit.status === deposit.STATUS.FULFILLED) {
      setInputValue('')
    }
  }, [deposit.status, deposit.STATUS.FULFILLED])

  let assetAmount: BigNumber | undefined
  if (inputValue) {
    assetAmount = parseUnits(
      trimDecimalPlaces(inputValue, selectedToken.decimals),
      selectedToken.decimals
    )
  }

  let assetBalance: BigNumber | undefined
  if (balances) {
    assetBalance = balances.assets?.[selectedToken.address]
  }

  const requiresApproval = selectedToken.symbol !== TOKEN_LIST_ETH.symbol

  let depositAssetAllowanceForVault: BigNumber | undefined
  if (allowances) {
    depositAssetAllowanceForVault =
      allowances.assetsForDeposit?.[selectedToken.address]
  }

  let vaultTokenRateInQuote: BigNumber | undefined
  if (rates) {
    vaultTokenRateInQuote = rates.vaultTokenQuoteRates?.[selectedToken.address]
  }

  const preparedApprove = prepareNucleusApproveAssetForDeposit({
    assetAmount,
    assetAddress: selectedToken.address,
    assetBalance,
  })
  const preparedDeposit = prepareNucleusDeposit({
    auth,
    depositAmount: assetAmount,
    depositAssetAllowanceForVault,
    depositAssetBalance: assetBalance,
    depositToken: selectedToken,
    requiresApproval,
    slippage: DEPOSIT_SLIPPAGE,
    supportedAssets,
    vaultState,
    vaultTokenRateInQuote,
    baseAsset: vault.baseAsset,
  })

  const accountAndNoBalances = Boolean(account && !balances)
  const noRates = !rates
  const preventInteraction =
    anyTransactionInProgress || accountAndNoBalances || noRates

  const mustApproveDeposit =
    preparedDeposit.error === NucleusErrors.InsufficientAllowance

  let buttonNode: React.ReactNode
  if (mustApproveDeposit) {
    buttonNode = (
      <NucleusApproveAssetForDepositButton
        approveAssetForDeposit={approveAssetForDeposit}
        prepared={preparedApprove}
        preventInteraction={preventInteraction}
      />
    )
  } else {
    buttonNode = (
      <NucleusDepositButton
        deposit={deposit}
        prepared={preparedDeposit}
        preventInteraction={preventInteraction}
      />
    )
  }

  let errorMessage: string | null = null
  if (touched) {
    if (mustApproveDeposit) {
      if (preparedApprove.error) {
        errorMessage = preparedApprove.error
      }
    } else if (preparedDeposit.error) {
      errorMessage = preparedDeposit.error
    }
  }

  const tokenBalances = [] as Token[]
  if (balances) {
    for (const token of vault.depositAssets) {
      tokenBalances.push({
        ...token,
        balance: balances.assets?.[token.address],
      })
    }
  }

  const depositSummary = makeNucleusDepositSummary({
    assetAmount,
    balances,
    baseAssetRateUsd,
    rates,
    selectedToken,
    vaultToken: vault.vaultToken,
    base: vault.baseAsset,
  })

  let depositTokens = [...vault.depositAssets]
  if (SELECT_ONLY_SUPPORTED_TOKENS) {
    depositTokens = depositTokens.filter(
      (t) => supportedAssets?.[t.address].isSupported
    )
  }

  const depositWidget = (
    <FlexRow direction="column" maxWidth="353px">
      <FlexRow justify="space-between">
        <AvailableChipV2 available={depositSummary.availableBalance} />
        <TokenSelectMenu
          title="Deposit Asset"
          selectedToken={selectedToken}
          setSelectedToken={setSelectedToken}
          tokens={depositTokens}
        />
      </FlexRow>
      <div style={{ height: '32.5px' }} />
      <DepositAssetInput
        setTouched={setTouched}
        inputValue={inputValue}
        setInputValue={setInputValue}
        errorMessage={errorMessage}
        balances={balances}
        disabled={preventInteraction}
        depositAsset={selectedToken}
        usdLabel={depositSummary.depositUsd}
      />
      {buttonNode}
    </FlexRow>
  )

  const positionsTable = <NucleusPositionsTable positions={positions} />

  if (is900Up) {
    return (
      <Layout gap="62" height="208px">
        {depositWidget}
        {positionsTable}
      </Layout>
    )
  }

  return (
    <Layout gap="48" direction="column">
      {depositWidget}
      {positionsTable}
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  > div:first-child {
    flex-shrink: 0;
  }
`
