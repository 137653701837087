import { useErrors } from '@/state/errors/context'
import { useSwellWeb3 } from '@/swell-web3/core'
import { extractErrorCode } from '@/util/errors'
import { parseJsError } from '@/util/jsError'
import { useEffect } from 'react'

export function useWeb3ErrorReporting() {
  const { onError } = useErrors()
  const { provider } = useSwellWeb3()

  useEffect(() => {
    if (!provider) return

    provider.on('error', (error) => {
      const [, err] = parseJsError(error)
      onError({
        message: err.message,
        stack: err.stack || '',
        code: extractErrorCode(err) || '',
        type: 'web3_provider',
        data: { originalError: String(error) },
        name: err.name,
      })
    })

    return () => {
      provider.off('error')
    }
  }, [provider, onError])
}
