import { FlexRow } from '@/swell-ui/FlexRow'
import { Tooltip } from '@/swell-ui/Tooltip'
import { NucleusTokens } from '@/types/nucleus'
import { Token } from '@/types/tokens'
import { useMediaQuery } from '@mui/material'
import styled from 'styled-components'

const TOKEN_ICON_SIZE = 24

export function NucleusVaultTitle({
  depositAssets: depositTokens,
  vaultTitle,
  supportedTokens,
  scrollToId,
}: {
  vaultTitle: string
  depositAssets: Token[]
  supportedTokens: NucleusTokens | undefined
  scrollToId: string
}) {
  const is900Up = useMediaQuery('(min-width:900px)')

  return (
    <Layout
      gap={is900Up ? '24' : '12'}
      direction={is900Up ? 'row' : 'column'}
      justify={is900Up ? 'space-between' : ''}
      align={is900Up ? '' : 'flex-start'}
    >
      <span className="nv-title" id={scrollToId}>
        {vaultTitle}
      </span>
      <FlexRow gap="6">
        {depositTokens.map((token, i) => (
          <Tooltip key={i} title={token.name}>
            <DepositToken token={token} supportedTokens={supportedTokens} />
          </Tooltip>
        ))}
      </FlexRow>
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  .nv-title {
    white-space: nowrap;
    color: var(--Swell-White-50, #fff);
    /* Headlines/H6 */
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 33.8px */
    letter-spacing: -0.78px;
  }

  .nv-deposit-token {
    width: ${TOKEN_ICON_SIZE}px;
    height: ${TOKEN_ICON_SIZE}px;
    border-radius: 50%;

    &.unsupported {
      opacity: 0.35;
    }
  }
`

function DepositToken({
  token,
  supportedTokens,
}: {
  token: Token
  supportedTokens: NucleusTokens | undefined
}) {
  let supported = true
  if (supportedTokens) {
    supported = !!(supportedTokens.deposit[token.address]?.isSupported ?? false)
  }
  let className = 'nv-deposit-token'
  if (!supported) {
    className += ' unsupported'
  }
  return (
    <img
      className={className}
      src={token.logoURI}
      alt={token.symbol}
      width={TOKEN_ICON_SIZE}
      height={TOKEN_ICON_SIZE}
    />
  )
}
