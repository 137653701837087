import { StakingWidget } from '@/components/StakingWidget'

function StakingPage() {
  return (
    <>
      <StakingWidget stakingEnabled={{ isLoading: false, value: true }} />
    </>
  )
}

export { StakingPage }
