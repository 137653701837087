import {
  NucleusApproveVaultTokenForAtomicQueue,
  NucleusRequestWithdraw,
} from '@/state/nucleusVault/hooks'
import {
  NucleusAllowances,
  NucleusBalances,
  NucleusSharesState,
  NucleusVaultState,
} from '@/state/nucleusVault/types'
import { useTransactionContext } from '@/state/transactions/context'
import { FlexRow } from '@/swell-ui/FlexRow'
import { useSwellWeb3 } from '@/swell-web3/core'
import { Token } from '@/types/tokens'
import { trimDecimalPlaces } from '@/util/number'
import { BigNumber } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  NucleusErrors,
  prepareNucleusApproveVaultTokenForAtomicQueue,
  prepareNucleusRequestWithdraw,
} from '../nucleusCalls'
import { INucleusActiveWithdrawal } from '../nucleusHooks'
import { useNowMs } from '@/hooks/useTimeCountdown'
import { TokenSelectMenu } from '@/components/TokenSelectMenu'
import { WithdrawAssetInput } from '../NucleusInputs'
import {
  NucleusApproveVaultTokenForAtomicQueueButton,
  NucleusRequestWithdrawButton,
} from '../NucleusButtons'
import { useMediaQuery } from '@mui/material'
import { ColoredHeadingTypography } from '@/swell-ui/Typography/typographyPresets'
import { NucleusRates, NucleusVault } from '@/types/nucleus'
import {
  makeNucleusVaultWithdrawalsSummary,
  nucleusWithdrawRequestSummary,
} from '../nucleusFormatting'
import { AvailableChipV2 } from '@/components/StakingWidget/AvailableChipV2'
import { ScrollableYArea } from '@/swell-ui/ScrollableArea'

export function NucleusWithdrawView({
  requestWithdraw,
  approveVaultTokenForAtomicQueue,
  allowances,
  balances,
  activeWithdrawal,
  vault,
  rates,
  sharesState,
  vaultState,
  baseAssetRateUsd,
  goToClaim,
}: {
  activeWithdrawal: INucleusActiveWithdrawal | undefined
  vault: NucleusVault

  goToClaim: () => void

  requestWithdraw: NucleusRequestWithdraw
  approveVaultTokenForAtomicQueue: NucleusApproveVaultTokenForAtomicQueue

  // user
  allowances: NucleusAllowances | undefined
  balances: NucleusBalances | undefined
  sharesState: NucleusSharesState | undefined

  // vault
  rates: NucleusRates | undefined
  vaultState: NucleusVaultState | undefined
  baseAssetRateUsd: number | undefined
}) {
  // switch to claim tab when withdrawal is successfully requested
  useEffect(() => {
    if (
      activeWithdrawal?.status === 'Requesting' &&
      requestWithdraw.status === requestWithdraw.STATUS.FULFILLED
    ) {
      goToClaim()
    }
  }, [
    activeWithdrawal?.status,
    goToClaim,
    requestWithdraw.STATUS.FULFILLED,
    requestWithdraw.status,
  ])

  const is900Up = useMediaQuery('(min-width:900px)')

  const { anyTransactionInProgress } = useTransactionContext()
  const { account } = useSwellWeb3()
  const [touched, setTouched] = useState(false)
  const [selectedToken, setSelectedToken] = useState<Token>(
    vault.withdrawAssets[0]
  )
  const [inputValue, setInputValue] = useState('')
  const nowMs = useNowMs()

  let fromAmount: BigNumber | undefined
  if (inputValue) {
    fromAmount = parseUnits(
      trimDecimalPlaces(inputValue, vault.vaultToken.decimals),
      vault.vaultToken.decimals
    )
  }

  // clear input when tx is fulfilled
  useEffect(() => {
    if (requestWithdraw.status === requestWithdraw.STATUS.FULFILLED) {
      setInputValue('')
    }
  }, [requestWithdraw.status, requestWithdraw.STATUS.FULFILLED])

  let vaultAllowanceForAtomicQueue: BigNumber | undefined
  if (allowances) {
    vaultAllowanceForAtomicQueue = allowances.vaultTokenForAtomicQueue
  }

  const preparedApprove = prepareNucleusApproveVaultTokenForAtomicQueue({
    vaultTokenAmount: fromAmount,
    vaultTokenBalance: balances?.vaultToken,
  })
  const preparedRequestWithdrawal = prepareNucleusRequestWithdraw({
    nowMs,
    offerAmount: fromAmount,
    activeWithdrawal,
    sharesState,
    vaultAllowanceForAtomicQueue: vaultAllowanceForAtomicQueue,
    vaultTokenBalance: balances?.vaultToken,
    vaultTokenRateInQuote: rates?.vaultTokenQuoteRates[selectedToken.address],
    wantAsset: selectedToken,
    vaultState,
    baseAsset: vault.baseAsset,
  })

  const accountAndNoBalances = Boolean(account && !balances)
  const noRates = !rates
  const preventInteraction =
    anyTransactionInProgress || accountAndNoBalances || noRates

  const mustApproveWithdrawal =
    preparedRequestWithdrawal.error === NucleusErrors.InsufficientAllowance

  let buttonNode: React.ReactNode
  if (mustApproveWithdrawal) {
    buttonNode = (
      <NucleusApproveVaultTokenForAtomicQueueButton
        approveVaultTokenForAtomicQueue={approveVaultTokenForAtomicQueue}
        prepared={preparedApprove}
        preventInteraction={preventInteraction}
      />
    )
  } else {
    buttonNode = (
      <NucleusRequestWithdrawButton
        prepared={preparedRequestWithdrawal}
        preventInteraction={preventInteraction}
        requestWithdraw={requestWithdraw}
      />
    )
  }

  let errorMessage: string | null = null
  if (touched) {
    if (mustApproveWithdrawal) {
      if (preparedApprove.error) {
        errorMessage = preparedApprove.error
      }
    } else if (preparedRequestWithdrawal.error) {
      errorMessage = preparedRequestWithdrawal.error
    }
  }

  const summary = nucleusWithdrawRequestSummary({
    balances,
    fromAmount,
    baseAsset: vault.baseAsset,
    baseAssetRateUsd,
    rates,
    vaultToken: vault.vaultToken,
  })

  const tokenBalances: Token[] = []
  tokenBalances.push({
    ...vault.vaultToken,
    balance: balances?.vaultToken,
  })

  const withdrawWidget = (
    <FlexRow direction="column" maxWidth="353px">
      <FlexRow justify="space-between">
        <AvailableChipV2 available={summary.availableBalance} />
        <TokenSelectMenu
          title="Withdraw Asset"
          selectedToken={selectedToken}
          setSelectedToken={setSelectedToken}
          tokens={vault.withdrawAssets}
        />
      </FlexRow>
      <div style={{ height: '32.5px' }} />
      <WithdrawAssetInput
        setTouched={setTouched}
        inputValue={inputValue}
        setInputValue={setInputValue}
        errorMessage={errorMessage}
        balances={balances}
        disabled={preventInteraction}
        vaultToken={vault.vaultToken}
        usdLabel={summary.withdrawUsd}
      />
      {buttonNode}
    </FlexRow>
  )

  const withdrawalsSummary = makeNucleusVaultWithdrawalsSummary({
    vaultState,
  })

  const withdrawInfo = (
    <NucleusWithdrawInfo
      processingTime={withdrawalsSummary.processingTime}
      solverFee={withdrawalsSummary.solverFee}
    />
  )

  if (is900Up) {
    return (
      <Layout gap="62" height="208px">
        {withdrawWidget}
        {withdrawInfo}
      </Layout>
    )
  }

  return (
    <Layout gap="48" direction="column" height="100%">
      {withdrawWidget}
      {withdrawInfo}
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  > div:first-child {
    flex-shrink: 0;
  }
`

function NucleusWithdrawInfo({
  processingTime,
  solverFee,
}: {
  processingTime: string
  solverFee: string
}) {
  return (
    <ScrollableYArea containerType="flex">
      <Grid>
        <div>
          <ColoredHeadingTypography>Processing time</ColoredHeadingTypography>
        </div>
        <div>
          <span>{processingTime}</span>
        </div>
        <div>
          <ColoredHeadingTypography>Solver fee</ColoredHeadingTypography>
        </div>
        <div>
          <span>{solverFee}</span>
        </div>
        <div>
          <ColoredHeadingTypography>Withdrawal notice</ColoredHeadingTypography>
        </div>
        <div />
        <div className="wide">
          <p>
            Only one withdrawal may be initiated at a time. All withdrawals are
            actioned by solvers and any fulfilled withdrawal requests will be
            sent to your withdrawal address within the processing time. While in
            the withdrawal queue you will not earn any yield or points. At times
            withdrawals will be unable to be fulfilled due to impermanent loss
            or market volatility. If this happens, please re-queue your
            withdrawal at the new rate.
          </p>
        </div>
      </Grid>
    </ScrollableYArea>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 32px;
  row-gap: 16px;
  font-size: 14px;
  color: #fff;

  .wide {
    grid-column: 1 / span 2;
  }

  p {
    margin: 0;
  }
  ${ColoredHeadingTypography} {
    font-size: 12px;
  }
`
