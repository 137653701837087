import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components/macro'
import { CheckCircleIcon } from '@swell-ui/icons/CheckCircleIcon'
import { ExternalLinkIcon } from '@swell-ui/icons/ExternalLinkIcon'
import { CloseIcon } from '@swell-ui/icons/CloseIcon'
import { FlexRow } from '@swell-ui/FlexRow'
import { Typography } from '@swell-ui/Typography'
import { useChainInfo } from '@/state/deployments/hooks'

interface StakingConfirmationProgressWidgetProps {
  confirming: boolean
  complete: boolean
  open: boolean
  message: React.ReactNode
  onClose?: () => any
  pending: boolean
  title: string
  txHash?: string
}

const progress = keyframes`
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
`

const Wrapper = styled.div`
  position: fixed;
  left: 20px;
  bottom: 56px;
  min-width: 256px;
  max-width: 356px;
  overflow: hidden;
`

const Inner = styled.div<any>`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  border: ${({ theme }) => theme.toast.border};
  background: ${({ theme }) => theme.toast.bgColor};

  ${({ complete, theme }) =>
    complete &&
    `
    background: ${theme.toast.altBgColor};
  `}
`

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 2px;
  right: 4px;

  path {
    stroke: ${({ theme }) => theme.mainColor};
  }
`

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  width: 14px;
  height: 14px;

  path {
    stroke: ${({ theme }) => theme.mainColor};
  }
`

const StyledExternalLinkIcon = styled(ExternalLinkIcon)`
  width: 14px;
  height: 14px;

  path {
    stroke: ${({ theme }) => theme.mainColor};
  }
`

const Message = styled.div`
  color: ${({ theme }) => theme.toast.messageColor};

  a {
    color: ${({ theme }) => theme.mainColor};
  }
`

const ProgressBarContainer = styled.div`
  width: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.black['50']};
`

const ProgressBar = styled.div`
  height: 6px;
  border-radius: 6px;
`

const ProgressBarConfirming = styled(ProgressBar)`
  width: 100%;
  background: #ffa608;
`

const ProgressBarPending = styled(ProgressBar)`
  background: #009e49;
  animation: ${progress} 40s cubic-bezier(0.1, 0.1, 0.01, 1) forwards;
`

const Link = styled.a`
  color: ${({ theme }) => theme.colors.white['50']};
  text-decoration: underline !important;

  &:hover {
    color: ${({ theme }) => theme.colors.white['150']};
  }
`

function StakingConfirmationProgressWidget({
  complete,
  confirming,
  open,
  pending,
  onClose,
  title,
  message,
  txHash,
}: StakingConfirmationProgressWidgetProps) {
  const { explorer } = useChainInfo()
  const transactionProgressEl = useRef(
    document.getElementById('transaction-progress-portal')
  ).current as Element

  if (!open) {
    return null
  }

  return ReactDOM.createPortal(
    <Wrapper>
      {complete && <StyledCloseIcon aria-label="close" onClick={onClose} />}
      <Inner complete={complete}>
        <FlexRow gap="5">
          <Typography variant="body" size="xsmall">
            {title}
          </Typography>
          {!complete && txHash && (
            <Link
              href={`${explorer}/tx/${txHash}`}
              target="_blank"
              rel="noreferrer"
            >
              <FlexRow>
                <StyledExternalLinkIcon />
              </FlexRow>
            </Link>
          )}
          {complete && <StyledCheckCircleIcon />}
        </FlexRow>
        <Message>
          <Typography variant="body" size="xsmall">
            {message}
            {complete && txHash && (
              <>
                <br />
                <Link
                  href={`${explorer}/tx/${txHash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View transaction
                </Link>
              </>
            )}
          </Typography>
        </Message>
        {!complete && (
          <ProgressBarContainer>
            {confirming && <ProgressBarConfirming />}
            {pending && <ProgressBarPending />}
          </ProgressBarContainer>
        )}
      </Inner>
    </Wrapper>,
    transactionProgressEl
  )
}

export { StakingConfirmationProgressWidget }
