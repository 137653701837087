import { displayCryptoLocale } from '@/util/displayCrypto'
import styled, { useTheme } from 'styled-components'
import { Token } from '@/types/tokens'
import { FlexRow } from '@/swell-ui/FlexRow'
import { YearnPendingWithdrawInfo } from '../ExchangeInfo/YearnCancelWithdrawInfo'
import { Divider } from '@/swell-ui/Divider'
import { yearnWithdrawTypographyCSS } from './common'
import { YearnWithdrawRequest } from '@/state/yearnVault/types'
import { assetsReceivedFromWithdrawal } from '../yearnConversions'
import { BigNumber } from 'ethers'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { YearnAeraTransactionFees, YearnWithdrawPendingSummary } from '../types'
import { YearnWithdrawAsset } from '@/types/yearnAera'
import { ProgressBar } from '@/swell-ui/ProgressBar'

const TOKEN_IMG_SIZE_PX = 32

export function YearnWithdrawPending({
  withdrawAsset,
  withdrawRequest,
  vaultToken,
  withdrawAssetToken,
  pricePerShare,
  transactionFees,
  pendingSummary,
}: {
  withdrawRequest: YearnWithdrawRequest
  withdrawAsset: YearnWithdrawAsset | undefined
  vaultToken: Token
  withdrawAssetToken: Token
  pricePerShare: BigNumber | undefined
  transactionFees: Partial<YearnAeraTransactionFees>
  pendingSummary: YearnWithdrawPendingSummary
}) {
  const { assetsAtTimeOfRequest, maturityUnix, shares } = withdrawRequest

  let receivingAmountStr: string | undefined
  if (pricePerShare && withdrawAsset) {
    const receiveAssets = assetsReceivedFromWithdrawal({
      assetsAtTimeOfRequest,
      shares,
      pricePerShare,
      withdrawFeeBasisPoints: withdrawAsset.withdrawFeeBasisPoints,
      vaultTokenDecimals: vaultToken.decimals,
    })
    receivingAmountStr = displayCryptoLocale(
      receiveAssets,
      withdrawAssetToken.decimals,
      { btc: true }
    )
  }
  const withdrawingAmountStr = displayCryptoLocale(
    shares,
    vaultToken.decimals,
    { btc: true }
  )

  const deadlineMs = maturityUnix * 1000

  let withdrawRequestDelayMs: number | undefined
  if (withdrawAsset) {
    withdrawRequestDelayMs = withdrawAsset.withdrawDelaySeconds * 1000
  }

  const hidden = withdrawRequestDelayMs === undefined

  return (
    <Layout style={{ visibility: hidden ? 'hidden' : 'visible' }}>
      <span className="title">Pending</span>
      <PendingViz
        withdrawingToken={vaultToken}
        receivingToken={withdrawAssetToken}
        withdrawingAmount={withdrawingAmountStr}
        receivingAmount={receivingAmountStr}
        deadlineMs={deadlineMs}
        withdrawRequestDelayMs={withdrawRequestDelayMs ?? 0}
      />
      <Divider />
      <YearnPendingWithdrawInfo
        pendingSummary={pendingSummary}
        transactionFees={transactionFees}
      />
    </Layout>
  )
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${yearnWithdrawTypographyCSS}

  .target-price {
    color: var(--white-125, #b0b0b0);
    /* Body/xsmall bold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 19.2px */
    height: 24px;
  }
`

function PendingViz({
  withdrawingToken,
  deadlineMs,
  receivingAmount,
  receivingToken,
  withdrawingAmount,
  withdrawRequestDelayMs,
}: {
  withdrawingToken: Token
  receivingToken: Token
  withdrawingAmount: string
  receivingAmount: string | undefined
  deadlineMs: number
  withdrawRequestDelayMs: number
}) {
  return (
    <VizLayout>
      <FlexRow direction="row" justify="space-between">
        <FlexRow direction="column" align="start" gap="8" width="auto">
          <span className="subheading">Withdrawing</span>
          <span className="big">{withdrawingAmount}</span>
          <FlexRow gap="8" justify="start">
            <TokenIcon
              width={TOKEN_IMG_SIZE_PX}
              height={TOKEN_IMG_SIZE_PX}
              src={withdrawingToken.logoURI}
            />
            <span className="med">{withdrawingToken.symbol}</span>
          </FlexRow>
        </FlexRow>
        <div>
          <ArrowRight />
        </div>
        <FlexRow
          direction="column"
          align="start"
          gap="8"
          width="auto"
          style={{ minWidth: '130px' }}
        >
          <span className="subheading">Receiving</span>
          <AsyncDiv loading={!receivingAmount}>
            {() => <span className="big">{receivingAmount}</span>}
          </AsyncDiv>
          <FlexRow gap="8" justify="start">
            <TokenIcon
              width={TOKEN_IMG_SIZE_PX}
              height={TOKEN_IMG_SIZE_PX}
              src={receivingToken.logoURI}
            />
            <span className="med">{receivingToken.symbol}</span>
          </FlexRow>
        </FlexRow>
      </FlexRow>
      <Divider />
      <ProgressBar
        deadlineMs={deadlineMs}
        durationMs={withdrawRequestDelayMs}
        title="Time remaining"
        titleAlign="center"
      />
    </VizLayout>
  )
}

const VizLayout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  white-space: nowrap;

  /* center content in loading wrappers */
  *[aria-busy='false'] {
    min-width: unset;
  }

  .progress-bar-base {
    > span:first-child {
      color: var(--white-125, #b0b0b0);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 19.2px */
      letter-spacing: 0.9px;
      text-transform: uppercase;
    }
  }
`

const TokenIcon = styled.img`
  width: ${TOKEN_IMG_SIZE_PX}px;
  height: ${TOKEN_IMG_SIZE_PX}px;
  border-radius: 50%;
`

function ArrowRight() {
  const {
    progressBar: { barColor },
  } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="6"
      viewBox="0 0 32 6"
      fill="none"
    >
      <path
        d="M32 3L27 0.113249V5.88675L32 3ZM0 3.5H27.5V2.5H0V3.5Z"
        fill={barColor}
      />
    </svg>
  )
}
