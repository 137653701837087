import useChainDetection from '@/hooks/useChainDetection'
import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import { useSwellWeb3 } from '@/swell-web3/core'
import { useMemo } from 'react'
import {
  useRswellBackendURLs,
  useSwBTCBackendURLs,
} from '../deployments/hooks/yearnSettings'
import { IPointsFetcher, IVaultStatsFetcher } from './api/deps'

const BOOTSTRAP_APY = 3 // 3%

// swbtc

export function useBtcLrtPointsFetcher(): IPointsFetcher {
  const { pointsURL, statsURL } = useSwBTCBackendURLs()

  const statsClient = useV3BackendClient(statsURL).v3BackendClient.stats
  const walletClient = useV3BackendClient(pointsURL).v3BackendClient.wallet
  const { account } = useSwellWeb3()

  return {
    userPoints: async () => {
      if (!account) {
        throw new Error('no account')
      }

      const { blackPearls } = await walletClient.btcLrtUser({
        walletAddress: account,
      })
      return {
        blackPearls,
        eigenLayerPoints: 0,
        karakPoints: 0,
      }
    },
    vaultPoints: async () => {
      // not part of the Sentio points system
      const stats = await statsClient.btcLrt({})
      return {
        vaultSymbioticPoints: stats.symbioticPointsVault,
      }
    },
  }
}

export function useBtcLrtStatsFetcher(): IVaultStatsFetcher {
  const { statsURL } = useSwBTCBackendURLs()
  const statsClient = useV3BackendClient(statsURL).v3BackendClient.stats

  const { isTestnetChain, allowTestnet } = useChainDetection()

  return useMemo(() => {
    return {
      stats: async () => {
        if (isTestnetChain && allowTestnet) {
          // stubbed testnet
          return {
            apr: {
              indicative: true,
              indicativeAprPercent: BOOTSTRAP_APY,
            },
            totalDepositors: 1234,
          }
        }

        const { depositorCountUsers, vaultAprPercent } =
          await statsClient.btcLrt({})

        let totalDepositors = parseInt(depositorCountUsers, 10)
        if (isNaN(totalDepositors)) {
          totalDepositors = 0
        }

        let apr = parseFloat(vaultAprPercent)
        if (isNaN(apr)) {
          apr = BOOTSTRAP_APY
        }

        return {
          apr: {
            indicative: true,
          },
          totalDepositors,
        }
      },
    }
  }, [allowTestnet, isTestnetChain, statsClient])
}

// rswell

export function useRswellStatsFetcher(): IVaultStatsFetcher {
  const { statsURL } = useRswellBackendURLs()
  const statsClient = useV3BackendClient(statsURL).v3BackendClient.stats

  const { isTestnetChain, allowTestnet } = useChainDetection()

  return useMemo(() => {
    return {
      stats: async () => {
        if (isTestnetChain && allowTestnet) {
          // stubbed testnet
          return {
            apr: {
              indicative: true,
              indicativeAprPercent: BOOTSTRAP_APY,
            },
            totalDepositors: 1234,
          }
        }

        const { depositorCountUsers, vaultAprPercent } =
          await statsClient.rswell({})

        let totalDepositors = parseInt(depositorCountUsers, 10)
        if (isNaN(totalDepositors)) {
          totalDepositors = 0
        }

        let apr = parseFloat(vaultAprPercent)
        if (isNaN(apr)) {
          apr = BOOTSTRAP_APY
        }

        return {
          apr: {
            indicative: true,
          },
          totalDepositors,
        }
      },
    }
  }, [allowTestnet, isTestnetChain, statsClient])
}

export function useRswellPointsFetcher(): IPointsFetcher {
  const { statsURL, pointsURL } = useRswellBackendURLs()
  const walletClient = useV3BackendClient(pointsURL).v3BackendClient.wallet
  const { account } = useSwellWeb3()
  const statsClient = useV3BackendClient(statsURL).v3BackendClient.stats

  return {
    userPoints: async () => {
      if (!account) {
        throw new Error('no account')
      }

      const { blackPearls } = await walletClient.rswellUser({
        walletAddress: account,
      })
      return {
        blackPearls,
        eigenLayerPoints: 0,
        karakPoints: 0,
      }
    },
    vaultPoints: async () => {
      const stats = await statsClient.rswell({})
      return {
        vaultSymbioticPoints: stats.symbioticPointsVault,
      }
    },
  }
}
