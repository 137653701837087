import {
  NucleusApproveAssetForDeposit,
  NucleusApproveVaultTokenForAtomicQueue,
  NucleusCancelWithdraw,
  NucleusDeposit,
  NucleusRequestWithdraw,
} from '@/state/nucleusVault/hooks'
import {
  NucleusSharesState,
  NucleusVaultAuth,
  NucleusVaultStateDeposit,
  NucleusVaultStateWithdraw,
} from '@/state/nucleusVault/types'
import { Token } from '@/types/tokens'
import { BigNumber } from 'ethers'
import {
  depositAssetMinimumMint,
  makeNucleusWithdrawalRequest,
} from './nucleusConversions'
import { NucleusActiveWithdrawalResult } from './nucleusWithdrawals'
import { NucleusSupportedTokenMap } from '@/types/nucleus'

export const NucleusErrors = {
  AmountMustBeGreaterThanZero: 'Amount must be greater than 0',
  InsufficientBalance: 'Insufficient balance',
  InsufficientAllowance: 'Insufficient allowance',
  InvalidSlippage: 'Invalid slippage',
  UnstakeAmountTooLow: 'Unstake amount too low',
  UnstakeAmountTooHigh: 'Unstake amount too high',
  SharesAreLocked: 'Shares are locked',
  UnsupportedDepositAsset: 'Unsupported deposit asset',
  AlreadyRequestingWithdrawal: 'Already requesting withdrawal',
  DepositIsPaused: 'Deposits are paused',
  NotAuthorizedToDeposit: 'Not authorized to deposit',
  InvalidSolverDiscount: 'Invalid solver discount',
}

type ValidatedArgs<T> =
  | {
      args?: never
      error: string | null
    }
  | {
      args: T
      error?: never
    }

export function prepareNucleusApproveAssetForDeposit({
  assetAmount,
  assetAddress,
  assetBalance,
}: {
  assetAmount: BigNumber | undefined
  assetAddress: string
  assetBalance: BigNumber | undefined
}): ValidatedArgs<Parameters<NucleusApproveAssetForDeposit['call']>> {
  if (assetAmount === undefined || assetBalance === undefined) {
    return { error: null }
  }

  if (assetAmount.lte(0)) {
    return { error: NucleusErrors.AmountMustBeGreaterThanZero }
  }
  if (assetAmount.gt(assetBalance)) {
    return { error: NucleusErrors.InsufficientBalance }
  }

  return {
    args: [{ amount: assetAmount, assetAddress }],
  }
}
export type PreparedNucleusApproveAssetForDeposit = ReturnType<
  typeof prepareNucleusApproveAssetForDeposit
>

export function prepareNucleusApproveVaultTokenForAtomicQueue({
  vaultTokenAmount,
  vaultTokenBalance,
}: {
  vaultTokenAmount: BigNumber | undefined
  vaultTokenBalance: BigNumber | undefined
}): ValidatedArgs<Parameters<NucleusApproveVaultTokenForAtomicQueue['call']>> {
  if (vaultTokenAmount === undefined || vaultTokenBalance === undefined) {
    return { error: null }
  }

  if (vaultTokenAmount.lte(0)) {
    return { error: NucleusErrors.AmountMustBeGreaterThanZero }
  }
  if (vaultTokenAmount.gt(vaultTokenBalance)) {
    return { error: NucleusErrors.InsufficientBalance }
  }

  return {
    args: [{ amount: vaultTokenAmount }],
  }
}
export type PreparedNucleusApproveVaultTokenForAtomicQueue = ReturnType<
  typeof prepareNucleusApproveVaultTokenForAtomicQueue
>

export function prepareNucleusDeposit({
  depositAmount,
  depositToken,
  depositAssetBalance,
  depositAssetAllowanceForVault,
  slippage,
  vaultTokenRateInQuote,
  supportedAssets,
  requiresApproval,
  vaultState,
  auth,
  baseAsset,
}: {
  requiresApproval: boolean
  depositToken: Token
  slippage: number
  depositAmount: BigNumber | undefined
  depositAssetBalance: BigNumber | undefined
  depositAssetAllowanceForVault: BigNumber | undefined
  vaultTokenRateInQuote: BigNumber | undefined
  supportedAssets: NucleusSupportedTokenMap | undefined
  vaultState: NucleusVaultStateDeposit | undefined
  auth: NucleusVaultAuth | undefined
  baseAsset: Token
}): ValidatedArgs<Parameters<NucleusDeposit['call']>> {
  if (
    depositAmount === undefined ||
    depositAssetBalance === undefined ||
    vaultTokenRateInQuote === undefined ||
    supportedAssets === undefined ||
    vaultState === undefined ||
    auth === undefined
  ) {
    return { error: null }
  }

  if (requiresApproval) {
    if (depositAssetAllowanceForVault === undefined) {
      return { error: null }
    }
  }

  if (vaultState.isDepositPaused) {
    return { error: NucleusErrors.DepositIsPaused }
  }
  if (!auth.isAuthorizedToDeposit) {
    return { error: NucleusErrors.NotAuthorizedToDeposit }
  }
  if (!supportedAssets[depositToken.address]?.isSupported) {
    return { error: NucleusErrors.UnsupportedDepositAsset }
  }

  if (slippage < 0 || slippage > 1) {
    return { error: NucleusErrors.InvalidSlippage }
  }
  if (depositAmount.lte(0)) {
    return { error: NucleusErrors.AmountMustBeGreaterThanZero }
  }
  if (depositAmount.gt(depositAssetBalance)) {
    return { error: NucleusErrors.InsufficientBalance }
  }

  if (requiresApproval) {
    if (depositAmount.gt(depositAssetAllowanceForVault!)) {
      return { error: NucleusErrors.InsufficientAllowance }
    }
  }

  const minimumMint = depositAssetMinimumMint({
    slippage,
    toSendAsset: depositAmount,
    vaultTokenRateInQuote,
    base: baseAsset,
  })

  const depositAsset = depositToken.address

  return {
    args: [{ depositAmount, depositAsset, minimumMint }],
  }
}
export type PreparedNucleusDeposit = ReturnType<typeof prepareNucleusDeposit>

export function prepareNucleusRequestWithdraw({
  offerAmount,
  wantAsset,
  vaultTokenBalance,
  sharesState,
  vaultAllowanceForAtomicQueue,
  vaultTokenRateInQuote,
  nowMs,
  activeWithdrawal,
  vaultState,
  baseAsset,
}: {
  wantAsset: Token
  baseAsset: Token
  nowMs: number
  offerAmount: BigNumber | undefined
  vaultTokenBalance: BigNumber | undefined
  vaultAllowanceForAtomicQueue: BigNumber | undefined
  sharesState: NucleusSharesState | undefined
  vaultTokenRateInQuote: BigNumber | undefined
  vaultState: NucleusVaultStateWithdraw | undefined
  activeWithdrawal: NucleusActiveWithdrawalResult | undefined
}): ValidatedArgs<Parameters<NucleusRequestWithdraw['call']>> {
  if (
    offerAmount === undefined ||
    vaultTokenBalance === undefined ||
    vaultAllowanceForAtomicQueue === undefined ||
    sharesState === undefined ||
    vaultTokenRateInQuote === undefined ||
    activeWithdrawal === undefined ||
    vaultState === undefined
  ) {
    return { error: null }
  }
  const solverDiscount = vaultState.solverFeePercent / 100
  const deadlineDurationMs = vaultState.withdrawalProcessingDurationUnix * 1000

  if (activeWithdrawal.status === 'Requesting') {
    return { error: NucleusErrors.AlreadyRequestingWithdrawal }
  }

  if (solverDiscount < 0 || solverDiscount > 1) {
    return { error: NucleusErrors.InvalidSolverDiscount }
  }

  if (offerAmount.lte(0)) {
    return { error: NucleusErrors.AmountMustBeGreaterThanZero }
  }
  if (offerAmount.gt(vaultTokenBalance)) {
    return { error: NucleusErrors.InsufficientBalance }
  }
  if (offerAmount.gt(vaultAllowanceForAtomicQueue)) {
    return { error: NucleusErrors.InsufficientAllowance }
  }

  const deadlineUnix = (nowMs + deadlineDurationMs) / 1000
  if (sharesState.shareUnlock.isLocked) {
    if (deadlineUnix < sharesState.shareUnlock.unlockTimeUnix) {
      return { error: NucleusErrors.SharesAreLocked }
    }
  }

  const wantAssetAddress = wantAsset.address
  const withdrawRequest = makeNucleusWithdrawalRequest({
    deadlineUnix,
    solverDiscount,
    toSendVaultToken: offerAmount,
    vaultTokenRateInQuote,
    base: baseAsset,
  })

  return {
    args: [{ withdrawRequest, wantAssetAddress }],
  }
}
export type PreparedNucleusRequestWithdraw = ReturnType<
  typeof prepareNucleusRequestWithdraw
>

export function prepareNucleusCancelWithdraw({
  cancelAssetAddress,
}: {
  cancelAssetAddress: string | undefined
}): ValidatedArgs<Parameters<NucleusCancelWithdraw['call']>> {
  if (cancelAssetAddress === undefined) {
    return { error: null }
  }

  return {
    args: [{ wantAssetAddress: cancelAssetAddress }],
  }
}
export type PreparedNucleusCancelWithdraw = ReturnType<
  typeof prepareNucleusCancelWithdraw
>
