import {
  DEPLOYMENT_NAMES,
  DEPLOYMENT_SETS,
  INITIAL_DEPLOYMENT_SET_NAME,
} from '@/configuration/deploymentSets'
import { DeploymentSet } from '@/types/deployments'

export function getSentryDSN() {
  let ds: DeploymentSet
  {
    // todo: improve this

    let idx = -1
    for (let i = 0; i < DEPLOYMENT_NAMES.length; i++) {
      if (DEPLOYMENT_NAMES[i] === INITIAL_DEPLOYMENT_SET_NAME) {
        idx = i
        break
      }
    }

    if (idx === -1) {
      return ''
    }

    ds = DEPLOYMENT_SETS[idx]

    if (!ds) return ''
  }

  const { sentryDSN } = ds
  return sentryDSN ?? ''
}
