import { DurationBreakdown } from '@/util/datetime'

export function formatProgressBar({
  countdown,
  durationMs,
}: {
  countdown: DurationBreakdown
  durationMs: number
}) {
  if (durationMs <= 0) {
    return {
      countdownLeft: '',
      countdownMiddle: '',
      countdownRight: '',
    }
  }

  const oneHour = 60 * 60 * 1000
  const oneDay = oneHour * 24

  let countdownLeft: string
  let countdownMiddle: string
  let countdownRight: string

  const durationSeconds = Math.round(durationMs / 1000)
  const durationHours = Math.round(durationMs / oneHour)
  const durationDays = Math.round(durationMs / oneDay)

  if (durationMs >= oneDay || durationHours === 24) {
    countdownLeft = `0d`
    countdownMiddle = `${countdown.days}d ${countdown.hours}h ${countdown.minutes}m`
    countdownRight = `${durationDays}d`
  } else if (durationMs >= oneHour) {
    countdownLeft = `0h`
    countdownMiddle = `${countdown.hours}h ${countdown.minutes}m ${countdown.seconds}s`
    countdownRight = `${durationHours}h`
  } else {
    countdownLeft = `0s`
    countdownMiddle = `${countdown.minutes}m ${countdown.seconds}s`
    countdownRight = `${durationSeconds}s`
  }

  return {
    countdownLeft,
    countdownMiddle,
    countdownRight,
  }
}
