import { useEffect } from 'react'
import { RestakingWidget } from '@/components/StakingWidget/RestakingWidget'
import { useZap } from '@/state/zap/hooks'
import { TOKEN_LIST_ETH } from '@/constants/tokens'
import {
  useTokenBalances,
  useTokenBalancesAlchemy,
} from '@/hooks/erc20/useTokenBalances'
import { useEthBalance } from '@/state/user/hooks'
import { parseEther } from 'ethers/lib/utils'
import { useRswEthExitCalls } from './hooks'
import {
  useClaimEigenStakedrop,
  useEigenToken,
  useRswEXITAllowances,
  useRswEXITClaims,
  useRswEthBalance,
  useRswEthEigenStakedrop,
  useRswEthEigenStakedropContractsState,
  useRswEthExitAssets,
  useRswEthToken,
} from '@/state/rsweth/hooks'
import { EXIT_ASSET_RSWETH_ETH } from '@/constants/exits'
import { useWeb3CallErrorNotify } from '@/hooks/useWeb3CallErrorNotify'

const MAX_UNSTAKE_AMOUNT = parseEther('500')
const MIN_UNSTAKE_AMOUNT = parseEther('0.005')
const WITHDRAWAL_DELAY_DURATION_DAYS = 21

function Restaking() {
  const rswEthToken = useRswEthToken()
  const eigenToken = useEigenToken()
  const exitAssets = useRswEthExitAssets()

  const exitClaimsQuery = useRswEXITClaims()
  const exitAllowancesQuery = useRswEXITAllowances()
  const ethBalanceQuery = useEthBalance()
  const rswETHBalanceQuery = useRswEthBalance()
  const tokenBalancesQuery = useTokenBalancesAlchemy()

  const eigenStakedropResultQuery = useRswEthEigenStakedrop()
  const eigenStakedropContractsStateQuery =
    useRswEthEigenStakedropContractsState()

  const ethBalance = ethBalanceQuery.data?.balance
  const rswETHBalance = rswETHBalanceQuery.data
  let tokenBalances = tokenBalancesQuery.data?.tokenBalances
  const exitClaims = exitClaimsQuery.data
  const exitAllowances = exitAllowancesQuery.data
  const eigenStakedropResult = eigenStakedropResultQuery.data
  const eigenStakedropContractsState = eigenStakedropContractsStateQuery.data

  const zap = useZap()
  const claimEigenStakedrop = useClaimEigenStakedrop()
  const {
    approveRswETHForWithdrawal,
    createWithdrawRequest,
    finalizeWithdrawal,
  } = useRswEthExitCalls()

  // trigger data refetch on successful transactions
  useEffect(() => {
    if (
      approveRswETHForWithdrawal.status ===
      approveRswETHForWithdrawal.STATUS.FULFILLED
    ) {
      exitAllowancesQuery.mutate()
    }
  }, [
    approveRswETHForWithdrawal.STATUS.FULFILLED,
    approveRswETHForWithdrawal.status,
    exitAllowancesQuery,
  ])
  useEffect(() => {
    if (
      createWithdrawRequest.status === createWithdrawRequest.STATUS.FULFILLED
    ) {
      exitClaimsQuery.mutate()
      rswETHBalanceQuery.mutate()
      exitAllowancesQuery.mutate()
    }
  }, [
    createWithdrawRequest.STATUS.FULFILLED,
    createWithdrawRequest.status,
    exitAllowancesQuery,
    exitClaimsQuery,
    rswETHBalanceQuery,
  ])
  useEffect(() => {
    if (finalizeWithdrawal.status === finalizeWithdrawal.STATUS.FULFILLED) {
      exitClaimsQuery.mutate()
      tokenBalancesQuery.mutate()
      ethBalanceQuery.mutate()
    }
  }, [
    finalizeWithdrawal.STATUS.FULFILLED,
    finalizeWithdrawal.status,
    exitClaimsQuery,
    rswETHBalanceQuery,
    tokenBalancesQuery,
    ethBalanceQuery,
  ])
  useEffect(() => {
    if (zap.status === zap.STATUS.FULFILLED) {
      tokenBalancesQuery.mutate()
      ethBalanceQuery.mutate()
    }
  }, [ethBalanceQuery, tokenBalancesQuery, zap.STATUS.FULFILLED, zap.status])
  useEffect(() => {
    if (claimEigenStakedrop.status === claimEigenStakedrop.STATUS.FULFILLED) {
      eigenStakedropResultQuery.mutate()
    }
  }, [
    claimEigenStakedrop.STATUS.FULFILLED,
    claimEigenStakedrop.status,
    eigenStakedropResultQuery,
  ])

  // add rsweth and eth to the tokenBalances list
  tokenBalances = tokenBalances?.filter(
    (t) =>
      t.address !== TOKEN_LIST_ETH.address && t.address !== rswEthToken.address
  )
  tokenBalances?.push({ ...rswEthToken, balance: rswETHBalance })
  tokenBalances?.push({ ...TOKEN_LIST_ETH, balance: ethBalance })

  // error notifications
  useWeb3CallErrorNotify(zap)
  useWeb3CallErrorNotify(createWithdrawRequest)
  useWeb3CallErrorNotify(finalizeWithdrawal)
  useWeb3CallErrorNotify(approveRswETHForWithdrawal)
  useWeb3CallErrorNotify(claimEigenStakedrop)

  const defaultDepositAsset = TOKEN_LIST_ETH
  let defaultWithdrawAsset = EXIT_ASSET_RSWETH_ETH
  if (exitAssets.length > 0) {
    defaultWithdrawAsset = exitAssets[0]
  }

  return (
    <>
      <RestakingWidget
        withdrawalDelayDurationDays={WITHDRAWAL_DELAY_DURATION_DAYS}
        maxUnstakeAmount={MAX_UNSTAKE_AMOUNT}
        minUnstakeAmount={MIN_UNSTAKE_AMOUNT}
        defaultDepositAsset={defaultDepositAsset}
        defaultWithdrawAsset={defaultWithdrawAsset}
        lrtToken={rswEthToken}
        lrtBalance={rswETHBalance}
        approveLrtForWithdrawal={approveRswETHForWithdrawal}
        zap={zap}
        createWithdrawRequest={createWithdrawRequest}
        ethBalance={ethBalance}
        exitAllowanceMap={exitAllowances}
        exitClaimMap={exitClaims}
        finalizeWithdrawal={finalizeWithdrawal}
        nativeCurrency={TOKEN_LIST_ETH}
        stakingEnabled={{ value: true, isLoading: false }}
        tokenBalances={tokenBalances}
        exitAssets={exitAssets}
        restakeAssets={[TOKEN_LIST_ETH]}
        eigenStakedropResult={eigenStakedropResult}
        eigenStakedropContractsState={eigenStakedropContractsState}
        claimEigenStakedrop={claimEigenStakedrop}
        eigenToken={eigenToken}
      />
    </>
  )
}

export { Restaking }
