import { NucleusVaultStateWithdraw } from '@/state/nucleusVault/types'
import { FlexRow } from '@/swell-ui/FlexRow'
import { ProgressBar } from '@/swell-ui/ProgressBar'
import React, { ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { INucleusActiveWithdrawal } from '../../nucleusHooks'
import { NucleusClaimBreakdown } from './NucleusClaimBreakdown'
import { Pill } from '@/swell-ui/Pill'
import {
  NucleusCancelWithdrawButton,
  NucleusWithdrawFailedAckButton,
  NucleusWithdrawFulfilledAckButton,
} from '../../NucleusButtons'
import { prepareNucleusCancelWithdraw } from '../../nucleusCalls'
import { NucleusCancelWithdraw } from '@/state/nucleusVault/hooks'
import { useTransactionContext } from '@/state/transactions/context'
import { ThemeData } from '@/swell-ui/theme/branding'
import { useMediaQuery } from '@mui/material'
import { NucleusVault } from '@/types/nucleus'

export function NucleusClaimView({
  vaultState,
  activeWithdrawal,
  returnToWithdraw,
  cancelWithdraw,
  vault,
}: {
  vaultState: NucleusVaultStateWithdraw | undefined
  activeWithdrawal: INucleusActiveWithdrawal | undefined
  returnToWithdraw: () => void
  cancelWithdraw: NucleusCancelWithdraw
  vault: NucleusVault
}) {
  const is900Up = useMediaQuery('(min-width:900px)')

  let actionContent: ReactNode = null
  let summaryContent: ReactNode = null
  let shouldReturnToWithdraw = false
  if (!activeWithdrawal) {
    shouldReturnToWithdraw = true
  } else if (activeWithdrawal.status === 'NoActiveWithdrawal') {
    shouldReturnToWithdraw = true
  } else if (activeWithdrawal.status === 'Requesting') {
    actionContent = (
      <ClaimPending
        vaultState={vaultState}
        activeWithdrawal={activeWithdrawal}
        cancelWithdraw={cancelWithdraw}
      />
    )
    summaryContent = (
      <NucleusClaimBreakdown
        activeWithdrawal={activeWithdrawal}
        vaultState={vaultState}
        vaultToken={vault.vaultToken}
        withdrawAssets={vault.withdrawAssets}
        baseAsset={vault.baseAsset}
      />
    )
  } else if (activeWithdrawal.status === 'UnackedFailed') {
    actionContent = <ClaimFailed activeWithdrawal={activeWithdrawal} />
    summaryContent = <FailedSummary />
  } else if (activeWithdrawal.status === 'UnackedFulfilled') {
    actionContent = <ClaimFulfilled activeWithdrawal={activeWithdrawal} />
    summaryContent = (
      <NucleusClaimBreakdown
        activeWithdrawal={activeWithdrawal}
        vaultState={vaultState}
        vaultToken={vault.vaultToken}
        withdrawAssets={vault.withdrawAssets}
        baseAsset={vault.baseAsset}
      />
    )
  } else {
    shouldReturnToWithdraw = true
  }

  useEffect(() => {
    if (shouldReturnToWithdraw) {
      returnToWithdraw()
    }
  }, [returnToWithdraw, shouldReturnToWithdraw])

  if (is900Up) {
    return (
      <LayoutWide
        height="208px"
        justify="space-between"
        gap="20"
        align="flex-start"
      >
        {actionContent}
        {summaryContent}
      </LayoutWide>
    )
  }

  return (
    <LayoutTall gap="48" direction="column" align="center">
      {actionContent}
      {summaryContent}
    </LayoutTall>
  )
}

const LayoutBase = styled(FlexRow)`
  flex-grow: 1;
  .light {
    color: white;
  }
  font-size: 14px;
`

const LayoutWide = styled(LayoutBase)``

const LayoutTall = styled(LayoutBase)`
  > div:last-child {
    margin: 0 auto;
    flex: 1;
    align-self: stretch;
  }
`

function ClaimPending({
  activeWithdrawal,
  vaultState,
  cancelWithdraw,
}: {
  vaultState: NucleusVaultStateWithdraw | undefined
  activeWithdrawal: INucleusActiveWithdrawal
  cancelWithdraw: NucleusCancelWithdraw
}) {
  const { anyTransactionInProgress } = useTransactionContext()

  if (activeWithdrawal.status !== 'Requesting') {
    return null
  }
  let durationMs: number | undefined
  let deadlineMs: number | undefined
  if (vaultState) {
    durationMs = vaultState.withdrawalProcessingDurationUnix * 1000
    deadlineMs = activeWithdrawal.request.deadlineUnix * 1000
  }

  const preparedCancelWithdraw = prepareNucleusCancelWithdraw({
    cancelAssetAddress: activeWithdrawal.wantToken,
  })

  return (
    <FlexRow
      direction="column"
      maxWidth="353px"
      height="216px"
      gap="20"
      padding="10px 0 0 0"
    >
      <Pill variant="warning" style={{ alignSelf: 'flex-start' }}>
        Pending
      </Pill>
      <ProgressBar durationMs={durationMs} deadlineMs={deadlineMs} />
      <NucleusCancelWithdrawButton
        cancelWithdraw={cancelWithdraw}
        prepared={preparedCancelWithdraw}
        preventInteraction={anyTransactionInProgress}
      />
    </FlexRow>
  )
}

function ClaimFulfilled({
  activeWithdrawal,
}: {
  activeWithdrawal: INucleusActiveWithdrawal
}) {
  return (
    <FlexRow
      direction="column"
      maxWidth="353px"
      height="216px"
      gap="20"
      padding="10px 0 0 0"
    >
      <Pill variant="success" style={{ alignSelf: 'flex-start' }}>
        Completed
      </Pill>
      <Tick />
      <span className="light">Claim completed. Click OK to continue.</span>
      <NucleusWithdrawFulfilledAckButton activeWithdrawal={activeWithdrawal} />
    </FlexRow>
  )
}

function ClaimFailed({
  activeWithdrawal,
}: {
  activeWithdrawal: INucleusActiveWithdrawal
}) {
  return (
    <FlexRow
      direction="column"
      maxWidth="353px"
      height="216px"
      gap="20"
      padding="10px 0 0 0"
    >
      <Pill variant="error" style={{ alignSelf: 'flex-start' }}>
        Failed
      </Pill>
      <Cross />
      <span className="light">Claim incomplete. Click OK to continue.</span>
      <NucleusWithdrawFailedAckButton activeWithdrawal={activeWithdrawal} />
    </FlexRow>
  )
}

function FailedSummary() {
  return (
    <div>
      <span className="light">Summary</span>
      <p className="light">
        Your withdrawal request was not able to be fulfilled by the solver at
        the requested target price. Please click ok to attempt another
        withdrawal request.
      </p>
    </div>
  )
}

function Tick() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="63"
      viewBox="0 0 63 63"
      fill="none"
    >
      <g clip-path="url(#clip0_10433_2979)">
        <path
          d="M31.5 6.47949C26.5184 6.47949 21.6486 7.95671 17.5066 10.7244C13.3645 13.492 10.1362 17.4257 8.2298 22.0282C6.32341 26.6306 5.82462 31.6949 6.79648 36.5808C7.76835 41.4667 10.1672 45.9547 13.6898 49.4772C17.2123 52.9998 21.7003 55.3987 26.5862 56.3705C31.4721 57.3424 36.5364 56.8436 41.1388 54.9372C45.7413 53.0308 49.675 49.8025 52.4427 45.6604C55.2103 41.5184 56.6875 36.6486 56.6875 31.667C56.6805 24.989 54.0245 18.5866 49.3025 13.8645C44.5804 9.14248 38.178 6.48654 31.5 6.47949ZM42.5583 27.2253L28.9958 40.7878C28.8159 40.9679 28.6022 41.1108 28.367 41.2083C28.1317 41.3058 27.8796 41.356 27.625 41.356C27.3704 41.356 27.1183 41.3058 26.8831 41.2083C26.6479 41.1108 26.4342 40.9679 26.2542 40.7878L20.4417 34.9753C20.0782 34.6117 19.8739 34.1186 19.8739 33.6045C19.8739 33.0903 20.0782 32.5973 20.4417 32.2337C20.8053 31.8702 21.2984 31.6659 21.8125 31.6659C22.3267 31.6659 22.8197 31.8702 23.1833 32.2337L27.625 36.6779L39.8167 24.4837C39.9967 24.3037 40.2104 24.1609 40.4456 24.0635C40.6808 23.9661 40.9329 23.9159 41.1875 23.9159C41.4421 23.9159 41.6942 23.9661 41.9294 24.0635C42.1646 24.1609 42.3783 24.3037 42.5583 24.4837C42.7383 24.6637 42.8811 24.8774 42.9785 25.1126C43.0759 25.3478 43.1261 25.5999 43.1261 25.8545C43.1261 26.1091 43.0759 26.3612 42.9785 26.5964C42.8811 26.8316 42.7383 27.0453 42.5583 27.2253Z"
          fill={ThemeData.Swell.SwellVaultTurquoise}
        />
      </g>
      <defs>
        <clipPath id="clip0_10433_2979">
          <rect
            width="62"
            height="62"
            fill="white"
            transform="translate(0.5 0.666992)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

function Cross() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="63"
      viewBox="0 0 63 63"
      fill="none"
    >
      <g clip-path="url(#clip0_10433_4444)">
        <path
          d="M31.5 6.47949C26.5184 6.47949 21.6486 7.95671 17.5066 10.7244C13.3645 13.492 10.1362 17.4257 8.2298 22.0282C6.32341 26.6306 5.82462 31.6949 6.79648 36.5808C7.76835 41.4667 10.1672 45.9547 13.6898 49.4772C17.2123 52.9998 21.7003 55.3987 26.5862 56.3705C31.4721 57.3424 36.5364 56.8436 41.1388 54.9372C45.7413 53.0308 49.675 49.8025 52.4427 45.6604C55.2103 41.5184 56.6875 36.6486 56.6875 31.667C56.6805 24.989 54.0245 18.5866 49.3025 13.8645C44.5804 9.14248 38.178 6.48654 31.5 6.47949ZM40.6208 38.0462C40.8008 38.2262 40.9436 38.4399 41.041 38.6751C41.1384 38.9103 41.1886 39.1624 41.1886 39.417C41.1886 39.6716 41.1384 39.9237 41.041 40.1589C40.9436 40.3941 40.8008 40.6078 40.6208 40.7878C40.4408 40.9678 40.2271 41.1106 39.9919 41.208C39.7567 41.3054 39.5046 41.3556 39.25 41.3556C38.9954 41.3556 38.7433 41.3054 38.5081 41.208C38.273 41.1106 38.0592 40.9678 37.8792 40.7878L31.5 34.4061L25.1208 40.7878C24.9408 40.9678 24.7271 41.1106 24.4919 41.208C24.2567 41.3054 24.0046 41.3556 23.75 41.3556C23.4954 41.3556 23.2433 41.3054 23.0082 41.208C22.773 41.1106 22.5592 40.9678 22.3792 40.7878C22.1992 40.6078 22.0564 40.3941 21.959 40.1589C21.8616 39.9237 21.8114 39.6716 21.8114 39.417C21.8114 39.1624 21.8616 38.9103 21.959 38.6751C22.0564 38.4399 22.1992 38.2262 22.3792 38.0462L28.7609 31.667L22.3792 25.2878C22.0157 24.9242 21.8114 24.4311 21.8114 23.917C21.8114 23.4028 22.0157 22.9098 22.3792 22.5462C22.7428 22.1827 23.2359 21.9784 23.75 21.9784C24.2642 21.9784 24.7572 22.1827 25.1208 22.5462L31.5 28.9279L37.8792 22.5462C38.0592 22.3662 38.273 22.2234 38.5081 22.126C38.7433 22.0286 38.9954 21.9784 39.25 21.9784C39.5046 21.9784 39.7567 22.0286 39.9919 22.126C40.2271 22.2234 40.4408 22.3662 40.6208 22.5462C40.8008 22.7262 40.9436 22.9399 41.041 23.1751C41.1384 23.4103 41.1886 23.6624 41.1886 23.917C41.1886 24.1716 41.1384 24.4237 41.041 24.6589C40.9436 24.8941 40.8008 25.1078 40.6208 25.2878L34.2392 31.667L40.6208 38.0462Z"
          fill="#F2BAC9"
        />
      </g>
      <defs>
        <clipPath id="clip0_10433_4444">
          <rect
            width="62"
            height="62"
            fill="white"
            transform="translate(0.5 0.666992)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
