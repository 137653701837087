import React from 'react'
import sybilUrl from '@/assets/images/sybil.png'
import styled from 'styled-components'
import { FlexRow } from '@/swell-ui/FlexRow'
import { paragraphHintCSS, title1CSS } from '../styles/common'

const whitePearlUrl =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66ac75489816f0a5806aaeed_White%20Pearl.svg'
const PEARL_SIZE = 20
const antiSybilMeasuresLink = 'https://www.swellnetwork.io/post/swell-token'

export function SybilDetected({ pearlsStr }: { pearlsStr: string }) {
  return (
    <Layout
      direction="column"
      margin="0 auto"
      maxWidth="575px"
      gap="24"
      align="center"
    >
      <img id={'sybil'} src={sybilUrl} width={204} height={192} />
      <h1>Sybil detected!</h1>
      <p className="major">
        You earned{' '}
        <img
          className="pearl"
          src={whitePearlUrl}
          width={PEARL_SIZE}
          height={PEARL_SIZE}
        />{' '}
        {pearlsStr} White Pearls
      </p>
      <p className="hint">
        Unfortunately you have not qualified for any SWELL tokens as your wallet
        has been flagged as a Sybil. If you feel this is a mistake please raise
        a support ticket in the chat on this screen.
      </p>
      <a href={antiSybilMeasuresLink} target="_blank" rel="noopener noreferrer">
        Learn more
      </a>
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  margin: auto 0;

  #sybil {
    width: 204px;
    height: 192px;
  }
  h1 {
    ${title1CSS}
    margin: 0;
    text-align: center;
    width: 100%;
  }

  p,
  ul {
    margin: 0;
  }

  p.major {
    text-align: center;

    color: var(--Swell-White-50, #fff);
    /* Body/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    letter-spacing: -0.48px;

    @media (min-width: 900px) {
      color: var(--Swell-White-50, #fff);
      /* Body/large */
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 38.4px */
      letter-spacing: -0.72px;
    }
  }

  p {
    text-align: center;
  }
  .hint {
    ${paragraphHintCSS}
  }

  img.pearl {
    margin-left: 2px;
    transform: translateY(4px);

    @media (min-width: 900px) {
      transform: translateY(2px);
    }
  }
`
