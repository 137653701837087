import { useState, useRef, useEffect } from 'react'

export function useDimensions() {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let done = false
    function animate() {
      if (done) return
      if (!containerRef.current) return requestAnimationFrame(animate)

      const { width, height } = containerRef.current.getBoundingClientRect()
      setDimensions({ width, height })

      requestAnimationFrame(animate)
    }
    animate()
    return () => {
      done = true
    }
  }, [])

  return { dimensions, containerRef }
}
