import {
  getApproveGasEstimate,
  getYearnCancelWithdrawGasEstimate,
  getYearnCompleteWithdrawGasEstimate,
  getYearnDepositGasEstimate,
  getYearnRequestWithdrawGasEstimate,
} from '@/constants/gasEstimates'
import { useDisplayNativeCurrencyPriceFiat } from '@/hooks/useNativeCurrencyDisplay'
import useTransactionFee, {
  TransactionFeeResult,
} from '@/hooks/useTransactionFee'
import { useWeb3CallErrorNotify } from '@/hooks/useWeb3CallErrorNotify'
import { YearnVaultCalls } from '@/state/yearnVault/hooks'
import { useEffect } from 'react'
import { YearnAeraTransactionFees } from '../YearnAeraVaultWidget/types'

export function useYearnVaultTransactionFees(): Partial<YearnAeraTransactionFees> {
  const displayNativeCurrencyPriceFiat = useDisplayNativeCurrencyPriceFiat()
  const depositTransactionFee = useTransactionFee({
    gasAmount: getYearnDepositGasEstimate().toNumber(),
  })
  const approveTransactionFee = useTransactionFee({
    gasAmount: getApproveGasEstimate().toNumber(),
  })
  const cancelWithdrawFee = useTransactionFee({
    gasAmount: getYearnCancelWithdrawGasEstimate().toNumber(),
  })
  const completeWithdrawFee = useTransactionFee({
    gasAmount: getYearnCompleteWithdrawGasEstimate().toNumber(),
  })
  const requestWithdrawFee = useTransactionFee({
    gasAmount: getYearnRequestWithdrawGasEstimate().toNumber(),
  })

  const toUsd = (res: TransactionFeeResult | undefined) => {
    if (!res) {
      return ''
    }
    return `${displayNativeCurrencyPriceFiat(res.feeWei, res.ethUsdRate)} USD`
  }

  const fees: Partial<YearnAeraTransactionFees> = {
    approveAssetForDeposit: toUsd(approveTransactionFee.data),
    approveVaultTokenForWithdraw: toUsd(approveTransactionFee.data),
    cancelWithdraw: toUsd(cancelWithdrawFee.data),
    requestWithdraw: toUsd(requestWithdrawFee.data),
    completeWithdraw: toUsd(completeWithdrawFee.data),
    deposit: toUsd(depositTransactionFee.data),
  }

  return fees
}

export function useYearnVaultCallsNotify(calls: YearnVaultCalls) {
  const {
    approveAssetForDeposit,
    approveVaultTokenForWithdraw,
    cancelWithdraw,
    completeWithdraw,
    deposit,
    requestWithdraw,
  } = calls
  useWeb3CallErrorNotify(deposit)
  useWeb3CallErrorNotify(approveAssetForDeposit)
  useWeb3CallErrorNotify(requestWithdraw)
  useWeb3CallErrorNotify(approveVaultTokenForWithdraw)
  useWeb3CallErrorNotify(cancelWithdraw)
  useWeb3CallErrorNotify(completeWithdraw)

  useEffect(() => {
    if (deposit.status === deposit.STATUS.FULFILLED) {
      const t = setTimeout(() => {
        deposit.clear()
      }, 5000)
    }
  }, [deposit])
  useEffect(() => {
    if (
      approveAssetForDeposit.status === approveAssetForDeposit.STATUS.FULFILLED
    ) {
      setTimeout(() => {
        approveAssetForDeposit.clear()
      }, 5000)
    }
  }, [approveAssetForDeposit])
  useEffect(() => {
    if (requestWithdraw.status === requestWithdraw.STATUS.FULFILLED) {
      setTimeout(() => {
        requestWithdraw.clear()
      }, 5000)
    }
  }, [requestWithdraw])
  useEffect(() => {
    if (
      approveVaultTokenForWithdraw.status ===
      approveVaultTokenForWithdraw.STATUS.FULFILLED
    ) {
      setTimeout(() => {
        approveVaultTokenForWithdraw.clear()
      }, 5000)
    }
  }, [approveVaultTokenForWithdraw])
  useEffect(() => {
    if (cancelWithdraw.status === cancelWithdraw.STATUS.FULFILLED) {
      setTimeout(() => {
        cancelWithdraw.clear()
      }, 5000)
    }
  }, [cancelWithdraw])
  useEffect(() => {
    if (completeWithdraw.status === completeWithdraw.STATUS.FULFILLED) {
      setTimeout(() => {
        completeWithdraw.clear()
      }, 5000)
    }
  }, [completeWithdraw])
}
