import { createContext, useContext } from 'react'
import { Token } from '@/types/tokens'
import { NucleusGlobalApi } from './types'
import { NucleusVault } from '@/types/nucleus'

const MISSING_PROVIDER = Symbol()

export type NucleusGlobalContext = NucleusGlobalApi & {
  atomicQueueAddress: string
  vaults: NucleusVault[]
}

const NucleusGlobalContext = createContext<
  NucleusGlobalContext | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

function useNucleusGlobalApi() {
  const ctx = useContext(NucleusGlobalContext)
  if (ctx === MISSING_PROVIDER) {
    throw new Error(
      'NucleusGlobalContext hooks must be wrapped in a <NucleusGlobalContext.Provider>'
    )
  }
  return ctx
}

export { NucleusGlobalContext, useNucleusGlobalApi }
