import { HOST_ENV } from '@/configuration/hostEnv'
import { tryGetAccount } from '@/util/wallet'
import * as Sentry from '@sentry/browser'

export class SentryService {
  private dsn: string

  constructor(dsn: string) {
    this.dsn = dsn
  }

  init = () => {
    if (!this.dsn) {
      console.error('No Sentry DSN provided')
      return
    }
    Sentry.init({
      dsn: this.dsn,
      environment: HOST_ENV,
      ignoreErrors: [/user rejected the request/i],
      beforeSend: async (event) => {
        const a = await tryGetAccount()
        if (a) {
          const { account, wallet } = a
          event.extra = event.extra || {}
          event.extra.account = account
          event.extra.wallet = wallet
        }
        return event
      },
    })
  }
}
