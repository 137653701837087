export const AirdropStep = {
  Intro: 0 as const,
  TheJourney: 1 as const,
  TheFuture: 2 as const,
  NewSwellEra: 3 as const,
  Claim: 4 as const,
  Complete: 5 as const,
}
export type AirdropStep = (typeof AirdropStep)[keyof typeof AirdropStep]

export type AirdropClaimSummary = {
  baseReward: string
  loyaltyBonus: string
  totalClaimable: string
  vestingAmount: string
  vestedAmount: string
  claimableDate: string
}

export type AirdropCompletionSummary = {
  claimedToWalletAmount: string
  totalRestakedAmount: string
  totalClaimedAmount: string
  totalClaimedTokenSymbols: string[]
}

export type AirdropVestingUnlockSchedule = {
  initialUnlockUnix: number
  firstUnlockUnix: number
  secondUnlockUnix: number
  thirdUnlockUnix: number
}
