import { FlexRow } from '@/swell-ui/FlexRow'
import { Menu, MenuConfig } from '@/swell-ui/Menu'
import { Token } from '@/types/tokens'
import styled from 'styled-components'

const ICON_SIZE = 22

export function TokenSelectMenu({
  tokens,
  selectedToken,
  setSelectedToken,
  title,
}: {
  tokens: Token[]
  selectedToken: Token
  setSelectedToken: (token: Token) => void
  title: string
}) {
  let selectedItemLabel: React.ReactNode = null
  const items: MenuConfig['items'] = [{ label: title, type: 'heading' }]
  for (const t of tokens) {
    const label = (
      <ItemLayout gap="6">
        <img src={t.logoURI} width={ICON_SIZE} height={ICON_SIZE} />
        <span>{t.symbol}</span>
      </ItemLayout>
    )

    items.push({
      label,
      onClick: () => {
        setSelectedToken(t)
      },
      type: 'button',
      noIndent: true,
    })

    if (t.address === selectedToken.address) {
      selectedItemLabel = label
    }
  }

  return (
    <Layout>
      <SMenu
        config={{ items, label: selectedItemLabel }}
        disableScrollLock
        align="right"
        minWidth="248px"
        caret
      />
    </Layout>
  )
}

const SMenu = styled(Menu)``
const Layout = styled.div``
const ItemLayout = styled(FlexRow)`
  color: var(--white-50, #fff);
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;

  > img {
    border-radius: 50%;
  }
`
