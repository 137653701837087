import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Box } from '@/swell-ui/Box'
import styled, { css } from 'styled-components'
import { FlexRow } from '@/swell-ui/FlexRow'
import { EarnFilters, EarnPositionsOrderBy } from '@/state/earn/types'
import { stableHash } from 'swr/_internal'
import { getChainIcon, getChainName } from '@/constants/chainInfo'
import { SearchInput } from '@/swell-ui/SearchInput'
import { AddMoreButton } from './PortfolioButtons'
import { PortfolioDefiPositionSummary } from './types'
import { SortingDirection } from '@/types/sorting'
import { TableV2 } from '@/swell-ui/TableV2/TableV2'
import { nextSortingDirection } from '@/util/sorting'
import { Checkbox } from '@/swell-ui/Checkbox'
import { useSwellWeb3 } from '@/swell-web3/core'
import { TokenFilterIcons } from '@/components/TokenFilterIcons'
import { ChainMultiSelect } from '@/components/ChainMultiSelect'
import { ActiveFiltersPill } from '@/components/ActiveFiltersPill'
import { Token } from '@/types/tokens'
import { useMediaQuery } from '@mui/material'
import { AccordionV2 } from '@/swell-ui/AccordionV2'

const bodyMedium = css`
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
`
const bodyXSmall = css`
  /* Body/xsmall */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  letter-spacing: -0.36px;
`
const bodySmall = css`
  /* Body/small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.42px;
`
const bodyUppercaseSmall = css`
  /* Body/uppercase small */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.9px;
  text-transform: uppercase;
`
type Col =
  | 'protocol'
  | 'asset'
  | 'category'
  | 'tvl'
  | 'apr'
  | 'multiplier'
  | 'pearls'
  | 'balance'
  | 'actions'
type Row = Record<Col, ReactNode>
type TableProps = React.ComponentProps<typeof TableV2<Col, Row>>

export function PortfolioDefiPositions({
  positions,
  clearSearchTerm,
  filters,
  setSearchTermFilter,
  setHideZeroBalances: setHideZeroBalancesProp,
  orderBy,
  orderDirection,
  setOrderBy,
  setDirection,
  loading,
  filterTokenOptions,
  setSelectedTokens,
  setChainFilter,
  activeFilters,
  clearFilters,
  filterChainOptions,
}: {
  positions: PortfolioDefiPositionSummary[] | undefined
  filters: EarnFilters
  setSearchTermFilter: (term: string) => void
  clearSearchTerm: () => void
  setHideZeroBalances: (v: boolean) => void
  orderBy: EarnPositionsOrderBy
  orderDirection: SortingDirection
  setOrderBy: Dispatch<SetStateAction<EarnPositionsOrderBy>>
  setDirection: Dispatch<SetStateAction<SortingDirection>>
  loading: boolean
  filterTokenOptions: Token[]
  setSelectedTokens: (tokens: string[]) => void
  setChainFilter: (chainIds: number[]) => void
  activeFilters: number
  clearFilters: () => void
  filterChainOptions: number[]
}) {
  const isTablet = useMediaQuery('(max-width: 1163px)')
  const { account } = useSwellWeb3()
  const [hideZeroBalances, setHideZeroBalances] = useState(false)

  useEffect(() => {
    setHideZeroBalancesProp(hideZeroBalances)
  }, [hideZeroBalances, setHideZeroBalancesProp])

  const onClickSortProtocol = (d: SortingDirection) => {
    setOrderBy('protocol')
    setDirection(
      nextSortingDirection({
        action: d,
        currentDirection: orderDirection,
        currentlyOrderingBy: orderBy,
        thisKey: 'protocol',
        defaultSortingDirectionForThis: SortingDirection.asc,
      })
    )
  }
  const onClickSortAsset = (d: SortingDirection) => {
    setOrderBy('asset')
    setDirection(
      nextSortingDirection({
        action: d,
        currentDirection: orderDirection,
        currentlyOrderingBy: orderBy,
        thisKey: 'asset',
        defaultSortingDirectionForThis: SortingDirection.asc,
      })
    )
  }
  const onClickSortCategory = (d: SortingDirection) => {
    setOrderBy('category')
    setDirection(
      nextSortingDirection({
        action: d,
        currentDirection: orderDirection,
        currentlyOrderingBy: orderBy,
        thisKey: 'category',
        defaultSortingDirectionForThis: SortingDirection.asc,
      })
    )
  }
  const onClickSortTVL = (d: SortingDirection) => {
    setOrderBy('tvl')
    setDirection(
      nextSortingDirection({
        action: d,
        currentDirection: orderDirection,
        currentlyOrderingBy: orderBy,
        thisKey: 'tvl',
        defaultSortingDirectionForThis: SortingDirection.desc,
      })
    )
  }
  const onClickSortAPR = (d: SortingDirection) => {
    setOrderBy('apr')
    setDirection(
      nextSortingDirection({
        action: d,
        currentDirection: orderDirection,
        currentlyOrderingBy: orderBy,
        thisKey: 'apr',
        defaultSortingDirectionForThis: SortingDirection.desc,
      })
    )
  }
  const onClickSortBoost = (d: SortingDirection) => {
    setOrderBy('multiplier')
    setDirection(
      nextSortingDirection({
        action: d,
        currentDirection: orderDirection,
        currentlyOrderingBy: orderBy,
        thisKey: 'multiplier',
        defaultSortingDirectionForThis: SortingDirection.desc,
      })
    )
  }
  const onClickSortPearls = (d: SortingDirection) => {
    setOrderBy('pearls')
    setDirection(
      nextSortingDirection({
        action: d,
        currentDirection: orderDirection,
        currentlyOrderingBy: orderBy,
        thisKey: 'pearls',
        defaultSortingDirectionForThis: SortingDirection.desc,
      })
    )
  }
  const onClickSortBalance = (d: SortingDirection) => {
    setOrderBy('balance')
    setDirection(
      nextSortingDirection({
        action: d,
        currentDirection: orderDirection,
        currentlyOrderingBy: orderBy,
        thisKey: 'balance',
        defaultSortingDirectionForThis: SortingDirection.desc,
      })
    )
  }

  const sortingBy: TableProps['sortingBy'] = {
    [orderBy]: orderDirection,
  }
  const header: TableProps['header'] = {
    protocol: {
      label: 'Protocol',
      width: '120px',
      onClickSort: onClickSortProtocol,
    },
    asset: {
      label: 'Asset',
      width: '130px',
      onClickSort: onClickSortAsset,
    },
    category: {
      label: 'Category',
      width: '100px',
      onClickSort: onClickSortCategory,
      align: 'center',
    },
    tvl: {
      label: 'TVL',
      width: '100px',
      onClickSort: onClickSortTVL,
    },
    apr: {
      label: 'APR',
      width: '110px',
      onClickSort: onClickSortAPR,
    },
    multiplier: {
      width: '70px',
      label: 'Boost',
      onClickSort: onClickSortBoost,
    },
    pearls: {
      width: '70px',
      label: 'Pearls',
      onClickSort: onClickSortPearls,
    },
    balance: {
      width: '70px',
      label: 'Balance',
      onClickSort: onClickSortBalance,
    },
    actions: {
      label: '',
      width: '49px',
    },
  }

  if (!isTablet) {
    return (
      <DesktopView
        header={header}
        filters={filters}
        setSearchTermFilter={setSearchTermFilter}
        clearSearchTerm={clearSearchTerm}
        filterTokenOptions={filterTokenOptions}
        setSelectedTokens={setSelectedTokens}
        setChainFilter={setChainFilter}
        activeFilters={activeFilters}
        clearFilters={clearFilters}
        filterChainOptions={filterChainOptions}
        hideZeroBalances={hideZeroBalances}
        setHideZeroBalances={setHideZeroBalances}
        hideBalancesCheckboxDisabled={!account}
        positions={positions}
        sortingBy={sortingBy}
        orderBy={orderBy}
        orderDirection={orderDirection}
        loading={loading}
      />
    )
  }

  return (
    <TabletView
      header={header}
      filters={filters}
      setSearchTermFilter={setSearchTermFilter}
      clearSearchTerm={clearSearchTerm}
      filterTokenOptions={filterTokenOptions}
      setSelectedTokens={setSelectedTokens}
      setChainFilter={setChainFilter}
      activeFilters={activeFilters}
      clearFilters={clearFilters}
      filterChainOptions={filterChainOptions}
      hideZeroBalances={hideZeroBalances}
      setHideZeroBalances={setHideZeroBalances}
      hideBalancesCheckboxDisabled={!account}
      positions={positions}
      sortingBy={sortingBy}
      orderBy={orderBy}
      orderDirection={orderDirection}
      loading={loading}
    />
  )
}

function DesktopView({
  filters,
  setSearchTermFilter,
  clearSearchTerm,
  filterTokenOptions,
  setSelectedTokens,
  setChainFilter,
  activeFilters,
  clearFilters,
  filterChainOptions,
  hideZeroBalances,
  setHideZeroBalances,
  hideBalancesCheckboxDisabled,
  header,
  sortingBy,
  positions,
  orderBy,
  orderDirection,
  loading,
}: {
  positions: PortfolioDefiPositionSummary[] | undefined
  header: TableProps['header']
  sortingBy: TableProps['sortingBy']
  loading: TableProps['loading']
  orderBy: EarnPositionsOrderBy
  orderDirection: SortingDirection
  filters: EarnFilters
  setSearchTermFilter: (term: string) => void
  clearSearchTerm: () => void
  filterTokenOptions: Token[]
  setSelectedTokens: (tokens: string[]) => void
  setChainFilter: (chainIds: number[]) => void
  activeFilters: number
  clearFilters: () => void
  filterChainOptions: number[]
  hideZeroBalances: boolean
  setHideZeroBalances: (v: boolean) => void
  hideBalancesCheckboxDisabled: boolean
}) {
  const rows: TableProps['rows'] = []
  for (const position of positions ?? []) {
    const key = stableHash([
      filters,
      orderBy,
      orderDirection,
      hideZeroBalances,
      position.positionName,
    ])

    let chainName = getChainName(position.chainId)
    if (!chainName) {
      chainName = `Chain ${position.chainId}`
    }

    const chainIcon = getChainIcon(position.chainId)

    let chainIconNode: ReactNode = null
    if (chainIcon) {
      chainIconNode = (
        <img
          src={chainIcon.uri}
          width="16px"
          height="16px"
          style={{ borderRadius: chainIcon.shape === 'circle' ? '50%' : '0' }}
        />
      )
    }

    rows.push({
      _key: key,
      protocol: {
        loaded: true,
        result: (
          <ProtocolLayout>
            <div className="p-icon">
              <img src={position.protocolLogoURI} width="32" height="32" />
            </div>
            <div className="p-name">{position.protocolName}</div>
            <div className="p-chain">
              {chainName}
              {chainIconNode}
            </div>
          </ProtocolLayout>
        ),
      },
      actions: {
        loaded: true,
        result: <AddMoreButton internal={false} href={position.link} />,
      },
      asset: { loaded: true, result: position.positionName },
      category: { loaded: true, result: position.category },
      tvl: { loaded: true, result: position.tvl },
      apr: { loaded: true, result: position.apr },
      multiplier: { loaded: true, result: position.boost },
      pearls: position.pearls
        ? { loaded: true, result: position.pearls }
        : { loaded: false },
      balance: position.balance
        ? { loaded: true, result: position.balance }
        : { loaded: false },
    })
  }

  return (
    <>
      <StickyTop>
        <FlexRow justify="space-between" width="100%">
          <span className="nobreak section-heading">DeFi Positions</span>
          <FlexRow gap="12" justify="flex-end">
            <div>
              <FlexRow gap="12" align="center">
                <span className="body-small nobreak">Filter by:</span>
                <TokenFilterIcons
                  tokens={filterTokenOptions}
                  selectedTokens={filters.includeTokenSymbols ?? []}
                  setSelectedTokens={setSelectedTokens}
                />
              </FlexRow>
            </div>
            <div>
              <ChainMultiSelect
                chainIds={filterChainOptions}
                selectedChainIds={filters.chainIds ?? []}
                setChainFilter={setChainFilter}
              />
            </div>
            <div>
              <ActiveFiltersPill
                numFilters={activeFilters}
                onClearAll={clearFilters}
              />
            </div>
          </FlexRow>
        </FlexRow>
        <TopBox>
          <FlexRow direction="column" gap="24" style={{ maxHeight: '100%' }}>
            <FlexRow direction="column" gap="24">
              <FlexRow justify="space-between">
                <SearchInput
                  searchTerm={filters.searchTerm ?? ''}
                  onClear={clearSearchTerm}
                  setSearchTerm={setSearchTermFilter}
                />
                <Checkbox
                  value={hideZeroBalances}
                  onChange={setHideZeroBalances}
                  label="Hide zero balances"
                  disabled={hideBalancesCheckboxDisabled}
                />
              </FlexRow>
              <div className="table-header-only">
                <TableV2
                  fixedLayout
                  rows={[]}
                  header={header}
                  sortingBy={sortingBy}
                  loading={false}
                  onlyHeader
                  maxHeight="24px"
                  loadingHeight="24px"
                />
              </div>
            </FlexRow>
          </FlexRow>
        </TopBox>
      </StickyTop>
      <BottomBox>
        <div className="table-container">
          <TableV2
            fixedLayout
            rows={rows}
            header={header}
            sortingBy={sortingBy}
            loading={loading}
            loadingHeight="54px"
            noHeader
            gradientRows
          />
        </div>
      </BottomBox>
    </>
  )
}

const StickyTop = styled.div`
  position: sticky;
  top: 24px;
  z-index: 10;
  display: flex;
  flex-flow: column nowrap;

  ::before {
    position: absolute;
    z-index: 9;
    content: '';
    width: 100%;
    height: calc(100% + 24px);
    bottom: 0;
    background: var(--Swell-Black-100, #000);
  }

  .mob-hidden {
    @media (max-width: 427px) {
      display: none;
    }
  }

  > div {
    z-index: 11;
  }

  gap: 12px;
  padding-bottom: 12px;
  @media (min-width: 1164px) {
    gap: 24px;
    padding-bottom: unset;
  }
`

const SBox = styled(Box)`
  border: none !important;
  display: flex;

  .nobreak {
    white-space: nowrap;
  }

  .table-container {
    width: 100%;
    display: flex;
  }

  .table-header-only {
    width: 100%;
    display: flex;
  }
`

const TopBox = styled(SBox)`
  padding: 24px 24px 0 24px;
  border-radius: 16px 16px 0 0;

  position: sticky;
  top: 0;
`
const BottomBox = styled(SBox)`
  padding: 4px 24px 24px 24px;
  border-radius: 0 0 16px 16px;
  flex-grow: 1;
`

const ProtocolLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 16px 16px;
  grid-template-areas:
    'icon name'
    'icon chain';
  grid-column-gap: 6px;
  grid-row-gap: 4px;
  line-height: 1;

  .p-icon {
    grid-area: icon;
    align-self: center;
  }
  .p-name {
    grid-area: name;
    ${bodyMedium};
    color: #fff;
    line-height: 1;
    white-space: nowrap;
  }
  .p-chain {
    grid-area: chain;
    display: flex;
    flex-flow: row nowrap;
    gap: 4px;
    align-items: center;

    ${bodyXSmall};
    color: var(--Swell-White-125, #b0b0b0);
    line-height: 1;
    white-space: nowrap;
  }
`

function TabletView({
  filters,
  setSearchTermFilter,
  clearSearchTerm,
  filterTokenOptions,
  setSelectedTokens,
  setChainFilter,
  activeFilters,
  clearFilters,
  filterChainOptions,
  hideZeroBalances,
  setHideZeroBalances,
  hideBalancesCheckboxDisabled,
  header,
  sortingBy,
  positions,
  orderBy,
  orderDirection,
  loading,
}: {
  positions: PortfolioDefiPositionSummary[] | undefined
  header: TableProps['header']
  sortingBy: TableProps['sortingBy']
  loading: TableProps['loading']
  orderBy: EarnPositionsOrderBy
  orderDirection: SortingDirection
  filters: EarnFilters
  setSearchTermFilter: (term: string) => void
  clearSearchTerm: () => void
  filterTokenOptions: Token[]
  setSelectedTokens: (tokens: string[]) => void
  setChainFilter: (chainIds: number[]) => void
  activeFilters: number
  clearFilters: () => void
  filterChainOptions: number[]
  hideZeroBalances: boolean
  setHideZeroBalances: (v: boolean) => void
  hideBalancesCheckboxDisabled: boolean
}) {
  const cards: ReactNode[] = []
  for (const position of positions ?? []) {
    const key = stableHash([
      filters,
      orderBy,
      orderDirection,
      hideZeroBalances,
      position.positionName,
    ])

    cards.push(
      <MobileAccordion key={key} position={position} logoSquare={false} />
    )
  }

  return (
    <>
      <StickyTop>
        <FlexRow direction="column" width="100%" align="start" gap="12">
          <span className="nobreak section-heading">DeFi Positions</span>
          <FlexRow gap="12" justify="space-between" align="center" wrap>
            <div>
              <FlexRow gap="12" align="center">
                <span className="body-small nobreak mob-hidden">
                  Filter by:
                </span>
                <TokenFilterIcons
                  tokens={filterTokenOptions}
                  selectedTokens={filters.includeTokenSymbols ?? []}
                  setSelectedTokens={setSelectedTokens}
                />
              </FlexRow>
            </div>
            <FlexRow width="auto">
              <div>
                <ChainMultiSelect
                  chainIds={filterChainOptions}
                  selectedChainIds={filters.chainIds ?? []}
                  setChainFilter={setChainFilter}
                />
              </div>
              <div>
                <ActiveFiltersPill
                  numFilters={activeFilters}
                  onClearAll={clearFilters}
                  compact
                />
              </div>
            </FlexRow>
          </FlexRow>
        </FlexRow>
        <SBox padding="16px">
          <FlexRow direction="column" gap="12" style={{ maxHeight: '100%' }}>
            <FlexRow direction="column" gap="12">
              <FlexRow justify="space-between" wrap gap="18">
                <SearchInput
                  searchTerm={filters.searchTerm ?? ''}
                  onClear={clearSearchTerm}
                  setSearchTerm={setSearchTermFilter}
                />
                <Checkbox
                  value={hideZeroBalances}
                  onChange={setHideZeroBalances}
                  label="Hide zero balances"
                  disabled={hideBalancesCheckboxDisabled}
                />
              </FlexRow>
              {/* TODO: NO SORTING YET ? */}
              {/* <div className="table-header-only">
                <TableV2
                  rows={[]}
                  header={header}
                  sortingBy={sortingBy}
                  loading={false}
                  onlyHeader
                  maxHeight="24px"
                  loadingHeight="24px"
                />
              </div> */}
            </FlexRow>
          </FlexRow>
        </SBox>
      </StickyTop>
      <FlexRow direction="column" align="stretch" gap="12" padding="12px 0 0 0">
        {cards}
      </FlexRow>
    </>
  )
}

function MobileAccordion({
  logoSquare,
  position,
}: {
  position: PortfolioDefiPositionSummary
  logoSquare?: boolean
}) {
  return (
    <AccordionV2
      summary={
        <ProtocolLayout style={{ height: '45px' }}>
          <div className="p-icon">
            <img
              src={position.protocolLogoURI}
              width="32"
              height="32"
              style={{ borderRadius: logoSquare ? '0' : '50%' }}
            />
          </div>
          <div className="p-name">{position.protocolName}</div>
          <div className="p-chain">
            {position.chainName}
            <img src={position.chainLogoURI} width="16px" height="16px" />
          </div>
        </ProtocolLayout>
      }
      details={
        <AccordionContent>
          <div className="p-asset">
            <FlexRow direction="column" gap="6" align="start">
              <span className="body-uppercase-small">Asset</span>
              <span className="body-small">{position.positionName}</span>
            </FlexRow>
          </div>
          <div className="p-category">
            <FlexRow direction="column" gap="6" align="start">
              <span className="body-uppercase-small">Category</span>
              <span className="body-small">{position.category}</span>
            </FlexRow>
          </div>
          <div className="p-tvl">
            <FlexRow direction="column" gap="6" align="start">
              <span className="body-uppercase-small">TVL</span>
              <span className="body-small">{position.tvl}</span>
            </FlexRow>
          </div>
          <div className="p-apr">
            <FlexRow direction="column" gap="6" align="start">
              <span className="body-uppercase-small">APR</span>
              <span className="body-small">{position.apr}</span>
            </FlexRow>
          </div>
          <div className="p-boost">
            <FlexRow direction="column" gap="6" align="start">
              <span className="body-uppercase-small">Boost</span>
              <span className="body-small">{position.boost}</span>
            </FlexRow>
          </div>
          <div className="p-pearls">
            <FlexRow direction="column" gap="6" align="start">
              <span className="body-uppercase-small">Pearls</span>
              <span className="body-small">{position.pearls}</span>
            </FlexRow>
          </div>
          <div className="p-balance">
            <FlexRow direction="column" gap="6" align="start">
              <span className="body-uppercase-small">Balance</span>
              <span className="body-small">{position.balance}</span>
            </FlexRow>
          </div>
          <div className="p-actions">
            <AddMoreButton internal={false} href={position.link} />
          </div>
        </AccordionContent>
      }
    />
  )
}

const AccordionContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, auto);
  grid-template-areas:
    'asset category'
    'tvl apr'
    'boost pearls'
    'balance actions';
  grid-column-gap: 12px;
  grid-row-gap: 12px;

  .p-asset {
    grid-area: asset;
  }
  .p-category {
    grid-area: category;
  }
  .p-tvl {
    grid-area: tvl;
  }
  .p-apr {
    grid-area: apr;
  }
  .p-boost {
    grid-area: boost;
  }
  .p-pearls {
    grid-area: pearls;
  }
  .p-balance {
    grid-area: balance;
  }
  .p-actions {
    grid-area: actions;
    button {
      margin-left: -16px;
    }
  }

  .body-small {
    ${bodySmall};
  }

  .body-uppercase-small {
    color: var(--Swell-Lightblue-75, #6e79e9);
    ${bodyUppercaseSmall};
  }
`
