import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { FlexRow } from '../FlexRow'
import { useNowMs, useTimeCountdown } from '@/hooks/useTimeCountdown'
import { useSwellUiTheme } from '../theme'
import { v4 as uuid } from 'uuid'
import { useDimensions } from '@/hooks/useDimensions'
import { formatProgressBar } from './progressBarFormatting'

// ProgressBar component
// This component is used to display a progress bar that shows the time remaining until a deadline.
export function ProgressBar({
  title = 'Max time remaining',
  durationMs,
  deadlineMs,
  titleAlign = 'right',
}: {
  title?: string
  durationMs: number | undefined
  deadlineMs: number | undefined
  titleAlign?: 'left' | 'center' | 'right'
}) {
  const { containerRef, dimensions } = useDimensions()
  const { theme } = useSwellUiTheme()
  const countdown = useTimeCountdown(deadlineMs)
  const nowMs = useNowMs()

  let trueProgress = 0
  let durationDays = 0
  if (deadlineMs && durationMs) {
    const remainingDuration = deadlineMs - nowMs
    trueProgress = 1 - remainingDuration / durationMs
    durationDays = Math.ceil(durationMs / (1000 * 60 * 60 * 24))
  }

  const [progress, setProgress] = useState(trueProgress)

  useEffect(() => {
    let done = false
    const animate = () => {
      if (done) return

      setProgress((p) => {
        const diff = trueProgress - p
        let v = p + diff * 0.1
        v = Math.min(1, v)
        v = Math.max(0, v)
        if (v < 0.015) v = 0.015
        return v
      })

      requestAnimationFrame(animate)
    }
    animate()
    return () => {
      done = true
    }
  }, [trueProgress])

  const {
    barColor,
    barHeight,
    borderRadius,
    borderColor,
    bgColor,
    barBorderRadius = borderRadius,
  } = theme.progressBar

  const ID = useRef({
    barShapeId: uuid(),
    progressMaskId: uuid(),
  })

  let titleAlignSelf: 'flex-start' | 'center' | 'flex-end' = 'flex-end'
  if (titleAlign === 'left') {
    titleAlignSelf = 'flex-start'
  } else if (titleAlign === 'center') {
    titleAlignSelf = 'center'
  }

  let countdownLeft = ''
  let countdownMiddle = ''
  let countdownRight = ''
  if (countdown && durationMs) {
    ;({ countdownLeft, countdownMiddle, countdownRight } = formatProgressBar({
      countdown,
      durationMs,
    }))
  }

  return (
    <Layout direction="column" gap="12" className="progress-bar-base">
      <span style={{ alignSelf: titleAlignSelf }}>{title}</span>
      <FlexRow gap="6" direction="column">
        <FlexRow
          height={barHeight}
          ref={containerRef}
          className="bar-container"
        >
          <svg height={barHeight} width={dimensions.width} overflow="visible">
            <defs>
              <rect
                id={ID.current.barShapeId}
                x={0}
                y={0}
                width={dimensions.width}
                height={dimensions.height}
                rx={borderRadius}
                ry={borderRadius}
              />
              <mask id={ID.current.progressMaskId}>
                <rect
                  x={0}
                  y={0}
                  width={progress * dimensions.width}
                  height={dimensions.height}
                  fill="white"
                  rx={barBorderRadius}
                  ry={barBorderRadius}
                />
              </mask>
            </defs>
            <use
              href={`#${ID.current.barShapeId}`}
              fill={bgColor}
              strokeWidth={1}
              stroke={borderColor}
            />
            <use
              href={`#${ID.current.barShapeId}`}
              mask={`url(#${ID.current.progressMaskId})`}
              fill={barColor}
              strokeWidth={1}
              stroke={borderColor}
            />
          </svg>
        </FlexRow>
        <FlexRow
          justify="space-between"
          style={{
            visibility: durationDays === 0 ? 'hidden' : 'visible',
          }}
        >
          <span>{countdownLeft}</span>
          {countdown && <span className="emphasis">{countdownMiddle}</span>}
          <span>{countdownRight}</span>
        </FlexRow>
      </FlexRow>
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  .emphasis {
    font-weight: 600;
    color: #fff;
  }

  .bar-container {
    position: relative;

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`
