import _ from 'lodash'

function niceNum(localRange: number, round: boolean) {
  const exponent = Math.floor(Math.log10(localRange))
  const fraction = localRange / Math.pow(10, exponent)
  let niceFraction

  if (round) {
    if (fraction < 1.5) niceFraction = 1
    else if (fraction < 3) niceFraction = 2
    else if (fraction < 7) niceFraction = 5
    else niceFraction = 10
  } else {
    if (fraction <= 1) niceFraction = 1
    else if (fraction <= 2) niceFraction = 2
    else if (fraction <= 5) niceFraction = 5
    else niceFraction = 10
  }

  return niceFraction * Math.pow(10, exponent)
}

// https://stackoverflow.com/questions/8506881/nice-label-algorithm-for-charts-with-minimum-ticks
// Find this is easier to use than d3's nice()
export function niceRange(niceRange: number[], maxTicks: number) {
  niceRange = _.uniq(niceRange).sort((a, b) => a - b)
  niceRange = [niceRange[0], niceRange[niceRange.length - 1]].map(
    (v) => v * 1e6
  )
  const [minPoint, maxPoint] = niceRange
  const range = niceNum(maxPoint - minPoint, false)
  const tickSpacing = niceNum(range / (maxTicks - 1), true)
  const niceMin = Math.floor(minPoint / tickSpacing) * tickSpacing
  const niceMax = Math.ceil(maxPoint / tickSpacing) * tickSpacing

  const results = []
  let c = niceMin
  while (c <= niceMax) {
    results.push(c)
    c += tickSpacing
  }

  return results.map((v) => v / 1e6)
}

// does something similar to d3.scaleLinear().domain().range()(value)
export function scaleLinear(
  value: number,
  domain: [number, number],
  range: [number, number]
) {
  const [domainMin, domainMax] = domain
  const [rangeMin, rangeMax] = range

  return (
    ((value - domainMin) / (domainMax - domainMin)) * (rangeMax - rangeMin) +
    rangeMin
  )
}
