import { useNowMs } from '@/hooks/useTimeCountdown'
import { useEffect, useMemo, useState } from 'react'
import {
  nucleusActiveWithdrawalMemo,
  NucleusActiveWithdrawalResult,
} from './nucleusWithdrawals'
import { Token } from '@/types/tokens'
import {
  NucleusWithdrawRequestResult,
  NucleusFulfilledRequestEvent,
} from '@/types/nucleus'

export type INucleusActiveWithdrawal = NucleusActiveWithdrawalResult & {
  ackCompleted: () => void
  ackFailed: () => void
}

// Nucleus withdrawals can be in 4 states:
// - requesting / unsolved / waiting
// - recently solved, unacknowledged
// - recently failed, unacknowledged
// - no active withdrawal
// This hook selects data from nucleus APIs to construct this UI state
// It provides a `ack` functions to acknowledge the completion/failure of a withdrawal
// It saves the state of acknowledgements in localStorage
export function useNucleusActiveWithdrawal({
  fulfilledRequests,
  currentWithdrawRequest,
  vaultToken,
}: {
  currentWithdrawRequest: NucleusWithdrawRequestResult | undefined
  fulfilledRequests: NucleusFulfilledRequestEvent[] | undefined
  vaultToken: Token
}): INucleusActiveWithdrawal | undefined {
  const acknowledgeCompleted = useAcknowledgeCompleted({ vaultToken })
  const acknowledgeFailed = useAcknowledgeFailed({ vaultToken })

  const nowMs = useNowMs()
  const nowUnix = Math.floor(nowMs / 1000)

  const awm = useMemo(() => {
    if (!currentWithdrawRequest || !fulfilledRequests) {
      return undefined
    }
    return nucleusActiveWithdrawalMemo({
      ackCompletedUnix: acknowledgeCompleted.unix,
      ackFailedUnix: acknowledgeFailed.unix,
      fulfilledRequests,
      nowUnix,
      currentWithdrawRequest,
    })
  }, [
    currentWithdrawRequest,
    fulfilledRequests,
    acknowledgeCompleted.unix,
    acknowledgeFailed.unix,
    nowUnix,
  ])

  if (!awm) {
    return undefined
  }

  return {
    ...awm,
    ackCompleted: acknowledgeCompleted.ack,
    ackFailed: acknowledgeFailed.ack,
  }
}

// localStorage keys for acknowledging completed & failed withdrawals
const BASE_NUCLEUS_ACKNOWLEDGE_WITHDRAWAL_COMPLETION_DATE_UNIX_KEY =
  'nucleus:acknowledgeWithdrawalCompletionDateUnix'
const BASE_NUCLEUS_ACKNOWLEDGE_WITHDRAWAL_FAILED_DATE_UNIX_KEY =
  'nucleus:acknowledgeWithdrawalFailedDateUnix'

export function useNucleusWithdrawLocalStorageKeys({
  vaultToken,
}: {
  vaultToken: Token
}) {
  return {
    acknowledgeWithdrawalCompletionDateUnixKey: `${BASE_NUCLEUS_ACKNOWLEDGE_WITHDRAWAL_COMPLETION_DATE_UNIX_KEY}:${vaultToken.address}`,
    acknowledgeWithdrawalFailedDateUnixKey: `${BASE_NUCLEUS_ACKNOWLEDGE_WITHDRAWAL_FAILED_DATE_UNIX_KEY}:${vaultToken.address}`,
  }
}

// generic hook for acknowledgment state/callbacks
function useAckDate(key: string) {
  const nowMs = useNowMs()

  const defaultAckDate = useMemo(() => {
    const v = localStorage.getItem(key)
    if (v) {
      const vNum = parseInt(v, 10)
      if (!isNaN(vNum)) return vNum
      return null
    }
    return null
  }, [key])
  const [ackUnix, setAckUnix] = useState<number | null>(defaultAckDate)
  useEffect(() => {
    if (ackUnix) {
      localStorage.setItem(key, `${ackUnix}`)
    }
  }, [ackUnix, key])
  const ack = () => {
    setAckUnix(Math.floor(nowMs / 1000))
  }

  return {
    ack,
    unix: ackUnix ? ackUnix : null,
  }
}

// binds to localStorage for acknowledging completed withdrawals
function useAcknowledgeCompleted({ vaultToken }: { vaultToken: Token }) {
  const { acknowledgeWithdrawalCompletionDateUnixKey } =
    useNucleusWithdrawLocalStorageKeys({ vaultToken })
  return useAckDate(acknowledgeWithdrawalCompletionDateUnixKey)
}
function useAcknowledgeFailed({ vaultToken }: { vaultToken: Token }) {
  const { acknowledgeWithdrawalFailedDateUnixKey } =
    useNucleusWithdrawLocalStorageKeys({ vaultToken })
  return useAckDate(acknowledgeWithdrawalFailedDateUnixKey)
}
