import styled from 'styled-components'
import { useTimeCountdown } from '@/hooks/useTimeCountdown'
import { DaoWavedropResult } from '@/state/dao/types'
import { useSwellUiTheme } from '@/swell-ui/theme'
import { Tooltip } from '@/swell-ui/Tooltip'
import { useSwellWeb3 } from '@/swell-web3/core'
import { formatWithConfig } from '@/util/number'
import { useMediaQuery } from '@mui/material'
import { InfoIcon } from '@/swell-ui/icons/InfoIcon'
import { DurationBreakdown } from '@/util/datetime'
import { DecoBox } from '../DecoBox'
import { FlexRow } from '@/swell-ui/FlexRow'
import { CountdownBoxClock } from '../CountdownFlipClock/CountdownBoxClock'
import blackPearlUrl from '@/assets/images/black-pearls-128x128.png'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { TOKEN_LIST_SWELL } from '@/constants/tokens'
import {
  ClaimWavedropButton,
  ConnectWalletButton,
} from '@/pages/SwellCity/buttons'
import { Divider } from '@/swell-ui/Divider'

const BLACK_PEARLS_WIP_INFO_URL =
  'https://docs.swellnetwork.io/swell-dao/faqs/swell-city-black-pearl-integration'

export function WavedropCard({
  endTimeUnix,
  wavedrop,
  ended,
  showMultiplier,
  endTimeFormatted,
  waveNumber,
  claimTooltip,
}: {
  endTimeUnix: number
  wavedrop: DaoWavedropResult | undefined
  ended: boolean
  showMultiplier?: boolean
  endTimeFormatted: string
  waveNumber: number
  claimTooltip?: string
}) {
  const { theme } = useSwellUiTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { account } = useSwellWeb3()

  const countdown = useTimeCountdown(endTimeUnix * 1000)
  const showCountdown = countdown !== null

  let currentWaveTitle = `Wave ${waveNumber}: Ends ${endTimeFormatted}`
  if (ended) {
    currentWaveTitle = `Wave ${waveNumber}: Ended ${endTimeFormatted}`
  }

  // user values
  let blackPearlsStr = '-'
  let multiplierStr = ''
  const swellBalanceStr = '-' // N/A for now
  if (account) {
    // trigger loading appearance with falsy values
    blackPearlsStr = ''

    if (wavedrop) {
      blackPearlsStr = formatWithConfig(wavedrop.blackPearls, {
        localize: true,
        precision: 0,
      })
      if (showMultiplier) {
        const multiplierNumStr = formatWithConfig(wavedrop.multiplier, {
          localize: true,
          precision: 1,
        })
        multiplierStr = `${multiplierNumStr}x`
      }
    }
  }

  // Black Pearls (1x boost)
  let blackPearlsLabelStr = 'Black Pearls'
  if (multiplierStr) {
    blackPearlsLabelStr += ` (${multiplierStr} boost)`
  }

  let widget = (
    <WidgetMobile
      waveNumber={waveNumber}
      showCountdown={showCountdown}
      countdown={countdown}
      blackPearlsStr={blackPearlsStr}
      swellBalanceStr={swellBalanceStr}
      account={account}
      blackPearlsLabelStr={blackPearlsLabelStr}
      currentWaveTitle={currentWaveTitle}
      claimTooltip={claimTooltip}
    />
  )
  if (isMdUp) {
    widget = (
      <WidgetDesktop
        claimTooltip={claimTooltip}
        waveNumber={waveNumber}
        showCountdown={showCountdown}
        countdown={countdown}
        blackPearlsStr={blackPearlsStr}
        swellBalanceStr={swellBalanceStr}
        account={account}
        blackPearlsLabelStr={blackPearlsLabelStr}
        currentWaveTitle={currentWaveTitle}
      />
    )
  }

  return widget
}

function BlackPearlsWIPTooltip() {
  return (
    <Tooltip
      title={
        <>
          Not all positions earning Black Pearls are being displayed yet, please{' '}
          <a
            href={BLACK_PEARLS_WIP_INFO_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            check here
          </a>
          .
        </>
      }
      placement="top"
      offset={[0, -10]}
      arrow
    >
      <InfoIcon />
    </Tooltip>
  )
}

function WidgetDesktop({
  account,
  blackPearlsLabelStr,
  blackPearlsStr,
  claimTooltip,
  countdown,
  showCountdown,
  swellBalanceStr,
  currentWaveTitle,
  waveNumber,
}: {
  showCountdown: boolean
  countdown: DurationBreakdown | undefined
  blackPearlsStr: string
  claimTooltip: string | undefined
  swellBalanceStr: string
  account: string | undefined
  blackPearlsLabelStr: string
  currentWaveTitle: string
  waveNumber: number
}) {
  return (
    <DecoBox padding="42px !important" direction="row" gap="48">
      <FlexRow
        direction="column"
        gap="24"
        justify="center"
        align="start"
        width="433px"
        style={{
          alignSelf: 'stretch',
          visibility: showCountdown ? 'visible' : 'visible',
          flexShrink: 0,
        }}
      >
        <span className="swc-label-1">{currentWaveTitle}</span>
        {countdown ? (
          <CountdownBoxClock countdown={countdown} />
        ) : (
          <CountdownBoxClock
            countdown={{
              days: 0,
              hours: 0,
              minutes: 0,
              seconds: 0,
            }}
          />
        )}
      </FlexRow>
      <VDivider />
      <FlexRow
        direction="column"
        justify="space-between"
        align="center"
        gap="12"
      >
        <span className="swc-label-colored info-icon">
          {blackPearlsLabelStr} <BlackPearlsWIPTooltip />
        </span>
        <FlexRow gap="12" width="auto">
          <img src={blackPearlUrl} width={32} height={32} />
          <AsyncDiv loading={!blackPearlsStr}>
            {() => <span className="swc-med-value">{blackPearlsStr}</span>}
          </AsyncDiv>
        </FlexRow>
        <span className="swc-label-colored">Wavedrop {waveNumber}</span>
        <FlexRow gap="12" width="auto">
          <img src={TOKEN_LIST_SWELL.logoURI} width={32} height={32} />
          <AsyncDiv loading={!swellBalanceStr}>
            {() => <span className="swc-med-value">{swellBalanceStr}</span>}
          </AsyncDiv>
        </FlexRow>
        <FlexRow width="100%" padding="12px 0 0">
          {account ? (
            <ClaimWavedropButton tooltip={claimTooltip} />
          ) : (
            <ConnectWalletButton />
          )}
        </FlexRow>
      </FlexRow>
    </DecoBox>
  )
}

function WidgetMobile({
  account,
  blackPearlsLabelStr,
  blackPearlsStr,
  claimTooltip,
  countdown,
  showCountdown,
  swellBalanceStr,
  currentWaveTitle,
  waveNumber,
}: {
  showCountdown: boolean
  countdown: DurationBreakdown | undefined
  blackPearlsStr: string
  swellBalanceStr: string
  account: string | undefined
  blackPearlsLabelStr: string
  currentWaveTitle: string
  waveNumber: number
  claimTooltip?: string
}) {
  return (
    <DecoBox gap="24">
      <FlexRow
        direction="column"
        style={{
          visibility: showCountdown ? 'visible' : 'visible',
        }}
        gap="24"
      >
        <span className="swc-label-1">{currentWaveTitle}</span>
        {countdown ? (
          <CountdownBoxClock countdown={countdown} compact />
        ) : (
          <CountdownBoxClock
            countdown={{
              days: 0,
              hours: 0,
              minutes: 0,
              seconds: 0,
            }}
            compact
          />
        )}
      </FlexRow>
      <Divider />
      <FlexRow
        direction="column"
        justify="space-between"
        align="center"
        gap="12"
      >
        <span className="swc-label-colored info-icon">
          {blackPearlsLabelStr} <BlackPearlsWIPTooltip />
        </span>
        <FlexRow gap="12" width="auto">
          <img src={blackPearlUrl} width={32} height={32} />
          <AsyncDiv loading={!blackPearlsStr}>
            {() => <span className="swc-med-value">{blackPearlsStr}</span>}
          </AsyncDiv>
        </FlexRow>
        <span className="swc-label-colored">Wavedrop {waveNumber}</span>
        <FlexRow gap="12" width="auto">
          <img src={TOKEN_LIST_SWELL.logoURI} width={32} height={32} />
          <AsyncDiv loading={!swellBalanceStr}>
            {() => <span className="swc-med-value">{swellBalanceStr}</span>}
          </AsyncDiv>
        </FlexRow>
        <FlexRow width="100%" padding="12px 0 0">
          {account ? (
            <ClaimWavedropButton tooltip={claimTooltip} />
          ) : (
            <ConnectWalletButton />
          )}
        </FlexRow>
      </FlexRow>
    </DecoBox>
  )
}

const VDivider = styled.div`
  width: 1px;
  align-self: stretch;
  background: rgba(255, 255, 255, 0.2);
`
