import { createContext, useContext } from 'react'
import { IPricesApi } from './types'

const MISSING_PROVIDER = Symbol()

export type PricesContext = IPricesApi
export const PricesContext = createContext<
  PricesContext | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

export function usePricesApi() {
  const connectors = useContext(PricesContext)
  if (connectors === MISSING_PROVIDER) {
    throw new Error('PricesContext hooks must be wrapped in a <PricesProvider>')
  }
  return connectors
}
