import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import { usePricesSettings } from '../deployments/hooks/pricesSettings'
import { PricesContext } from './context'
import { useDeploymentSetConfig } from '../deployments/hooks'
import { useMulticallContract } from '@/hooks/useContract'
import { fetchPrices, fetchPrimaryRates } from './fetchers'

export function usePricesApiImpl(): PricesContext {
  const { pricesBaseURL } = usePricesSettings()
  const statsClient = useV3BackendClient(pricesBaseURL).v3BackendClient.stats

  const {
    addresses: { swETH, rswETH },
  } = useDeploymentSetConfig()
  const mc = useMulticallContract()!

  return {
    prices: async () => {
      return fetchPrices({ statsClient })
    },
    primaryRates: async () => {
      return fetchPrimaryRates({
        swETHAddress: swETH,
        rswETHAddress: rswETH,
        multicall: mc,
      })
    },
  }
}
