export function parseJsError(
  error: unknown
): [boolean, { message: string } & { [key: string]: any }] {
  if (error instanceof Error || typeof (error as any)?.message === 'string') {
    return [true, error as any]
  }

  if (typeof error === 'string') {
    return [true, new Error(error)]
  }

  return [false, new Error('Unknown error')]
}
